import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import { vars } from "styles";

import * as Styled from "./BaseDialog.styles";

type Props = {
  initialOpen?: boolean;
  useClickInteraction?: boolean;
};

export function useBaseDialog({ initialOpen = false, useClickInteraction = true }: Props) {
  const [open, setOpen] = useState(initialOpen);

  const useFloatingProps = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: internalOpenChanged,
  });

  function internalOpenChanged(open: boolean) {
    setOpen(open);
    !open && useFloatingProps.context.events.emit("didClose");
  }

  useEffect(() => {
    const handlePopState = () => {
      setOpen(false);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const dismiss = useDismiss(useFloatingProps.context); // This accepts various dismissal options as second argument
  const click = useClick(useFloatingProps.context); // Buttons don't have to set the state manually

  const { getFloatingProps } = useInteractions([dismiss, useClickInteraction ? click : undefined]);

  return {
    context: useFloatingProps.context,
    floating: useFloatingProps.floating,
    getFloatingProps,
    open,
    setOpen,
  };
}

export function BaseDialog({
  size,
  transparent,
  fullHeight,
  noBackground,
  noPadding,
  isBottom,
  ...baseDialogProps
}: ReturnType<typeof useBaseDialog> & {
  children: ReactNode;
  size?: "small" | "normal" | "wide";
  transparent?: boolean;
  noBackground?: boolean;
  fullHeight?: boolean;
  noPadding?: boolean;
  isBottom?: boolean;
}) {
  const { open, context, floating, getFloatingProps, children } = baseDialogProps;

  const dialogWidth = () => {
    switch (size ?? "small") {
      case "wide":
        return "90rem";
      case "normal":
        return "66rem";
      case "small":
        return "46rem";
    }
  };

  const MotionFloatingOverlay = useMemo(() => motion(FloatingOverlay), []);

  return (
    <>
      <AnimatePresence>
        {open && (
          <FloatingPortal>
            <MotionFloatingOverlay
              lockScroll
              style={{
                background: "rgba(0, 0, 0, 0.8)",
                zIndex: vars.zIndex.modalOverlay,
                display: "flex",
                alignItems: isBottom ? "bottom" : "center",
                justifyContent: "center",
              }}
              variants={Styled.modalVariant}
              initial={"initial"}
              animate={"isOpen"}
              exit={"exit"}
            >
              <FloatingFocusManager context={context}>
                <Styled.DialogContentWrap
                  ref={floating}
                  {...getFloatingProps()}
                  $dialogWidth={dialogWidth()}
                  $transparent={transparent}
                  $noBackground={noBackground}
                  $fullHeight={fullHeight}
                  variants={Styled.containerVariant}
                  initial={"initial"}
                  animate={"isOpen"}
                  exit={"exit"}
                  $noPadding={noPadding}
                >
                  <BaseDialogContextProvider useBaseDialogProps={baseDialogProps}>{children}</BaseDialogContextProvider>
                </Styled.DialogContentWrap>
              </FloatingFocusManager>
            </MotionFloatingOverlay>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
}

const BaseDialogContext = createContext<ReturnType<typeof useBaseDialog>>({} as ReturnType<typeof useBaseDialog>);

function BaseDialogContextProvider({
  children,
  useBaseDialogProps,
}: {
  children: ReactNode;
  useBaseDialogProps: ReturnType<typeof useBaseDialog>;
}) {
  return <BaseDialogContext.Provider value={useBaseDialogProps}>{children}</BaseDialogContext.Provider>;
}

/// Nested children can use the values without drilling.
export function useBaseDialogContext() {
  return useContext(BaseDialogContext);
}
