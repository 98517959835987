import { Socket } from "socket.io-client";

import { App, BasicWorkspace, Workspace } from "models";
import { ChangelogNotification } from "models/Changelog.model";
import { Member } from "models/Member.model";
import { ServerToClientEvents } from "models/webSocketEvents";

export interface AppState {
  workspaces: Workspace[];
  joinableWorkspaces: BasicWorkspace[];
  wsTeamMembers?: Member[];
  apps: App[];
  socket?: Socket<ServerToClientEvents, never>;
  loading: boolean;
  workspacesLoading: boolean;
  updatingSelectedWorkspace: boolean;
  unreadChangeLogNotifications: ChangelogNotification[];
}

export enum AppContextActionType {
  SET_WORKSPACES = "SET_WORKSPACES",
  SET_APPS = "SET_APPS",
  SET_SOCKET_CONNECTION = "SET_SOCKET_CONNECTION",
  SET_LOADING = "SET_LOADING",
  SET_WS_TEAM_MEMBERS = "SET_WS_TEAM_MEMBERS",
  SET_UPDATING_WORKSPACE = "SET_UPDATING_WORKSPACE",
  SET_JOINABLE_WORKSPACES = "SET_JOINABLE_WORKSPACES",
  SET_WORKSPACES_LOADING = "SET_WORKSPACES_LOADING",
  RESET_CONTEXT = "RESET_CONTEXT",
  SET_UNREAD_CHANGE_LOG_NOTIFICATIONS = "SET_UNREAD_CHANGE_LOG_NOTIFICATIONS",
}

export interface SetWorkspaces {
  type: AppContextActionType.SET_WORKSPACES;
  payload: Workspace[];
}

export interface SetWSTeamMembers {
  type: AppContextActionType.SET_WS_TEAM_MEMBERS;
  payload?: Member[];
}

export interface SetApps {
  type: AppContextActionType.SET_APPS;
  payload: App[];
}

export interface SetSocketConnection {
  type: AppContextActionType.SET_SOCKET_CONNECTION;
  payload?: Socket;
}

export interface SetLoading {
  type: AppContextActionType.SET_LOADING;
  payload: boolean;
}

export interface SetUpdatingWorkspace {
  type: AppContextActionType.SET_UPDATING_WORKSPACE;
  payload: boolean;
}

export interface SetJoinableWorkspaces {
  type: AppContextActionType.SET_JOINABLE_WORKSPACES;
  payload: BasicWorkspace[];
}

export interface SetWorkspacesLoading {
  type: AppContextActionType.SET_WORKSPACES_LOADING;
  payload: boolean;
}

export interface SetUnreadChangeLogNotifications {
  type: AppContextActionType.SET_UNREAD_CHANGE_LOG_NOTIFICATIONS;
  payload: ChangelogNotification[];
}

export type AppContextAction =
  | SetWorkspaces
  | SetApps
  | SetSocketConnection
  | SetLoading
  | SetUpdatingWorkspace
  | SetJoinableWorkspaces
  | SetWorkspacesLoading
  | SetWSTeamMembers
  | SetUnreadChangeLogNotifications;
