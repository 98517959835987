import React from "react";

import UserDetailsView from "pages/Users/views/UserDetailsView";

import dependencyInjection from "util/dependencyInjection";

import { UsersService } from "./services/Users.service";
import UsersPageView from "./views/UsersPageView";

interface Services {
  usersService: UsersService;
}

const usersService = new UsersService();

const [UsersDependencyProvider, useUsersDeps] = dependencyInjection<Services>({
  services: {
    usersService,
  },
});

export function UsersPage() {
  return (
    <UsersDependencyProvider>
      <UsersPageView />
    </UsersDependencyProvider>
  );
}

export function UsersDetailPage() {
  return (
    <UsersDependencyProvider>
      <UserDetailsView />
    </UsersDependencyProvider>
  );
}

export { useUsersDeps };
