import React from "react";

/* import { generatePath, Navigate } from "react-router-dom";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { MemberRole } from "models/Member.model";

import { RoutePaths } from "router/config/routePaths"; */

interface Props {
  children: JSX.Element;
}

// maybe this will be needed later
export default function RoleProtected({ children }: Props) {
  /*   const { selectedWorkspace } = useAppSelectionContext();

  const location = window.location.pathname; */

  /* const isProtected =
    location === generatePath(RoutePaths.ADMINISTRATION_BILLING, { workspaceSlug: selectedWorkspace?.slug }); */

  /* if (selectedWorkspace?.role !== MemberRole.ADMIN && isProtected) {
    return (
      <Navigate to={generatePath(RoutePaths.ADMINISTRATION_GENERAL, { workspaceSlug: selectedWorkspace?.slug })} />
    );
  } */

  return <>{children}</>;
}
