import React, { useEffect, useMemo, useState } from "react";

import Card from "components/Card/Card";
import Heading from "components/Heading/Heading";
import { useModal } from "components/Modal/Modal";

import { useAppContext } from "context/App/App.context";

import AppNotificationSettings from "pages/Account/components/NotifyMeAboutCard/AppNotificationSettings";
import GeneralNotificationSettings from "pages/Account/components/NotifyMeAboutCard/GeneralNotificationSettings";

import { getActiveApps } from "util/appsUtil";
import identifiers from "util/identifiers.json";

export default function NotifyMeAboutCard() {
  const {
    Modal: NotifyMeAboutModal,
    toggle,
    modalProps,
  } = useModal({
    size: "narrow",
  });
  const { apps } = useAppContext();
  const activeApps = useMemo(() => getActiveApps(apps), [apps]);

  const [selectedAppsNumber, setSelectedAppsNumber] = useState<number>(0);

  useEffect(() => {
    const count = activeApps.filter((app) => app.follow_app).length;
    setSelectedAppsNumber(count);
  }, [setSelectedAppsNumber, activeApps]);

  return (
    <>
      <Card>
        <Heading
          as="h3"
          heading3
          marginBottom={24}
          testId={identifiers["notifications.notify.header"]}
        >
          Notify me about
        </Heading>

        <GeneralNotificationSettings
          toggleModal={toggle}
          selectedAppsNumber={selectedAppsNumber}
        />
      </Card>

      <AppNotificationSettings
        toggleModal={toggle}
        setSelectedAppsNumber={setSelectedAppsNumber}
        Modal={NotifyMeAboutModal}
        internalProps={modalProps}
      />
    </>
  );
}
