import React from "react";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "router/config/routePaths";
import RedirectWithLoading from "router/redirect/RedirectWithLoading";

export default function AppsRedirect(): JSX.Element {
  const { selectedWorkspace } = useAppSelectionContext();
  const { apps } = useAppContext();

  const dynamicValues = {
    workspaceSlug: selectedWorkspace?.slug,
    appKey: apps[0]?.key,
  };

  return (
    <RedirectWithLoading
      path={RoutePaths.ADMINISTRATION_APPS_GENERAL}
      dynamicValues={dynamicValues}
    />
  );
}
