import { useCallback, useEffect, useState } from "react";

const MAX_ALLOWED_STRING_LENGHT = 5000;

export function useChunkedString(full: string) {
  const [state, setState] = useState(calculateInitialState(full));

  const updateChunk = useCallback(() => {
    if (!state) return;
    const newDisplay = state.display + full.substring(state.index, state.index + MAX_ALLOWED_STRING_LENGHT);
    setState({
      display: newDisplay,
      hasMore: newDisplay.length < full.length,
      index: state.index + MAX_ALLOWED_STRING_LENGHT,
    });
  }, [state, full]);

  useEffect(() => {
    setState(calculateInitialState(full));
  }, [full]);

  return {
    display: state?.display,
    loadMore: updateChunk,
    hasMore: state?.hasMore,
  };
}

function calculateInitialState(full: string) {
  const tooBig = full.length > MAX_ALLOWED_STRING_LENGHT;
  return {
    display: tooBig ? full.substring(0, MAX_ALLOWED_STRING_LENGHT) : full,
    hasMore: tooBig,
    index: MAX_ALLOWED_STRING_LENGHT,
  };
}
