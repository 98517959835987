export const removeAttributesFromLocalStorage = () => {
  // User feedback
  localStorage.removeItem("shakebugs.feedback_attributes");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith("shakebugs.feedback_attributes")) {
      localStorage.removeItem(key);
    }
  }

  // Crash reporting
  localStorage.removeItem("shakebugs.crashes_attributes");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith("shakebugs.crashes_attributes")) {
      localStorage.removeItem(key);
    }
  }

  // App users
  localStorage.removeItem("shakebugs.user_attributes");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith("shakebugs.user_attributes")) {
      localStorage.removeItem(key);
    }
  }

  localStorage.removeItem("shakebugs.log_attributes");
};
