import React, { Fragment, PropsWithChildren } from "react";

import { HelperText } from "components/HelperText/HelperText";
import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

import * as Styled from "./AnalyticsCard.styles";

export interface AnalyticsCardProps {
  title: string;
  helperText?: string;
}

export default function AnalyticsCard({ title, helperText, children }: PropsWithChildren<AnalyticsCardProps>) {
  return (
    <Styled.AnalyticsCard>
      <Paragraph
        fontSize={20}
        fontWeight="bold"
        color={vars.colors.grey30}
      >
        {title}
      </Paragraph>

      <Fragment>
        <Styled.Container $noPaddingBottom={!helperText}>{children}</Styled.Container>
        {helperText && <HelperText>{helperText}</HelperText>}
      </Fragment>
    </Styled.AnalyticsCard>
  );
}
