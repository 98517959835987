import styled from "styled-components";

import { Form, FormLinks } from "pages/shared/styles/Form.styles";

import { vars } from "styles";

export const CheckInboxForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 0rem;
  padding: 0;

  button {
    padding: 0rem;
    a {
      padding: 1rem 2rem 1rem 1.2rem;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover,
      &:active,
      &:link {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: ${vars.colors.grey100};
      text-decoration: none;
    }

    &:last-child {
      img {
        margin: 0 1rem;
      }
    }
  }
`;

export const CheckInboxLinks = styled(FormLinks)`
  margin-top: 6rem;
`;
