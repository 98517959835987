import styled from "styled-components";

import { Paragraph } from "components/Paragraph/Paragraph.styles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.2rem;
`;

export const LeftContainer = styled.div`
  margin-right: 1rem;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StepNumberContainer = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 2.4rem;
`;

export const StepNumberText = styled(Paragraph)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #00cfbe;
  font-size: 12px;
`;
