import { RefObject, useEffect, useRef, useState } from "react";
import React, { ReactNode } from "react";

import { AnimatePresence } from "framer-motion";

import { ButtonProps } from "components/Button/Button";

import useOnClickOutside from "hooks/useOnClickOutside";
import { useEscapeKey } from "hooks/useOnEscapeModal";

import * as Styled from "./Modal.styles";
import Card from "../Card/Card";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { ModalHeader, ModalHeaderProps } from "./ModalHeader/ModalHeader";

export interface ModalProps {
  active?: boolean;
  setActive?: (active: boolean) => void;
  size?: "wide" | "narrow" | "full" | "fuller";
  withTabs?: boolean;
  buttonElement?: JSX.Element;
  button?: ButtonProps;
  className?: string;
  children?: ReactNode;
  scrollHeight?: number;
  hideFooter?: boolean;
  noAnimation?: boolean;
  leftButtonElement?: JSX.Element;
}

const Modal = React.forwardRef((props: ModalProps & ModalHeaderProps, ref) => {
  const {
    children,
    size,
    withTabs,
    button,
    buttonElement,
    active,
    setActive,
    className,
    hideFooter,
    noAnimation,
    leftButtonElement,
    ...headerProps
  } = props;

  return (
    <AnimatePresence>
      {active && (
        <Styled.ModalOverlay
          active={active}
          onClick={button?.onClick}
          variants={!noAnimation ? Styled.ModalVariant : undefined}
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
        >
          <Styled.Modal
            className="modal"
            $active={active}
            $size={size}
            $withTabs={withTabs}
            variants={!noAnimation ? Styled.ContainerVariant : undefined}
          >
            <Styled.ModalInner
              ref={ref as RefObject<HTMLDivElement>}
              className={`modal-inner ${className}`}
            >
              <Card scrollHeight={props.scrollHeight}>
                <ModalHeader
                  {...headerProps}
                  hasBorderBottom={children ? true : false}
                />
                <Styled.ModalScroll>{children}</Styled.ModalScroll>

                {!hideFooter && (
                  <ModalFooter
                    leftButton={leftButtonElement}
                    button={buttonElement}
                    toggle={() => {
                      setActive?.(false);
                    }}
                  />
                )}
              </Card>
            </Styled.ModalInner>
          </Styled.Modal>
        </Styled.ModalOverlay>
      )}
    </AnimatePresence>
  );
});

export function useModal({ size, active: activeProp = false }: Pick<ModalProps, "size" | "active">) {
  const [active, setActive] = useState(activeProp);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const clickOutSideHandlerRef = useRef(() => {
    setActive(false);
  });

  const toggleRef = useRef(() => {
    setActive((currentActive) => !currentActive);
  });

  useOnClickOutside(modalRef, clickOutSideHandlerRef.current);
  useEscapeKey(setActive);

  useEffect(() => {
    const handlePopState = () => {
      setActive(false);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return {
    modalProps: {
      active,
      size,
      ref: modalRef,
      setActive,
    },
    Modal,
    toggle: toggleRef.current,
    active,
    setActive,
  };
}

Modal.displayName = "Modal";
export default Modal;
