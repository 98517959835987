import React from "react";

import Heading from "components/Heading/Heading";
import { Paragraph } from "components/Paragraph/Paragraph.styles";

import * as Styled from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox.styles";

export interface Props {
  title: string;
  subtitle: string;
}

export const ModuleDescriptionBox = ({ title, subtitle }: Props) => {
  return (
    <Styled.Container>
      <Heading marginBottom={10}>{title}</Heading>
      <Paragraph
        $lineHeight={28}
        $fontSize={20}
      >
        {subtitle}
      </Paragraph>
    </Styled.Container>
  );
};

export const FeedbackModuleDescriptionBox = () => {
  return (
    <ModuleDescriptionBox
      title={"User feedback"}
      subtitle={
        "Whether your testers are sending you bug reports or your production users are suggesting app improvements, gain instant insights into the root causes of bugs or users' input, enabling informed decisions and efficient debugging."
      }
    />
  );
};

export const CrashesModuleDescriptionBox = () => {
  return (
    <ModuleDescriptionBox
      title={"Crash reports"}
      subtitle={
        "Track crashes and uncaught errors in your app — with a twist. Know everything about the device, user and what they were tapping in minutes leading up to the crash."
      }
    />
  );
};

export const UsersModuleDescriptionBox = () => {
  return (
    <ModuleDescriptionBox
      title={"App users"}
      subtitle={
        "All your users and their info in one list, plus live chat. In parallel, your users can see their previously submitted tickets."
      }
    />
  );
};

export const AnalyticsModuleDescriptionBox = () => {
  return (
    <ModuleDescriptionBox
      title={"Stats"}
      subtitle={
        "Gain valuable insights into your application's performance, user behavior, and feedback. Make informed decisions, improve user experience, and enhance the overall quality of your app."
      }
    />
  );
};
