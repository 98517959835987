import React, { useMemo } from "react";

import Heading from "components/Heading/Heading";
import { ToggleSection } from "components/ToggleSection/ToggleSection";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Workspace } from "models";
import { MemberRole } from "models/Member.model";

import * as Styled from "pages/Account/components/NotifyMeAboutCard/GeneralNotificationSettings.styles";
import useNotificationSettingsApiConsumer from "pages/Account/consumers/api/useNotificationSettingsApiConsumer";

import { getActiveApps } from "util/appsUtil";
import identifiers from "util/identifiers.json";

interface Props {
  toggleModal: () => void;
  selectedAppsNumber: number;
}

export default function GeneralNotificationSettings({ toggleModal, selectedAppsNumber }: Props) {
  const { apps } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const activeApps = useMemo(() => getActiveApps(apps), [apps]);
  const { updateUserNotificationSettings, loading } = useNotificationSettingsApiConsumer();

  const handleSelectAllButtonClick = () => {
    if (selectedWorkspace?.notify_new_bug && selectedWorkspace?.notify_new_chat_message) {
      return updateUserNotificationSettings({ notify_new_bug: false, notify_new_chat_message: false });
    }

    updateUserNotificationSettings({ notify_new_bug: true, notify_new_chat_message: true });
  };

  const toggleNewAppNotificationSettings = () => {
    return updateUserNotificationSettings({ notify_new_app: !selectedWorkspace?.notify_new_app });
  };

  const toggleUserFeedbackNotificationSettings = () => {
    return updateUserNotificationSettings({ notify_new_bug: !selectedWorkspace?.notify_new_bug });
  };

  const toggleChatMessageNotificationSettings = () => {
    return updateUserNotificationSettings({ notify_new_chat_message: !selectedWorkspace?.notify_new_chat_message });
  };

  const toggleUnreadActivityNotificationSettings = () => {
    updateUserNotificationSettings({
      notify_unread_activity: !selectedWorkspace?.notify_unread_activity,
    });
  };

  const toggleDesktopNotificationSettings = () => {
    updateUserNotificationSettings({ notify_desktop: !selectedWorkspace?.notify_desktop });
  };

  return (
    <>
      {selectedWorkspace?.role !== MemberRole.GUEST && (
        <Styled.Option
          label="New app is added to or archived from this workspace"
          disabled={loading}
          checked={selectedWorkspace?.notify_new_app}
          handleCheck={toggleNewAppNotificationSettings}
        />
      )}
      <Styled.LabelContentContainer>
        {getSelectAllButton(loading, handleSelectAllButtonClick, selectedWorkspace)}

        <Styled.AppSettingsButton
          onClick={toggleModal}
          disabled={!activeApps.length || loading}
          testId={identifiers["notifications.notify.button.apps"]}
          tooltip={{
            position: "top",
            text: "There are no active apps to choose from",
            disabled: Boolean(activeApps.length),
          }}
          $disabled={!activeApps.length || loading}
          color="grey"
        >
          {selectedAppsNumber} {selectedAppsNumber === 1 ? "app" : "apps"}
        </Styled.AppSettingsButton>
      </Styled.LabelContentContainer>

      <Styled.SettingsContainer>
        <Styled.Option
          label="New user feedback is created"
          disabled={loading}
          checked={selectedWorkspace?.notify_new_bug}
          handleCheck={toggleUserFeedbackNotificationSettings}
          testId={identifiers["notifications.notify.checkbox.feedback"]}
        />
        <Styled.Option
          label="New note or comment"
          disabled={loading}
          checked={selectedWorkspace?.notify_new_chat_message}
          handleCheck={toggleChatMessageNotificationSettings}
          testId={identifiers["notifications.notify.checkbox.note"]}
        />
      </Styled.SettingsContainer>

      <div style={{ marginTop: "3.2rem", marginBottom: "0.8rem" }}>
        <Heading
          as="h4"
          heading4
          marginBottom={24}
          testId={identifiers["notifications.how.header"]}
          fontWeight="bold"
        >
          How to notify you
        </Heading>

        <Styled.HowToNotifyYouCardContent>
          <ToggleSection
            title={unreadActivityToggleText.title}
            subtitle={unreadActivityToggleText.subtitle}
            disabled={loading}
            checked={!!selectedWorkspace?.notify_unread_activity}
            onChange={toggleUnreadActivityNotificationSettings}
            testId={identifiers["notifications.how.toggle.email"]}
          />

          <ToggleSection
            title={desktopNotificationsToggleText.title}
            subtitle={desktopNotificationsToggleText.subtitle}
            disabled={loading}
            checked={!!selectedWorkspace?.notify_desktop}
            onChange={toggleDesktopNotificationSettings}
            testId={identifiers["notifications.how.toggle.desktop"]}
          />
        </Styled.HowToNotifyYouCardContent>
      </div>
    </>
  );
}

const unreadActivityToggleText = {
  title: "Email",
  subtitle:
    "If I'm not on the Dashboard to immediately see my notifications, send me an email with them. Shake checks for unread activity every 15 minutes.",
};

const desktopNotificationsToggleText = {
  title: "Desktop notifications",
  subtitle: "If my Shake tab is not focused at that moment, send me a desktop notification",
};

const getSelectAllButton = (
  loading: boolean,
  handleCheck: () => Promise<void> | undefined,
  selectedWorkspace?: Workspace,
) => {
  if (!selectedWorkspace) return;

  const isCheckboxPartial =
    (!selectedWorkspace?.notify_new_bug && selectedWorkspace?.notify_new_chat_message) ||
    (selectedWorkspace?.notify_new_bug && !selectedWorkspace?.notify_new_chat_message);

  const bothChecked = selectedWorkspace?.notify_new_bug && selectedWorkspace?.notify_new_chat_message;

  if (isCheckboxPartial) {
    return (
      <Styled.SelectAllButton
        onClick={handleCheck}
        $alignItems="center"
      >
        <Styled.SelectAllIcon
          disabled={loading}
          testId={identifiers["notifications.notify.checkbox.all"]}
        >
          -
        </Styled.SelectAllIcon>
        <label> User Feedback </label>
      </Styled.SelectAllButton>
    );
  }
  return (
    <Styled.Option
      label="User Feedback"
      disabled={loading}
      checked={bothChecked}
      handleCheck={handleCheck}
      testId={identifiers["notifications.notify.checkbox.all"]}
    />
  );
};
