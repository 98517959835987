import React from "react";

import { generatePath, Link } from "react-router-dom";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import Input from "components/Input/Input";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import { useAuthContext } from "context/Auth/Auth.context";

import usePersonalInfoChangePasswordFormConsumer from "pages/Account/consumers/form/usePersonalInfoChangePasswordFormConsumer";
import { EmailPrefillState } from "pages/ForgotPassword/consumers/useForgotPasswordFormConsumer";

import { RoutePaths } from "router/config/routePaths";

import identifiers from "util/identifiers.json";

import * as Styled from "./Form.styles";

interface Props {
  onSubmitCallback?: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function ChangePasswordForm({ onSubmitCallback, Modal, internalProps }: Props) {
  const { userData } = useAuthContext();
  const { formState, formError, handleFormChange, handleFormSubmit, loading, isErrorOnSubmit } =
    usePersonalInfoChangePasswordFormConsumer({ onSubmitCallback });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !formState.currentPassword || !formState.newPassword;

  return (
    <Modal
      {...internalProps}
      testId={
        userData?.hasPassword
          ? identifiers["info.security.modal.header.change"]
          : identifiers["info.security.modal.header.set"]
      }
      heading={userData?.hasPassword ? "Change password" : "Set account password"}
      buttonElement={
        <Button
          size="small"
          type="submit"
          testId={identifiers["info.security.modal.button.save"]}
          disabled={isButtonDisabled || loading}
          tooltip={{
            position: "top",
            text: "Please enter current and new password first",
            disabled: !isButtonDisabled,
          }}
          loadingText={loading && "Saving..."}
          onClick={handleFormSubmit}
        >
          Save password
        </Button>
      }
    >
      <Styled.FormContainer>
        <Styled.Form
          onSubmit={handleFormSubmit}
          noValidate
        >
          <Input
            disabled={loading}
            name="currentPassword"
            label="Current password"
            type="password"
            helper={renderCurrentPasswordHelperMessage(formError.currentPassword, isErrorOnSubmit, userData?.email)}
            error={!!formError.currentPassword}
            value={formState.currentPassword}
            onChange={handleChange}
            testId={identifiers["info.security.modal.input.current"]}
            autoFocus
          />
          <Input
            disabled={loading}
            name="newPassword"
            label="New password"
            type="password"
            helper={formError.newPassword?.message}
            error={!!formError.newPassword}
            value={formState.newPassword}
            onChange={handleChange}
            testId={identifiers["info.security.modal.input.new"]}
          />
        </Styled.Form>

        <HelperText>
          Have you forgot your current password altogether? It happens to everyone,{" "}
          <Link
            to={generatePath(RoutePaths.FORGOT_PASSWORD)}
            state={{ prefillEmail: userData?.email } as EmailPrefillState}
            data-testid={identifiers["info.security.modal.link"]}
          >
            let’s set a new one
          </Link>
          .
        </HelperText>
      </Styled.FormContainer>
    </Modal>
  );
}

const renderCurrentPasswordHelperMessage = (
  wrongPasswordError?: Error,
  isErrorOnSubmit?: boolean,
  prefillEmail?: string,
) => {
  if (!wrongPasswordError) return;

  if (isErrorOnSubmit) {
    return (
      <>
        {wrongPasswordError.message} Please try again or&nbsp;
        <Link
          to={generatePath(RoutePaths.FORGOT_PASSWORD)}
          state={{ prefillEmail } as EmailPrefillState}
        >
          request a new one.
        </Link>
      </>
    );
  }

  return wrongPasswordError.message;
};
