import styled from "styled-components";

import { vars } from "styles";

export const TicketContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const TicketDetailsPane = styled.div`
  width: 48rem;
  margin: 1.6rem 1rem;
`;

export const DetailsPaneWrap = styled.div`
  height: 100%;
  border-radius: 2rem;
  background-color: ${vars.colors.grey100};
  padding: 3.2rem 0.8rem 3.2rem 2.4rem;
  cursor: default;
`;

export const TicketInfoWrap = styled.div`
  flex: 1;
  padding: 0 1rem;
`;
