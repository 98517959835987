import { useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { ApprovedDomain } from "models/organization";

import { useAdministrationDeps } from "../Administration";

export default function useDomainVerificationApiConsumer() {
  const location = useLocation();

  const code = new URLSearchParams(location.search).get("code");

  const { administrationService } = useAdministrationDeps();

  const [approvedDomain, setApprovedDomain] = useState<ApprovedDomain>();

  const activateApprovedDomain = useCallback(async () => {
    if (!code) return;
    try {
      const { data } = await administrationService.activateApprovedDomain(code);
      setApprovedDomain(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  }, [code, administrationService]);

  useEffect(() => {
    activateApprovedDomain();
  }, [activateApprovedDomain]);

  return {
    approvedDomain,
  };
}
