export const getRandomColor = (userId?: string) => {
  const lastHex = userId ? userId.slice(-1) : "a";

  const lastHexConvertedToInt = parseInt(lastHex, 16);
  if (!lastHexConvertedToInt) return availableBackgroundColors[0];

  return availableBackgroundColors[lastHexConvertedToInt];
};

const availableBackgroundColors = [
  { background: "#FF624D", text: "#FFFFFF" },
  { background: "#663F69", text: "#C49FCA" },
  { background: "#20462C", text: "#7CD49B" },
  { background: "#FFE48D", text: "#C27812" },
  { background: "#143B83", text: "#DCE8FF" },
  { background: "#2D6ADF", text: "#FEFDFA" },
  { background: "#A280FF", text: "#ECE6FF" },
  { background: "#AEC8C0", text: "#18212E" },
  { background: "#FF9471", text: "#902700" },
  { background: "#4C445B", text: "#AEAEAE" },
  { background: "#EA53C3", text: "#FBD3FF" },
  { background: "#D82F5A", text: "#FFD8E1" },
  { background: "#71C1B2", text: "#147559" },
  { background: "#FF9900", text: "#BA4300" },
  { background: "#15976B", text: "#E7F5F0" },
  { background: "#48A5F2", text: "#143B83" },
];
