import { RoutePaths } from "router/config/routePaths";

export const resolveCurrentDomainRoute = (pathname: string) => {
  if (pathname.includes("user-feedback")) {
    return RoutePaths.USER_FEEDBACK;
  }
  if (pathname.includes("crash-reports")) {
    return RoutePaths.CRASH_REPORTS;
  }
  if (pathname.includes("users")) {
    return RoutePaths.USERS;
  }
  if (pathname.includes("stats")) {
    return RoutePaths.STATS;
  }
};
