import React from "react";

import GoogleIcon from "assets/icons/google_icon.svg";

import Button from "components/Button/Button";
import { CenteredForm } from "components/CenteredForm/CenteredForm";
import CenteredMessage from "components/CenteredMessage/CenteredMessage";
import Input from "components/Input/Input";

import { useForm } from "hooks/useForm";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";
import { Form, FormLinks } from "pages/shared/styles/Form.styles";
import { useWorkspaceJoinInvitationViewApiConsumer } from "pages/WorkspaceJoinInvitation/consumer/useWorkspaceJoinInvitationViewApiConsumer";
import * as Styled from "pages/WorkspaceJoinInvitation/views/WorkspaceJoinInvitationView.styles";

import { vars } from "styles";

import { validatePassword } from "util/ValidatorFunctions";

interface FormState {
  password: string;
}

const invites = {
  expired: {
    title: "Invite expired.",
    message: (
      teamName: string,
    ) => `For your safety, invites are active only for a week. The one you've just clicked on has either expired or is
    invalid so you haven't joined ${teamName} workspace. Yet. Their admins can easily invite you again - just ask them to.`,
  },
  invalid: {
    title: "Invite invalid.",
    message: "The invite you've just clicked is invalid. Admins can easily invite you again - just ask them to.",
  },
};

export const WorkspaceJoinInvitationView = () => {
  const { formState, formError, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: {
      password: "",
    },
    onSubmit: handleSubmit,
    validators: {
      password: validatePassword,
    },
  });

  const { password } = formState;
  const { password: passError } = formError;

  const { teamInfo, joinWithPassword, validating, expiredInfo, loading, redirectToGoogle, googleInvalidEmailJoin } =
    useWorkspaceJoinInvitationViewApiConsumer();

  function handleSubmit() {
    joinWithPassword(password);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  if (validating) return <CenteredMessage title="Validating invite." />;

  if (!teamInfo) {
    const title = expiredInfo ? invites.expired.title : invites.invalid.title;
    const message = expiredInfo ? invites.expired.message(expiredInfo.team_name) : invites.invalid.message;
    return (
      <CenteredMessage
        title={title}
        message={message}
      />
    );
  }

  const { member_email, team_name } = teamInfo;

  return (
    <CenteredForm>
      <FormTitle
        title={`Welcome to the ${team_name} workspace`}
        subtitle="Use Google sign in to join this workspace or enter your password."
      />
      <Styled.GoogleSignInButton
        disabled={loading}
        size="full"
        onClick={redirectToGoogle}
      >
        <img
          src={GoogleIcon}
          alt="Google Icon"
          width={20}
        />
        Continue with Google
      </Styled.GoogleSignInButton>
      {googleInvalidEmailJoin && (
        <Styled.GoogleError
          style={{
            color: vars.colors.red,
          }}
        >{`You were invited to join ${team_name} using ${member_email}. 
        \nPlease make sure you use that account when joining this workspace with Google.`}</Styled.GoogleError>
      )}
      <Styled.FormSeparatorText
        style={{
          margin: "2rem 0",
          textAlign: "center",
        }}
      >
        or
      </Styled.FormSeparatorText>
      <Form
        onSubmit={handleFormSubmit}
        noValidate
      >
        <p>{member_email}</p>
        <Input
          name="password"
          type="password"
          label="Password"
          value={password}
          error={!!passError}
          helper={passError && passError.message}
          onChange={handleChange}
          autoFocus
        />
        <Button
          disabled={loading || !password}
          size="full"
          type="submit"
          tooltip={{
            position: "top",
            text: "Please enter your password first",
            disabled: loading || Boolean(password),
          }}
          loadingText={loading && "Signing you in..."}
        >
          Continue with email
        </Button>
      </Form>
      <FormLinks>
        <p>
          By joining the workspace, you agree to the{" "}
          {<a href="https://www.shakebugs.com/privacy">Shake Privacy Policy</a>} and{" "}
          {<a href="https://www.shakebugs.com/terms">Terms of Service</a>}.
        </p>
      </FormLinks>
    </CenteredForm>
  );
};
