import React from "react";

import { PAGE_NAVIGATION_ELEMENT_ROOT_ID } from "layouts/components/PageNavigation/PageNavigation";

import * as Styled from "./NavigationCustomElement.styles";

const NavigationCustomElement: React.FC = ({ children }) => {
  return (
    <Styled.NavigationCustomElement id={PAGE_NAVIGATION_ELEMENT_ROOT_ID}>{children}</Styled.NavigationCustomElement>
  );
};

export default NavigationCustomElement;
