import React from "react";

import RedirectIfAuthenticated from "pages/shared/components/RedirectIfAuthenticated";
import { WorkspaceJoinInvitationService } from "pages/WorkspaceJoinInvitation/service/WorkspaceJoinInvitationService";
import { WorkspaceJoinInvitationView } from "pages/WorkspaceJoinInvitation/views/WorkspaceJoinInvitationView";

import dependencyInjection from "util/dependencyInjection";

interface Services {
  invitationService: WorkspaceJoinInvitationService;
}

const invitationService = new WorkspaceJoinInvitationService();

const [WorkspaceJoinInvitationDependencyProvider, useWorkspaceJoinInvitationDeps] = dependencyInjection<Services>({
  services: { invitationService },
});

const WorkspaceJoinInvitationPage = () => {
  return (
    <RedirectIfAuthenticated>
      <WorkspaceJoinInvitationDependencyProvider>
        <WorkspaceJoinInvitationView />
      </WorkspaceJoinInvitationDependencyProvider>
    </RedirectIfAuthenticated>
  );
};

export { useWorkspaceJoinInvitationDeps, WorkspaceJoinInvitationPage };
