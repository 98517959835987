import { useCallback, useEffect, useState } from "react";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";

import { TicketSortOption, UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useUsersAttributes } from "hooks/filtering/modules/useUsersAttributes";
import { useAppBoundStorage } from "hooks/filtering/useAppBoundStorage";
import useAbortController from "hooks/useAbortController";
import { Page } from "hooks/useShakePaginaton";

import { AppUser } from "models";

import { useUsersDeps } from "pages/Users/Users";

import { RoutePaths } from "router/config/routePaths";

import { analyticsTrack } from "util/analyticsData";

interface Params {
  userId?: string;
}

export default function useUserDetailsPageApiConsumer<TData>({ userId }: Params) {
  const [user, setUser] = useState<AppUser>();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const queryClient = useQueryClient();

  const { validAttributes } = useUsersAttributes();

  const { state: sortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>(
    "shakebugs.users_sort",
    "created",
  );
  const sortValue = sortOption.value;
  const queryKey = `users ${selectedApp?.id}`;

  const deps = [selectedWorkspace?.id, selectedApp?.id, validAttributes, sortValue];

  const { usersService } = useUsersDeps();
  const { abortSignal } = useAbortController();

  const navigate = useNavigate();

  const fetchUserDetail = useCallback(
    async (userId) => {
      if (!userId || !selectedWorkspace?.id || !selectedApp?.id) return;

      try {
        const { data } = await usersService.getUser(selectedWorkspace.id, selectedApp.id, userId, abortSignal);
        setUser(data);
      } catch (error) {
        navigate(
          generatePath(RoutePaths.USERS, {
            workspaceSlug: selectedWorkspace?.slug,
            appKey: selectedApp?.key,
          }),
          { replace: true },
        );
        if (abortSignal.aborted) return;
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
    },
    [usersService, abortSignal, navigate, selectedWorkspace, selectedApp],
  );

  const changeUserMode = async (isBanned?: boolean) => {
    if (!userId || !selectedWorkspace?.id || !selectedApp?.id) return;

    try {
      await usersService.updateUser(selectedWorkspace.id, selectedApp.id, userId, isBanned);

      await fetchUserDetail(userId);
      return isBanned;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
    return;
  };

  const { mutate: mutateUserMode } = useMutation({
    mutationFn: changeUserMode,
    onSuccess: (isBanned) => {
      if (isBanned == undefined) return;

      // eslint-disable-next-line
      return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<AppUser>) => {
          const pageItems = page.items.map<AppUser>((us) => (us.id === user?.id ? { ...us, banned: isBanned } : us));
          return { ...page, items: pageItems };
        }),
      }));
    },
  });

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
      analyticsTrack("AppUser visited");
    }
  }, [fetchUserDetail, userId]);

  return { user, changeUserMode: mutateUserMode };
}
