import { useState } from "react";

import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import { useForm } from "hooks/useForm";

import { UserData } from "models";

import usePersonalInfoApiConsumer from "pages/Account/consumers/api/usePersonalInfoApiConsumer";
import { validateUserInfoName } from "pages/Account/util/validatorFunctions";

import { analyticsChangedData, setAnalyticsData } from "util/analyticsData";
import { validateEmail } from "util/ValidatorFunctions";

interface AccountBasicsFormState {
  email: string;
  name: string;
}

const validators = {
  email: validateEmail,
  name: validateUserInfoName,
};

enum ErrorStatusCodes {
  EMAIL_ALREADY_IN_USE = 409,
}

export default function usePersonalInfoBasicsFormConsumer() {
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const {
    updateUserInfoBasics,
    handlePictureUpdate,
    loading: pictureUpdating,
    deletePicture,
  } = usePersonalInfoApiConsumer();

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AccountBasicsFormState>({
    initialState: { email: userData?.email ?? "", name: userData?.name ?? "" },
    onSubmit: handleUserInfoUpdate,
    validators,
  });

  const [loading, setLoading] = useState(false);

  async function handleUserInfoUpdate() {
    try {
      setLoading(true);

      const oldUserInfo = {
        email: userData?.email,
        name: userData?.name,
      };

      const newUserInfo = {
        email: formState.email,
        name: formState.name,
      };

      await updateUserInfoBasics(newUserInfo, oldUserInfo);

      setAnalyticsData(undefined, getNewUserData(newUserInfo.email, newUserInfo.name, userData), selectedWorkspace);
      analyticsChangedData();
    } catch (error) {
      if (error.response?.status === ErrorStatusCodes.EMAIL_ALREADY_IN_USE) {
        setError({ email: new Error("The provided email is already in use, please use a different one") });
      }
    } finally {
      setLoading(false);
    }
  }

  return {
    handleFormChange,
    handleFormSubmit,
    formState,
    formError,
    loading,
    handlePictureUpdate,
    pictureUpdating,
    deletePicture,
  };
}

const getNewUserData = (newEmail?: string, newName?: string, userData?: UserData) => {
  if (!userData) return { email: newEmail ?? "", name: newName ?? "" };
  return { ...userData, email: newEmail, name: newName };
};
