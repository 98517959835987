import { useEffect, useState } from "react";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

export function useAppBoundStorage<T>(key: string, initial: T) {
  /// store to local storage bound to key
  /// if the returned storage state is not bound to the currently selected app, purge the state, and set to initial
  const { selectedApp } = useAppSelectionContext();

  type BoundValue = {
    value: T;
    appId: string | undefined;
  };

  const [state, setState] = useState<BoundValue>(() => {
    const stored = localStorage.getItem(key);
    if (!stored) return { appId: selectedApp?.id, value: initial };
    let state;
    try {
      state = JSON.parse(stored) as BoundValue;
      if (state.appId !== selectedApp?.id) {
        localStorage.removeItem(key);
        return { appId: selectedApp?.id, value: initial };
      }
    } catch (error) {
      return { appId: selectedApp?.id, value: initial };
    }
    return state;
  });

  useEffect(() => {
    if (!selectedApp?.id) return;
    if (selectedApp.id == state.appId) return;
    setState({ value: initial, appId: selectedApp.id });
  }, [selectedApp?.id, state]);

  useEffect(() => {
    if (!state) {
      localStorage.removeItem(key);
      return;
    }

    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      localStorage.removeItem(key);
    }
  }, [state, key]);

  return {
    state,
    setState: (value: T) => {
      setState({ appId: selectedApp?.id, value });
    },
  };
}
