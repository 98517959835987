import styled from "styled-components";

export const NavigationCustomElement = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
