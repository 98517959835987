import styled from "styled-components";

import { ReactComponent as GlobeIcon } from "assets/images/shared-icons/globe-certificate-icon.svg";

import { HelperText } from "components/HelperText/HelperText";
import BaseInput from "components/Input/Input";

import { vars } from "styles/abstracts/vars";

export const Globe = styled(GlobeIcon)`
  path {
    stroke: ${vars.colors.grey30};
  }
`;

export const Helper = styled(HelperText)`
  margin-top: 2.4rem;
  margin-bottom: 1.2rem;
`;

export const Input = styled(BaseInput)`
  label {
    cursor: pointer;
  }
  cursor: pointer;
`;
