import React from "react";

import Heading from "components/Heading/Heading";
import PageTitle from "components/PageTitle/PageTitle";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";
import { Spinner } from "components/Spinner/Spinner";

import { useApprovedDomainsConsumer } from "pages/Administration/consumers/useApprovedDomainsConsumer";
import { useTeamMembersConsumer } from "pages/Administration/consumers/useTeamMembersConsumer";

import identifiers from "util/identifiers.json";
import { t } from "util/translator";

import ApprovedDomainsCard from "../../components/ApprovedDomainsCard/ApprovedDomainsCard";
import TeamMembersCard from "../../components/TeamMembersCard/TeamMembersCard";

export default function MembersView() {
  const {
    domains,
    addApprovedDomain,
    deleteApprovedDomain,
    initialLoading: domainInitialLoad,
    loading: domainsLoading,
  } = useApprovedDomainsConsumer();
  const {
    teamData,
    inviteMembers,
    deleteMember,
    deleteMemberInvitation,
    customizeTeamMember,
    resendInvite,
    deleteCurrentMember,
    initialLoading: membersInitialLoad,
    loading: membersLoading,
  } = useTeamMembersConsumer();

  return (
    <>
      <Title />
      {domainInitialLoad || membersInitialLoad ? (
        <Spinner />
      ) : (
        <PageWrap>
          <ApprovedDomainsCard
            domains={domains}
            addApprovedDomain={addApprovedDomain}
            deleteApprovedDomain={deleteApprovedDomain}
            loading={domainsLoading}
          />
          <TeamMembersCard
            teamData={teamData}
            inviteMembers={inviteMembers}
            deleteMember={deleteMember}
            deleteMemberInvitation={deleteMemberInvitation}
            customizeTeamMember={customizeTeamMember}
            resendInvite={resendInvite}
            deleteCurrentMember={deleteCurrentMember}
            loading={membersLoading}
          />
        </PageWrap>
      )}
    </>
  );
}

const Title = () => (
  <PageTitle>
    <Heading
      as="h1"
      heading1
      marginBottom={8}
      testId={identifiers["workspaceAdministration.header.members"]}
    >
      {t("administration.members.heading")}
    </Heading>
    <Paragraph>{t("administration.members.subtitle")}</Paragraph>
  </PageTitle>
);
