import React from "react";

import { ReactComponent as GoogleIcon } from "assets/images/shared-icons/logo-google-1.svg";

import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Heading from "components/Heading/Heading";
import { useModal } from "components/Modal/Modal";

import { useAuthContext } from "context/Auth/Auth.context";

import { getUserInitials, UserData } from "models";

import SetPasswordForm from "pages/Account/components/shared/forms/SetPasswordForm";
import usePersonalInfoBasicsFormConsumer from "pages/Account/consumers/form/usePersonalInfoBasicsFormConsumer";
import LogoComponent from "pages/shared/components/LogoComponent/LogoComponent";
import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";

import identifiers from "util/identifiers.json";

import * as Styled from "../shared/forms/Form.styles";

export default function BasicsCard() {
  const { userData } = useAuthContext();
  const {
    formState,
    formError,
    handleFormChange,
    handleFormSubmit,
    loading,
    handlePictureUpdate,
    pictureUpdating,
    deletePicture,
  } = usePersonalInfoBasicsFormConsumer();

  const { Modal: BasicsCardModal, toggle, modalProps } = useModal({ size: "narrow" });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  const handleBasicsFormSubmit = (e?: React.FormEvent) => {
    e?.preventDefault();
    const isGoogleAccountWithoutPassword = userData?.hasGoogle && !userData?.hasPassword;
    const emailChanged = userData?.email !== formState.email;

    if (isGoogleAccountWithoutPassword && emailChanged) {
      toggle();
      return;
    }

    handleFormSubmit(e);
  };

  const setPasswordFormCallback = () => {
    toggle();
    handleFormSubmit();
  };

  const isButtonDisabled = userData?.name === formState.name && userData.email === formState.email;

  return (
    <>
      <Card>
        <Heading
          as="h3"
          heading3
          marginBottom={24}
          testId={identifiers["info.basic.header"]}
        >
          Basics
        </Heading>

        <Styled.Form
          onSubmit={handleBasicsFormSubmit}
          noValidate
        >
          <LogoComponent
            title="Profile photo"
            subtitle="Optional • Visible to workspace members only."
            image={userData?.picture ?? undefined}
            backgroundColor={getRandomColor(userData?.id).background}
            textColor={getRandomColor(userData?.id).text}
            letters={getUserInitials(userData?.name)}
            onUpdatePicture={handlePictureUpdate}
            onDeletePicture={deletePicture}
            loading={pictureUpdating}
            canChange
          />

          <Styled.Input
            disabled={loading}
            name="name"
            label="Full name"
            helper={formError.name?.message ?? "Visible to your colleagues and app users you chat to"}
            error={!!formError.name}
            value={formState.name}
            onChange={handleChange}
            testId={identifiers["info.basic.input.fullName"]}
          />

          <Styled.Input
            disabled={loading}
            name="email"
            type="email"
            label="Work email"
            helper={formError.email?.message ?? resolveEmailHelperText(userData)}
            error={!!formError.email}
            value={formState.email}
            onChange={handleChange}
            testId={identifiers["info.basic.input.workEmail"]}
            iconComponent={getGoogleIcon(userData)}
          />

          <Button
            size="small"
            disabled={isButtonDisabled || loading}
            type="submit"
            testId={identifiers["info.basic.button.save"]}
            tooltip={{
              position: "top",
              text: "Please enter name and email first",
              disabled: !isButtonDisabled,
            }}
            loadingText={loading && "Saving..."}
          >
            Save changes
          </Button>
        </Styled.Form>
      </Card>

      <SetPasswordForm
        onSubmitCallback={setPasswordFormCallback}
        Modal={BasicsCardModal}
        internalProps={modalProps}
        heading="So, you want to change your mail?"
      >
        <p>
          You have to <i>set your password first</i> because you don&apos;t have a password yet. And you need it so you
          can use your new email plus password combination to sign into Shake.
        </p>
      </SetPasswordForm>
    </>
  );
}

const resolveEmailHelperText = (userData?: UserData) => {
  return userData?.hasGoogle
    ? "If you change it to something else, you won't be able to use this Google account to sign in anymore"
    : undefined;
};

const getGoogleIcon = (userData?: UserData) => {
  if (!userData || !userData.hasGoogle) return;

  return (
    <Styled.Tooltip
      position="top"
      text="Authenticated with Google"
    >
      <GoogleIcon />
    </Styled.Tooltip>
  );
};
