import styled from "styled-components";

import BaseParagraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

export const ResumeSubscriptionCard = styled.div`
  padding: 3.2rem;
  border-radius: 1.5rem;
  background-color: ${vars.colors.redLighter};
  margin-bottom: 1.2rem;
`;
export const CancelDateParagraph = styled(BaseParagraph)`
  padding-top: 0.8rem;
`;
export const Link = styled.a`
  display: inline-block;
`;
