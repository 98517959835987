import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "components/SelectDropdown/SelectDropdown";

import { Workspace } from "models";
import { forwardingOptions } from "models/integrations";
import { Github } from "models/integrations/Github.model";

import { Form } from "../ModalBody.styles";
import { GithubOption, useGithubIntegrationFormConsumer } from "./consumers/useGithubIntegrationFormConsumer";

export interface BaseIntegrationModalProps {
  onFinish: () => void;
}

interface Props extends BaseIntegrationModalProps {
  integration?: Github;
  selectedAppId: string;
  workspace: Workspace;
}

export const GithubIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const { forwardingOption, projectOption, projectOptions, handleFormChange, handleFormSubmit, disabled, loading } =
    useGithubIntegrationFormConsumer({ selectedAppId, onFinish, integration });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const isButtonDisabled = checkIsButtonDisabled(disabled, projectOption, forwardingOption, integration);

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="projectOption"
          value={projectOption}
          options={projectOptions}
          placeholder="Please choose"
          label="Repository"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />

        <HelperText>
          The repository you need is not listed? Disconnect and then connect the integration again.
        </HelperText>
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};

const checkIsButtonDisabled = (
  disabled: boolean,
  projectOption: Option,
  forwardingOption: Option,
  integration?: Github,
) => {
  if (disabled) return true;
  if (!projectOption || !forwardingOption) return true;
  if (!integration) return false;

  const parsedProjectOption = JSON.parse(projectOption.value) as GithubOption;
  if (
    parsedProjectOption.repoId !== integration.repository_id ||
    !!forwardingOption.value !== integration.automatic_forward
  ) {
    return false;
  }
  return true;
};
