import React from "react";

import Heading from "components/Heading/Heading";
import PageTitle from "components/PageTitle/PageTitle";
import PageWrap from "components/PageWrap/PageWrap";

import BasicsCard from "pages/Account/components/BasicsCard/BasicsCard";
import { SecurityCard } from "pages/Account/components/SecurityCard/SecurityCard";

import identifiers from "util/identifiers.json";

import * as Styled from "./PersonalInfoView.styles";

export default function PersonalInfoView() {
  return (
    <>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          testId={identifiers["myAccount.header.info"]}
        >
          Personal info
        </Heading>
        <p>Everything about you and only you</p>
      </PageTitle>
      <PageWrap>
        <Styled.PersonalInfoViewCardsContainer>
          <BasicsCard />
          <SecurityCard />
        </Styled.PersonalInfoViewCardsContainer>
      </PageWrap>
    </>
  );
}
