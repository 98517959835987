import React, { useRef, useState } from "react";

import { Highlight, Prism, themes, Token } from "prism-react-renderer";

import * as Styled from "./CodeBlock.styles";
import CopyButton from "./CopyButton";
import TabItem from "./TabItem";
import { TabController } from "./useCodeTabsController";

(typeof global !== "undefined" ? global : window).Prism = Prism;
require("prismjs/components/prism-dart");
require("prismjs/components/prism-java");
require("prismjs/components/prism-bash");
require("prismjs/components/prism-groovy");

interface Props {
  tabs: Tab[];
  tabController: TabController;
}

export interface Tab {
  title: string;
  file: string;
  code: string;
  language: string;
  highlightedLines: number[];
}

interface Group {
  highlighted: boolean;
  tokens: Token[][];
}

export default function CodeBlock({ tabs, tabController }: Props) {
  const [copyPosition, setCopyPosition] = useState({
    left: 0,
    height: "2.8rem",
    color: "rgba(0, 0, 0, 0.3)",
  });
  const scrollRef = useRef(null);

  const onCodeGroupHover = () => {
    if (scrollRef.current != null) {
      const { scrollLeft, clientWidth } = scrollRef.current;
      setCopyPosition({
        left: scrollLeft + clientWidth - 80,
        height: "2.8rem",
        color: "rgba(0, 0, 0, 0.3)",
      });
    }
  };

  return (
    <Styled.CodeBlock>
      {tabs.length > 1 && (
        <Styled.TabsContent>
          {tabs.map((tab, index) => (
            <TabItem
              key={tab.title}
              isSelected={index === tabController.selectedTab}
              onClick={() => {
                tabController.setSelectedTab(index);
              }}
              text={tab.title}
            />
          ))}
        </Styled.TabsContent>
      )}
      <Styled.CodeContent>
        <Styled.CodeTitle>{tabs?.[tabController.selectedTab ?? 0]?.file}</Styled.CodeTitle>
        <Highlight
          theme={themes.dracula}
          code={tabs?.[tabController.selectedTab ?? 0]?.code}
          language={tabs?.[tabController.selectedTab ?? 0]?.language}
        >
          {({ tokens, getTokenProps }) => {
            const groups = groupLines(tabs, tabController, tokens);
            return (
              <Styled.CodeText ref={scrollRef}>
                {groups.map((group, key) => (
                  <Styled.CodeGroup
                    onMouseEnter={onCodeGroupHover}
                    key={key}
                    $highlight={group.highlighted}
                  >
                    {group.highlighted && (
                      <CopyButton
                        style={copyPosition}
                        copyText={getGroupText(group)}
                      />
                    )}
                    {group.tokens.map((line, i) => {
                      return (
                        <Styled.CodeLine key={i}>
                          {line.map((token, key) => (
                            <span
                              key={key}
                              {...getTokenProps({ token })}
                            />
                          ))}
                        </Styled.CodeLine>
                      );
                    })}
                  </Styled.CodeGroup>
                ))}
              </Styled.CodeText>
            );
          }}
        </Highlight>
      </Styled.CodeContent>
    </Styled.CodeBlock>
  );
}

const groupLines = (tabs: Tab[], tabController: TabController, array: Token[][]) => {
  const result: Group[] = [];

  array.forEach((tokens, index: number) => {
    const tab = tabs?.[tabController.selectedTab];
    if (!tab.highlightedLines.includes(index)) {
      result.push({ highlighted: false, tokens: [tokens] });
    } else {
      if (tab.highlightedLines.includes(index - 1)) {
        result[result.length - 1].tokens.push(tokens);
      } else {
        result.push({ highlighted: true, tokens: [tokens] });
      }
    }
  });

  return result;
};

const getGroupText = (group: Group) => {
  let text = "";
  group.tokens.forEach((line) => {
    line.forEach((token) => {
      text += token.content;
    });
    text += "\n";
  });
  return text;
};
