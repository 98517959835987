import styled from "styled-components";

export const BackgroundContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100rem auto;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 62rem;
  cursor: default;
`;

export const HeadingBoxWrapper = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 5rem;
  margin-top: 9.5rem;
`;

export const StepContent = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 3.8rem;

  a {
    text-decoration: underline;
  }
`;
