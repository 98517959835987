import React from "react";

import CenteredMessage from "components/CenteredMessage/CenteredMessage";

import { getIntegrationTypeName } from "models/integrations";

import WorkspaceJoinElement from "../components/WorkspaceSelectElement";
import { useIntegrationActivationConsumer } from "../consumers/useIntegrationActivationConsumer";

export const IntegrationActivationView = () => {
  const { invalid, loading, setLoading, integrationType } = useIntegrationActivationConsumer();

  const message = () => {
    if (loading || invalid) {
      return loading ? "Loading..." : "Something went wrong. Try again.";
    }
  };

  const showMessage = loading || invalid;

  return (
    <>
      {showMessage ? (
        <CenteredMessage
          title={`Integrating ${getIntegrationTypeName(integrationType)}`}
          paragraph={message()}
          isError={invalid}
        />
      ) : (
        <WorkspaceJoinElement setLoading={setLoading} />
      )}
    </>
  );
};
