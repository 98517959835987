import React, { useEffect, useState } from "react";

import { Row } from "@tanstack/react-table";

import Button from "components/Button/Button";
import { getTagColor } from "components/Input/InputWithTags/InputWithTags";
import { ModalHeader } from "components/Modal/ModalHeader/ModalHeader";
import Option from "components/Option/Option";

import useCommonTagsApiConsumer from "consumers/useCommonTagsApiConsumer";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Tag } from "models";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./ModalBody.styles";

export function AddTagsModalBody<TData>({
  title,
  confirmAction,
  close,
  selectedRows,
}: {
  title: string;
  confirmAction: (rows: TData[], tags: string[]) => void;
  close: () => void;
  selectedRows: Row<TData>[];
}) {
  const { selectedWorkspace } = useAppSelectionContext();
  const { commonTags } = useCommonTagsApiConsumer({ selectedWorkspaceId: selectedWorkspace?.id });
  const [filteredTags, setFilteredTags] = useState<Tag[]>(commonTags.sort((a, b) => a.name.localeCompare(b.name)));

  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleCheck = (name: string) => {
    const selectedCopy = [...selected];

    if (selectedCopy.find((sel) => sel === name)) {
      setSelected(selectedCopy.filter((sel) => sel !== name));
    } else setSelected([...selectedCopy, name]);
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredTags(commonTags.filter((tag) => tag.name.includes(searchValue)));
    } else if (selected.find((sel) => sel.includes("Add new tag"))) {
      const commonCopy = [...commonTags];
      selected.map((sel) => {
        if (sel.includes("Add new tag")) {
          commonCopy.push({ name: sel });
        }
      });
      setFilteredTags(commonCopy);
    } else {
      setFilteredTags(commonTags);
    }
  }, [searchValue, commonTags, selected]);

  return (
    <Flex $column>
      <Styled.HeaderContainer>
        <ModalHeader
          heading={title}
          noContent
        />
      </Styled.HeaderContainer>
      <Styled.Container $smallerPadding>
        <Styled.SearchContainer>
          <Styled.Search
            placeholder="Search..."
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.currentTarget.value)}
          />
        </Styled.SearchContainer>

        <Styled.ScrollContainer>
          {filteredTags.map((tag, index) => {
            return (
              <Element
                key={index}
                value={(tag as Tag).name}
                selected={selected}
                setSelected={handleCheck}
              />
            );
          })}
          {searchValue && !commonTags.find((tag) => tag.name === searchValue) && (
            <Element
              value={`Add new tag '${searchValue}'`}
              selected={selected}
              setSelected={handleCheck}
            />
          )}
        </Styled.ScrollContainer>
        <Styled.ButtonContainer>
          <Button
            type="submit"
            size="small"
            onClick={() => {
              confirmAction(
                selectedRows.map((row) => row.original),
                selected.map((sel) => {
                  if (sel.includes("Add new tag")) {
                    return sel.split("'")[1].replace("'", "");
                  }
                  return sel;
                }),
              );
              close();
            }}
            disabled={!Boolean(selected.length)}
            tooltip={{ text: "Please choose a tag first", position: "top", disabled: Boolean(selected.length) }}
          >
            Add
          </Button>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Flex>
  );
}

function Element({
  value,
  selected,
  setSelected,
}: {
  setSelected: (sel: string) => void;
  value: string;
  selected: string[];
}) {
  return (
    <Styled.OptionContainer>
      <Option
        checked={Boolean(selected.find((sel) => sel === value))}
        label={value}
        iconElement={<Styled.ColorDot style={{ backgroundColor: getTagColor(value) }} />}
        handleCheck={() => setSelected(value)}
      />
    </Styled.OptionContainer>
  );
}
