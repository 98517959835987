import React, { ChangeEvent, useState } from "react";

import { CSSProperties } from "styled-components";

import { useKeyboardPress } from "../useKeyboardPress";
import { InputCheckboxWrap } from "./BaseTableStyles";

export function InputCheckBox({
  checked,
  onChange,
  testID,
  style,
}: {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement> | undefined, shiftSelected: boolean) => void;
  testID: string;
  style?: CSSProperties;
}) {
  const uniqueId = (prefix: string): string => {
    let b = "";
    let a = 0;
    for (; a++ < 36; b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : "-");
    return prefix + b;
  };

  const [shiftSelected, setShiftSelected] = useState(false);

  useKeyboardPress({
    keyboardKey: "Shift",
    onPress: () => setShiftSelected(true),
    enabled: true,
  });

  const inputIDRef = React.useRef(uniqueId("input-"));

  return (
    <InputCheckboxWrap style={style ?? {}}>
      <input
        id={inputIDRef.current}
        type="checkbox"
        checked={checked}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => onChange(e, shiftSelected)}
        data-testid={testID}
      />
      <label
        style={{ paddingLeft: "1rem" }}
        className="__table_checkbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
        htmlFor={inputIDRef.current}
      />
    </InputCheckboxWrap>
  );
}
