import { useCallback, useEffect, useState } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useTeamMembersConsumer } from "pages/Administration/consumers/useTeamMembersConsumer";
import { useUserFeedbackDeps } from "pages/UserFeedback/UserFeedback";

import { RoutePaths } from "router/config/routePaths";

export const useNoSubscriptionConsumer = () => {
  const navigate = useNavigate();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const { userFeedbackService } = useUserFeedbackDeps();
  const { teamData } = useTeamMembersConsumer();
  const [loading, setLoading] = useState(false);

  const fetchNoSubscriptionInitialData = useCallback(async () => {
    if (!selectedApp || !selectedWorkspace) return;
    setLoading(true);
    try {
      const issues = await userFeedbackService.getUserFeedbackTickets(selectedWorkspace.id, selectedApp.id, 20, 10);

      if (issues) {
        navigate(
          generatePath(RoutePaths.USER_FEEDBACK, {
            workspaceSlug: selectedWorkspace.slug,
            appKey: selectedApp.key,
          }),
        );
      }
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  }, [selectedApp, selectedWorkspace, userFeedbackService, navigate]);

  useEffect(() => {
    fetchNoSubscriptionInitialData();
  }, [fetchNoSubscriptionInitialData]);

  return { teamData, loading };
};
