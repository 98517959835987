import { useCallback, useEffect, useRef } from "react";

import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { setUserTokenAction } from "context/Auth/Auth.actions";
import { useAuthDispatch } from "context/Auth/Auth.context";
import { USER_TOKEN_LOCAL_STORAGE_KEY } from "context/Auth/Auth.types";

import { FROM_SIGNUP } from "pages/SignUp/consumers/useSignUpFormConsumer";

import { RoutePaths } from "router/config/routePaths";

import { sendGtagEvent, setAnalyticsData } from "util/analyticsData";

import { useConfirmSignUpDeps } from "../ConfirmSignUp";

enum StatusCodes {
  ACTIVATION_CODE_VALID_WORKSPACE_EXISTS = 226,
}

const toastCopy = {
  success: {
    title: "Welcome",
    content: "Email successfully verified — welcome to Shake.",
  },
  alreadyConfirmed: {
    title: "Already confirmed",
    content: "Email already confirmed — sign in with your credentials below.",
  },
};

export const useConfirmSignUpConsumer = () => {
  const navigate = useNavigate();

  const { confirmSignUpService } = useConfirmSignUpDeps();

  const authDispatch = useAuthDispatch();

  const [searchParams] = useSearchParams();

  const activationCodeRef = useRef(searchParams.get("code"));

  const confirmSignUpAndRedirect = useCallback(async () => {
    try {
      if (!activationCodeRef.current) {
        navigate(generatePath(RoutePaths.ROOT));
        throw new Error("Activation failed. Missing activation code. Try clicking the link in the email again.");
      }
      const { data: userToken, status } = await confirmSignUpService.confirmSignup(activationCodeRef.current);
      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(userToken));
      authDispatch(setUserTokenAction(userToken));

      setAnalyticsData();
      if (window.analytics) {
        window.analytics.track("User Confirmation", {});
        window.analytics.track("Confirmed Signup", {});
      }

      sendGtagEvent("AW-11045616142/UWp_CJv964cYEI70-pIp");

      if (status === StatusCodes.ACTIVATION_CODE_VALID_WORKSPACE_EXISTS) {
        navigate(RoutePaths.JOIN_WORKSPACE, { state: { from: FROM_SIGNUP } });
        return;
      }

      displayToast({
        id: "email-verified",
        ...toastCopy.success,
      });

      navigate(RoutePaths.ROOT, { state: { from: FROM_SIGNUP } });
    } catch (error) {
      switch (error.response?.status) {
        case 401: {
          displayToast({
            title: "Something went wrong",
            content: "Activation link expired. Please sign up again to get a new activation link.",
          });
          navigate(RoutePaths.SIGNUP);
          break;
        }
        case 404: {
          displayToast({
            title: "Something went wrong.",
            content: "Please try again.",
          });
          navigate(RoutePaths.SIGNUP);
          break;
        }
        case 409: {
          displayToast({
            id: "already-activated",
            ...toastCopy.alreadyConfirmed,
          });
          navigate(RoutePaths.SIGNIN);
          break;
        }
        default:
          displayToast({
            title: "Something went wrong.",
            content: error.message,
          });
          break;
      }
    }
  }, [authDispatch, confirmSignUpService, navigate]);

  useEffect(() => {
    confirmSignUpAndRedirect();
  }, [confirmSignUpAndRedirect]);
};
