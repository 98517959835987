import React from "react";

import Button from "components/Button/Button";
import { ModalHeader } from "components/Modal/ModalHeader/ModalHeader";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import { useBaseDialogContext } from "../BaseDialog";
import * as Styled from "./ConfirmDialogBody.styles";

export function ConfirmDialogBody({
  title,
  subtitle,
  confirmAction,
  buttonText,
  notDelete,
}: {
  title: string;
  subtitle: string;
  confirmAction: () => void;
  buttonText?: string;
  notDelete?: boolean;
}) {
  const { setOpen } = useBaseDialogContext();

  return (
    <Flex $column>
      <ModalHeader
        heading={title}
        subtitle={subtitle}
        noContent
      />
      <Styled.Container>
        <Styled.Button
          size="small"
          styling="outline"
          onClick={() => setOpen(false)}
          testId={identifiers["modules.master.table.modal.button.cancel"]}
        >
          Cancel
        </Styled.Button>
        <Button
          type="submit"
          size="small"
          color={!notDelete ? "red" : undefined}
          onClick={() => confirmAction()}
          testId={identifiers["modules.master.table.modal.button.delete"]}
        >
          {buttonText ? buttonText : "Delete"}
        </Button>
      </Styled.Container>
    </Flex>
  );
}
