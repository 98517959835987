import React, { Context, createContext, useContext } from "react";

interface DependencyProviderProps {
  children: JSX.Element;
}

export default function dependencyInjection<Services>({
  services,
}: {
  [key: string]: Services;
}): [({ children }: DependencyProviderProps) => JSX.Element, () => Services] {
  const DependencyContext: Context<Services> = createContext(services);

  function DependencyProvider({ children }: DependencyProviderProps) {
    return <DependencyContext.Provider value={services}>{children}</DependencyContext.Provider>;
  }

  function useDependency(): Services {
    return useContext(DependencyContext);
  }

  return [DependencyProvider, useDependency];
}
