import styled from "styled-components";

export const FormTitle = styled.div`
  img {
    width: 4rem;
  }

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  strong {
    font-weight: lighter;
  }
`;
