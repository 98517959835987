import styled from "styled-components";

import BaseHeading from "components/Heading/Heading";
import { HelperText as BaseHelperText } from "components/HelperText/HelperText";
import BaseParagraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

export const Container = styled.div`
  max-height: 100%;
  min-height: 60rem;
  display: flex;
  justify-content: center;
  max-width: 900px;
  flex-direction: column;
  margin: 0 auto;
  cursor: default;
`;

export const BannerContainer = styled.div`
  background-color: ${vars.colors.grey100Darken};
  border: 1px solid;
  border-color: ${vars.colors.grey90};
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  gap: 1rem;
  padding: 2rem;
  margin: 2rem 4rem 0;
`;

export const CopyContainer = styled.div`
  max-width: 400px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 1.6rem;
  height: 100%;
`;

export const Icon = styled.img`
  color: ${vars.colors.grey60};
  width: 70px;
  height: 70px;
`;

export const Heading = styled(BaseHeading)`
  color: ${vars.colors.grey30};
  max-width: 40rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 0.5rem;
`;

export const Paragraph = styled(BaseParagraph)`
  word-break: break-word;
`;

export const Link = styled.a`
  margin-top: 1rem;
`;

export const InfoBlock = styled(BaseHelperText)`
  margin-top: 0.8rem;
`;
