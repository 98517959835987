import React from "react";

import { generatePath, NavigateFunction } from "react-router-dom";

import { CrashReport } from "models/crashReporting";

import { RoutePaths } from "router/config/routePaths";

import identifiers from "util/identifiers.json";

import * as Styled from "./CrashReportPageNavigationContent.styles";

interface Props {
  crashReport?: CrashReport;
  selectedWorkspaceSlug?: string;
  selectedAppKey?: string;
  navigate: NavigateFunction;
}

export default function CrashReportPageNavigationContent({
  crashReport,
  selectedWorkspaceSlug,
  selectedAppKey,
  navigate,
}: Props) {
  if (!selectedWorkspaceSlug || !selectedAppKey || !crashReport) {
    return <></>;
  }

  const commonParamValues = {
    workspaceSlug: selectedWorkspaceSlug,
    appKey: selectedAppKey,
    crashReportKey: crashReport.key.toString(),
  };

  const allEventsPath = generatePath(RoutePaths.CRASH_REPORT_EVENT, {
    ...commonParamValues,
    crashReportEventId: crashReport.last_event,
  });
  const crashOverviewPath = generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, commonParamValues);

  const isOverviewClicked = window.location.pathname.includes("overview");

  return (
    <Styled.CrashNavigationLinksWrapper>
      <Styled.CrashNavigationLinkWrapper
        $active={isOverviewClicked}
        onClick={() => navigate(crashOverviewPath)}
        data-testid={identifiers["navbar.crashReport.overview"]}
      >
        {"Overview"}
      </Styled.CrashNavigationLinkWrapper>
      <Styled.CrashNavigationLinkWrapper
        $active={!isOverviewClicked}
        onClick={() => navigate(allEventsPath)}
        data-testid={identifiers["navbar.crashReport.allEvents"]}
      >
        {"All events"}
      </Styled.CrashNavigationLinkWrapper>
    </Styled.CrashNavigationLinksWrapper>
  );
}
