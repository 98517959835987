import React from "react";

import AppDevelopmentImage from "assets/images/app-development/app-development.png";

import Heading from "components/Heading/Heading";
import Paragraph from "components/Paragraph/Paragraph";

import * as Styled from "./AppDevelopmentModule.styles";

export const AppDevelopmentModule = () => {
  const onReadMoreClick = () => {
    window.open("https://decode.agency/software-engineering-shake/", "_blank");
  };

  return (
    <Styled.AppDevelopmentContainer>
      <Styled.Image
        src={AppDevelopmentImage}
        alt="screenshot"
      />
      <Heading
        heading3
        fontWeight="bold"
      >
        {"Need help with app development?"}
      </Heading>
      <Paragraph
        p1
        marginTop={15}
      >
        {"Fun fact time! Peter, the cofounder of Shake, also cofounded DECODE. " +
          "We are an EU-based software development team that — apart from releasing Shake — collaborates with Microsoft, Royal Caribbean and other Fortune 500 companies."}
      </Paragraph>
      <Paragraph
        p1
        marginBottom={24}
      >
        {"If you need an extra pair of hands with app development, we have prepared everything:"}
      </Paragraph>
      <Styled.ReadMoreButton
        size="small"
        onClick={onReadMoreClick}
      >
        {"Read about DECODE"}
      </Styled.ReadMoreButton>
    </Styled.AppDevelopmentContainer>
  );
};
