// eslint-disable-next-line
const links: { [index: string]: any } = {
  iOS: {
    installation: "https://docs.shakebugs.com/docs/ios/install/spm/",
    import: "https://docs.shakebugs.com/docs/ios/import-from-instabug",
    registerAppUser: "https://docs.shakebugs.com/docs/ios/users/register-user/",
    addTicketMetadata: "https://www.shakebugs.com/docs/ios/configuration-and-data/ticket-metadata/",
    invoke: "https://docs.shakebugs.com/docs/ios/user-feedback/invoke/",
    enableLogs: "https://docs.shakebugs.com/docs/ios/configuration-and-data/activity-history",
    deobfuscation: "https://docs.shakebugs.com/docs/ios/crash-reports/symbolicate/",
    testCrashReports: "https://docs.shakebugs.com/docs/ios/crash-reports/test-it-out/",
    addUserMetadata: "https://www.shakebugs.com/docs/ios/users/update-user-metadata/",
    autoAttach: "https://docs.shakebugs.com/docs/ios/configuration-and-data/auto-attach-files/",
  },
  Android: {
    installation: "https://docs.shakebugs.com/docs/android/installation/",
    import: "https://docs.shakebugs.com/docs/android/import-from-instabug",
    registerAppUser: "https://docs.shakebugs.com/docs/android/users/register-user/",
    addTicketMetadata: "https://www.shakebugs.com/docs/android/configuration-and-data/ticket-metadata/",
    invoke: "https://docs.shakebugs.com/docs/android/user-feedback/invoke/",
    activityHistory: "https://docs.shakebugs.com/docs/android/configuration-and-data/activity-history",
    deobfuscation: "https://docs.shakebugs.com/docs/android/crash-reports/deobfuscation/",
    testCrashReports: "https://docs.shakebugs.com/docs/android/crash-reports/test-it-out/",
    addUserMetadata: "https://www.shakebugs.com/docs/android/users/update-user-metadata/",
    autoAttach: "https://docs.shakebugs.com/docs/android/configuration-and-data/auto-attach-files/",
  },
  ReactNative: {
    installation: "https://docs.shakebugs.com/docs/react/install/npm/",
    import: "https://docs.shakebugs.com/docs/react/import-from-instabug",
    registerAppUser: "https://docs.shakebugs.com/docs/react/users/register-user/",
    addTicketMetadata: "https://www.shakebugs.com/docs/react/configuration-and-data/ticket-metadata/",
    invoke: "https://docs.shakebugs.com/docs/react/user-feedback/invoke/",
    enableLogs: "https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history",
    deobfuscation: "https://docs.shakebugs.com/docs/react/crash-reports/deobfuscation/",
    addUserMetadata: "https://www.shakebugs.com/docs/react/users/update-user-metadata/",
    autoAttach: "https://docs.shakebugs.com/docs/react/configuration-and-data/auto-attach-files/",
  },
  Flutter: {
    installation: "https://docs.shakebugs.com/docs/flutter/installation/",
    import: "https://docs.shakebugs.com/docs/flutter/import-from-instabug",
    registerAppUser: "https://docs.shakebugs.com/docs/flutter/users/register-user/",
    addTicketMetadata: "https://www.shakebugs.com/docs/flutter/configuration-and-data/ticket-metadata/",
    invoke: "https://docs.shakebugs.com/docs/flutter/user-feedback/invoke/",
    enableLogs: "https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history",
    deobfuscation: "https://docs.shakebugs.com/docs/flutter/crash-reports/deobfuscation/",
    addUserMetadata: "https://www.shakebugs.com/docs/flutter/users/update-user-metadata/",
    autoAttach: "https://docs.shakebugs.com/docs/flutter/configuration-and-data/auto-attach-files/",
  },
  Web: {
    installation: "https://docs.shakebugs.com/docs/web/install/npm/",
    import: "https://docs.shakebugs.com/docs/web/import-from-instabug",
    registerAppUser: "https://docs.shakebugs.com/docs/web/users/register-user/",
    addTicketMetadata: "https://www.shakebugs.com/docs/web/configuration-and-data/ticket-metadata/",
    invoke: "https://docs.shakebugs.com/docs/web/user-feedback/invoke/",
    enableLogs: "https://docs.shakebugs.com/docs/web/configuration-and-data/activity-history",
    deobfuscation: "https://docs.shakebugs.com/docs/web/crash-reports/deobfuscation/",
    addUserMetadata: "https://www.shakebugs.com/docs/web/users/update-user-metadata/",
    autoAttach: "https://docs.shakebugs.com/docs/web/configuration-and-data/auto-attach-files/",
  },
};

const docLink = (platform?: string) => {
  return platform ? links[platform] : "";
};

export { docLink };
