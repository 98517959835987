import React, { PropsWithChildren } from "react";

import * as Styled from "./PageContent.styles";

interface Props {
  className?: string;
  width?: number;
}

const PageContent = ({ children, width }: PropsWithChildren<Props>) => {
  return (
    <Styled.PageContentWrap>
      <Styled.PageLayout $width={width}>{children}</Styled.PageLayout>
    </Styled.PageContentWrap>
  );
};

export default PageContent;
