import React from "react";

import TagsService from "services/Tags.service";

import dependencyInjection from "util/dependencyInjection";

import UserFeedbackService from "./services/UserFeedback.service";
import UserFeedbackActivityHistoryPageView from "./views/UserFeedbackActivityHistoryPageView";
import { UserFeedbackDetailsPageView } from "./views/UserFeedbackDetailsPageView";
import { UserFeedbackListPageView } from "./views/UserFeedbackListPageView";

interface Services {
  userFeedbackService: UserFeedbackService;
  tagsService: TagsService;
}

const userFeedbackService = new UserFeedbackService();
const tagsService = new TagsService();

const [UserFeedbackProvider, useUserFeedbackDeps] = dependencyInjection<Services>({
  services: {
    userFeedbackService,
    tagsService,
  },
});

export const UserFeedbackListPage = () => {
  return (
    <UserFeedbackProvider>
      <UserFeedbackListPageView />
    </UserFeedbackProvider>
  );
};

export const UserFeedbackDetailsPage = () => {
  return (
    <UserFeedbackProvider>
      <UserFeedbackDetailsPageView />
    </UserFeedbackProvider>
  );
};

export const UserFeedbackActivityHistoryPage = () => {
  return (
    <UserFeedbackProvider>
      <UserFeedbackActivityHistoryPageView />
    </UserFeedbackProvider>
  );
};

export { useUserFeedbackDeps };
