import { NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

const btnWiggle = keyframes`
  0% { transform: rotate(0deg); }
  2% { transform: rotate(-5deg); }
  3.5% { transform: rotate(5deg); }
  5% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

export const AddAppButton = styled.div<{ $isWiggling?: boolean }>`
  background: linear-gradient(91.04deg, #4fcf70 2.28%, #fad648 26.49%, #a767e5 50.69%, #12bcfe 74.9%, #44ce7b 99.11%);
  border-radius: 2rem;
  width: 16rem;
  height: 36px;
  padding: 2px;
  margin-bottom: 0.8rem;
  box-shadow: 0 2px 10px 0 #1c1c1e;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.$isWiggling &&
    css`
      animation: ${btnWiggle} 3s infinite;
    `}
`;

export const AddAppInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  border-radius: 2rem;
  width: 100%;
  height: 100%;
`;

export const AddAppAction = styled(NavLink)`
  display: flex;
  flex-direction: row;
  text-decoration-line: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0.6rem 1.6rem;
  margin: 0;

  :hover {
    opacity: 0.7;
    text-decoration: none;
  }
`;

export const AddAppIcon = styled.img`
  margin-right: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
`;
