import React, { PropsWithChildren } from "react";

import Button from "components/Button/Button";
import { CenteredForm } from "components/CenteredForm/CenteredForm";

import * as Styled from "pages/shared/components/Forms/CheckInbox/CheckInbox.styles";
import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";

import identifiers from "../../../../../util/identifiers.json";

interface Props {
  email: string;
  resendEmail: (email: string) => Promise<void>;
  subtitleText?: string;
  footerLinkContent?: JSX.Element;
}

export default function CheckInbox({ email, resendEmail, subtitleText, footerLinkContent }: Props) {
  return (
    <CenteredForm>
      <FormTitle
        title="Check your inbox"
        subtitle={subtitleText}
        titleTestId={identifiers["checkInbox.header.text"]}
        subtitleTestId={identifiers["checkInbox.header.paragraph"]}
      />

      <Styled.CheckInboxForm>
        <EmailLink href="https://gmail.com">
          <img
            src="https://www.logo.wine/a/logo/Gmail/Gmail-Logo.wine.svg"
            alt="Gmail Logo"
            width={40}
          />
          Gmail
        </EmailLink>

        <EmailLink href="https://outlook.com">
          <img
            src="https://www.logo.wine/a/logo/Outlook.com/Outlook.com-Logo.wine.svg"
            alt="Outlook Logo"
            width={40}
          />
          Outlook
        </EmailLink>

        <EmailLink href="https://mail.yahoo.com">
          <img
            src="https://cdn.iconscout.com/icon/free/png-512/yahoo-2752017-2284834.png"
            alt="Yahoo Logo"
            width={20}
          />
          Yahoo
        </EmailLink>
      </Styled.CheckInboxForm>

      <Styled.CheckInboxLinks>
        {footerLinkContent && <p>{footerLinkContent}</p>}

        <p>
          Haven&apos;t received an email? Check the Spam folder. Still nothing?
          <br />
          <a
            onClick={() => resendEmail(email)}
            data-testid={identifiers["checkInbox.link.resendEmail"]}
          >
            Re-send the email
          </a>
        </p>
      </Styled.CheckInboxLinks>
    </CenteredForm>
  );
}

interface EmailLinkProps {
  href: string;
}

const EmailLink = ({ children, href }: PropsWithChildren<EmailLinkProps>) => {
  return (
    <Button styling="light">
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        data-testid={identifiers["checkInbox.button.emails"]}
      >
        {children}
      </a>
    </Button>
  );
};
