import styled from "styled-components";

import { vars } from "styles";

export const ButtonNavigation = styled.nav`
  display: flex;
  width: 88rem;
  gap: 0.4rem;
  position: relative;

  cursor: default;

  div {
    color: ${vars.colors.grey50};
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.25;
    border-radius: 1rem;
    border: 2px solid transparent;

    transition: ${vars.transitions.hover};
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: none;
      color: ${vars.colors.white};
      border: 2px solid ${vars.colors.grey80};
    }

    &.active {
      color: ${vars.colors.white};
      border: 2px solid ${vars.colors.grey80};

      &:hover {
        color: ${vars.colors.white};
      }
    }
    a {
      text-decoration: none;
      width: 100%;
      padding: 0.9rem 1.6rem 1.1rem 1.6rem;
      font-size: 1.4rem;

      color: ${vars.colors.grey50};

      &:hover {
        color: ${vars.colors.white};
      }

      &.active {
        color: ${vars.colors.white};
      }
    }
  }
`;
