import styled from "styled-components";

export const VideosSection = styled.div`
  justify-content: flex-start;
  margin-top: 2.8rem;
  max-width: 88rem;
  width: 100%;
`;

export const Cards = styled.div`
  display: flex;
  gap: 2rem;
  max-width: 88rem;
  flex-wrap: wrap;
`;
