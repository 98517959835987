import styled from "styled-components";

import { PlayIcon } from "pages/shared/components/Attachments/icons";

import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const Play = styled(PlayIcon)`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const VideoContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
    border-radius: 1rem;
  }
`;

export const Flex = styled(BaseFlex)`
  video {
    object-fit: contain;
    outline: none;
    width: 100% !important;
    cursor: pointer;
    max-width: 90vw;
  }

  > div {
    display: flex;
    justify-content: center;
    border-radius: 2rem;
  }
`;
