const helpCenterLinks = {
  blockUsers: "http://help.shakebugs.com/en/articles/7919363-blocking-spammy-users",
  integrationStoppedWorking:
    "https://help.shakebugs.com/en/articles/5660834-our-integration-appears-to-have-stopped-working-and-i-m-getting-the-a-hiccup-happened-message",
  slackStoppedWorking:
    "https://help.shakebugs.com/en/articles/4949336-our-slack-integration-appears-to-have-stopped-working",
  jiraDoesntAcceptTickets:
    "https://help.shakebugs.com/en/articles/3474366-i-m-having-trouble-integrating-shake-with-my-jira-project-it-connects-fine-but-jira-doesn-t-accept-tickets",
  jiraNoAttachments:
    "https://help.shakebugs.com/en/articles/5248924-an-integration-with-jira-works-but-the-screenshot-and-other-files-aren-t-attached",
  pushNotifAndroid: "https://help.shakebugs.com/en/articles/7931355-how-to-enable-push-notifications-on-android",
  pushNotifIos: "https://help.shakebugs.com/en/articles/7931290-how-to-enable-push-notifications-on-ios",
};

export { helpCenterLinks };
