import React from "react";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./ModalFooter.styles";

export type ModalFooterProps = {
  toggle: () => void;
  button?: JSX.Element;
  leftButton?: JSX.Element;
};

export interface ModalButton {
  text: string;
  size?: "small" | "extraSmall" | "full";
  color?: "red" | "grey";
  styling?: "outline" | "light" | "textual";
  onClick?: () => void;
}

export const ModalFooter = ({ toggle, button, leftButton }: ModalFooterProps) => {
  return (
    <Styled.Flex $justifyContent="space-between">
      <Flex $justifyContent="start">{leftButton && leftButton}</Flex>
      <Flex $justifyContent="flex-end">
        <Styled.Button
          $hasPaddingRight={button && true}
          styling="outline"
          onClick={toggle}
          size="small"
        >
          Cancel
        </Styled.Button>
        {button && button}
      </Flex>
    </Styled.Flex>
  );
};
