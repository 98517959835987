import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import React from "react";

import { App } from "models";

import { useAppContext } from "./App.context";
import { useAppSelectionContext } from "./AppSelectionContext";

type TempAppSelectionContext = {
  setTempSelectedApp: (app: App | undefined) => void;
  tempSelectedApp: App | undefined;
};

const initial: TempAppSelectionContext = {
  setTempSelectedApp: () => {
    return;
  },
  tempSelectedApp: undefined,
};

type TempAppSelectionContextInternal = {
  tempSelectedApp: App | undefined;
};

const temoAppSelectionContext = createContext<TempAppSelectionContext>(initial);

export const TempAppSelectionContextProvider = ({ children }: { children: ReactNode }) => {
  const { apps } = useAppContext();
  const { selectedApp } = useAppSelectionContext();
  const [selection, setSelection] = useState<TempAppSelectionContextInternal>({ tempSelectedApp: undefined });

  const setTempSelectedApp = useCallback(
    (tempSelectedApp: App | undefined) => {
      setSelection({
        tempSelectedApp: tempSelectedApp
          ? apps.filter((a) => a.active).find((a) => a.key === tempSelectedApp.key)
          : undefined,
      });
    },
    [apps],
  );

  useEffect(() => {
    selectedApp && setSelection({ tempSelectedApp: undefined });
  }, [selectedApp]);

  return (
    <temoAppSelectionContext.Provider
      value={{
        tempSelectedApp: selection.tempSelectedApp,
        setTempSelectedApp,
      }}
    >
      {children}
    </temoAppSelectionContext.Provider>
  );
};

/**
 * We use URL to sync app/ws selection.
 * This context acts like a temporary in-memory selection override to enable
 * pages with no global app/ws selection to dictate navigation context on other screens.
 */
export const useTempAppSelectionContext = () => {
  const context = useContext(temoAppSelectionContext);
  return context;
};
