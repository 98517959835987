import React, { Fragment } from "react";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import Input from "components/Input/Input";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import Paragraph from "components/Paragraph/Paragraph";

import { Workspace } from "models";
import { Zapier } from "models/integrations/Zapier.model";

import { vars } from "styles";

import { Form } from "../ModalBody.styles";
import { IntegrationHelperText } from "./components/IntegrationHelperText";
import { useZapierIntegrationFormConsumer } from "./consumers/useZapierIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "./GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Zapier;
  selectedAppId: string;
  workspace: Workspace;
}

export const ZapierIntegrationModalBody = ({ integration, selectedAppId, onFinish, workspace }: Props) => {
  const {
    buttonTitle,
    customWebhookURL,
    handleFormChange,
    handleFormSubmit,
    loading,
    loadingTitle,
    customWebhookURLErr,
  } = useZapierIntegrationFormConsumer({
    integration,
    selectedAppId,
    workspace,
    onFinish,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  const isButtonDisabled =
    !customWebhookURL || customWebhookURL === integration?.webhook_url || Boolean(customWebhookURLErr);
  const tooltipText = !customWebhookURL
    ? "Please enter webhook URL first"
    : customWebhookURLErr
    ? "Please enter a valid webhook first"
    : "No changes have been made";

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <IntegrationHelperText
          arrowColor={vars.colors.zapierOrange}
          titleComponent={
            <Fragment>
              Click this link to&nbsp;
              {
                <a
                  href="https://zapier.com/editor/sandbox/draft/1/setup"
                  target="_blank"
                  rel="noreferrer"
                >
                  Create a New Zap
                </a>
              }
              &nbsp;on Zapier
            </Fragment>
          }
          textElements={[
            <Paragraph key="1">Select Webhooks by Zapier</Paragraph>,
            <Paragraph key="2">As an Event choose Catch Hook</Paragraph>,
            <Paragraph key="3">Click Continue</Paragraph>,
            <Paragraph key="4">Skip choosing the Child Key and click Continue again</Paragraph>,
            <Paragraph key="5">Paste the generated webhook URL below.</Paragraph>,
          ]}
        />

        <Input
          name="customWebhookURL"
          value={customWebhookURL}
          label="Your webhook URL"
          onChange={handleChange}
          placeholder="e.g. https://hooks.zapier.com/catch/15329/m5t7i"
          autoFocus
          error={!!customWebhookURLErr}
          helper={customWebhookURLErr?.message}
        />
        <HelperText>
          Once you click the <em>Connect</em>&nbsp;button below, you&apos;re done here and this modal will close. On
          Zapier you still have to click <em>Test trigger</em>, connect to any tool you&apos;d like to and then{" "}
          <em>Publish Zap</em>.
        </HelperText>
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: tooltipText,
              disabled: !isButtonDisabled || loading,
            }}
            disabled={isButtonDisabled || loading || Boolean(customWebhookURLErr)}
            loadingText={loading && loadingTitle}
            onClick={handleFormSubmit}
          >
            {buttonTitle}
          </Button>
        }
      />
    </Fragment>
  );
};
