import React from "react";

import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Heading from "components/Heading/Heading";
import { useModal } from "components/Modal/Modal";

import { useAuthContext } from "context/Auth/Auth.context";

import SecurityCardModalForm from "pages/Account/components/SecurityCard/SecurityCardModalForm";

import identifiers from "util/identifiers.json";

export const SecurityCard = () => {
  const { userData } = useAuthContext();
  const { Modal: SecurityCardModal, toggle, modalProps } = useModal({ size: "narrow" });

  return (
    <>
      <Card>
        <Heading
          as="h3"
          heading3
          marginBottom={24}
          testId={identifiers["info.security.header"]}
        >
          Security
        </Heading>

        <Button
          size="small"
          testId={
            userData?.hasPassword
              ? identifiers["info.security.button.changePass"]
              : identifiers["info.security.button.setPass"]
          }
          onClick={toggle}
        >
          {userData?.hasPassword ? "Change password" : "Set password"}
        </Button>
      </Card>

      <SecurityCardModalForm
        toggle={toggle}
        Modal={SecurityCardModal}
        internalProps={modalProps}
      />
    </>
  );
};
