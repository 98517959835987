import React, { Fragment } from "react";

import { HelperText } from "components/HelperText/HelperText";

import { Platform, PlatformName, PlatformOs } from "models";
import { ConsoleLogEventRes, ConsoleLogType, LogType, LogUnionType } from "models/eventTracking";

import * as Icon from "pages/shared/icons/icons";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getStatusColor } from "./LogDetails/NetworkRequestDetails";

export const iconSrcForLog = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) return <Icon.ShakeIcon />;
      return <Icon.UserEventIcon />;
    case LogType.NOTIFICATION:
      return <Icon.NotificationIcon />;
    case LogType.SYSTEM_EVENT:
      return <Icon.SystemEventIcon />;
    case LogType.SCREEN_EVENT:
      return <Icon.ScreenChangeEventIcon />;
    case LogType.CONSOLE_LOG:
      return getConsoleLogIcon(log);
    case LogType.CUSTOM_LOG:
      return <Icon.CustomLogIcon />;
    case LogType.NETWORK_REQUEST:
      return <Icon.NetworkLogIcon />;
    default:
      return <></>;
  }
};

const getConsoleLogIcon = (log: ConsoleLogEventRes) => {
  switch (log.type) {
    case ConsoleLogType.VERBOSE:
      return <Icon.ConsoleLogIconVerbose />;
    case ConsoleLogType.DEBUG:
      return <Icon.ConsoleLogIconDebug />;
    case ConsoleLogType.INFO:
      return <Icon.ConsoleLogIconInfo />;
    case ConsoleLogType.WARNING:
      return <Icon.ConsoleLogIconWarning />;
    case ConsoleLogType.ERROR:
      return <Icon.ConsoleLogIconError />;
    default:
      return <Icon.ConsoleLogIconVerbose />;
  }
};

export const messageForLog = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) return log.description;
      const property = !log.property ? "" : log.property;
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {`${log.description} ${log.class_name} ${property}`}
        </p>
      );
    case LogType.NOTIFICATION:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.title} / ${log.description}`}</p>
      );
    case LogType.SYSTEM_EVENT:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.description}`}</p>
      );
    case LogType.SCREEN_EVENT:
      return (
        <p
          style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >{`${log.description}`}</p>
      );
    case LogType.CONSOLE_LOG:
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{log.message}</p>
      );
    case LogType.CUSTOM_LOG:
      return (
        <p style={{ overflow: "hidden", flex: "1", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{log.message}</p>
      );
    case LogType.NETWORK_REQUEST:
      return (
        <Flex
          $alignItems="center"
          style={{
            overflow: "hidden",
            flex: "1",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <p style={{ color: `${vars.colors.grey30}` }}>{`${log.method}`} </p>
          {log.status_code && (
            <div style={{ paddingLeft: "2rem", display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "0.9rem",
                  height: "0.9rem",
                  borderRadius: "50%",
                  backgroundColor: getStatusColor(log.status_code),
                  marginRight: "0.4rem",
                  color: `${vars.colors.white}`,
                }}
              />
              <p style={{ color: `${vars.colors.grey30}` }}>{log.status_code}</p>
            </div>
          )}
          <p
            style={{
              paddingLeft: "2rem",
            }}
          >
            {log.url}
          </p>
        </Flex>
      );
  }
};

export const getStringLogMessage = (log: LogUnionType) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) return log.description;
      const property = !log.property ? "" : log.property;
      return `${log.description} ${log.class_name} ${property}`;
    case LogType.NOTIFICATION:
      return `${log.title} / ${log.description}`;
    case LogType.SYSTEM_EVENT:
      return `${log.description}`;
    case LogType.SCREEN_EVENT:
      return `${log.description}`;
    case LogType.CONSOLE_LOG:
      return log.message;
    case LogType.CUSTOM_LOG:
      return log.message;
    case LogType.NETWORK_REQUEST:
      return `${log.method} ${log.status_code} ${log.url}`;
  }
};

export const getActivityHistoryHelperText = (platform?: Platform) => {
  if (!platform || platform.name === PlatformName.WEB) return;
  return (
    <div style={{ position: "fixed", right: 16, width: "30vw", top: 16 }}>
      <HelperText>
        While the {getAutomatedLogs(platform)} are tracked <em>automatically</em>, the following <em>aren’t</em> so
        please enable them, in case you haven&apos;t already: {getManualLogs(platform)}
      </HelperText>
    </div>
  );
};

const getAutomatedLogs = (platform: Platform) => {
  switch (platform.name) {
    case PlatformName.ANDROID:
      return "user actions, console logs, app lifecycle and screen change events";
    case PlatformName.IOS:
      return "user actions, notifications, console logs and app lifecycle events";
    case PlatformName.FLUTTER:
      if (platform.os === PlatformOs.IOS) return "console logs, notifications, app lifecycle and screen change events";
      return "console logs, app lifecycle and screen change events";
    case PlatformName.REACT_NATIVE:
      if (platform.os === PlatformOs.IOS) return "user actions, notifications, app lifecycle and screen change events";
      return "user actions, console logs, app lifecycle and screen change events";
  }
};

const getManualLogs = (platform: Platform) => {
  switch (platform.name) {
    case PlatformName.ANDROID:
      return (
        <Fragment>
          <a
            href="https://docs.shakebugs.com/docs/android/configuration-and-data/activity-history/#network-traffic"
            target="_blank"
            rel="noreferrer"
          >
            network traffic
          </a>
          ,{" "}
          <a
            href="https://docs.shakebugs.com/docs/android/configuration-and-data/activity-history/#notifications"
            target="_blank"
            rel="noreferrer"
          >
            notifications
          </a>{" "}
          and{" "}
          <a
            href="https://docs.shakebugs.com/docs/android/configuration-and-data/activity-history/#custom-logs"
            target="_blank"
            rel="noreferrer"
          >
            custom logs
          </a>
          .
        </Fragment>
      );
    case PlatformName.IOS:
      return (
        <Fragment>
          <a
            href="https://docs.shakebugs.com/docs/ios/configuration-and-data/activity-history/#network-traffic"
            target="_blank"
            rel="noreferrer"
          >
            network traffic
          </a>
          ,{" "}
          <a
            href="https://docs.shakebugs.com/docs/ios/configuration-and-data/activity-history/#screen-changes"
            target="_blank"
            rel="noreferrer"
          >
            screen change events
          </a>{" "}
          and{" "}
          <a
            href="https://docs.shakebugs.com/docs/ios/configuration-and-data/activity-history/#custom-logs"
            target="_blank"
            rel="noreferrer"
          >
            custom logs
          </a>
          .
        </Fragment>
      );
    case PlatformName.FLUTTER:
      if (platform.os === PlatformOs.IOS)
        return (
          <Fragment>
            <a
              href="https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history/#network-traffic"
              target="_blank"
              rel="noreferrer"
            >
              network traffic
            </a>{" "}
            and{" "}
            <a
              href="https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history/#custom-logs"
              target="_blank"
              rel="noreferrer"
            >
              custom logs
            </a>
            .
          </Fragment>
        );
      return (
        <Fragment>
          <a
            href="https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history/#network-traffic"
            target="_blank"
            rel="noreferrer"
          >
            network traffic
          </a>
          ,{" "}
          <a
            href="https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history/#notifications"
            target="_blank"
            rel="noreferrer"
          >
            notifications
          </a>{" "}
          and{" "}
          <a
            href="https://docs.shakebugs.com/docs/flutter/configuration-and-data/activity-history/#custom-logs"
            target="_blank"
            rel="noreferrer"
          >
            custom logs
          </a>
          .
        </Fragment>
      );
    case PlatformName.REACT_NATIVE:
      if (platform.os === PlatformOs.IOS)
        return (
          <Fragment>
            <a
              href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#network-traffic"
              target="_blank"
              rel="noreferrer"
            >
              network traffic
            </a>
            ,{" "}
            <a
              href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#console-logs"
              target="_blank"
              rel="noreferrer"
            >
              console
            </a>{" "}
            and{" "}
            <a
              href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#custom-logs"
              target="_blank"
              rel="noreferrer"
            >
              custom logs
            </a>
            .
          </Fragment>
        );
      return (
        <Fragment>
          <a
            href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#network-traffic"
            target="_blank"
            rel="noreferrer"
          >
            network traffic
          </a>
          ,{" "}
          <a
            href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#notifications"
            target="_blank"
            rel="noreferrer"
          >
            notifications
          </a>{" "}
          and{" "}
          <a
            href="https://docs.shakebugs.com/docs/react/configuration-and-data/activity-history/#custom-logs"
            target="_blank"
            rel="noreferrer"
          >
            custom logs
          </a>
          .
        </Fragment>
      );
  }
};
