import { useCallback, useEffect, useState } from "react";

import { useAppDeps } from "App.dependencies";
import { AxiosError } from "axios";
import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useUserDataApiConsumer from "consumers/useUserDataApiConsumer";
import useWorkspaceAndAppChange from "consumers/useWorkspaceAndAppChange";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import useAbortController from "hooks/useAbortController";

import { WorkspaceData } from "models";
import { getRoleAsText, Invitation, Member, MemberRole } from "models/Member.model";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import { useAdministrationDeps } from "../Administration";

export const useTeamMembersConsumer = () => {
  const navigate = useNavigate();
  const { abortSignal } = useAbortController();
  const { selectedWorkspace } = useAppSelectionContext();

  const { administrationService } = useAdministrationDeps();
  const { appsService } = useAppDeps();
  const [teamData, setTeamData] = useState<WorkspaceData>();
  const { handleWorkspaceChange } = useWorkspaceAndAppChange();
  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getTeamData = useCallback(async () => {
    try {
      if (!selectedWorkspace) return;

      const { data } = await appsService.getTeamData(selectedWorkspace.id, abortSignal);
      setTeamData(data);
      setInitialLoading(false);
    } catch (error) {
      if (abortSignal.aborted) return;
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching team data",
      });
    }
  }, [abortSignal, appsService, selectedWorkspace]);

  const deleteMember = useCallback(
    async (userId: string) => {
      setLoading(true);
      try {
        if (!selectedWorkspace) return;

        await administrationService.deleteMember(selectedWorkspace.id, userId).then(getTeamData);

        displayToast({
          content: "Member deleted",
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: "There was an error deleting member.",
        });
      } finally {
        setLoading(false);
      }
    },
    [administrationService, selectedWorkspace, getTeamData],
  );

  const deleteCurrentMember = useCallback(
    async (userId: string) => {
      setLoading(true);
      try {
        if (!selectedWorkspace) return;

        await administrationService.deleteMember(selectedWorkspace.id, userId);

        const { workspaces } = await fetchUserDataAndDispatch();

        const availableWorkspace = [...workspaces].shift();

        if (availableWorkspace) {
          await handleWorkspaceChange(availableWorkspace);
        } else {
          navigate(generatePath(RoutePaths.CREATE_WORKSPACE), { replace: true });
        }

        displayToast({
          content: `You have been removed from ${selectedWorkspace.name} workspace.`,
        });
      } catch (error) {
        if (error.response && error.response.status === 409) {
          displayToast({
            title: "Action not performed",
            content: "There needs to be at least one admin in a team!",
          });
          return;
        }

        displayToast({
          title: "Something went wrong",
          content: "There was an error removing you from workspace.",
        });
      } finally {
        setLoading(false);
      }
    },
    [administrationService, fetchUserDataAndDispatch, handleWorkspaceChange, navigate, selectedWorkspace],
  );

  const deleteMemberInvitation = useCallback(
    async (invitation: Invitation) => {
      setLoading(true);
      try {
        if (!selectedWorkspace) return;

        await administrationService.deleteMemberInvitation(selectedWorkspace.id, invitation.id).then(getTeamData);

        displayToast({
          title: "Invite cancelled",
          content: `Invite for ${invitation.email} has been cancelled.`,
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: "There was an error deleting member invitation",
        });
      } finally {
        setLoading(false);
      }
    },
    [administrationService, selectedWorkspace, getTeamData],
  );

  const inviteMembers = useCallback(
    async (emails: string[], role: MemberRole, appIds?: string[]) => {
      setLoading(true);
      try {
        if (!selectedWorkspace) return;

        await administrationService
          .inviteMembers(selectedWorkspace.id, emails, role, appIds)
          .then(getTeamData)
          .catch((error: AxiosError) => {
            if (error.response?.status === 422)
              throw new Error(t("administration.members.teamMembers.api.email.type.error"));
          });

        displayToast({
          title: `Invite sent`,
          content: `${getRoleAsText(role)} invite sent to ${emails.join(", ")}.`,
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error?.response.data.message || "There was an error while inviting team members",
        });
      } finally {
        setLoading(false);
      }
    },
    [administrationService, selectedWorkspace, getTeamData],
  );

  const resendInvite = useCallback(
    async (invitation) => {
      setLoading(true);
      try {
        if (!selectedWorkspace) return;

        await administrationService
          .resendInvite(selectedWorkspace.id, invitation.id)
          .then(getTeamData)
          .catch((error: AxiosError) => {
            if (error.response?.status === 422)
              throw new Error(t("administration.members.teamMembers.api.email.type.error"));
          });

        displayToast({
          title: `Invite resent`,
          content: `${getRoleAsText(invitation.role)} invite resent to ${invitation.email}.`,
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error?.response.data.message || `Failed to resend invite to ${invitation.email}.`,
        });
      } finally {
        setLoading(false);
      }
    },
    [administrationService, selectedWorkspace, getTeamData],
  );

  const customizeTeamMember = async (role: MemberRole, selectedMember: Member, apps: string[]) => {
    setLoading(true);
    try {
      if (!selectedWorkspace?.id || !selectedMember) return;

      const { data } = await administrationService.customizeTeamMember(
        selectedWorkspace.id,
        selectedMember.user.id,
        role,
        apps,
      );
      if (teamData) {
        setTeamData({
          ...teamData,
          members: [...teamData.members.filter((member) => member.id !== data.id), data],
        });
      }

      displayToast({
        title: "Role customized",
        content: `${selectedMember.user.name}'s role has been successfully customized.`,
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message || "There was an error while customizing team member",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTeamData();
  }, [getTeamData]);

  return {
    teamData,
    deleteMember,
    deleteMemberInvitation,
    inviteMembers,
    customizeTeamMember,
    deleteCurrentMember,
    resendInvite,
    initialLoading,
    loading,
  };
};
