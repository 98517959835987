import React from "react";

import { AccountService } from "pages/Account/services/Account.service";
import NotificationsView from "pages/Account/views/Notifications/NotificationsView";
import PersonalInfoView from "pages/Account/views/PersonalInfo/PersonalInfoView";

import dependencyInjection from "util/dependencyInjection";

interface Services {
  accountService: AccountService;
}

const accountService = new AccountService();

const [AccountDependencyProvider, useAccountDeps] = dependencyInjection<Services>({
  services: { accountService },
});

export const AccountInfoPage = () => {
  return (
    <AccountDependencyProvider>
      <PersonalInfoView />
    </AccountDependencyProvider>
  );
};

export const NotificationsPage = () => {
  return (
    <AccountDependencyProvider>
      <NotificationsView />
    </AccountDependencyProvider>
  );
};

export { useAccountDeps };
