import { AxiosResponse } from "axios";

import { Changelog, ChangelogNotification } from "models/Changelog.model";

import ApiService from "services/Api.service";

export class FeaturesService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getChangeLogs(
    sort: "latest" | "priority" = "latest",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Changelog[]>> {
    return this.apiService.get({
      resource: `misc/changelogs?return_all=true&sort=${sort}`,
      config: {
        signal: abortSignal,
      },
    });
  }

  getChangeLogsNotifications(abortSignal?: AbortSignal): Promise<AxiosResponse<ChangelogNotification[]>> {
    return this.apiService.get({
      resource: `misc/changelog_notification`,
      config: {
        signal: abortSignal,
      },
    });
  }

  markNotificationAsRead(notificationIdId: string) {
    return this.apiService.delete({
      resource: `/misc/changelog_notification/${notificationIdId}/read`,
    });
  }
}
