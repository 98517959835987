import React from "react";

import Button from "components/Button/Button";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

interface Props {
  deleteApiKey: () => void;
  loading: boolean;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function DeleteApiKeyModalBody({ deleteApiKey, loading, Modal, internalProps }: Props) {
  const handleDeleteApiKey = (e?: React.MouseEvent<Element, MouseEvent>) => {
    e?.preventDefault();
    deleteApiKey();
  };

  return (
    <Modal
      {...internalProps}
      heading={"Are you sure?"}
      subtitle={"This will permanently delete the API key. This action in irreversible."}
      buttonElement={
        <Button
          type="button"
          size="small"
          color="red"
          disabled={loading}
          loadingText={loading && "Deleting..."}
          onClick={(e) => handleDeleteApiKey(e)}
        >
          Delete
        </Button>
      }
    ></Modal>
  );
}
