import { AsanaIntegrationUpdatePayload, AsanaStartIntegrationPayload } from "./Asana.model";
import { AzureIntegrationPayload } from "./Azure.model";
import { ClickupIntegrationUpdatePayload } from "./Clickup.model";
import { GithubIntegrationUpdatePayload, GithubStartIntegrationPayload } from "./Github.model";
import { JiraIntegrationUpdatePayload, JiraStartIntegrationPayload } from "./Jira.model";
import { LinearIntegrationUpdatePayload } from "./Linear.model";
import { SlackIntegrationPayload } from "./Slack.model";
import { TrelloIntegrationUpdatePayload } from "./Trello.model";
import { WebhookIntegrationPayload } from "./Webhook.model";
import { ZapierIntegrationUpdatePayload } from "./Zapier.model";

// Base model for integrations array for app
export interface Integration {
  active: boolean;
  created: string;
  updated: string;
  id: string;
  type: IntegrationType;
}

export interface BaseActivationChallengeRes {
  state: string;
}

export enum IntegrationType {
  ASANA = "Asana",
  CLICKUP = "Clickup",
  GITHUB = "Github",
  JIRA = "Jira",
  JIRA_SERVER = "Jira Server",
  JIRA_CLOUD = "Jira Cloud",
  TRELLO = "Trello",
  ZAPIER = "Zapier",
  AZURE_DEVOPS = "Azure",
  SLACK = "Slack",
  LINEAR = "Linear",
  WEBHOOK = "Webhook",
  SENTRY = "Sentry",
}

export type IntegrationPayload =
  | GithubIntegrationUpdatePayload
  | AsanaIntegrationUpdatePayload
  | ClickupIntegrationUpdatePayload
  | SlackIntegrationPayload
  | TrelloIntegrationUpdatePayload
  | ZapierIntegrationUpdatePayload
  | AzureIntegrationPayload
  | JiraIntegrationUpdatePayload
  | LinearIntegrationUpdatePayload
  | WebhookIntegrationPayload;

export type StartIntegrationPayload =
  | AsanaStartIntegrationPayload
  | GithubStartIntegrationPayload
  | JiraStartIntegrationPayload;

export interface OAuth2CallbackParams {
  state: string;
  code: string;
}

export type TrelloCallbackParams = { state: string; token: string };

export type SentryCallbackParams = {
  state: string;
  code: string;
  installationId: string;
};

export interface SentryPasssthroughState {
  workspaceId: string;
  workspaceSlug: string;
}

export interface CommonPassthroughState {
  workspaceId: string;
  appId: string;
  workspaceSlug: string;
}

export interface OAuth2PassthroughState extends CommonPassthroughState {
  stateId: string;
}

const integrationName = {
  [IntegrationType.GITHUB]: "GitHub",
  [IntegrationType.AZURE_DEVOPS]: "Azure DevOps",
  [IntegrationType.CLICKUP]: "ClickUp",
};

export const getIntegrationTypeName = (type?: IntegrationType) => {
  if (!type) return "";
  return integrationName[type as keyof typeof integrationName] ?? type;
};

export const getLabelWhenNotSent = (type: IntegrationType) => {
  switch (type) {
    case IntegrationType.SLACK:
      return "Slack";
    case IntegrationType.WEBHOOK:
      return "Webhook";
    case IntegrationType.ZAPIER:
      return "Zapier";
    default:
      return "Not sent";
  }
};

export const automaticForwardOption = {
  value: "1",
  label: "Automatically",
  subtitle: "All tickets are forwarded immediately",
};

export const manualForwardOption = {
  value: "",
  label: "By hand",
  subtitle: "Manually forward only the tickets you choose to",
};

export const forwardingOptions = [manualForwardOption, automaticForwardOption];
