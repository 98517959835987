import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { HelperText } from "components/HelperText/HelperText";
import CustomInput from "components/Input/Input";
import Paragraph from "components/Paragraph/Paragraph";
import BaseSelectDropdown from "components/SelectDropdown/SelectDropdown";

import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const Input = styled(CustomInput)`
  padding-bottom: 2.4rem;
`;

export const HeadingSubtitle = styled(Paragraph)`
  display: inline-block;
`;

export const AppsLink = styled(Link)`
  margin-bottom: 0rem;
`;

export const ButtonNote = styled(Paragraph)`
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
`;

export const SelectDropdown = styled(BaseSelectDropdown)`
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const Flex = styled(BaseFlex)`
  padding-top: 0.8rem;
`;

export const Helper = styled(HelperText)<{ $noMargin?: boolean }>`
  margin-bottom: 0.8rem;
  ${(props) =>
    !props.$noMargin &&
    css`
      margin-bottom: 2.4rem;
    `}
`;
