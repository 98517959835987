import { css } from "styled-components";

import { vars } from "../abstracts/vars";

export const base = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  :root {
    --toastify-color-progress-dark: ${vars.colors.purple};
  }

  html {
    // this defines the size of 1 rem to be 10px (0.625 of 16px, which is the base browser font size, 0.625 * 16 = 10
    font-size: 62.5%;
    height: 100%;
  }

  body {
    box-sizing: border-box;
    background-color: ${vars.colors.black};
    height: 100%;
    overflow: hidden;

    #__preview_portal {
      > * {
        pointer-events: none;
      }
    }
  }

  #root {
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: ${vars.colors.grey90} transparent;
  }

  *::-webkit-scrollbar {
    width: 1rem;
  }

  *::-webkit-scrollbar-track {
    background: none;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${vars.colors.grey90};
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
