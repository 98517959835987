import styled from "styled-components";

export const InputContainer = styled.div<{
  $paddingBottom?: number;
}>`
  padding-top: 0.8rem;
  padding-bottom: ${(props) => (props.$paddingBottom ? `${props.$paddingBottom}rem` : "1rem")};
`;

export const ModalInput = styled.div`
  margin-bottom: 2rem;
`;
