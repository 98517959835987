import { AxiosResponse } from "axios";

import {
  AnalyticsData,
  CrashesAnalyticsPayload,
  FeedbacksAnalyticsPayload,
  UsersAnalyticsPayload,
} from "models/Analytics.model";

import ApiService from "services/Api.service";

export class AnalyticsService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getCrashAnalytics(
    teamId: string,
    appId: string,
    payload: CrashesAnalyticsPayload,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AnalyticsData>> {
    return this.apiService.get({
      resource: `analytics/${teamId}/apps/${appId}/crashes?start_date=${payload.start_date}&end_date=${
        payload.end_date
      }&group=${payload.group}${payload.app_version ? `&app_version=${payload.app_version}` : ""}`,
      config: {
        signal: abortSignal,
      },
    });
  }

  getUserFeedbackAnalytics(
    teamId: string,
    appId: string,
    payload: FeedbacksAnalyticsPayload,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AnalyticsData>> {
    return this.apiService.get({
      resource: `analytics/${teamId}/apps/${appId}/issues?start_date=${payload.start_date}&end_date=${
        payload.end_date
      }&group=${payload.group}${payload.app_version ? `&app_version=${payload.app_version}` : ""}${
        payload.tag ? `&tag=${payload.tag}` : ""
      }`,
      config: {
        signal: abortSignal,
      },
    });
  }

  getUsersAnalytics(
    teamId: string,
    appId: string,
    payload: UsersAnalyticsPayload,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AnalyticsData>> {
    return this.apiService.get({
      resource: `analytics/${teamId}/apps/${appId}/users?start_date=${payload.start_date}&end_date=${payload.end_date}&group=${payload.group}`,
      config: {
        signal: abortSignal,
      },
    });
  }
}
