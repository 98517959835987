import { useMemo } from "react";

import { matchRoutes, useLocation } from "react-router-dom";

import { RoutePaths } from "../config/routePaths";

export const useCurrentPathRouteConfig = () => {
  const location = useLocation();

  const matchData = useMemo(
    () =>
      matchRoutes(
        Object.values(RoutePaths).map((path) => {
          return { path };
        }),
        location,
      ),
    [location],
  );

  const firstPillName = useMemo(() => {
    if (!matchData) return;

    const dynamicPath = matchData[0].route.path;

    if (!dynamicPath) return;

    if (dynamicPath.includes(RoutePaths.USER_FEEDBACK_MODULE_ROOT)) return "User feedback";
    if (dynamicPath.includes(RoutePaths.CRASH_MODULE_ROOT)) return "Crash reports";
    if (dynamicPath.includes(RoutePaths.USERS_MODULE_ROOT)) return "App users";
    if (dynamicPath.includes(RoutePaths.STATS_MODULE_ROOT)) return "Stats";

    if (dynamicPath.includes(RoutePaths.ADMINISTRATION_ROOT)) return "Administration";
    if (dynamicPath.includes(RoutePaths.ACCOUNT_ROOT)) return "Account";
  }, [matchData]);

  const hasBorderBottom = useMemo(() => {
    if (!matchData) return;

    const dynamicPath = matchData[0].route.path;

    if (!dynamicPath) return;

    if (
      dynamicPath.includes(RoutePaths.USER_FEEDBACK_MODULE_ROOT) &&
      !dynamicPath.includes(RoutePaths.USER_FEEDBACK_DETAILS)
    )
      return false;
    if (
      dynamicPath.includes(RoutePaths.CRASH_MODULE_ROOT) &&
      !dynamicPath.includes(RoutePaths.CRASH_REPORT_OVERVIEW) &&
      !dynamicPath.includes(RoutePaths.CRASH_REPORT_EVENT)
    )
      return false;
    if (dynamicPath.includes(RoutePaths.USERS_MODULE_ROOT) && !dynamicPath.includes(RoutePaths.USER_DETAILS))
      return false;

    if (dynamicPath.includes(RoutePaths.USER_FEEDBACK_LOGS) || dynamicPath.includes(RoutePaths.CRASH_REPORT_LOGS))
      return false;

    return true;
  }, [matchData]);

  return { firstPillName, hasBorderBottom };
};
