import styled from "styled-components";

import BaseCard from "components/Card/Card";
import BaseParagraph from "components/Paragraph/Paragraph";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const CardImage = styled.img`
  background: transparent;
  width: 2.35rem;
  height: 1.55rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  margin-right: 1rem;
`;

export const Dots = styled.p`
  margin-right: 1rem;
  ::before {
    content: "• • • •";
  }
`;

export const Flex = styled(BaseFlex)`
  margin-right: 10rem;
`;

export const Card = styled(BaseCard)`
  margin-bottom: 1.2rem;
`;

export const Paragraph = styled(BaseParagraph)`
  padding-bottom: 0.2rem;
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.8rem;
  margin-bottom: 1.6rem;
  width: 3.6rem;
  height: 2rem;
  background-color: ${vars.colors.green};
  border-radius: 1.6rem;
  padding: 1px 6px 3px 6px;

  p {
    color: ${vars.colors.black};
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
