import { useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import usePersonalInfoApiConsumer from "pages/Account/consumers/api/usePersonalInfoApiConsumer";
import { validateCurrentPassword } from "pages/Account/util/validatorFunctions";

import { validatePassword } from "util/ValidatorFunctions";

interface HookParams {
  onSubmitCallback?: () => void;
}

interface ChangePasswordFormState {
  currentPassword: string;
  newPassword: string;
}

const validators = {
  currentPassword: validateCurrentPassword,
  newPassword: validatePassword,
};

enum ErrorStatusCodes {
  WRONG_PASSWORD = 403,
}

export default function usePersonalInfoChangePasswordFormConsumer(params?: HookParams) {
  const { changePassword } = usePersonalInfoApiConsumer();

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<ChangePasswordFormState>({
    initialState: { currentPassword: "", newPassword: "" },
    onSubmit: handleChangePassword,
    validators,
  });

  const [loading, setLoading] = useState(false);
  const [isErrorOnSubmit, setIsErrorOnSubmit] = useState(false);

  async function handleChangePassword() {
    try {
      setLoading(true);
      setIsErrorOnSubmit(false);

      await changePassword({ current_password: formState.currentPassword, new_password: formState.newPassword });

      displayToast({ title: "Fantastic!", content: "Password changed successfully" });

      params?.onSubmitCallback?.();
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.WRONG_PASSWORD) {
        setError({
          currentPassword: new Error("Hmm, that's not the right password."),
        });
        setIsErrorOnSubmit(true);
        return;
      }
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  }

  return { handleFormChange, handleFormSubmit, formState, formError, loading, isErrorOnSubmit };
}
