import React from "react";

import Tooltip from "components/Tooltip/Tooltip";

import * as Styled from "./DetailsPaneInfoElement.styles";

interface EssentialItemProps {
  icon?: string;
  iconComponent?: JSX.Element;
  text?: string;
  tip?: string;
  onClick?: () => void;
  error?: boolean;
  Dropdown?: JSX.Element;
  testId?: string;
  subtitle?: string;
  isButton?: boolean;
}

export const DetailsPaneInfoElement: React.FC<EssentialItemProps> = ({
  iconComponent,
  text,
  tip,
  onClick,
  error,
  Dropdown,
  testId,
  subtitle,
  isButton,
}) => {
  const tooltipWrapper = (children: React.ReactNode) => {
    return (
      <Tooltip
        text={tip ?? ""}
        position="top"
      >
        {children}
      </Tooltip>
    );
  };

  const itemContent = Dropdown ? (
    <Styled.DropdownInfoElement
      $error={error}
      data-testid={testId}
    >
      {Dropdown}
    </Styled.DropdownInfoElement>
  ) : (
    <Styled.DetailsPaneInfoElement
      data-testid={testId}
      $error={error}
      onClick={onClick}
      $isButton={isButton}
    >
      {getItemContentChildren(iconComponent, text, subtitle)}
    </Styled.DetailsPaneInfoElement>
  );

  return tip ? tooltipWrapper(itemContent) : itemContent;
};

const getItemContentChildren = (iconComponent?: JSX.Element, text?: string, subtitle?: string) => {
  if (iconComponent && subtitle) {
    return (
      <Styled.InfoContent>
        <Styled.IconContainer>{iconComponent}</Styled.IconContainer>
        <div>
          <Styled.InfoContentTitle fontSize={14}>{text}</Styled.InfoContentTitle>
          {subtitle && <Styled.InfoContentSubtitle>{subtitle}</Styled.InfoContentSubtitle>}
        </div>
      </Styled.InfoContent>
    );
  }
  if (iconComponent) {
    return (
      <div style={{ padding: "0rem 0.5rem" }}>
        <Styled.IconContainer $notClickable>{iconComponent}</Styled.IconContainer>
        <div>
          <Styled.InfoContentTitle
            $notClickable
            fontSize={14}
          >
            {text}
          </Styled.InfoContentTitle>
          {subtitle && <Styled.InfoContentSubtitle>{subtitle}</Styled.InfoContentSubtitle>}
        </div>
      </div>
    );
  }
  return <span>{text}</span>;
};
