import React, { Fragment, PropsWithChildren, useState } from "react";

import { ReactComponent as CaretDownIcon } from "assets/images/shared-icons/caret-down-icon.svg";

import { DropdownItem } from "components/Dropdown/Dropdown";

import {
  CrashesFilters,
  FeedbackFilters,
  FilterType,
  TimeGroup,
  TimeRange,
  UsersFilters,
} from "models/Analytics.model";

import { groupPretty } from "pages/Analytics/helpers/analyticsHelper";

import * as Styled from "./Filters.styles";

type Filters = UsersFilters | CrashesFilters | FeedbackFilters;

export interface FiltersProps {
  appVersions?: string[];
  tags?: string[];
  filters: UsersFilters | CrashesFilters | FeedbackFilters;
  setFilters: (fil: Filters) => void;
}

export default function Filters({ filters, setFilters, appVersions, tags }: PropsWithChildren<FiltersProps>) {
  return (
    <Styled.Filters $gap={0.4}>
      <Fragment>{Dropdown("time", Object.values(TimeRange), setFilters, filters.time)}</Fragment>
      {Dropdown("group", getGroupValues(filters.time), setFilters, filters.group)}
      {appVersions &&
        "app_version" in filters &&
        Dropdown("app_version", [""].concat(appVersions), setFilters, filters.app_version)}
      {tags && "tag" in filters && Dropdown("tag", [""].concat(tags), setFilters, filters.tag)}
    </Styled.Filters>
  );
}

const Dropdown = (
  filterType: FilterType,
  items: string[],
  setFilters: (fil: UsersFilters | CrashesFilters | FeedbackFilters) => void,
  filter?: string,
) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const filteredItems = getFilteredItems(items, filterType, filter);

  return (
    <Styled.Tooltip
      position="top"
      text={filterType === "app_version" ? "Choose an app version" : "Choose a tag"}
      disabled={filterType === "group" || filterType === "time"}
    >
      <Styled.Dropdown
        label={filter ? displayItem(filterType, filter) : "All"}
        labelComponent={items.length > 1 || (items.length === 1 && filter === "") ? <CaretDownIcon /> : undefined}
        buttonElement={<Fragment />}
        position="left"
        isOpened={dropdownOpened}
        setIsOpened={setDropdownOpened}
        $disabled={!(items.length > 1 || (items.length === 1 && filter === ""))}
        disabled={!(items.length > 1 || (items.length === 1 && filter === ""))}
      >
        <div style={{ maxHeight: "150px", overflow: "overlay" }}>
          {filteredItems.length ? (
            filteredItems.map((item) => {
              return (
                <DropdownItem
                  key={item}
                  onClick={() => {
                    setDropdownOpened(false);
                    setByType(filterType, setFilters, item);
                  }}
                >
                  {item ? displayItem(filterType, item) : "All"}
                </DropdownItem>
              );
            })
          ) : (
            <Styled.Paragraph fontSize={14}>No options</Styled.Paragraph>
          )}
        </div>
      </Styled.Dropdown>
    </Styled.Tooltip>
  );
};

const setByType = (
  type: FilterType,
  // eslint-disable-next-line
  setFilters: (fil: any) => void, //type this
  item: string | TimeRange,
) => {
  switch (type) {
    case "group":
      setFilters((prev: Filters) => {
        return {
          ...prev,
          group: item,
        } as Filters;
      });
      return;
    case "time":
      return setFilters((prev: Filters) => {
        return {
          ...prev,
          group: getGroupByTime(item as TimeRange, prev.group),
          time: item,
        };
      });
    case "app_version":
      return setFilters((prev: Filters) => {
        return {
          ...prev,
          app_version: item,
        };
      });
    case "tag":
      return setFilters((prev: Filters) => {
        return {
          ...prev,
          tag: item,
        };
      });
  }
};

const getGroupValues = (time: TimeRange): string[] => {
  if (time === TimeRange.NINETY) {
    return Object.values(TimeGroup).filter((a) => a !== TimeGroup.DAY);
  }
  if (time === TimeRange.SEVEN) return [TimeGroup.DAY];
  if (time === TimeRange.TWENTY_EIGHT) {
    return Object.values(TimeGroup).filter((a) => a !== TimeGroup.MONTH);
  }
  return [TimeGroup.MONTH];
};

const getGroupByTime = (chosenTime: TimeRange, chosenGroup: TimeGroup) => {
  if (chosenTime === TimeRange.SEVEN && chosenGroup !== TimeGroup.DAY) return TimeGroup.DAY;

  if (
    (chosenTime === TimeRange.NINETY && chosenGroup === TimeGroup.DAY) ||
    (chosenTime === TimeRange.TWENTY_EIGHT && chosenGroup === TimeGroup.MONTH)
  ) {
    return TimeGroup.WEEK;
  }
  if (chosenTime === TimeRange.YEAR && chosenGroup !== TimeGroup.MONTH) {
    return TimeGroup.MONTH;
  }
  if (chosenTime === TimeRange.TWENTY_EIGHT && chosenGroup === TimeGroup.DAY) {
    return TimeGroup.WEEK;
  }
  return chosenGroup;
};

const displayItem = (type: FilterType, item: string) => {
  if (type === "group") {
    return groupPretty(item as TimeGroup);
  }
  return item;
};

const getFilteredItems = (items: string[], type: FilterType, filter?: string) => {
  const filteredItems = filter ? items.filter((a) => a !== filter) : items;

  return filteredItems;
};
