import { rgba } from "polished";
import styled, { css } from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/close-icon.svg";

import { vars } from "styles";

export const LineSeparator = styled.div`
  height: 1px;
  background-color: ${rgba(vars.colors.grey100, 1)};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2.5rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.8rem;
  padding-top: 0.4rem;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  margin: -0.7rem;
`;

export const KeyboardKey = styled.div`
  border-radius: 4px;
  border: 1px solid ${vars.colors.grey80};
  background: ${vars.colors.grey90};
  padding: 1px 5px 2px 5px;
  text-color: ${vars.colors.grey30};
`;

export const HeaderTip = styled.p`
  padding-right: 1rem;
  display: flex;
  font-size: 12px;
  color: ${vars.colors.grey60};
  gap: 0.4rem;
  align-items: center;
`;

export const NetworkRequestSelectButton = styled.button<{ $selected?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  margin-right: 2rem;
  font-size: 1.5rem;
  font-weight: medium;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  background-color: transparent;
  color: ${vars.colors.grey20};
  transition: ${vars.transitions.hover};
  text-decoration: none;

  ${({ $selected }) =>
    !$selected &&
    css`
      color: ${vars.colors.grey70};
    `}

  &:hover, &:active, &:focus {
    text-decoration: none;
  }
`;
