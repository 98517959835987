import styled from "styled-components";

import Dropdown from "components/Dropdown/Dropdown";
import { HelperText } from "components/HelperText/HelperText";
import Paragraph from "components/Paragraph/Paragraph";
import BaseTooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";

export const UserModeDropdown = styled(Dropdown)`
  background: ${vars.colors.grey100};
  border: 1px solid ${vars.colors.grey90};
  border-radius: 1rem;

  > div:first-child {
    padding: 1rem;
  }

  span {
    color: ${vars.colors.grey30};
    white-space: nowrap;
  }
`;

export const ModeIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;

export const Subtitle = styled(Paragraph)`
  margin-left: 2rem;
  margin-bottom: 0.8rem;
`;

export const Helper = styled(HelperText)`
  height: 3.1rem;
  p {
    display: flex;
    align-items: center;
  }
`;

export const Tooltip = styled(BaseTooltip)`
  width: 12.5rem;
`;
