import React, { useMemo } from "react";

import { generatePath } from "react-router-dom";

import { ReactComponent as Phone } from "assets/images/billing/phone-icon.svg";
import { ReactComponent as Warning } from "assets/images/billing/warning-icon.svg";
import { ReactComponent as Desktop } from "assets/images/billing/website-icon.svg";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import { CancelSubscriptionBody } from "components/Dialog/configurations/CancelSubscriptionBody";
import { useModal } from "components/Modal/Modal";
import Paragraph from "components/Paragraph/Paragraph";
import { GroupedOptions, Option } from "components/SelectDropdown/SelectDropdown";
import Tooltip from "components/Tooltip/Tooltip";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";
import { Customer, Plan, PlanInterval, PlanType } from "models/billing";
import { isAdmin } from "models/Member.model";

import { useSubscriptionFormConsumer } from "pages/Administration/consumers/useSubscriptionFormConsumer";

import { RoutePaths } from "router/config/routePaths";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getBillableApps } from "util/appsUtil";
import identifiers from "util/identifiers.json";

import * as Styled from "./YourSubscriptionCard.styles";
import { ChangeSubscriptionModalBody } from "../../ModalBody/Subscriptions/ChangeSubscriptionModalBody";

const defaultPlanValue = { label: "Free plan", value: "", subtitle: "$0 forever" };
const defaultPlanAddOnValue = {
  label: "Up to 10k app installs",
  value: "",
  subtitle: "$0 forever",
} as Option;

interface Props {
  plans: Plan[];
  addOns: Plan[];
  customer?: Customer;
  cancelSubscription: () => Promise<void>;
}

export function YourSubscriptionCard({ plans, addOns, customer, cancelSubscription }: Props) {
  const { apps } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const isUserAdmin = isAdmin(selectedWorkspace?.role);

  const activeApps = useMemo(() => getBillableApps(apps), [apps]);

  const {
    Modal: SubscriptionChangeModal,
    toggle: toggleChangeSubscription,
    modalProps: subscriptionChangeInternal,
  } = useModal({ size: "full" });

  const cancelModal = useBaseDialog({});

  const { formState, handleFormChange, handleFormSubmit, loading } = useSubscriptionFormConsumer({
    customer,
    toggleChangeSubscription,
    plans,
    addOns,
  });

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);

    if (name === "plan" && option.label === "Free plan") {
      handleFormChange("addOn", defaultPlanAddOnValue);
    }

    if (name === "plan" && formState.addOn.value) {
      const chosenPlan = plans.find((plan) => plan.id === option.value) as Plan;
      const chosenAddOn = addOns.find((plan) => plan.id === formState.addOn.value) as Plan;

      if (chosenPlan && chosenPlan.interval !== chosenAddOn.interval) {
        const addOnToMatch = addOns.find(
          (addOn) => addOn.product_name === chosenAddOn.product_name && addOn.interval === chosenPlan.interval,
        );

        if (addOnToMatch) {
          const addOnToMatchOption = {
            value: addOnToMatch?.id,
            label: addOnToMatch.product_name,
            subtitle: getPlanSubtitle(addOnToMatch),
          };
          handleFormChange("addOn", addOnToMatchOption);
        }
      }
    }
  };

  const isDataUnchanged =
    (Boolean(customer?.subscription?.items.find((item) => item.plan.id === formState.plan.value)) &&
      Boolean(customer?.subscription?.items.find((item) => item.plan.id === formState.addOn.value))) ||
    (Boolean(customer?.subscription?.items.find((item) => item.plan.id === formState.plan.value)) &&
      formState.addOn.value === "" &&
      !Boolean(customer?.subscription?.items.find((item) => item.plan.type === "devices"))) ||
    (formState.addOn.value === "" &&
      formState.plan.value === "" &&
      !Boolean(customer?.subscription?.items.find((item) => item.plan.type === "devices")) &&
      !Boolean(customer?.subscription?.items.find((item) => item.plan.type === "plan")));
  const canUserEdit = isUserAdmin || (!isUserAdmin && !Boolean(customer?.subscription));

  const isButtonDisabled = !formState.plan.value;

  const tooltipText = customer?.subscription?.cancel_at_period_end
    ? "Please resume subscription first"
    : !canUserEdit
    ? "Only admins can change the subscription."
    : isButtonDisabled
    ? "Please choose a plan first"
    : isDataUnchanged
    ? "No changes have been made"
    : "";

  return (
    <AdministrationCard
      title="Your subscription"
      testId={identifiers["subscription.header.yourSubscription"]}
    >
      <Styled.Helper $noMargin={true}>
        <Paragraph>
          Read all about Shake plans, startup discounts and add-ons on our&nbsp;
          <a
            href="https://www.shakebugs.com/pricing/"
            target="_blank"
            rel="noreferrer"
          >
            website
          </a>
          .
        </Paragraph>
      </Styled.Helper>
      <Styled.Helper>
        <Paragraph>
          Tiny new startup?&nbsp;
          <a
            href="https://www.shakebugs.com/startups"
            target="_blank"
            rel="noreferrer"
          >
            Apply for a discount
          </a>
          . Approved? Follow the instructions you received.
        </Paragraph>
      </Styled.Helper>
      <Paragraph
        fontWeight="bold"
        color={vars.colors.grey30}
        marginBottom={10}
      >
        Plan
      </Paragraph>
      <Flex
        $alignItems="center"
        $gap={0.4}
        style={{ paddingBottom: "1.2rem" }}
      >
        <Desktop />
        <Styled.HeadingSubtitle fontWeight={500}>
          {`Your workspace has ${
            activeApps.filter((a) => a.platform.name === PlatformName.WEB).length
          } active websites - Shake for websites is free.`}
        </Styled.HeadingSubtitle>
      </Flex>
      <Flex
        $alignItems="flex-start"
        $gap={0.4}
      >
        <Phone />
        <Styled.HeadingSubtitle fontWeight={500}>
          {`Your workspace has ${
            activeApps.filter((a) => a.platform.name !== PlatformName.WEB).length
          } active mobile apps. To lower this, deactivate some of the `}
          <Styled.AppsLink
            to={generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL, {
              workspaceSlug: selectedWorkspace?.slug ?? null,
            })}
            data-testid={identifiers["subscription.link.apps"]}
          >
            Apps
          </Styled.AppsLink>
          .
        </Styled.HeadingSubtitle>
      </Flex>

      <Tooltip
        text={tooltipText}
        disabled={(canUserEdit && !customer?.subscription?.cancel_at_period_end) || loading}
        position="top"
        offset={0}
      >
        <Styled.SelectDropdown
          label="Plan"
          isSearchable={false}
          defaultValue={defaultPlanValue}
          groupedOptions={getGroupedOptions(getPlansAsOptions(plans, PlanType.PLAN))}
          disabled={!canUserEdit || loading || customer?.subscription?.cancel_at_period_end}
          value={formState.plan}
          name="plan"
          onChange={optionDidChange}
          testId={identifiers["subscription.currentPlan.button"]}
          hasSubtitles
        />
      </Tooltip>

      <Paragraph
        fontWeight="bold"
        color={vars.colors.grey30}
        marginBottom={10}
        style={{ marginTop: "1.2rem" }}
      >
        Add-on
      </Paragraph>

      <Flex
        $alignItems="center"
        $gap={0.4}
        style={{ paddingBottom: "1.2rem" }}
      >
        <Desktop />
        <Styled.HeadingSubtitle fontWeight={500}>
          Website visits are not counted — they are free.
        </Styled.HeadingSubtitle>
      </Flex>

      <Flex
        $alignItems="center"
        $gap={0.4}
      >
        {isAboveInstallLimits(
          selectedWorkspace?.unique_devices,
          addOns.find((a) => a.id === formState.addOn.value),
        ) ? (
          <Warning />
        ) : (
          <Phone />
        )}
        <Styled.HeadingSubtitle fontWeight={500}>
          {`There are ${selectedWorkspace?.unique_devices} app installs in active apps. To lower this, deactivate some of the `}
          <Styled.AppsLink
            to={generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL, {
              workspaceSlug: selectedWorkspace?.slug ?? "",
            })}
          >
            Apps
          </Styled.AppsLink>
          .
        </Styled.HeadingSubtitle>
      </Flex>

      <Tooltip
        text={tooltipText}
        disabled={(canUserEdit && !customer?.subscription?.cancel_at_period_end && !isButtonDisabled) || loading}
        position="top"
        offset={0}
      >
        <Styled.SelectDropdown
          label="Add-on"
          isSearchable={false}
          defaultValue={defaultPlanAddOnValue}
          disabled={isButtonDisabled || !canUserEdit || loading || customer?.subscription?.cancel_at_period_end}
          groupedOptions={getGroupedOptions(
            [defaultPlanAddOnValue].concat(getAddOnOptionsPerInterval(addOns, formState.plan, plans) ?? []),
          )}
          value={formState.addOn}
          name="addOn"
          onChange={optionDidChange}
          testId={identifiers["subscription.addon.button"]}
          hasSubtitles
        />
      </Tooltip>

      <Styled.Flex>
        <Button
          type="submit"
          size="small"
          disabled={!canUserEdit || isDataUnchanged || loading}
          onClick={() =>
            Boolean(customer?.subscription) && formState.plan.value === "" && formState.addOn.value === ""
              ? cancelModal.setOpen(true)
              : !Boolean(customer?.subscription)
              ? handleFormSubmit()
              : toggleChangeSubscription()
          }
          tooltip={{
            text: tooltipText,
            position: "top",
            disabled: canUserEdit && !isDataUnchanged,
          }}
          testId={identifiers["subscription.button.proceed"]}
          loadingText={loading && "Proceeding..."}
        >
          Preview the cost breakdown
        </Button>
        <Styled.ButtonNote p2>No worries, clicking this button does not charge you</Styled.ButtonNote>
      </Styled.Flex>

      <SubscriptionChangeModal
        {...subscriptionChangeInternal}
        heading="Subscription update"
        testId={identifiers["subscription.modal.header.change"]}
        buttonElement={
          <Button
            onClick={handleFormSubmit}
            disabled={loading}
            size="small"
            testId={identifiers["subscription.modal.button.change"]}
            loadingText={loading && "Confirming..."}
          >
            Confirm
          </Button>
        }
      >
        <ChangeSubscriptionModalBody
          currentPlan={customer?.subscription?.items.find((a) => a.plan.type === PlanType.PLAN)?.plan as Plan}
          currentAddOn={customer?.subscription?.items.find((a) => a.plan.type === PlanType.DEVICES)?.plan as Plan}
          newPlan={plans.find((plan) => plan.id === formState.plan.value)}
          newAddOn={addOns.find((a) => a.id === formState.addOn.value)}
        />
      </SubscriptionChangeModal>

      <BaseDialog {...cancelModal}>
        <CancelSubscriptionBody
          onCancel={() => {
            cancelModal.setOpen(false);
            cancelSubscription();
          }}
        />
      </BaseDialog>
    </AdministrationCard>
  );
}

const getGroupedOptions = (options: Option[]) => {
  const free = {
    label: "",
    options: options.filter((a) => a.value === ""),
  } as GroupedOptions;

  const yearly = {
    label: "Annual billing • Save 20%",
    options: options.filter(
      (a) => a.subtitle?.includes(`once a ${PlanInterval.WEEK}`) || a.subtitle?.includes(`once a ${PlanInterval.YEAR}`),
    ),
  } as GroupedOptions;

  const monthly = {
    label: "Monthly billing",
    options: options.filter(
      (a) => a.subtitle?.includes(`once a ${PlanInterval.DAY}`) || a.subtitle?.includes(`once a ${PlanInterval.MONTH}`),
    ),
  } as GroupedOptions;

  return [free].concat([monthly]).concat([yearly]);
};

const getAddOnOptionsPerInterval = (addOns: Plan[], chosenPlanOption: Option, plans: Plan[]) => {
  if (!chosenPlanOption.value) {
    return getPlansAsOptions(addOns, PlanType.DEVICES);
  }

  const chosenPlan = plans.find((plan) => plan.id === chosenPlanOption.value) as Plan;
  const intervalPlans = addOns.filter((plan) => plan.interval === chosenPlan.interval);
  return getPlansAsOptions(intervalPlans, PlanType.DEVICES);
};

const getPlansAsOptions = (plans: Plan[], type: PlanType) => {
  const free = { label: "Free plan", value: "", subtitle: "$0 forever" } as Option;
  const planOptions = plans.map((plan) => {
    return {
      label: plan.product_name,
      value: plan.id,
      subtitle: getPlanSubtitle(plan),
    } as Option;
  });

  if (type === PlanType.PLAN) return [free].concat(planOptions);
  return planOptions;
};

export const getPlanSubtitle = (plan: Plan) => {
  const amountPerMonth =
    plan.interval === PlanInterval.YEAR || plan.interval === PlanInterval.WEEK ? plan.amount / 12 : plan.amount;
  return `$${amountPerMonth.toString().slice(0, -2)} per month \u00A0\u00A0\u00A0\u00A0Billed once a ${
    plan.interval
  } as $${plan.amount.toString().slice(0, -2)}`;
};

const isAboveInstallLimits = (uniqueDevicesNum?: number, addOn?: Plan) => {
  if (!uniqueDevicesNum) return false;
  if (!addOn) {
    if (uniqueDevicesNum >= 10000) return true;
    return false;
  }

  const addOnNumString = addOn.product_name.split(" ")[0];
  let addOnNum = 0;

  if (addOnNumString.includes("M")) addOnNum = parseInt(addOnNumString.replace("M", "")) * 1000000;
  if (addOnNumString.includes("k")) addOnNum = parseInt(addOnNumString.replace("K", "")) * 1000;
  if (uniqueDevicesNum >= addOnNum) return true;
  return false;
};
