import React, { Fragment, useState } from "react";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Paragraph from "components/Paragraph/Paragraph";
import Tooltip from "components/Tooltip/Tooltip";

import { Invoice } from "models/billing";
import { dateStringForMappingFiles } from "models/helpers/time/creationTime";

import { vars } from "styles";

import identifiers from "util/identifiers.json";

interface Props {
  receipt: Invoice;
}

export const ReceiptRow = ({ receipt }: Props) => {
  const DropdownButton = () => {
    const [isOpened, setIsOpened] = useState(false);
    if (!receipt.receipt_url) return <CenteredCell />;

    const handleClick = () => {
      window.open(receipt.receipt_url);
      setIsOpened(!isOpened);
    };

    return (
      <CellEnd>
        <Dropdown
          setIsOpened={setIsOpened}
          isOpened={isOpened}
          testId={identifiers["billing.button.options"]}
        >
          <DropdownItem
            onClick={handleClick}
            testId={identifiers["billing.dropdown.open"]}
          >
            Open
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    );
  };

  return (
    <Fragment>
      <CenteredCell data-testid={identifiers["billing.info.receipt.date"]}>
        <Paragraph color={vars.colors.grey30}>
          {receipt.finalized_at ? dateStringForMappingFiles(new Date(receipt.finalized_at)) : ""}
        </Paragraph>
      </CenteredCell>
      <CenteredCell data-testid={identifiers["billing.info.receipt.date"]}>
        <Paragraph color={vars.colors.grey30}>{`$${receipt.total.toString().slice(0, -2)}.${receipt.total
          .toString()
          .slice(-2)}`}</Paragraph>
      </CenteredCell>
      <Tooltip
        text={receipt.description}
        position="top"
        offset={0}
      >
        <CenteredCell>
          <Paragraph color={vars.colors.grey30}>{receipt.description}</Paragraph>
        </CenteredCell>
      </Tooltip>
      <DropdownButton />
    </Fragment>
  );
};
