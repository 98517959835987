import styled, { css } from "styled-components";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export const Title = styled(Flex)`
  top: 1rem;
  left: 1rem;
  svg {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0.4rem;
  }
  word-break: break-word;
`;

export const AttachmentContainer = styled.div`
  padding: 0.8rem;
  height: 100%;
  position: relative;
`;

export const DownloadButton = styled.a`
  height: 3.8rem;
  width: 3.8rem;
  border: 1px solid ${vars.colors.grey80};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${vars.colors.grey90};
`;

export const AttachmentText = styled.div<{ $longTitle?: boolean }>`
  max-height: 8.4rem;
  p {
    width: 24.4rem;
  }

  ${(props) =>
    props.$longTitle &&
    css`
      max-height: 7rem;
    `}

  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0.8rem;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
