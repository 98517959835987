import styled, { css } from "styled-components";

import BaseTooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";

const buttonSize = {
  small: css`
    height: 4rem;
    min-width: 9rem;
  `,
  extraSmall: css`
    height: 4rem;
    min-width: 7rem;
  `,
  xxSmall: css`
    height: 3.2rem;
    min-width: 8.3rem;
  `,
  full: css`
    display: flex;
    width: 100%;
  `,
  tallerSmall: css`
    height: 4.8rem;
  `,
  tallerFull: css`
    height: 4.8rem;
    display: flex;
    width: 100%;
  `,
};

const buttonStyling = {
  outline: css`
    border: 1px solid ${vars.colors.grey90};
    background-color: ${vars.colors.grey100};

    &:hover,
    &:focus,
    &:active {
      background-color: ${vars.colors.grey90};
      border-color: ${vars.colors.grey80};
    }
  `,
  textual: css`
    padding: 0;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      text-decoration: underline;
    }
  `,
  flat: css`
    padding: 0;
    background-color: transparent;
    font-size: 1.6rem;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      background-color: ${vars.colors.grey100};
    }
  `,
  light: css`
    background-color: ${vars.colors.grey100};

    &:hover,
    &:focus,
    &:active {
      background-color: ${vars.colors.grey100Darken};
    }
  `,
};

const buttonColor = {
  red: css`
    background-color: ${vars.colors.red};

    &:hover,
    &:focus,
    &:active {
      background-color: ${vars.colors.redDarken};
    }
  `,
  grey: css`
    background-color: ${vars.colors.grey100};

    &:hover,
    &:focus,
    &:active {
      background-color: ${vars.colors.grey100};
    }
  `,
};

const disabled = css`
  opacity: 0.3;
  cursor: not-allowed;

  &:active {
    transform: translateY(0);
  }
`;

const iconOnlyButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  padding: 1rem;

  .icon {
    margin-right: 0;
  }
`;

export const ButtonWrap = styled.button<{
  $size?: "small" | "extraSmall" | "full" | "tallerFull" | "tallerSmall" | "xxSmall";
  $styling?: "outline" | "light" | "flat" | "textual" | "";
  $icon?: boolean;
  $color?: "red" | "grey";
}>`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  color: ${vars.colors.white};
  background-color: ${vars.colors.purple};
  height: 4rem;
  min-width: 10rem;
  border-radius: 1rem;
  transition: ${vars.transitions.hover};

  &:hover {
    background-color: ${vars.colors.purpleDarken};
  }

  &:active {
    transform: translateY(1px);
  }

  .icon {
    margin-right: 1.2rem;
  }

  ${({ $size }) => $size && buttonSize[$size]}
  ${({ $icon }) => $icon && iconOnlyButton}
  ${({ $styling }) => $styling && buttonStyling[$styling]}
  ${({ $color }) => $color && buttonColor[$color]}
  ${(props) => props.disabled && disabled}
`;

export const Tooltip = styled(BaseTooltip)`
  width: auto;
`;
