import styled from "styled-components";

import { pxToRem } from "styles/abstracts/utils";

export const PageContentWrap = styled.section`
  padding: 2.4rem 2rem 4rem 2rem;
  display: flex;
  justify-content: center;
  min-width: 49rem;
  min-height: 100%;
  cursor: default;
`;

export const PageLayout = styled.div<{ $width?: number }>`
  width: ${(props) => (props.$width ? pxToRem(props.$width) : "70rem")};
  display: flex;
  flex-direction: column;
`;
