import styled from "styled-components";

import BaseParagraph from "components/Paragraph/Paragraph";

export const Paragraph = styled(BaseParagraph)`
  width: 21.6rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
