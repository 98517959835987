import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { QuickActionsMenu } from "pages/shared/components/QuickActionsMenu/QuickActionsMenu";

export function AppLevelLayout() {
  usePageTracking();
  return (
    <>
      <Outlet />
      <QuickActionsMenu />
    </>
  );
}

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics) window.analytics.page(location);
  }, [location]);
};
