import { useMemo } from "react";

import { useAppContext } from "context/App/App.context";

import { App } from "models";
import { Member } from "models/Member.model";
import { ConditionType } from "models/Rule.model";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { useUserFeedbackDeps } from "pages/UserFeedback/UserFeedback";

import {
  AllFieldNameUXOperators,
  Attribute,
  Filter,
  FilterHit,
  FilterHitDisplay,
  UseModuleAttributes,
} from "../sharedTypes";
import { useAttributes } from "../useAttributes";
import {
  attributeIconFor,
  findAssigneeFilterHits,
  getFieldValue,
  getMappedAssignees,
  getUXOperatorForFilter,
  getUXOperators,
  isRangeFilter,
  partialFilterFor,
} from "../utils";

const availableOperators = ["is", "contains"] as const;

export function useRulesAttributes(
  types?: ConditionType[],
  selectedApp?: App,
  predefinedAttributes?: Attribute[],
): UseModuleAttributes {
  const { wsTeamMembers } = useAppContext();
  const { userFeedbackService } = useUserFeedbackDeps();

  const { addAttribute, removeAttribute, updateAttribute, attributes } = useAttributes({
    predefinedAttributes,
    withoutSearchParams: true,
  });

  const validAttributesJSONString = useMemo(
    () => JSON.stringify(attributes.filter((attr) => isValidAttribute(attr))),
    [attributes],
  );

  return {
    attributes: attributes.map((attr) => {
      return {
        name: attr.field_name,
      };
    }),
    availableAttributes: useMemo(() => {
      return [{ valuesWithId: getAvailableAttributes(attributes, types), title: "" }];
    }, [types, attributes]),
    validAttributes: useMemo(() => JSON.parse(validAttributesJSONString), [validAttributesJSONString]),
    isFilterUXValid: (atIndex) => isFilterUXValid(atIndex, attributes),
    searchBoxInitialOpen,
    shouldOpenSearchBox,
    showsHitsResults,
    showSearch: (attributeName?: string) => {
      if (attributeName === "set_issue_priority" || attributeName === "set_issue_status") return false;
      return true;
    },
    fetchHitsFN: ({ deps, signal, attributeName, query }) => {
      if (!selectedApp) return Promise.resolve([]);
      const workspaceID = deps[0];

      if (
        attributeName === "issue_title" ||
        attributeName === "set_issue_priority" ||
        attributeName === "set_issue_status"
      ) {
        return Promise.resolve([]);
      }

      if (attributeName !== "assign_issue") {
        return userFeedbackService
          .getUserFeedbackFiltersHits(workspaceID, selectedApp.id, query, mapNameToFeedbackName(attributeName), signal)
          .then(({ data }) => data);
      } else {
        return Promise.resolve([]);
      }
    },
    add: (attributeName: string, rule_id?: string) => {
      addAttribute({
        field_name: attributeName,
        rule_id: rule_id,
        filter: undefined,
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    addExternal(attributeName: string, filterValue: string) {
      addAttribute({
        field_name: attributeName,
        filter: { ...partialFilterFor("is"), fieldValue: filterValue },
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    update: (identifier: number, value: string | undefined | null, selectedOperator: string) => {
      const current = attributes.find((_, index) => index === identifier);
      if (!current) return;

      const filter: Filter = { ...partialFilterFor(selectedOperator), fieldValue: value };

      const attr: Attribute = {
        attributeOperator: identifier == 0 ? undefined : "and",
        field_name: current.field_name,
        filter,
      };

      updateAttribute(identifier, attr);
    },
    remove: (atIndex: number) => {
      removeAttribute(atIndex);
    },
    filterSelectorInitialOpen: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;

      return (
        !attribute.filter?.fieldValue &&
        attribute.filter?.fieldValue !== null &&
        isFilterUXValid(identifier, attributes) &&
        identifier === attributes.length - 1
      );
    },
    getAttributeValuePresentation: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return "";
      return (
        (attribute.filter && getFieldValue(attribute.field_name, wsTeamMembers, attribute.filter.fieldValue)) ||
        " is missing value"
      );
    },
    getAttributeIcon: (attributeName: string) => {
      return attributeIconFor(attributeName);
    },
    getAttributeValue: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter?.fieldValue;
    },
    getAvailableUXOperators: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];
      return getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name));
    },
    getSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute || !attribute.filter) return;
      return getUXOperatorForFilter(attribute.filter, attribute.field_name);
    },
    getPreSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter
        ? getUXOperatorForFilter(attribute.filter, attribute.field_name)
        : getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name))[0];
    },
    getPredefinedValuesForAttribute: (identifier: number, query?: string) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];

      return predefinedValuesForFieldName(attribute.field_name, query, wsTeamMembers);
    },
    getAttributeOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.attributeOperator;
    },
    attributeHasDefinedFilter: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;
      return !!attribute.filter;
    },
    filterHitMapper,
    isEnterKeyEnabled: (attributeName: string) => {
      if (attributeName === "issue_title") return true;
      return false;
    },
    filterLabel: "clause",
    addButton: (attributeName: string) => {
      if (attributeName === "tag_issue" || attributeName === "issue_contains_tag" || attributeName === "tag") {
        return true;
      }
      return false;
    },
  };
}

function getAvailableAttributes(current: Array<Attribute>, types?: ConditionType[]) {
  if (!types) return [];
  return getAvailableFieldNames(types).filter((field) => !current.find((a) => a.field_name === field.name));
}

function getAvailableFieldNameUXOperators(fieldName: string) {
  switch (fieldName) {
    case "issue_title":
      return Array.from(availableOperators.filter((op) => op === "contains"));
    default:
      return Array.from(availableOperators.filter((op) => op !== "contains"));
  }
}

function isValidAttribute(attribute: Attribute) {
  if (!attribute.filter) return false;

  if (isRangeFilter(attribute.filter)) {
    return attribute.filter.fieldValue !== undefined;
  }

  if (!attribute.filter.fieldValue) return false;

  return attribute.filter.fieldValue.length > 0;
}

function isFilterUXValid(identifier: number, attributes: Attribute[]) {
  const attribute = attributes.find((_, index) => index === identifier);
  if (!attribute) return false;

  if (!attribute.filter) return true;
  return isValidAttribute(attribute);
}

function searchBoxInitialOpen(attributeName: string, selectedOperator: string) {
  const fieldName = attributeName;
  return fieldName !== "assign_issue" || (fieldName === "assign_issue" && selectedOperator !== "is_unknown");
}

function shouldOpenSearchBox(selectedUXOperator: string) {
  if ((selectedUXOperator as AllFieldNameUXOperators) === "is_unknown") return false;
  return true;
}

function showsHitsResults(attributeName: string) {
  if (
    attributeName === "issue_title" ||
    attributeName === "set_issue_priority" ||
    attributeName === "set_issue_status" ||
    attributeName === "assign_issue"
  ) {
    return false;
  }

  return true;
}

function predefinedValuesForFieldName(fieldName: string, query?: string, members?: Member[]) {
  switch (fieldName) {
    case "set_issue_priority":
      return [
        { name: TicketPriority.HIGH, value: TicketPriority.HIGH },
        { name: TicketPriority.MEDIUM, value: TicketPriority.MEDIUM },
        { name: TicketPriority.LOW, value: TicketPriority.LOW },
      ];
    case "set_issue_status":
      return [
        { name: TicketStatus.NEW, value: TicketStatus.NEW },
        { name: TicketStatus.IN_PROGRESS, value: TicketStatus.IN_PROGRESS },
        { name: TicketStatus.CLOSED, value: TicketStatus.CLOSED },
      ];
    case "assign_issue":
      return findAssigneeFilterHits(query ?? "", getMappedAssignees([], fieldName, members));
  }
  return [];
}

export function filterHitMapper(fieldName: string, issueFilters: FilterHit[], filterValue?: string | null | undefined) {
  const displayFilters = [] as FilterHitDisplay[];

  if (fieldName === "assign_issue")
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: filter.name,
        isChecked: filter.value === filterValue,
      });
    });
  else {
    issueFilters.map((filter) => {
      displayFilters.push({
        value: filter.value,
        name: filter.name,
        label: filter.value,
        isChecked: filter.value === filterValue,
      });
    });
  }

  return displayFilters;
}

export function getAvailableFieldNames(types: ConditionType[]) {
  const arr = [] as { id: string; name: string }[];
  types.map((type) => {
    arr.push({ name: type.method, id: type.id });
  });
  return arr;
}

const mapNameToFeedbackName = (attributeName: string) => {
  if (attributeName === "assign_issue") return "assignee_id";
  if (attributeName === "issue_app_version") return "app_version.full_version";
  if (attributeName === "issue_current_view") return "current_view";
  if (attributeName === "tag_issue" || attributeName === "issue_contains_tag" || attributeName === "tag") return "tags";
  if (attributeName === "set_issue_status") return "status";
  if (attributeName === "set_issue_priority") return "priority";
  return "";
};
