import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { CenteredForm } from "components/CenteredForm/CenteredForm";

import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import environment from "environments";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";

import { RoutePaths } from "router/config/routePaths";

export function MarketingChannelSurvey() {
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const tallyObject = Tally as any;
    if (!tallyObject) return;

    tallyObject.loadEmbeds();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const tallyObject = Tally as any;
    if (!tallyObject) return;

    // eslint-disable-next-line
    function handler(event: MessageEvent<any>) {
      if (typeof event.data !== "string") return;
      if (!event.data.includes("Tally.FormSubmitted")) return;
      navigate(RoutePaths.ROOT, { state: null });
    }

    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [navigate]);

  const formURL =
    environment.name === "production"
      ? `https://forms.shakebugs.com/marketing_channel?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&userId=${userData?.id}&workspaceId=${selectedWorkspace?.id}`
      : `https://forms.shakebugs.com/marketing_channel_dev?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&userId=${userData?.id}&workspaceId=${selectedWorkspace?.id}`;

  return (
    <CenteredForm>
      <FormTitle
        title="Welcome"
        subtitle="Before you start fixing, just 3 quick questions – so we can make Shake less shaky."
      />
      <iframe
        data-tally-src={formURL}
        loading="lazy"
        onLoad={() => {
          /// iFrame loaded
          return;
        }}
        frameBorder="0"
        width="100%"
        height="500"
      ></iframe>
    </CenteredForm>
  );
}
