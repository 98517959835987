import { useQuery } from "@tanstack/react-query";

import useAbortController from "hooks/useAbortController";

import { useUserFeedbackDeps } from "pages/UserFeedback/UserFeedback";

interface Params {
  selectedWorkspaceId?: string;
}

export default function useCommonTagsApiConsumer({ selectedWorkspaceId }: Params) {
  const { tagsService } = useUserFeedbackDeps();
  const { abortSignal } = useAbortController();

  async function getCommonTags() {
    if (!selectedWorkspaceId) return [];

    try {
      const { data } = await tagsService.getCommonTags(selectedWorkspaceId, { return_all: true }, abortSignal);
      return data ?? [];
    } catch (e) {
      if (e.name && e.name === "CanceledError") {
        return [];
      }
      return [];
    }
  }

  const { data: commonTags } = useQuery([`tags`, { selectedWorkspaceId }], getCommonTags, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return { commonTags: commonTags ?? [] };
}
