import styled, { css } from "styled-components";

import BaseHeading from "../../../components/Heading/Heading";
import { vars } from "../../../styles";

export const WorkspaceSelectListWrap = styled.div<{ $isModal?: boolean }>`
  ${(props) =>
    !props.$isModal &&
    css`
      overflow-y: auto;
      max-height: 25rem;
    `}

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const WorkspaceScroll = styled.div`
  max-height: calc(100vh - 30rem);
  overflow-y: overlay;
  margin-right: 0.8rem;
`;

export const WorkspaceHeading = styled.li`
  color: ${vars.colors.grey60};
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  word-break: break-word;
`;

export const WorkspaceItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 3.6rem;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${vars.colors.grey30};
  line-height: 1.8rem;
  transition: ${vars.transitions.hover};

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:hover {
    background-color: ${vars.colors.grey90};

    a > svg {
      filter: invert(0%) sepia(100%) saturate(3%) hue-rotate(159deg) brightness(103%) contrast(101%);
    }
  }

  a {
    display: flex;
    align-items: center;
    color: ${vars.colors.grey30};

    > *:not(:last-child) {
      margin-right: 0.8rem;
    }

    svg {
      margin: -0.4rem -0.9rem;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: ${vars.colors.white};
    }
  }

  .menu-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
`;

export const WorkspaceItemLink = styled.div`
  width: 100%;

  color: ${vars.colors.grey30};
  &:hover {
    text-decoration: none;
    color: ${vars.colors.white};
  }

  a,
  button {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    padding: 0.8rem 2rem;
    color: ${vars.colors.grey30};
    &:hover {
      text-decoration: none;
      color: ${vars.colors.white};
    }
  }
`;

export const WorkspaceItemText = styled.span`
  width: 24rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 1.4rem;
`;

export const ShakeImage = styled.img`
  margin-bottom: 1rem;
`;

export const Heading = styled(BaseHeading)<{ $noMargin?: boolean }>`
  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0.8rem;
    `}
`;
