import { useState } from "react";

import { QueryObserverResult } from "@tanstack/react-query";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useForm } from "hooks/useForm";

import { App } from "models";
import { Certificate } from "models/Certificate.model";

import { useAdministrationDeps } from "../Administration";

export const environmentOptions = [
  { label: "Development", value: "development" },
  { label: "Production", value: "production" },
];

export interface AddCertificateFormState {
  file: File | null;
  platform: string;
  key: string;
  environment: Option;
}

const AddCertificateInitialState: AddCertificateFormState = {
  key: "",
  platform: "",
  file: null,
  environment: environmentOptions[0],
};

export const useAddCertificateFormConsumer = (
  selectedApp: App,
  getCertificate: () => Promise<QueryObserverResult<Certificate | undefined>>,
  closeModal?: () => void,
) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const handleAddCertificate = async () => {
    if (!selectedWorkspace || !selectedApp) return;
    setLoading(true);

    try {
      await administrationService.addCertificate(
        selectedWorkspace.id,
        selectedApp.id,
        formState.file,
        formState.environment.value === "development" ? true : false,
      );
      setLoading(false);

      getCertificate?.();
      closeModal?.();

      displayToast({
        title: "Fantastic!",
        content: `Certificate has been added`,
      });
    } catch (error) {
      setLoading(false);

      closeModal?.();

      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AddCertificateFormState>({
    initialState: AddCertificateInitialState,
    onSubmit: handleAddCertificate,
    validators: {},
  });

  return { formState, handleFormChange, handleFormSubmit, formError, setError, loading };
};
