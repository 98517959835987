import styled, { css } from "styled-components";

export const Cards = styled.div<{ $isSmaller?: boolean }>`
  display: flex;
  gap: 1.2rem;
  maxwidth: 105.4rem;
  flex-wrap: wrap;

  ${(props) =>
    props.$isSmaller &&
    css`
      justify-content: center;
    `}
`;

export const AnalyticsSection = styled.div`
  justify-content: center;
  margin-top: 2.8rem;
  max-width: 105.4rem;
  margin-bottom: 2.8rem;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;
