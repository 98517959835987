import React, { useState } from "react";

import { CenteredForm } from "components/CenteredForm/CenteredForm";

import CheckInbox from "pages/shared/components/Forms/CheckInbox/CheckInbox";
import SignUpForm from "pages/SignUp/components/SignUpForm";

import { useSignUpConsumer } from "../consumers/useSignUpConsumer";

export function SignUpView() {
  const [successEmail, setSuccessEmail] = useState<string>();
  const { resendConfirmationEmail } = useSignUpConsumer({ successEmail });

  return (
    <>
      <CenteredForm>
        {successEmail ? (
          <CheckInbox
            email={successEmail}
            resendEmail={resendConfirmationEmail}
            subtitleText={`We've emailed a special link to ${successEmail}. Click the link to confirm your address and get started.`}
            footerLinkContent={
              <>
                Wrong email? <a onClick={() => setSuccessEmail(undefined)}>Sign up</a> with another one
              </>
            }
          />
        ) : (
          <SignUpForm setSuccessEmail={setSuccessEmail} />
        )}
      </CenteredForm>
    </>
  );
}
