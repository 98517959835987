import styled, { css } from "styled-components";

import ButtonComponent from "components/Button/Button";
import OptionComponent from "components/Option/Option";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export const LabelContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export const SettingsContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  padding: 1rem 3.2rem;
`;

export const AppSettingsButton = styled(ButtonComponent)<{ $disabled?: boolean }>`
  background-color: transparent;
  padding: 1rem 2rem;
  min-width: unset;
  border: 0.2rem solid transparent;

  ${(props) =>
    !props.$disabled &&
    css`
      border: 0.2rem solid ${vars.colors.grey80};

      &:hover,
      &:active,
      &:focus {
        background-color: ${vars.colors.grey80};
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      button {
        border: 0.2rem solid ${vars.colors.grey80};
      }

      padding: 1rem 2rem;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    `}
`;

export const Option = styled(OptionComponent)`
  label {
    font-weight: 400;
    font-size: 1.6rem;
  }
`;

export const SelectAllIcon = styled(ButtonComponent)`
  border-radius: 0.5rem;
  padding: 0.1rem 0.4rem;
  min-width: unset;
  height: 1.6rem;
  width: 1.6rem;
`;

export const SelectAllButton = styled(Flex)`
  cursor: default;
  label {
    margin-left: 0.8rem;
  }
`;

export const HowToNotifyYouCardContent = styled.div`
  > *:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;
