import React from "react";

import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";

import { Platform, PlatformName } from "models";

import { useAddAppConsumer } from "pages/AddApp/consumers/useAddAppConsumer";

import * as Styled from "./AddAppCreateApp.styles";
import AddAppLimitBanner from "./LimitBanner/AddAppLimitBanner";

interface Props {
  platform?: Platform;
}

export function AddAppCreateApp({ platform }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useAddAppConsumer({
    platformId: platform?.id ?? "",
  });

  const { bundleId, appName } = formState;
  const { bundleId: bundleIdError } = formError;

  function handleChange(e: React.KeyboardEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isWeb = platform?.name === PlatformName.WEB;
  const isButtonDisabled = (!isWeb && !bundleId) || !appName;

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {isWeb ? "Lastly, your app name" : "Lastly, your bundle ID"}
        </Heading>
        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {`Shake servers will start listening for your ${isWeb ? "website" : "app"} to ping them.`}
        </Paragraph>
        {!isWeb ? <AddAppLimitBanner /> : null}
        <Styled.InputWrapper>
          <Input
            id="appName"
            name="appName"
            type="text"
            label={"App name"}
            value={appName ?? ""}
            placeholder="e.g. Uber, Pinterest, Yourapp"
            onChange={handleChange}
            helper={`Optional, but ${isWeb ? "a website" : "an app"} with a name looks nicer here on Dashboard`}
          />
        </Styled.InputWrapper>
        {!isWeb && (
          <Styled.InputWrapper>
            <Input
              id="bundleId"
              name="bundleId"
              type="text"
              label={isWeb ? "Domain" : "Bundle ID"}
              value={bundleId ? bundleId : ""}
              placeholder={isWeb ? "e.g. docs.yoursite.com" : "e.g. com.yourdomain.appname"}
              onChange={handleChange}
              error={!!bundleIdError}
              helper={
                bundleIdError
                  ? bundleIdError.message
                  : isWeb
                  ? "Add new website for every domain prefix: app., www., docs."
                  : undefined
              }
            />
          </Styled.InputWrapper>
        )}
        <Styled.CreateButton
          loadingText={loading && "Adding..."}
          disabled={isButtonDisabled}
          tooltip={{
            position: "top",
            text: `${isWeb ? "Please enter domain first" : "Please enter bundle ID first"}`,
            disabled: !isButtonDisabled,
          }}
          testId="add-app"
          onClick={handleFormSubmit}
        >
          {isWeb ? "Add a website" : "Add an app"}
        </Styled.CreateButton>
      </Styled.Container>
    </PageWrap>
  );
}
