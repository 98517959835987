import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import * as Styled from "./Drawer.styles";

interface DrawerProps {
  children?: JSX.Element;
  fullWidth?: boolean;
  open: boolean;
}

export const Drawer = ({ children, fullWidth, open }: DrawerProps) => {
  const portalIdRef = useRef(generatePortalName());
  const portalRootRef = useRef(document.getElementById(portalIdRef.current) || createPortalRoot(portalIdRef.current));
  const bodyRef = useRef(document.querySelector("body"));
  const [displayed, setDisplayed] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);

  useLayoutEffect(() => {
    if (open) {
      bodyRef.current?.appendChild(portalRootRef.current);
    } else {
      const portal = portalRootRef.current;
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setShouldRenderChildren(false);
        portal.remove();
      }, 350); /// Must be synced with drawer animation transition
    }
  }, [open]);

  useEffect(() => {
    open && setShouldRenderChildren(true);
    setDisplayed(open);
  }, [open]);

  useEffect(() => {
    const timeout = timeoutRef.current;
    const portal = portalRootRef.current;
    return () => {
      timeout && clearTimeout(timeout);
      portal.remove();
    };
  }, []);

  return createPortal(
    <>
      <Styled.ContentContainer
        $fullWidth={fullWidth}
        $open={displayed}
      >
        <Styled.ChildrenContainer>{shouldRenderChildren && children}</Styled.ChildrenContainer>
      </Styled.ContentContainer>
    </>,
    portalRootRef.current,
  );
};

const generatePortalName = () => {
  return (Math.random() + 1).toString(36).substring(7) + "-drawer-root";
};

function createPortalRoot(portalId: string) {
  const drawerRoot = document.createElement("div");
  drawerRoot.setAttribute("id", portalId);
  return drawerRoot;
}
