import React from "react";

import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

import * as Styled from "./LargeButton.styles";

export interface Props {
  image: string;
  title: string;
  subtitle?: string;
  onClick: () => void;
  small?: boolean;
}

export default function LargeButton({ image, title, subtitle, onClick, small }: Props) {
  return (
    <Styled.LargeButton
      onClick={onClick}
      $small={small}
    >
      <Styled.ButtonImage src={image} />
      <Paragraph color={vars.colors.grey30}>{title}</Paragraph>
      {subtitle && (
        <Paragraph
          marginTop={4}
          fontSize={14}
        >
          {subtitle}
        </Paragraph>
      )}
    </Styled.LargeButton>
  );
}
