import React, { Fragment } from "react";

import { generatePath, Link, useLocation, useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import Input, { InputElementType } from "components/Input/Input";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import useSignOutApiConsumer from "consumers/useSignOutApiConsumer";

import { useAppContext } from "context/App/App.context";

import { useForm } from "hooks/useForm";

import { Workspace } from "models";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";

import { RoutePaths } from "router/config/routePaths";

import identifiers from "util/identifiers.json";

import * as Styled from "./WorkspaceCreateElement.styles";
import useWorkspaceCreateApiConsumer from "../../consumers/useWorkspaceCreateApiConsumer";

interface FormState {
  workspaceName: string;
}

const initialState: FormState = {
  workspaceName: "",
};

const validators = {
  workspaceName: workspaceNameValidator,
};

function workspaceNameValidator(value: string): Error | undefined {
  if (value.length < 3) return new Error("Invalid workspace name. A minimum of 3 letters is required.");
}

interface Props {
  toggleCreateWs?: () => void;
  toggleJoinWs?: () => void;
  isModal?: boolean;
  Modal?: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function WorkspaceCreateElement({ toggleCreateWs, toggleJoinWs, isModal, Modal, internalProps }: Props) {
  const { workspaces, joinableWorkspaces } = useAppContext();
  const location = useLocation();

  const { signOut } = useSignOutApiConsumer();
  const { createWorkspace, isLoading } = useWorkspaceCreateApiConsumer({
    toggle: toggleCreateWs,
  });
  const navigate = useNavigate();

  const { formState, formError, handleFormChange, handleFormSubmit, clearForm } = useForm<FormState>({
    initialState,
    onSubmit: () => createWorkspace(formState.workspaceName, clearForm),
    validators,
  });

  const workspaceNameError = formError.workspaceName;
  const workspaceName = formState.workspaceName;

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(event.currentTarget.name, event.currentTarget.value);
  };

  const handleSignOut = async () => {
    signOut().then(() => {
      navigate(RoutePaths.SIGNIN);
    });
  };

  const generateCancelLink = () => {
    return generatePath(RoutePaths.ROOT);
  };

  const handleOpenJoinModal = () => {
    if (toggleCreateWs && toggleJoinWs) {
      toggleCreateWs();
      toggleJoinWs();
    }
  };

  const hasJoinableWorkspaces = joinableWorkspaces && joinableWorkspaces?.length > 0;
  const showLinks = !isModal || (isModal && hasJoinableWorkspaces);

  const form = () => {
    return (
      <Fragment>
        {!isModal && (
          <FormTitle
            title="Create a new workspace"
            titleTestId={identifiers["createWorkspace.header"]}
          />
        )}

        <Styled.WorkspaceCreateForm
          onSubmit={(event) => event.preventDefault()}
          $isModal={isModal}
        >
          <Input
            elementType={InputElementType.INPUT}
            type="text"
            name="workspaceName"
            label="Workspace name"
            placeholder="e.g. Acme or Acme mobile devs"
            helper={resolveInputHelperText(workspaceNameError)}
            error={!!workspaceNameError}
            value={workspaceName}
            onChange={handleChangeEvent}
            testId={identifiers["createWorkspace.input.workspaceName"]}
            autoFocus
          />
          {!isModal && (
            <Button
              size={!isModal ? "full" : "small"}
              testId={identifiers["createWorkspace.button.create"]}
              disabled={!Boolean(workspaceName) || isLoading}
              tooltip={{
                position: "top",
                text: "Please enter workspace name first",
                disabled: Boolean(workspaceName),
              }}
              loadingText={isLoading && "Creating..."}
              onClick={(e) => handleFormSubmit(e)}
            >
              Create
            </Button>
          )}
        </Styled.WorkspaceCreateForm>

        <HelperText>{resolveWorkspacesInfoText(workspaces)}</HelperText>

        {showLinks && (
          <Styled.WorkspaceCreateFormLinks>
            {hasJoinableWorkspaces && (
              <p>
                Here&apos;s a list of&nbsp;
                {isModal ? (
                  <a onClick={handleOpenJoinModal}>workspaces you can join instantly</a>
                ) : (
                  <Link
                    to={generatePath(RoutePaths.JOIN_WORKSPACE)}
                    state={location.state}
                  >
                    workspaces you can join instantly
                  </Link>
                )}
              </p>
            )}

            {!isModal && (
              <p>
                I don&apos;t want to do anything here,&nbsp;
                {workspaces.length === 0 ? (
                  <a onClick={handleSignOut}>Sign me out</a>
                ) : (
                  <Link
                    to={generateCancelLink()}
                    data-testid={identifiers["createWorkspace.link.cancel"]}
                  >
                    cancel
                  </Link>
                )}
              </p>
            )}
          </Styled.WorkspaceCreateFormLinks>
        )}
      </Fragment>
    );
  };

  if (isModal && Modal) {
    return (
      <Modal
        {...internalProps}
        heading="Create a new workspace"
        buttonElement={
          <Button
            size={!isModal ? "full" : "small"}
            testId={identifiers["createWorkspace.button.create"]}
            disabled={!Boolean(workspaceName) || isLoading}
            tooltip={{
              position: "top",
              text: "Please enter workspace name first",
              disabled: Boolean(workspaceName),
            }}
            loadingText={isLoading && "Creating..."}
            onClick={(e) => handleFormSubmit(e)}
          >
            Create
          </Button>
        }
      >
        {form()}
      </Modal>
    );
  }

  return form();
}

const resolveWorkspacesInfoText = (workspaces: Workspace[]) => {
  return workspaces.length > 0
    ? `A Shake workspace is a private hub where you and your team members work together on projects. 
       At any given moment, you can be a member of as many workspaces as you want, create, join and leave them.`
    : `You're in zero workspaces now. If you're part of a team, tell a teammate to invite you to their existing workspace`;
};

const resolveInputHelperText = (error: Error | undefined) => {
  return error
    ? error.message
    : "Usually the name of your company or team, but you can be informal too. Minimum 3 letters.";
};
