export interface UTMTags {
  utm_medium: string | null;
  utm_content: string | null;
  utm_source: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
}

const TAGS_KEY = "shakebugs.dash.utmTags";

export function saveUTMTags(currentURL: URL) {
  const tagsObject: UTMTags = {
    utm_medium: currentURL.searchParams.get("utm_medium"),
    utm_content: currentURL.searchParams.get("utm_content"),
    utm_source: currentURL.searchParams.get("utm_source"),
    utm_campaign: currentURL.searchParams.get("utm_campaign"),
    utm_term: currentURL.searchParams.get("utm_term"),
  };

  const hasTags = Object.values(tagsObject).every((tag) => tag !== null);
  hasTags && localStorage.setItem(TAGS_KEY, JSON.stringify(tagsObject));
}

export function getUTMTags(): UTMTags | null {
  const storedTags = localStorage.getItem(TAGS_KEY);
  if (!storedTags) return null;
  return JSON.parse(storedTags);
}

export function deleteUTMTags() {
  localStorage.removeItem(TAGS_KEY);
}
