import styled from "styled-components";

import { vars } from "styles";

export const OptionsContainer = styled.div`
  margin-left: 1rem;

  > * {
    margin-right: 2.4rem;
  }

  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Key = styled.span`
  margin-right: 0.4rem;
  border: 1px solid ${vars.colors.grey80};
  padding: 0.2rem 0.4rem;
  font-size: 1.2rem;
  color: ${vars.colors.grey30};
  border-radius: 0.4rem;
`;
