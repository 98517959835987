import {
  File,
  supportedDocExtensions,
  SupportedFileTypes,
  supportedImageFileExtensions,
  supportedPdfExtensions,
  supportedPPTExtensions,
  supportedTextLogExtensions,
  supportedVideoFileExtensions,
  supportedXlsExtensions,
  supportedZipExtensions,
} from "models";

export const mapFilesToAttachmentUrls = (files: File[]): string[] => {
  return files.map((file) => file.url).filter((url) => !!url) as string[];
};

export const resolveFileType = (url?: string | null) => {
  if (!url) return;

  const fileExtension = resolveFileExtensionFromUrl(url);

  if (!fileExtension) return;

  if (supportedImageFileExtensions.includes(fileExtension)) {
    return SupportedFileTypes.IMAGE;
  }

  if (supportedVideoFileExtensions.includes(fileExtension)) {
    return SupportedFileTypes.VIDEO;
  }
  if (supportedTextLogExtensions.includes(fileExtension)) {
    return SupportedFileTypes.TXT;
  }
  if (supportedZipExtensions.includes(fileExtension)) {
    return SupportedFileTypes.ZIP;
  }
  if (supportedDocExtensions.includes(fileExtension)) {
    return SupportedFileTypes.DOC;
  }
  if (supportedPPTExtensions.includes(fileExtension)) {
    return SupportedFileTypes.PPT;
  }
  if (supportedPdfExtensions.includes(fileExtension)) {
    return SupportedFileTypes.PDF;
  }
  if (supportedXlsExtensions.includes(fileExtension)) {
    return SupportedFileTypes.XLS;
  }
};

export const resolveFileExtensionFromUrl = (url: string) => {
  const dotIndex = url.lastIndexOf(".");

  if (dotIndex === -1) return;

  return url.substring(dotIndex);
};
