import React, { PropsWithChildren } from "react";

import identifiers from "util/identifiers.json";

import * as Styled from "./CrashesPageNavigation.styles";

interface Props {
  crashTitle: string;
}

export default function CrashesPageNavigation({ children, crashTitle }: PropsWithChildren<Props>) {
  return (
    <Styled.CrashesNavigationWrapper>
      <p data-testid={identifiers["navbar.crashReport.detailsTitle"]}>{crashTitle}</p>
      {children}
    </Styled.CrashesNavigationWrapper>
  );
}
