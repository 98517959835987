import styled, { css } from "styled-components";

import { vars } from "styles";

import { pxToRem } from "../../styles/abstracts/utils";

export const Paragraph = styled.p<{
  $p1?: boolean;
  $p2?: boolean;
  $p3?: boolean;
  $fontSize?: number;
  $lineHeight?: number;
  $fontWeight?: "normal" | 500 | "bold" | 400;
  $marginBottom?: number;
  $marginTop?: number;
  $color?: string;
}>`
  font-size: ${(props) => (props.$fontSize ? pxToRem(props.$fontSize) : "1.6rem")};
  line-height: ${(props) => (props.$lineHeight ? pxToRem(props.$lineHeight) : "2rem")};
  font-weight: ${(props) => props.$fontWeight ?? 500};
  color: ${(props) => props.$color ?? `${vars.colors.grey60}`};
  margin-bottom: ${(props) =>
    props.$marginBottom || props.$marginBottom === 0 ? pxToRem(props.$marginBottom) : "0rem"};
  margin-top: ${(props) => (props.$marginTop || props.$marginTop === 0 ? pxToRem(props.$marginTop) : "0rem")};

  ${(props) =>
    props.$p1 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "1.6rem"};
      line-height: ${props.$lineHeight ?? "2rem"};
    `}
  ${(props) =>
    props.$p2 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "1.4rem"};
      line-height: ${props.$lineHeight ?? "1.8rem"};
    `}
  ${(props) =>
    props.$p3 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "1.2rem"};
      line-height: ${props.$lineHeight ?? "1.6rem"};
    `}
`;
