import React from "react";

import { generatePath, Outlet, useParams } from "react-router-dom";

import ChooseSubscriptionImage from "assets/images/FTUX/billing-choose-subscription.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "components/Spinner/Spinner";

import { SubscriptionState, useSubscriptionContext } from "context/Subscription/SubscriptionContext";

import { ZeroAppsCrashesView } from "pages/shared/components/ZeroAppsView/ZeroAppsView";

import { RoutePaths } from "router/config/routePaths";

export function CrashesModulePage() {
  const { workspaceSlug } = useParams();
  const buttonUrl = generatePath(RoutePaths.ADMINISTRATION_BILLING, { workspaceSlug: workspaceSlug ?? null });
  const { state } = useSubscriptionContext();

  if (!state) return <Spinner />;

  switch (state) {
    case SubscriptionState.noApps:
      return <ZeroAppsCrashesView />;
    case SubscriptionState.usageExceedsCurrentSubscription:
      return (
        <EmptyStateElement
          title="Which plan & add-on to choose"
          subtitle="Your usage of Shake exceeds your current workspace subscription. You can always check your workspace numbers and pick the appropriate subscription."
          imageSrc={ChooseSubscriptionImage}
          button={{ url: buttonUrl, title: "See subscription options" }}
        />
      );
    case SubscriptionState.good:
      return <Outlet />;
  }
}
