export class Validators {
  static validEmail(email: string): boolean {
    const emailRegEx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    return !!email.toLocaleLowerCase().trim().match(emailRegEx);
  }

  static required(value: string): boolean {
    return value.trim().length > 0;
  }

  static minLength(value: string, minLength: number): boolean {
    return value.trim().length >= minLength;
  }

  static maxLength(value: string, maxLength: number): boolean {
    return value.trim().length <= maxLength;
  }

  static alphanumeric(value: string) {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    return alphanumericRegex.test(value);
  }

  static validDomain(domain: string): boolean {
    const domainregEx = new RegExp(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/);
    return !!domain.trim().match(domainregEx);
  }
}
