import styled from "styled-components";

import BaseButton from "components/Button/Button";

export const Component = styled.div``;

export const Button = styled(BaseButton)`
  width: 7.5rem;
  height: 3.2rem;
  margin-left: 1.6rem;
`;

export const DeleteButton = styled(BaseButton)`
  width: 7.5rem;
  height: 3.2rem;
  margin-left: 0.8rem;
`;
