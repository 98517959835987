import React from "react";

import dependencyInjection from "util/dependencyInjection";

import AdministrationService from "./services/AdministrationService";
import BillingView from "./views/BillingView/BillingView";
import CommonCommentsView from "./views/CommonCommentsView/CommonCommentsView";
import DomainVerificationView from "./views/DomainVerificationView/DomainVerificationView";
import ExtensionsView from "./views/ExtensionsView/ExtensionsView";
import GeneralView from "./views/GeneralView/GeneralView";
import MembersView from "./views/MembersView/MembersView";
import WhiteLabelingView from "./views/WhiteLabelingView/WhiteLabelingView";

interface Services {
  administrationService: AdministrationService;
}

const administrationService = new AdministrationService();

const [AdministrationDependencyProvider, useAdministrationDeps] = dependencyInjection<Services>({
  services: { administrationService },
});

export function AdministrationGeneralPage() {
  return (
    <AdministrationDependencyProvider>
      <GeneralView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationExtensionsPage() {
  return (
    <AdministrationDependencyProvider>
      <ExtensionsView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationWhiteLabelingPage() {
  return (
    <AdministrationDependencyProvider>
      <WhiteLabelingView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationMembersPage() {
  return (
    <AdministrationDependencyProvider>
      <MembersView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationBillingPage() {
  return (
    <AdministrationDependencyProvider>
      <BillingView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationDomainVerificationPage() {
  return (
    <AdministrationDependencyProvider>
      <DomainVerificationView />
    </AdministrationDependencyProvider>
  );
}

export function AdministrationCommonCommentsPage() {
  return (
    <AdministrationDependencyProvider>
      <CommonCommentsView />
    </AdministrationDependencyProvider>
  );
}

export { useAdministrationDeps };
