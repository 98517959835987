import React from "react";

import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Subscription } from "models/billing";
import { dateStringForMappingFiles } from "models/helpers/time/creationTime";
import { isAdmin } from "models/Member.model";

import { vars } from "styles";

import * as Styled from "./ResumeSubscriptionCard.styles";
interface Props {
  subscription: Subscription;
  resumeSubscription: () => void;
}
export default function ResumeSubscriptionCard({ subscription, resumeSubscription }: Props): JSX.Element {
  const { selectedWorkspace } = useAppSelectionContext();
  return (
    <Styled.ResumeSubscriptionCard>
      <Paragraph
        color={vars.colors.red}
        fontSize={14}
      >
        {`Your subscription ends on ${dateStringForMappingFiles(
          new Date(subscription.current_period_end),
        )}. After that, you’re shaking with a limited Free Forever plan. Good thing is, it’s yours to use forever, for free.`}
        &nbsp;
        {isAdmin(selectedWorkspace?.role) && (
          <div style={{ paddingTop: "0.8rem" }}>
            <Styled.Link onClick={resumeSubscription}>Resume your subscription</Styled.Link>&nbsp;for Shake without
            limits.
          </div>
        )}
      </Paragraph>
      {isAdmin(selectedWorkspace?.role) && (
        <Styled.CancelDateParagraph
          color={vars.colors.red}
          fontSize={14}
        >
          {`Your credit card will be removed automatically on ${dateStringForMappingFiles(
            new Date(subscription.current_period_end),
          )}.`}
        </Styled.CancelDateParagraph>
      )}
    </Styled.ResumeSubscriptionCard>
  );
}
