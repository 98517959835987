import React, { Fragment, useState } from "react";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Paragraph from "components/Paragraph/Paragraph";
import displayToast from "components/Toast/displayToast";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import * as Styled from "./GeneralView.styles";

type GeneralCardRowProps = {
  title: string;
  value?: string;
  toastText?: string;
  testId?: string;
  IconComponent?: JSX.Element;
};

export default function GeneralCardRow({ title, value, toastText, testId, IconComponent }: GeneralCardRowProps) {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const handleClick = () => {
    if (!value) return;
    navigator.clipboard.writeText(value);
    displayToast({
      content: toastText ?? "",
    });
    setIsDropdownOpened(false);
  };

  return (
    <Fragment>
      <CenteredCell minWidth="110">
        <Flex
          $gap={0.8}
          $alignItems="center"
        >
          {IconComponent}
          <Paragraph color={vars.colors.grey30}>{title}</Paragraph>
        </Flex>
      </CenteredCell>
      <CenteredCell>
        <span onDoubleClick={handleClick}>
          <Styled.ClientID color={vars.colors.grey60}>{value}</Styled.ClientID>
        </span>
      </CenteredCell>

      <CellEnd>
        <Dropdown
          position="right"
          isOpened={isDropdownOpened}
          setIsOpened={setIsDropdownOpened}
          testId={testId}
        >
          <DropdownItem
            onClick={handleClick}
            testId={identifiers["general.dropdownButton.copy"]}
          >
            Copy
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    </Fragment>
  );
}
