import React from "react";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "components/PageWrap/PageWrap";
import { BaseTable } from "components/ShakeTable/BaseTable";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useUsersAttributes } from "hooks/filtering/modules/useUsersAttributes";
import { Attributes } from "hooks/filtering/ui/Attributes";
import { AttributesContainer } from "hooks/filtering/ui/Attributes.styles";

import { EmptyStateViewConf, EmptyTableView } from "pages/shared/components/EmptyTableView/EmptyTableView";
import { SdkSetupSteps } from "pages/shared/components/SDKSetup/SdkSetupSteps";
import { UsersModuleSampleAppFooter } from "pages/shared/components/TableFooter/TableFooter";
import { resolveEmptyStateView } from "pages/shared/helpers/resolveEmptyStateView";
import useUsersPageApiConsumer from "pages/Users/consumers/useUsersPageApiConsumer";

import { UsersModuleEmptyState } from "../../shared/components/ModuleEmptyState/UserModuleEmptyState";

export default function UsersPageView() {
  const { selectedApp } = useAppSelectionContext();

  const usersAttributeState = useUsersAttributes();

  const { items, loading, error, userTableProps, refetchData } = useUsersPageApiConsumer({
    attributesProps: usersAttributeState,
  });

  // Show SDK not added screen
  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  // Show error screen
  if (error) {
    return (
      <EmptyStateElement
        title="There was an error loading users. Try refreshing."
        subtitle="The link you followed may be broken, or the workspace may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  const isInitialLoading = typeof items == "undefined" || loading;

  const hasAttributes = Boolean(usersAttributeState.validAttributes.length);

  // Show data empty screen
  const hasItems = Boolean(items && items.length > 0);
  if (!isInitialLoading && !hasAttributes && !error && !hasItems) {
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <UsersModuleEmptyState />
        </PageWrap>
      </>
    );
  }

  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "users",
    hasItems,
    selectedApp,
    Boolean(usersAttributeState.validAttributes.length),
    Boolean(error),
    refetchData,
  ) as EmptyStateViewConf | undefined;

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...usersAttributeState}
            searchField="end_user_id"
            enableKeyboardShortcut={true}
          />
        </AttributesContainer>
        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!isInitialLoading && !emptyViewConfig && (
          <BaseTable
            {...userTableProps}
            footer={Boolean(selectedApp?.is_sample) && <UsersModuleSampleAppFooter />}
            isFetchingNext={userTableProps.isFetchingNext}
          />
        )}
      </PageWrap>
    </>
  );
}
