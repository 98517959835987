import { rgba } from "polished";
import styled, { css } from "styled-components";

import { ReactComponent as WebhookSecret } from "assets/images/integrations/webhook-secret.svg";

import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const ModalBodyWrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > :last-child {
    align-self: flex-start;
  }
`;

export const Subtitle = styled(Paragraph)`
  margin-bottom: 2.4rem;
`;

export const DragnDrop = styled.div<{ $entered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    background-color: ${vars.colors.grey90};
    border: 1px solid ${vars.colors.grey80};
    border-radius: 1rem;
  }

  ${(props) =>
    props.$entered &&
    css`
      background-color: ${rgba(vars.colors.orange, 0.1)};
      border: 1px solid ${vars.colors.orange};
      border-radius: 1rem;
    `}
`;

export const DragnDropText = styled(Paragraph)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const OptionsContainer = styled.div`
  margin-left: 1rem;

  > * {
    margin-right: 2.4rem;
  }

  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const Flex = styled(BaseFlex)`
  padding-top: 2.4rem;
  border-top: 1px solid ${vars.colors.grey90};
`;

export const CancelContainer = styled.div`
  margin-right: 1.6rem;
`;

export const WebhookSecretIcon = styled(WebhookSecret)`
  path {
    stroke: ${vars.colors.grey50};
  }
`;
