import React, { createContext, useCallback, useContext, useState } from "react";

import "./ShakingAnimation.css";

interface ShakingAnimationContextProps {
  registerShakeable: (id: string, ref: React.RefObject<HTMLElement>) => void;
  triggerShake: (id: string) => void;
}

const ShakingAnimationContext = createContext<ShakingAnimationContextProps | undefined>(undefined);

export const ShakingAnimationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [shakeableRefs, setShakeableRefs] = useState<Record<string, React.RefObject<HTMLElement>>>({});

  const registerShakeable = useCallback((id: string, ref: React.RefObject<HTMLElement>) => {
    setShakeableRefs((prev) => ({ ...prev, [id]: ref }));
  }, []);

  const triggerShake = (id: string) => {
    const ref = shakeableRefs[id];
    if (ref?.current) {
      ref.current.classList.add("shakingAnimation");
      setTimeout(() => {
        ref.current?.classList.remove("shakingAnimation");
      }, 300);
    }
  };

  return (
    <ShakingAnimationContext.Provider value={{ registerShakeable, triggerShake }}>
      {children}
    </ShakingAnimationContext.Provider>
  );
};

export const useShakingAnimation = (): ShakingAnimationContextProps => {
  const context = useContext(ShakingAnimationContext);
  if (!context) {
    throw new Error("useShake must be used within a ShakingAnimationProvider");
  }
  return context;
};
