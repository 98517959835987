import React from "react";

import { Spinner } from "components/Spinner/Spinner";

import Heading from "../../../../components/Heading/Heading";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { IntegrationType } from "../../../../models/integrations";
import identifiers from "../../../../util/identifiers.json";
import { t } from "../../../../util/translator";
import { SentryCard } from "../../components/ExtensionsCards/SentryCard";
import { useRedirectConsumer } from "../../components/ModalBody/UserFeedbackIntegrations/consumers/useRedirectConsumer";
import useExtensionsViewApiConsumer from "../../consumers/useExtensionsViewConsumer";

export default function ExtensionsView(): JSX.Element {
  const { teamData, disableSentry, selectedWorkspace, loading, initialLoading } = useExtensionsViewApiConsumer();
  const { startRedirection } = useRedirectConsumer({ workspace: selectedWorkspace, selectedAppId: "" }); // We don't need app id for sentry

  if (!teamData || !selectedWorkspace || initialLoading) {
    return <Spinner />;
  }

  const handleAddExtension = async () => {
    if (teamData.sentry) {
      await disableSentry();
    } else {
      startRedirection(IntegrationType.SENTRY);
    }
  };

  return (
    <>
      <Title />
      <SentryCard
        isSentryActive={!!teamData.sentry}
        handleActiveChange={handleAddExtension}
        disabled={loading || initialLoading}
      />
    </>
  );
}

const Title = () => (
  <PageTitle>
    <Heading
      as="h1"
      heading1
      marginBottom={8}
      testId={identifiers["workspaceAdministration.header.extensions"]}
    >
      {t("administration.extensions.title")}
    </Heading>
    <p>{t("administration.extensions.subtitle")}</p>
  </PageTitle>
);
