import React from "react";

import { WorkspaceCreateService } from "./services/WorkspaceCreateService";
import WorkspaceCreateView from "./views/WorkspaceCreateView";
import dependencyInjection from "../../util/dependencyInjection";

interface Services {
  workspaceCreateService: WorkspaceCreateService;
}

const workspaceCreateService = new WorkspaceCreateService();

const [WorkspaceCreateDependencyProvider, useWorkspaceCreateDeps] = dependencyInjection<Services>({
  services: { workspaceCreateService },
});

export const WorkspaceCreatePage = () => {
  return (
    <WorkspaceCreateDependencyProvider>
      <WorkspaceCreateView />
    </WorkspaceCreateDependencyProvider>
  );
};

export { useWorkspaceCreateDeps };
