import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { getIntegrationTypeName, Integration, IntegrationType } from "models/integrations";

import { useAdministrationDeps } from "../Administration";
import { determineInitialSelectedAppId } from "../util/determineInitialSelectedApp";

interface PostIntegrationSearchParams {
  appId?: string;
  type?: IntegrationType;
  appKey?: string;
}

export default function useAppsFeedbackViewConsumer() {
  const { administrationService } = useAdministrationDeps();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { appKey } = useParams();

  const { apps } = useAppContext();
  const { selectedWorkspace, selectedApp: globalSelectedApp } = useAppSelectionContext();

  const [localSelectedAppId, setLocalSelectedAppId] = useState<string | undefined>(
    determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey),
  );

  const localSelectedApp = useMemo(() => apps.find((app) => app.id === localSelectedAppId), [localSelectedAppId, apps]);

  const [, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const searchParamsRef = useRef(
    Object.fromEntries(new URLSearchParams(window.location.search)) as Partial<PostIntegrationSearchParams>,
  );

  const clearSearchParamsRef = useRef(() => {
    setSearchParams(new URLSearchParams());
  });

  const disconnectIntegration = useCallback(
    async (integration: Integration) => {
      setLoading(true);
      try {
        if (!selectedWorkspace?.id || !localSelectedApp) return;

        await administrationService.disconnectIntegrationByType(
          selectedWorkspace.id,
          localSelectedApp.id,
          integration.type,
        );

        await fetchAllApps(selectedWorkspace.id);

        displayToast({
          content: `${getIntegrationTypeName(integration.type)} has been disconnected`,
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: error?.response?.data?.message ?? "Please try again.",
        });
      }
      setLoading(false);
    },
    [localSelectedApp, selectedWorkspace, administrationService, fetchAllApps],
  );

  const exportTickets = useCallback(async () => {
    setLoading(true);
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;

      await administrationService.exportTickets(selectedWorkspace.id, localSelectedApp.id);

      displayToast({
        title: "Export started",
        content: "Check your email to find a .csv file",
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
    setLoading(false);
  }, [localSelectedApp, selectedWorkspace, administrationService]);

  const clearPreSelectedIntegrationType = useCallback(() => {
    searchParamsRef.current.type = undefined;
  }, [searchParamsRef]);

  useEffect(() => {
    if (!selectedWorkspace || localSelectedAppId) return;
    fetchAllApps(selectedWorkspace.id).then((allApps) => {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, allApps, window.location.search, appKey));
    });
  }, [selectedWorkspace, fetchAllApps, localSelectedAppId, globalSelectedApp, appKey]);

  useEffect(() => {
    if (searchParamsRef.current.type) clearSearchParamsRef.current();
  }, []);

  useEffect(() => {
    if (appKey) {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey));
    }
  }, [appKey, apps, globalSelectedApp]);

  return {
    disconnectIntegration,
    allApps: apps,
    loading,
    preSelectedIntegrationType: searchParamsRef.current.type,
    clearPreSelectedIntegrationType,
    localSelectedApp,
    exportTickets,
  };
}
