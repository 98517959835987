import NotificationsService from "services/Notifications.service";

import AppsService from "./services/Apps.service";
import AuthService from "./services/Auth.service";
import dependencyInjection from "./util/dependencyInjection";

interface AppServices {
  appsService: AppsService;
  notificationsService: NotificationsService;
}

interface AuthServices {
  authService: AuthService;
}

const appsService = AppsService.getInstance();
const notificationsService = new NotificationsService();

const authService = AuthService.getInstance();

const [AppDependencyProvider, useAppDeps] = dependencyInjection<AppServices>({
  services: { appsService, notificationsService },
});

const [AuthDependencyProvider, useAuthDeps] = dependencyInjection<AuthServices>({
  services: { authService },
});

export { AppDependencyProvider, useAppDeps, AuthDependencyProvider, useAuthDeps };
