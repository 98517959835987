import React, { ReactNode, useEffect, useRef, useState } from "react";

import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";

import AppPlaceholderPhoto from "assets/images/shared-icons/app-icon-placeholder.svg";

import Header from "components/Header/Header";
import Navigation, { NavItem } from "components/Navigation/Navigation";
import { ScrollContainer } from "components/ScrollContainer/ScrollContainer";
import Tooltip from "components/Tooltip/Tooltip";

import useWorkspaceAndAppChange from "consumers/useWorkspaceAndAppChange";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { SubscriptionState, useSubscriptionContext } from "context/Subscription/SubscriptionContext";

import { App, Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";
import { useCurrentPathRouteConfig } from "router/util/useCurrentPathRouteConfig";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getOSImage } from "util/contentResolvers";
import identifiers from "util/identifiers.json";
import { resolveCurrentDomainRoute } from "util/resolveCurrentDomainRoute";

import AppListDropdown from "./AppListDropdown";
import * as Styled from "./AppListDropdown.styles";

export const PAGE_NAVIGATION_ELEMENT_ROOT_ID = "page-navigation-element-root";
export const ELEMENT_CONTAINER_ID = "element-container-main";
export const NAV_LIST = "nav_list";

export function PageNavigation({ children }: { children: ReactNode }) {
  const { apps } = useAppContext();
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();
  const { state: subState } = useSubscriptionContext();

  if (!selectedWorkspace || !selectedApp || subState !== SubscriptionState.good)
    return <PageNavigationSkeleton>{children}</PageNavigationSkeleton>;
  return (
    <PageNavigationInternal
      selectedApp={selectedApp}
      selectedWorkspace={selectedWorkspace}
      appList={apps.filter((a) => a.active)}
    >
      {children}
    </PageNavigationInternal>
  );
}

interface InternalProps {
  selectedApp: App;
  appList: App[];
  selectedWorkspace: Workspace;
  children: ReactNode;
}

const PageNavigationInternal = ({ children, selectedApp, appList }: InternalProps) => {
  const { handleAppChange } = useWorkspaceAndAppChange();
  const { loading } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { firstPillName, hasBorderBottom } = useCurrentPathRouteConfig();

  const { workspaceSlug, appKey } = useParams();

  const [isOverflown, setIsOverflown] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const selectedAppOs = selectedApp && selectedApp?.platform ? selectedApp?.platform.os : "";

  const selectedAppName = appList.find((app) => app.id === selectedApp.id)?.name;

  const filteredAppList = appList.filter((app) => app.id !== selectedApp.id);

  useEffect(() => {
    const element = textRef.current as HTMLDivElement;

    if (!element) return;

    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, [selectedAppName]);

  return (
    <>
      {!loading && (
        <Header bottomBorder={hasBorderBottom}>
          <Navigation elementId={NAV_LIST}>
            <NavItem withClick={false}>
              <span data-testid={getTestIdForTitle(resolveCurrentDomainRoute(location.pathname))}>{firstPillName}</span>
            </NavItem>
            <NavItem>
              <Styled.SelectedAppWithOptions>
                <Styled.SelectedAppContainer
                  data-testid={identifiers["navbar.selectedApp.button"]}
                  onClick={() =>
                    navigate(
                      generatePath(resolveCurrentDomainRoute(location.pathname) ?? RoutePaths.USER_FEEDBACK, {
                        workspaceSlug: workspaceSlug ?? null,
                        appKey: appKey ?? null,
                      }),
                    )
                  }
                >
                  <Tooltip
                    disabled={!isOverflown}
                    position="bottom"
                    text={`${selectedAppName} ${selectedAppOs}`}
                  >
                    <Flex
                      $alignItems="center"
                      style={{ paddingLeft: "0.8rem" }}
                    >
                      <img
                        src={selectedApp.logo_url ?? AppPlaceholderPhoto}
                        width={28}
                        height={28}
                        style={{ borderRadius: "0.8rem", objectFit: "cover" }}
                      />
                      <Styled.SelectedApp ref={textRef}>
                        <Styled.AppNameParagraph>{selectedAppName}</Styled.AppNameParagraph>
                        <img
                          src={getOSImage(selectedApp.platform)}
                          width={20}
                          height={20}
                        />
                      </Styled.SelectedApp>
                    </Flex>
                  </Tooltip>
                </Styled.SelectedAppContainer>

                <AppListDropdown
                  appList={filteredAppList}
                  onAppSwitch={handleAppChange}
                />
              </Styled.SelectedAppWithOptions>
            </NavItem>
          </Navigation>
        </Header>
      )}
      <ScrollContainer>{children}</ScrollContainer>
    </>
  );
};

const getTestIdForTitle = (
  route?: RoutePaths.USER_FEEDBACK | RoutePaths.CRASH_REPORTS | RoutePaths.USERS | RoutePaths.STATS,
) => {
  switch (route) {
    case RoutePaths.USERS:
      return identifiers["users.header"];
    case RoutePaths.CRASH_REPORTS:
      return identifiers["crash.reports.header"];
    case RoutePaths.STATS:
      return "";
    default:
      return identifiers["user.feedback.header"];
  }
};

const PageNavigationSkeleton = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header></Header>
      {children}
    </>
  );
};
