import { IssueUser } from "./IssueUser.model";

export const enum IssueMessageType {
  CHAT = "chat",
  ACTIVITY = "activity",
}

export const enum ActivityType {
  STATUS = "Status",
  PRIORITY = "Priority",
  ASSIGNEE = "Assignee",
  TAG = "Tag",
  RULE = "Rule",
  TITLE = "Title",
  JIRA = "Jira",
  LINEAR = "Linear",
}

// request types
export interface IssueChatRequest {
  message: string;
  is_note: boolean;
}

// response types
export interface IssueChatResponse {
  id: string;
  message: string;
  updated: string;
  edited_at: string;
  created: string;
  user: IssueUser;
  is_note: boolean;
  time_since: string;
  is_read?: boolean;
}

export interface IssueActivityResponse {
  id: string;
  activity_description: string;
  activity_type: ActivityType;
  activity_url: string;
  updated: string;
  created: string;
  time_since: string;
  is_read?: boolean;
}

export type IssueMessageResponseUnionType = IssueChatResponse | IssueActivityResponse;

export interface IssueMessageResponse {
  object_type: IssueMessageType;
  object_data?: IssueMessageResponseUnionType;
}

// our types - so we can use tagged union type pattern
export interface IssueChat extends IssueChatResponse {
  type: IssueMessageType.CHAT;
}

export interface IssueActivity extends IssueActivityResponse {
  type: IssueMessageType.ACTIVITY;
}

export type IssueMessage = IssueChat | IssueActivity;

// mapping helpers - unfortunately we need to cast in here
export const mapToMessage = (issueMessageResponse: IssueMessageResponse): IssueMessage => {
  switch (issueMessageResponse.object_type) {
    case IssueMessageType.ACTIVITY:
      return { ...issueMessageResponse.object_data, type: IssueMessageType.ACTIVITY } as IssueActivity;
    case IssueMessageType.CHAT:
      return { ...issueMessageResponse.object_data, type: IssueMessageType.CHAT } as IssueChat;
  }
};
