import styled from "styled-components";

export const CenteredFormWrap = styled.div`
  display: flex;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .inner-container {
    width: 50rem;
    margin: auto;
    padding: 4rem 2rem;
  }
`;
