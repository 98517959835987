import React, { useCallback, useEffect } from "react";

import { useAppDeps } from "App.dependencies";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";

import { MarketingChannelSurvey } from "components/TallyForm/MarketingChannelSurvey";

import { useAppsSocketConsumer } from "consumers/useAppsSocketConsumer";
import useChangeLogUnreadNotificationsApiConsumer from "consumers/useChangeLogNotificationsApiConsumer";
import useDesktopNotificationsSocketConsumer from "consumers/useDesktopNotificationsSocketConsumer";
import useNotificationsApiConsumer from "consumers/useNotificationsApiConsumer";
import useTeamMembersApiConsumer from "consumers/useTeamMembersApiConsumer";

import { setUpdatingWorkspaceAction, setWorkspacesAction } from "context/App/App.actions";
import { useAppContext, useAppDispatch } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import useSocket from "hooks/useSocket";

import { Sidebar } from "layouts/components/Sidebar/Sidebar";

import { Workspace } from "models";

import { FROM_SIGNUP } from "pages/SignUp/consumers/useSignUpFormConsumer";

import * as Styled from "./WorkspaceRootLayout.styles";

export const WorkspaceRootLayout = () => {
  const location = useLocation();

  /// Connect to socket for workspace
  const { selectedWorkspace } = useAppSelectionContext();
  const { socket } = useSocket(selectedWorkspace?.id);
  useDesktopNotificationsSocketConsumer(socket);
  useAppsSocketConsumer(socket);
  const { fetchCurrentTeamMembersAndDispatch } = useTeamMembersApiConsumer();
  const { fetchUnreadNotificationsAndDispatch } = useNotificationsApiConsumer();
  const { fetchUnreadChangelogNotificationsAndDispatch } = useChangeLogUnreadNotificationsApiConsumer();

  useEffect(() => {
    if (!selectedWorkspace) return;
    const abortController = new AbortController();
    fetchUnreadNotificationsAndDispatch(selectedWorkspace.id, abortController.signal);
    fetchUnreadChangelogNotificationsAndDispatch(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [fetchUnreadNotificationsAndDispatch, fetchUnreadChangelogNotificationsAndDispatch, selectedWorkspace]);

  useEffect(() => {
    if (!selectedWorkspace) return;
    const abortController = new AbortController();

    fetchCurrentTeamMembersAndDispatch(selectedWorkspace.id);

    return () => {
      abortController.abort();
    };
  }, [fetchCurrentTeamMembersAndDispatch, selectedWorkspace]);

  // eslint-disable-next-line
  if (location.state && (location.state as any).from == FROM_SIGNUP) return <MarketingChannelSurvey />;

  return (
    <Styled.Container>
      <Sidebar />
      <Styled.InnerContainer>
        <Outlet />
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

/// Triggers fetchAllApps on workspace update.
let updateSelectedWSAbort = new AbortController();

export function useUpdateSelectedWorkspace() {
  const appDispatch = useAppDispatch();
  const { workspaces } = useAppContext();
  const { appsService } = useAppDeps();

  const updateSelectedWorkspace = useCallback(
    async (selectedWorkspace: Workspace) => {
      if (!selectedWorkspace) return;
      try {
        updateSelectedWSAbort.abort();
        updateSelectedWSAbort = new AbortController();

        appDispatch(setUpdatingWorkspaceAction(true));
        const { data: workspaceData } = await appsService.getTeamData(
          selectedWorkspace.id,
          updateSelectedWSAbort.signal,
        );
        if (updateSelectedWSAbort.signal && updateSelectedWSAbort.signal.aborted) return;

        const newWorkspaces = workspaces.map((w) => {
          if (w.id == workspaceData.id) {
            return {
              ...w,
              has_subscription: workspaceData.has_subscription,
              had_subscription: workspaceData.had_subscription,
              is_data_available: workspaceData.is_data_available,
            };
          }
          return w;
        });
        appDispatch(setWorkspacesAction(newWorkspaces));
      } catch (error) {
      } finally {
        if (updateSelectedWSAbort.signal && updateSelectedWSAbort.signal.aborted) return;
        appDispatch(setUpdatingWorkspaceAction(false));
      }
    },
    [appDispatch, workspaces, appsService],
  );

  return {
    updateSelectedWorkspace,
  };
}
