import styled from "styled-components";

import addAppBackground from "assets/images/empty-state/add-app-button-background.png";

import Button from "components/Button/Button";

export const BackgroundContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100rem auto;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 62rem;
  cursor: default;
`;

export const HeadingBoxWrapper = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 5rem;
  margin-top: 9.5rem;
`;

export const AddButtonBackground = styled.div`
  display: flex;
  flex: 1;
  cursor: default;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 41.6rem auto;
  background-image: url(${addAppBackground});
  padding-top: 4.2rem;
`;

export const AddButton = styled(Button)`
  margin-left: 2rem;
`;
