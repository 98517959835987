import React, { useState } from "react";

import * as Styled from "./AddAppDomainsModalBody.styles";
import { Form } from "./ModalBody.styles";
import Button from "../../../../components/Button/Button";
import Input, { InputElementType } from "../../../../components/Input/Input";
import { ModalProps } from "../../../../components/Modal/Modal";
import { ModalHeaderProps } from "../../../../components/Modal/ModalHeader/ModalHeader";
import { Validators } from "../../../../util/Validators";

interface Props {
  handleAdd: (domains: string[]) => void;
  loading: boolean;
  toggle: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function AddAppDomainsModalBody({ handleAdd, loading, toggle, Modal, internalProps }: Props) {
  const [domains, setDomains] = useState("");
  const [error, setError] = useState<string>();

  const handleAddClick = (e?: React.MouseEvent<Element, MouseEvent>) => {
    if (!e) return;
    e.preventDefault();

    if (domains.trim() === "") {
      setError("Whoopsie, that doesn't look like a valid domain.");
      return;
    }

    const appDomains = domains
      .toLocaleLowerCase()
      .replace(/\s/, "")
      .split(/,+/)
      .reduce(function (trimmedEmails: string[], item) {
        if (item.trim() !== "") {
          trimmedEmails.push(item.trim());
        }
        return trimmedEmails;
      }, []);
    if (!appDomains.every(Validators.validDomain) || domains === "") {
      setError("Whoopsie, that doesn't look like a valid domain.");
      return;
    }
    handleAdd(appDomains);
    toggle();
    setDomains("");
  };

  return (
    <Modal
      {...internalProps}
      heading="Add permitted URLs"
      size="full"
      buttonElement={
        <Button
          type="submit"
          size="small"
          disabled={!domains.length || loading}
          tooltip={{
            position: "top",
            text: "Please enter at least one domain first",
            disabled: Boolean(domains.length) || loading,
          }}
          loadingText={loading && "Adding..."}
          onClick={(e) => handleAddClick(e)}
        >
          Add
        </Button>
      }
    >
      <Form onSubmit={(event) => event.preventDefault()}>
        <Input
          elementType={InputElementType.TEXTAREA}
          id="domain"
          label={"Domains or subdomains"}
          value={domains}
          placeholder="example.com, dashboard.example.com, etc."
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
            setError(undefined);
            setDomains(e.currentTarget.value);
          }}
          rows={4}
          error={!!error}
          helper={
            error ? (
              error
            ) : (
              <div style={{ paddingTop: "0.2rem" }}>
                To add many URLs at once, just separate them with commas <Styled.Key>,</Styled.Key>.
              </div>
            )
          }
        />
      </Form>
    </Modal>
  );
}
