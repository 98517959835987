import React from "react";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "../../config/routePaths";
import RedirectWithLoading from "../RedirectWithLoading";

export const HowToRedirect = () => {
  const { selectedWorkspace } = useAppSelectionContext();

  const dynamicValues = { workspaceSlug: selectedWorkspace?.slug };

  return (
    <RedirectWithLoading
      path={RoutePaths.HOME_ALL}
      dynamicValues={dynamicValues}
    />
  );
};
