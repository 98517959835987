import React from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "./Spinner.styles";

export const Spinner = ({ style }: { style?: CSSProperties }) => {
  return (
    <Styled.SpinnerWrap>
      <Styled.Spinner style={style}></Styled.Spinner>
    </Styled.SpinnerWrap>
  );
};
