import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "components/Button/Button";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "components/SelectDropdown/SelectDropdown";

import { forwardingOptions } from "models/integrations";
import { Clickup } from "models/integrations/Clickup.model";

import { Form } from "../ModalBody.styles";
import { SpaceOption, useClickupIntegrationFormConsumer } from "./consumers/useClickupIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "./GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Clickup;
  selectedAppId: string;
}

export const ClickupIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const {
    disabled,
    loading,
    listOption,
    listOptions,
    spaceOption,
    spaceOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  } = useClickupIntegrationFormConsumer({ integration, selectedAppId, onFinish });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const isButtonDisabled = checkIsButtonDisabled(disabled, spaceOption, listOption, forwardingOption, integration);

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="spaceOption"
          value={spaceOption}
          options={spaceOptions}
          placeholder="Please choose"
          label="Space"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />
        <SelectDropdown
          name="listOption"
          value={listOption}
          options={listOptions}
          placeholder="Please choose"
          label="List"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || !spaceOption || loading}
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};

const checkIsButtonDisabled = (
  disabled: boolean,
  spaceOption: Option,
  listOption: Option,
  forwardingOption: Option,
  integration?: Clickup,
) => {
  if (disabled) return true;
  if (!spaceOption || !forwardingOption) return true;

  const parsedSpaceOption = JSON.parse(spaceOption.value) as SpaceOption;
  if (
    integration &&
    listOption &&
    listOption.value !== "" &&
    (parsedSpaceOption.spaceId !== integration?.space_id ||
      !!forwardingOption.value !== integration?.automatic_forward ||
      listOption.value !== integration?.list_id)
  ) {
    return false;
  }
  return true;
};
