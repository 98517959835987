import styled from "styled-components";

import { vars } from "styles";

export const WebBanner = styled.div`
  border-radius: 0px 0px 10px 10px;
  background: ${vars.colors.limeTransparent};
  cursor: pointer;

  p {
    color: ${vars.colors.lime};
    font-size: 1.2rem;
    line-height: 1.6rem;
    height: 1.6rem;
    pointer-events: none;
  }

  width: 38.7rem;
  height: 3.2rem;

  display: flex;
  align-items: flex-end;

  padding-left: 0.9rem;
  padding-bottom: 0.2rem;

  position: fixed;
  top: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: ${vars.zIndex.envRibbon};
`;
