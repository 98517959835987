import { useCallback, useEffect, useState } from "react";

import { useAppDeps } from "App.dependencies";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useUpdateSelectedWorkspace } from "layouts/views/WorkspaceRootLayout";

import { CreditCard, Customer, Invoice, Plan, PlanInterval, PlanType } from "models/billing";
import { isAdmin } from "models/Member.model";

import { useAdministrationDeps } from "../Administration";

export const useBillingViewConsumer = () => {
  const [customer, setCustomer] = useState<Customer>();
  const { selectedWorkspace } = useAppSelectionContext();
  const { appsService } = useAppDeps();
  const { administrationService } = useAdministrationDeps();
  const [creditCard, setCreditCard] = useState<CreditCard>();
  const [receipts, setReceipts] = useState<Invoice[]>([]);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [addOns, setAddOns] = useState<Plan[]>([]);

  const getBillingInfo = useCallback(async () => {
    if (!selectedWorkspace) return;
    setGlobalLoading(true);
    try {
      const { data: customer } = await appsService.getCustomerInfo(selectedWorkspace.id);
      setCustomer(customer);

      if (isAdmin(selectedWorkspace?.role)) {
        const { data } = await administrationService.getCreditCard(selectedWorkspace.id);
        setCreditCard(data);

        const invoices = await administrationService.getInvoices(selectedWorkspace.id);
        setReceipts(invoices.data);
      }
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching customer info",
      });
    } finally {
      setGlobalLoading(false);
    }
  }, [administrationService, appsService, selectedWorkspace]);

  const getSubscriptionPlans = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await administrationService.getSubscriptionPlans();
      const plans = data.filter((plan) => plan.type === PlanType.PLAN);

      const sortedPlans = plans
        .map((plan) => {
          if (plan.interval === PlanInterval.YEAR || plan.interval === PlanInterval.WEEK) {
            return { ...plan, sort_number: 0 };
          }
          return { ...plan, sort_number: 1 };
        })
        .sort((a, b) => a.sort_number - b.sort_number);

      setPlans(sortedPlans);

      const addOns = data.filter((addOn) => addOn.type === PlanType.DEVICES);
      setAddOns(addOns);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching subscription plans",
      });
    } finally {
      setLoading(false);
    }
    () => {
      setLoading(false);
    };
  }, [administrationService]);

  const cancelSubscription = useCallback(async () => {
    if (!selectedWorkspace) return;

    setLoading(true);

    try {
      await administrationService.handleSubscriptionActivation(selectedWorkspace.id, false);
      displayToast({
        title: "Your wish was our command",
        content: "Your subscription will be cancelled at the end of your current billing period. Sorry to see you go.",
      });

      const { data } = await appsService.getCustomerInfo(selectedWorkspace.id);
      setCustomer(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }, [administrationService, selectedWorkspace, appsService]);

  const resumeSubscription = useCallback(async () => {
    if (!selectedWorkspace) return;

    setLoading(true);

    try {
      await administrationService.handleSubscriptionActivation(selectedWorkspace.id, true);
      displayToast({
        title: "Subscription resumed",
        content: "Thank you and welcome back.",
      });

      const { data } = await appsService.getCustomerInfo(selectedWorkspace.id);

      await updateSelectedWorkspace(selectedWorkspace);

      setCustomer(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }, [administrationService, selectedWorkspace, appsService, updateSelectedWorkspace]);

  const updateCreditCard = useCallback(async () => {
    if (!selectedWorkspace) return;

    setLoading(true);

    try {
      const res = await administrationService.updatePaymentMethod(selectedWorkspace.id);

      window.location.replace(res.data.url);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
    }
  }, [administrationService, selectedWorkspace]);

  useEffect(() => {
    getBillingInfo();
    getSubscriptionPlans();
  }, [getBillingInfo, getSubscriptionPlans]);

  return {
    customer,
    globalLoading,
    loading,
    creditCard,
    updateCreditCard,
    receipts,
    plans,
    addOns,
    cancelSubscription,
    resumeSubscription,
  };
};
