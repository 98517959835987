import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "components/SelectDropdown/SelectDropdown";
import { ToggleSection } from "components/ToggleSection/ToggleSection";

import { Workspace } from "models";
import { forwardingOptions } from "models/integrations";
import { Linear } from "models/integrations/Linear.model";

import { Form } from "../ModalBody.styles";
import { TeamOption, useLinearIntegrationFormConsumer } from "./consumers/useLinearIntegrationFormConsumer";

export interface BaseIntegrationModalProps {
  onFinish: () => void;
}

interface Props extends BaseIntegrationModalProps {
  integration?: Linear;
  selectedAppId: string;
  workspace: Workspace;
}

export const LinearIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const {
    forwardingOption,
    teamOption,
    teamOptions,
    projectOption,
    projectOptions,
    handleFormChange,
    handleFormSubmit,
    disabled,
    loading,
    webhook,
    loadOptions,
  } = useLinearIntegrationFormConsumer({ selectedAppId, onFinish, integration });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const isButtonDisabled = checkIsButtonDisabled(
    disabled,
    teamOption,
    projectOption,
    forwardingOption,
    webhook,
    integration,
  );

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="teamOption"
          value={teamOption}
          options={teamOptions}
          placeholder="Please choose"
          label="Team"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
          loadOptions={loadOptions}
        />

        <HelperText>
          Forwarded tickets are sent to this team’s Triage inbox. Disable triage for this team in Linear › Workspace
          settings › Team › Triage if you want the tickets to be sent to the Project you choose below.
        </HelperText>

        <SelectDropdown
          name="projectOption"
          value={projectOption}
          options={projectOptions}
          placeholder="Please choose"
          label="Project"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || !teamOption || loading}
        />
        <ToggleSection
          name="webhooks"
          title="Linear updates Shake"
          subtitle="Changing Linear ticket’s status will automatically update its Shake status. All other changes to Linear ticket will be listed in Shake ticket too."
          checked={webhook}
          onChange={() => handleFormChange("webhook", !webhook)}
          disabled={disabled || !teamOption || loading}
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};

const checkIsButtonDisabled = (
  disabled: boolean,
  teamOption: Option,
  projectOption: Option,
  forwardingOption: Option,
  webhook: boolean,
  integration?: Linear,
) => {
  if (disabled) return true;
  if (!teamOption || !forwardingOption) return true;

  const parsedTeamOption = JSON.parse(teamOption.value) as TeamOption;
  if (
    integration &&
    (parsedTeamOption.teamId !== integration?.linear_team_id ||
      !!forwardingOption.value !== integration?.automatic_forward ||
      (integration.linear_project_id && projectOption.value !== integration?.linear_project_id) ||
      (!integration.linear_project_id && projectOption.value !== "{}") ||
      integration?.is_webhook_enabled !== webhook)
  ) {
    return false;
  }
  return true;
};
