import { UserData, UserToken } from "models";

import { AuthContextActionType, SetUserData, SetUserToken } from "./Auth.types";

export const setUserDataAction = (payload?: UserData): SetUserData => ({
  type: AuthContextActionType.SET_USER_DATA,
  payload,
});

export const setUserTokenAction = (payload?: UserToken): SetUserToken => ({
  type: AuthContextActionType.SET_USER_TOKEN,
  payload,
});
