import React from "react";

import { CenteredForm } from "components/CenteredForm/CenteredForm";

import { SignInForm } from "../components/SignInForm";

export const SignInView = () => {
  return (
    <CenteredForm>
      <SignInForm />
    </CenteredForm>
  );
};
