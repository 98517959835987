import { useEffect } from "react";

import { Socket } from "socket.io-client";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { CrashGroupAddedEvent, ServerToClientEvents, SocketEvents } from "models/webSocketEvents";

interface Params {
  socket?: Socket<ServerToClientEvents, never>;
  refetchData?: () => void;
}

export const useCrashGroupAddedSocketConsumer = ({ socket, refetchData }: Params) => {
  const { selectedWorkspace } = useAppSelectionContext();
  useEffect(() => {
    if (!selectedWorkspace || !socket) return;

    const eventHandler = (event: CrashGroupAddedEvent) => {
      selectedWorkspace.id == event.team_id && refetchData?.();
    };

    socket.on(SocketEvents.CRASH_GROUP_ADDED, eventHandler);
    return () => {
      socket.off(SocketEvents.CRASH_GROUP_ADDED, eventHandler);
    };
  }, [socket, selectedWorkspace, refetchData]);
};
