import React, { useEffect } from "react";

import { ModalHeader } from "components/Modal/ModalHeader/ModalHeader";

import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import environment from "environments";

export function CancelSubscriptionBody({ onCancel }: { onCancel: () => void }) {
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const tallyObject = Tally as any;
    if (!tallyObject) return;

    tallyObject.loadEmbeds();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const tallyObject = Tally as any;
    if (!tallyObject) return;

    // eslint-disable-next-line
    function handler(event: MessageEvent<any>) {
      if (typeof event.data !== "string") return;
      if (!event.data.includes("Tally.FormSubmitted")) return;
      onCancel();
    }

    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [onCancel]);

  const formURL =
    environment.name === "production"
      ? `https://forms.shakebugs.com/cancel_subscription?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&userId=${userData?.id}&workspaceId=${selectedWorkspace?.id}`
      : `https://forms.shakebugs.com/cancel_subscription_dev?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&userId=${userData?.id}&workspaceId=${selectedWorkspace?.id}`;

  return (
    <>
      <ModalHeader
        heading="OK, you're the boss"
        subtitle="Sorry to bug you, but why cancel Shake?"
      />
      <iframe
        data-tally-src={formURL}
        loading="lazy"
        onLoad={() => {
          /// iFrame loaded
          return;
        }}
        frameBorder="0"
        width="100%"
        height="450"
      ></iframe>
    </>
  );
}
