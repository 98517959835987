import { useCallback } from "react";

import { useIntl } from "react-intl";

import displayToast from "components/Toast/displayToast";

import { useSignUpDeps } from "../SignUp";

interface SignUpConsumerProps {
  successEmail: string | undefined;
}

export const useSignUpConsumer = ({ successEmail }: SignUpConsumerProps) => {
  const intl = useIntl();

  const { signUpService } = useSignUpDeps();

  const resendConfirmationEmail = useCallback(async () => {
    if (!successEmail) return;

    try {
      await signUpService.resendConfirmationEmail(successEmail);

      document.title = intl.formatMessage({ id: "email.check.title" });

      displayToast({
        id: "resend-confirmation",
        content: `${intl.formatMessage({ id: "email.api.resent" })} ${successEmail}.`,
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Request failed",
      });
    }
  }, [successEmail, signUpService, intl]);

  return { resendConfirmationEmail };
};
