import React from "react";

import { generatePath, useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";

import { useWorkspaceAppParams } from "pages/IntegrationActivation/helpers/useWorkspaceAppParams";

import { RoutePaths } from "router/config/routePaths";

import * as Styled from "./TableFooter.styles";
import { AddAppButton } from "../../../../components/AddAppButton/AddAppButton";

export interface TableFooterProps {
  text: string;
  buttonIcon: React.ReactNode;
  buttonText: string;
  onButtonClick: () => void;
  button?: JSX.Element;
}

const TableFooter = ({ text, buttonIcon, buttonText, onButtonClick, button }: TableFooterProps) => {
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title
          marginTop={8}
          marginBottom={2}
        >
          {text}
        </Styled.Title>
        {button ? (
          button
        ) : (
          <Button onClick={onButtonClick}>
            {buttonIcon}
            {buttonText}
          </Button>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export interface FreePlanFooterProps {
  text: string;
}

export const FreePlanFooter = ({ text }: FreePlanFooterProps) => {
  const onLearnMoreClick = () => {
    window.open("https://www.shakebugs.com/pricing/", "_blank");
  };

  return (
    <TableFooter
      text={text}
      buttonText={"Learn more"}
      buttonIcon={
        <Icon
          icon="spark"
          size={24}
        />
      }
      onButtonClick={onLearnMoreClick}
    />
  );
};

export const FeedbackModuleFreePlanFooter = () => {
  return (
    <FreePlanFooter
      text={
        "Tickets older than 1 month are hidden. Every paid plan includes at least a year of data retention and additional premium benefits."
      }
    />
  );
};

export const CrashModuleFreePlanFooter = () => {
  return (
    <FreePlanFooter
      text={
        "Crashes older than 1 month are hidden. Every paid plan includes at least a year of data retention and additional premium benefits."
      }
    />
  );
};

export interface SampleAppFooterProps {
  text: string;
}

export const SampleAppFooter = ({ text }: SampleAppFooterProps) => {
  const navigate = useNavigate();
  const { workspaceSlug } = useWorkspaceAppParams();

  const onAddAppClick = () => {
    if (workspaceSlug) navigate(generatePath(RoutePaths.ADD_APP, { workspaceSlug }));
  };

  return (
    <TableFooter
      text={text}
      buttonText={"Add an app"}
      buttonIcon={
        <Icon
          icon="plus"
          size={10}
        />
      }
      button={
        <AddAppButton
          navigateTo={generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null })}
          wiggle={true}
        />
      }
      onButtonClick={onAddAppClick}
    />
  );
};

export const FeedbackModuleSampleAppFooter = () => {
  return (
    <SampleAppFooter
      text={
        "These are sample user feedback tickets. Create a new app or select an existing one to start receiving your own."
      }
    />
  );
};

export const CrashesModuleSampleAppFooter = () => {
  return (
    <SampleAppFooter
      text={
        "These are sample crash report tickets. Create a new app or select an existing one to start receiving your own."
      }
    />
  );
};

export const UsersModuleSampleAppFooter = () => {
  return (
    <SampleAppFooter
      text={
        "These are sample app users. Create a new app or select an existing one to start receiving information about your app users."
      }
    />
  );
};
