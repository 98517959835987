export interface IssueCustomField {
  type: CustomFieldType;
  label: string;
  value: string;
}

export enum CustomFieldType {
  TITLE = "title",
  TEXT_INPUT = "text_input",
  EMAIL = "email",
  PICKER = "picker",
}
