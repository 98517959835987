import { useEffect, useRef } from "react";

export default function useAbortController() {
  const abortController = useRef(new AbortController());

  useEffect(() => {
    if (abortController.current.signal.aborted) {
      abortController.current = new AbortController();
    }
  }, [abortController.current.signal.aborted]);

  useEffect(() => {
    return () => abortController.current.abort();
  }, []);

  return { abortSignal: abortController.current.signal };
}
