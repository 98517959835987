import FlutterAndroid from "assets/images/platforms/flutter-android@2x.png";
import FlutterIos from "assets/images/platforms/flutter-ios@2x.png";
import NativeAndroid from "assets/images/platforms/native-android@2x.png";
import NativeIos from "assets/images/platforms/native-ios@2x.png";
import RNAndroid from "assets/images/platforms/rn-android@2x.png";
import RNIos from "assets/images/platforms/rn-ios@2x.png";
import WebSmall from "assets/images/platforms/website-sdk-small-icon.svg";
import WebOs from "assets/images/platforms/website-sdk@2x.png";

import { Platform, PlatformName, PlatformOs } from "models/Platform.model";

export const getAppPlatformOsLabel = (platform?: Platform) => {
  if (!platform) return "";
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.ANDROID) {
    return "Native Android";
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.IOS) {
    return "Native iOS";
  }
  if (platform.name === PlatformName.REACT_NATIVE) {
    return `React Native ${platform.os}`;
  }
  if (platform.name === PlatformName.WEB) {
    return "Web";
  }
  return `${platform.name} ${platform.os}`;
};

export const getAppPlatformLabel = (platform?: Platform) => {
  if (!platform) return "";
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.ANDROID) {
    return "Native Android";
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.IOS) {
    return "Native iOS";
  }
  if (platform.name === PlatformName.REACT_NATIVE) {
    return `React Native`;
  }
  return `${platform.name}`;
};

export const getPlatformName = (platform?: Platform) => {
  if (!platform) return "";

  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.ANDROID) {
    return "Native Android";
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.IOS) {
    return "Native iOS";
  }
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.REACT_NATIVE) {
    return "React Native Android";
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.REACT_NATIVE) {
    return "React Native iOS";
  }
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.FLUTTER) {
    return "Flutter Android";
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.FLUTTER) {
    return "Flutter iOS";
  }
  if (platform.os === PlatformOs.WEB && platform.name === PlatformName.WEB) {
    return "Website";
  }
  return "";
};

export const getPlatformImage = (platform?: Platform) => {
  if (!platform) return "";

  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.ANDROID) {
    return NativeAndroid;
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.IOS) {
    return NativeIos;
  }
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.REACT_NATIVE) {
    return RNAndroid;
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.REACT_NATIVE) {
    return RNIos;
  }
  if (platform.os === PlatformOs.ANDROID && platform.name === PlatformName.FLUTTER) {
    return FlutterAndroid;
  }
  if (platform.os === PlatformOs.IOS && platform.name === PlatformName.FLUTTER) {
    return FlutterIos;
  }
  if (platform.os === PlatformOs.WEB && platform.name === PlatformName.WEB) {
    return WebOs;
  }
  return "";
};

export const getOSImage = (platform?: Platform) => {
  if (!platform) return "";

  if (platform.os === PlatformOs.ANDROID) {
    return NativeAndroid;
  }
  if (platform.os === PlatformOs.IOS) {
    return NativeIos;
  }
  if (platform.os === PlatformOs.WEB) {
    return WebSmall;
  }
  return "";
};
