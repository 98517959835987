import { useCallback } from "react";

import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { App, Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";

import { getRouteFromPath } from "util/moduleRouteHelper";
import { removeAttributesFromLocalStorage } from "util/removeAttributesFromLocalStorage";
import { setShakeSdkAppMetadata, setShakeSdkWorkspaceMetadata } from "util/shakeSdkUtils";

export default function useWorkspaceAndAppChange() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();

  const handleWorkspaceChange = useCallback(
    async (workspace: Workspace) => {
      removeAttributesFromLocalStorage();
      localStorage.setItem("lastWorkspaceVisited", workspace.id);
      setShakeSdkWorkspaceMetadata(workspace);
      navigate(generatePath(RoutePaths.FEATURES_LATEST, { workspaceSlug: workspace.slug }));
    },
    [navigate],
  );

  const handleAppChange = useCallback(
    (app: App) => {
      if (!selectedWorkspace) return;
      // removeAttributesFromLocalStorage();
      setShakeSdkAppMetadata(app);
      const moduleAppRoute = getRouteFromPath(location.pathname);
      navigate(generatePath(moduleAppRoute, { workspaceSlug: selectedWorkspace.slug, appKey: app.key }));
    },
    [navigate, selectedWorkspace, location.pathname],
  );

  return { handleWorkspaceChange, handleAppChange };
}
