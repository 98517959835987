import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";
import { useIntl as translate } from "react-intl";

import english from "localization/english.json";

type TranslateValues = Record<string, PrimitiveType | FormatXMLElementFn<string, string>> | undefined;

export function t(id: keyof typeof english, values?: TranslateValues): string {
  const intl = translate();
  return intl.formatMessage({ id: id as string, defaultMessage: "IMPLEMENT_TRANSLATION_KEY" }, values);
}
