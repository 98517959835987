import { PassthroughState } from "pages/SignIn/utils/useGoogleSignIn";

interface GoogleParseProps {
  currentURL: URL;
}

/**
 * Google will redirect to URL and pass data as search parameters.
 * Use this method to parse the params.
 */
export function parseGoogleCallback({ currentURL }: GoogleParseProps) {
  const { auth_callback, code, error, state } = Object.fromEntries(currentURL.searchParams);

  const isGoogleAuthCallback = auth_callback === "google";

  if (!isGoogleAuthCallback) return {};

  const passThroughState = JSON.parse(state) as PassthroughState;

  const restoreOriginalURL = () => {
    passThroughState.originalURL && window.history.replaceState(null, "", passThroughState.originalURL.toString());
  };

  return {
    code,
    error,
    restoreOriginalURL,
    redirectURL: new URL(passThroughState.redirectURL),
    clientState: passThroughState.clientState,
    passThroughState,
  };
}
