import React from "react";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import RedirectWithLoading from "router/redirect/RedirectWithLoading";

import { RoutePaths } from "../config/routePaths";

export default function AccountRedirect() {
  const { selectedWorkspace } = useAppSelectionContext();

  const dynamicValues = { workspaceSlug: selectedWorkspace?.slug };

  return (
    <RedirectWithLoading
      path={RoutePaths.ACCOUNT_PERSONAL_INFO}
      dynamicValues={dynamicValues}
    />
  );
}
