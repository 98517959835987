import * as confetti from "canvas-confetti";

export const fireConfetti = () => {
  confetti.create(undefined, {
    resize: true,
    useWorker: false,
  })({
    angle: 315,
    spread: 90,
    ticks: 100,
    particleCount: 100,
    origin: {
      x: -0.1,
      y: -0.1,
    },
  });
  confetti.create(undefined, {
    resize: true,
    useWorker: false,
  })({
    angle: 225,
    spread: 90,
    ticks: 100,
    particleCount: 100,
    origin: {
      x: 1.1,
      y: -0.1,
    },
  });
};
