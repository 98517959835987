import { useEffect, useState } from "react";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { automaticForwardOption, IntegrationType, manualForwardOption } from "models/integrations";
import { Clickup, ClickupIntegrationInfo, ClickupIntegrationUpdatePayload } from "models/integrations/Clickup.model";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Clickup;
  selectedAppId: string;
  onFinish: () => void;
}

export interface SpaceOption {
  name: string;
  spaceId: string;
  integrationId: string;
}

interface FormState {
  listOption: Option;
  spaceOption: Option;
  forwardingOption: Option;
}

const initialFormState = {
  listOption: { value: "", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
  spaceOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
};

export const useClickupIntegrationFormConsumer = ({ selectedAppId, integration, onFinish }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<ClickupIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.CLICKUP,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);

  const [spaceOptions, setSpaceOptions] = useState<Option[]>([]);
  const [listOptions, setListOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { spaceOption, forwardingOption, listOption } = formState;

  async function handleConnect() {
    setLoading(true);
    try {
      if (!integrationInfo) throw new Error();

      const ClickupSpaceInfo = JSON.parse(spaceOption.value) as SpaceOption;

      const payload: ClickupIntegrationUpdatePayload = {
        automatic_forward: !!forwardingOption.value,
        integration_id: ClickupSpaceInfo.integrationId,
        space_id: ClickupSpaceInfo.spaceId,
        list_id: listOption.value,
      };

      const endpoint = !!integration ? updateIntegration : integrate;

      endpoint<Clickup>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setSpaceOptions([]);
      setListOptions([]);
      handleFormChange("spaceOption", null);
      handleFormChange("listOption", { value: "", label: "/" });
      return;
    }

    const newSpaceOptions: Option[] = integrationInfo.flatMap((info) => {
      return info.spaces.map((spaceInfo) => {
        const spaceOption: SpaceOption = {
          name: spaceInfo.space_name,
          spaceId: spaceInfo.space_id,
          integrationId: spaceInfo.integration_id,
        };
        return { value: JSON.stringify(spaceOption), label: spaceInfo.space_name };
      });
    });

    setSpaceOptions([...newSpaceOptions]);

    const preSelectedSpace = integration
      ? newSpaceOptions.find((option) => {
          const spaceOption = JSON.parse(option.value) as SpaceOption;
          return spaceOption.spaceId === integration.space_id;
        }) || null
      : null;

    handleFormChange("spaceOption", preSelectedSpace);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  useEffect(() => {
    if (!integrationInfo) return;

    if (spaceOption === null) {
      handleFormChange("listOption", null);
      return;
    }

    const selectedSpaceOption = JSON.parse(spaceOption.value) as SpaceOption;

    const listsForSelectedSpaceOption: Option[] | undefined = [];

    integrationInfo.map((info) =>
      info.spaces
        .find((space) => space.space_id === selectedSpaceOption.spaceId)
        ?.folders.map((folder) =>
          folder.lists.map(({ list_id, list_name }) => {
            listsForSelectedSpaceOption.push({ value: list_id, label: list_name });
          }),
        ),
    );

    listsForSelectedSpaceOption && setListOptions(listsForSelectedSpaceOption);

    if (!listsForSelectedSpaceOption) return;

    const preSelectedList = integration
      ? listsForSelectedSpaceOption.find((listOption) => {
          return integration.list_id === listOption.value;
        }) || listsForSelectedSpaceOption[0]
      : listsForSelectedSpaceOption[0];

    preSelectedList && handleFormChange("listOption", preSelectedList);
  }, [spaceOption, integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    disabled,
    loading,
    spaceOptions,
    spaceOption,
    listOption,
    listOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  };
};
