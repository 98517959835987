import React from "react";

import * as Styled from "./RoundedCanvas.styles";
import Icon from "../Icon/Icon";

export interface RoundedCanvasProps {
  size?: "small" | "normal" | "large" | "xsmall" | "xxsmall";
  icon?: string;
  image?: string;
  letter?: string;
  fgColor?: string;
  txtColor?: string;
  bgColor?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  imageSize?: number;
  testId?: string;
  square?: boolean;
}

const RoundedCanvas: React.FC<RoundedCanvasProps> = ({
  size = "normal",
  icon,
  image,
  letter,
  fgColor,
  txtColor,
  bgColor,
  onClick,
  onMouseOver,
  imageSize,
  testId,
  square,
}) => {
  return (
    <Styled.RoundedCanvasWrap
      $size={size}
      $icon={icon}
      $image={image}
      $letter={letter}
      $fgColor={fgColor}
      $bgColor={image ? "none" : bgColor}
      $txtColor={txtColor}
      onClick={onClick}
      onMouseOver={onMouseOver}
      $imageSize={imageSize}
      data-testid={testId}
      $square={square}
    >
      {image && (
        <img
          alt="avatar"
          src={image}
        />
      )}
      {icon && (
        <Icon
          icon={icon}
          color={fgColor}
        />
      )}
      {letter && <span className="letter">{letter}</span>}
    </Styled.RoundedCanvasWrap>
  );
};

export default RoundedCanvas;
