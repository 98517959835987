import React, { Fragment } from "react";

import { generatePath, useNavigate, useSearchParams } from "react-router-dom";

import * as Styled from "./WorkspaceSelectElement.style";
import ShakeIcon from "../../../assets/icons/shake_icon.svg";
import { CenteredForm } from "../../../components/CenteredForm/CenteredForm";
import CenteredMessage from "../../../components/CenteredMessage/CenteredMessage";
import { HelperText } from "../../../components/HelperText/HelperText";
import Paragraph from "../../../components/Paragraph/Paragraph";
import RoundedCanvas from "../../../components/RoundedCanvas/RoundedCanvas";
import { useAppContext } from "../../../context/App/App.context";
import SidebarMenuItem from "../../../layouts/components/Sidebar/SidebarMenuItem";
import { extractCallbackParams, typeToIntegrationType } from "../../../layouts/util/extractingFunctions";
import { SentryCallbackParams, SentryPasssthroughState } from "../../../models/integrations";
import { RoutePaths } from "../../../router/config/routePaths";
import { Flex } from "../../../styles/reusable/Flex/Flex.styles";
import { getRandomColor } from "../../shared/helpers/getRandomColorHelper";

interface Props {
  setLoading: (loading: boolean) => void;
}

export default function WorkspaceJoinElement({ setLoading }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const integrationType = typeToIntegrationType(searchParams.get("type"));
  const { workspaces } = useAppContext();

  const onSelectWorkspace = (workspaceId: string, workspaceSlug: string) => {
    if (!integrationType || !workspaceId || !workspaceSlug) throw new Error("Something went wrong");

    setLoading(true);
    const { code, installationId } = extractCallbackParams(searchParams, integrationType) as SentryCallbackParams;
    if (!code) throw new Error("Missing code");

    const stateParam: SentryPasssthroughState = {
      workspaceId: workspaceId,
      workspaceSlug: workspaceSlug,
    };
    navigate(
      generatePath(
        `${
          RoutePaths.INTEGRATION_ACTIVATION
        }?code=${code}&installationId=${installationId}&type=${integrationType}&state=${encodeURIComponent(
          JSON.stringify(stateParam),
        )}`,
      ),
      {
        replace: true,
      },
    );
  };

  return (
    <CenteredMessage
      title={"Select workspace"}
      paragraph={"Select a workspace you wish to integrate Sentry with."}
    >
      <HelperText>
        Due to Sentry API limitation, only a single workspace can have an active Sentry integration at one time.
      </HelperText>
      <br />
      {workspaces.length > 0 && (
        <Fragment>
          <Styled.WorkspaceScroll>
            {workspaces
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((workspace) => (
                <SidebarMenuItem
                  key={workspace.id}
                  onClick={() => onSelectWorkspace(workspace.id, workspace.slug)}
                >
                  <RoundedCanvas
                    letter={workspace.name[0]?.toLocaleUpperCase() || ""}
                    image={workspace?.logo_url ?? undefined}
                    bgColor={getRandomColor(workspace?.id).background}
                    txtColor={getRandomColor(workspace?.id).text}
                  />
                  <Styled.WorkspaceItemText>{workspace.name}</Styled.WorkspaceItemText>
                </SidebarMenuItem>
              ))}
          </Styled.WorkspaceScroll>
        </Fragment>
      )}
    </CenteredMessage>
  );
}
