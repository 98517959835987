import React from "react";

import { generatePath, useNavigate } from "react-router-dom";

import ShakeIcon from "assets/icons/shake_icon.svg";

import Button from "components/Button/Button";
import { CenteredForm } from "components/CenteredForm/CenteredForm";

import useDomainVerificationApiConsumer from "pages/Administration/consumers/useDomainVerificationConsumer";
import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import * as Styled from "./DomainVerificationView.styles";

export default function DomainVerificationView() {
  const navigate = useNavigate();

  const { approvedDomain } = useDomainVerificationApiConsumer();

  if (!approvedDomain) {
    return (
      <CenteredForm>
        <Styled.DomainVerificationViewBoxWrap>
          <img
            src={ShakeIcon}
            alt="Shake Logo"
          />
          <h1>{t("administration.members.approvedDomain.activation.error_title")}</h1>
        </Styled.DomainVerificationViewBoxWrap>
      </CenteredForm>
    );
  }

  return (
    <CenteredForm>
      <Styled.DomainVerificationViewBoxWrap>
        <FormTitle
          title={t("administration.members.approvedDomain.activation.title", {
            domain: approvedDomain.name,
          })}
          subtitle={t("administration.members.approvedDomain.activation.description", {
            domain: approvedDomain.name,
            team: approvedDomain.team.name,
          })}
        />
        <Button
          size="full"
          onClick={() => navigate(generatePath(RoutePaths.ROOT))}
        >
          {t("administration.members.approvedDomain.activation.button")}
        </Button>
      </Styled.DomainVerificationViewBoxWrap>
    </CenteredForm>
  );
}
