import { useMemo } from "react";

import { LogType } from "models/eventTracking";

import {
  AllFieldNameUXOperators,
  Attribute,
  AvailableAttribute,
  Filter,
  FilterHit,
  FilterHitDisplay,
  UseModuleAttributes,
} from "../sharedTypes";
import { useAttributes } from "../useAttributes";
import { attributeIconFor, getUXOperatorForFilter, getUXOperators, partialFilterFor } from "../utils";

const availableFieldNames = ["message", "log_type", "response_body", "request_body"] as const;

const groupedAvailableFieldNames = [
  {
    title: "Activity history data",
    values: availableFieldNames,
  },
] as const;

export type LogFieldName = (typeof availableFieldNames)[number];

export function useLogsAttributes(): UseModuleAttributes {
  const { addAttribute, removeAttribute, updateAttribute, attributes } = useAttributes({
    storageKey: "shakebugs.log_attributes",
  });

  const validAttributesJSONString = useMemo(
    () => JSON.stringify(attributes.filter((attr) => isValidAttribute(attr))),
    [attributes],
  );

  return {
    attributes: attributes.map((attr) => {
      return {
        name: attr.field_name,
      };
    }),
    availableAttributes: useMemo(() => getAvailableAttributes(attributes), [attributes]),
    validAttributes: useMemo(() => JSON.parse(validAttributesJSONString), [validAttributesJSONString]),
    isFilterUXValid: (atIndex) => isFilterUXValid(atIndex, attributes),
    searchBoxInitialOpen: () => {
      return true;
    },
    shouldOpenSearchBox: () => {
      return true;
    },
    showsHitsResults: () => {
      return false;
    },
    showSearch: (attributeName?: string) => {
      if (attributeName === "message" || attributeName === "response_body" || attributeName === "request_body") {
        return true;
      }
      return false;
    },
    add: (attributeName: string) => {
      addAttribute({
        field_name: attributeName,
        filter: undefined,
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    update: (identifier: number, value: string | undefined | null, selectedOperator: string) => {
      const current = attributes.find((_, index) => index === identifier);
      if (!current) return;

      const filter: Filter = { ...partialFilterFor(selectedOperator), fieldValue: value };

      const attr: Attribute = {
        attributeOperator: identifier == 0 ? undefined : "and",
        field_name: current.field_name,
        filter,
      };

      updateAttribute(identifier, attr);
    },
    remove: (atIndex: number) => {
      removeAttribute(atIndex);
    },
    filterSelectorInitialOpen: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;

      return (
        !attribute.filter?.fieldValue &&
        attribute.filter?.fieldValue !== null &&
        isFilterUXValid(identifier, attributes) &&
        identifier === attributes.length - 1
      );
    },
    getAttributeValuePresentation: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return "";
      if (attribute.field_name === "log_type")
        return (attribute.filter && getLogNames(attribute.filter.fieldValue)) || " is missing value";

      return attribute.filter?.fieldValue && attribute.filter?.fieldValue !== ""
        ? attribute.filter?.fieldValue
        : " is missing value";
    },
    getAttributeIcon: (attributeName: string) => {
      return attributeIconFor(attributeName);
    },
    getAttributeValue: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter?.fieldValue;
    },
    getAvailableUXOperators: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];
      return getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name));
    },
    getSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute || !attribute.filter) return;
      return getUXOperatorForFilter(attribute.filter, attribute.field_name);
    },
    getPreSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter
        ? getUXOperatorForFilter(attribute.filter, attribute.field_name)
        : getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name))[0];
    },
    getPredefinedValuesForAttribute: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];

      return predefinedValuesForFieldName(attribute.field_name).filter((field) => {
        return !attributes?.map((e) => e.filter?.fieldValue).includes(field.value);
      });
    },
    getAttributeOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.attributeOperator;
    },
    attributeHasDefinedFilter: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;
      return !!attribute.filter;
    },
    filterHitMapper,
    isEnterKeyEnabled: (attributeName: string) => {
      if (attributeName === "message" || attributeName === "response_body" || attributeName === "request_body")
        return true;
      return false;
    },
  };
}

function getAvailableAttributes(current: Array<Attribute>) {
  const availableTitleAttributes = [] as AvailableAttribute[];
  groupedAvailableFieldNames.map((field) => {
    const availableAttributes = [] as LogFieldName[];

    field.values.filter((value) => {
      if (!current.find((a) => a.field_name === value)) {
        if (
          (value !== "response_body" && value !== "request_body") ||
          (value === "response_body" &&
            current.find((a) => a.field_name === "log_type" && a.filter?.fieldValue === LogType.NETWORK_REQUEST)) ||
          (value === "request_body" &&
            current.find((a) => a.field_name === "log_type" && a.filter?.fieldValue === LogType.NETWORK_REQUEST))
        )
          availableAttributes.push(value);
      }
    });

    if (availableAttributes.length) {
      availableTitleAttributes.push({ title: field.title, values: availableAttributes });
    }
  });

  return availableTitleAttributes;
}

function getAvailableFieldNameUXOperators(fieldName: string) {
  const logOperators: Array<AllFieldNameUXOperators> = ["is"];
  const descriptionOperators: Array<AllFieldNameUXOperators> = ["contains"];

  switch (fieldName as LogFieldName) {
    case "message":
    case "response_body":
    case "request_body":
      return Array.from(descriptionOperators);
    case "log_type":
      return Array.from(logOperators);
    default:
      return Array.from(logOperators);
  }
}

function predefinedValuesForFieldName(fieldName: string) {
  switch (fieldName as LogFieldName) {
    case "log_type":
      return [
        { name: "User action", value: LogType.USER_EVENT },
        { name: "Network traffic", value: LogType.NETWORK_REQUEST },
        { name: "System event", value: LogType.SYSTEM_EVENT },
        { name: "Screen change event", value: LogType.SCREEN_EVENT },
        { name: "Notification", value: LogType.NOTIFICATION },
        { name: "Console log", value: LogType.CONSOLE_LOG },
        { name: "Custom log", value: LogType.CUSTOM_LOG },
      ];
  }
  return [];
}

function isValidAttribute(attribute: Attribute) {
  if (!attribute.filter) return false;

  if (!attribute.filter.fieldValue) return false;

  return attribute.filter.fieldValue.length > 0;
}

function isFilterUXValid(identifier: number, attributes: Attribute[]) {
  const attribute = attributes.find((_, index) => index === identifier);
  if (!attribute) return false;

  if (!attribute.filter) return true;
  return isValidAttribute(attribute);
}

function filterHitMapper(fieldName: string, issueFilters: FilterHit[], filterValue?: string | null | undefined) {
  const displayFilters = [] as FilterHitDisplay[];

  issueFilters.map((filter) => {
    displayFilters.push({
      value: filter.value,
      name: filter.name,
      label: filter.name,
      isChecked: filter.value === filterValue,
    });
  });

  return displayFilters;
}

function getLogNames(value?: string | null) {
  switch (value) {
    case LogType.USER_EVENT:
      return "User action";
    case LogType.NOTIFICATION:
      return "Notification";
    case LogType.SYSTEM_EVENT:
      return "System event";
    case LogType.SCREEN_EVENT:
      return "Screen change event";
    case LogType.CONSOLE_LOG:
      return "Console log";
    case LogType.CUSTOM_LOG:
      return "Custom log";
    case LogType.NETWORK_REQUEST:
      return "Network traffic";
    default:
      return "";
  }
}
