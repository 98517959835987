import styled, { css } from "styled-components";

import { HelperText } from "components/HelperText/HelperText";

import { vars } from "styles";

export const Container = styled.div<{ $smallerPadding?: boolean }>`
  padding: 2.4rem 0rem;
  user-select: none;
  ${(props) =>
    props.$smallerPadding &&
    css`
      padding: 2rem 0rem;
    `}
`;

export const MessageContainer = styled.div`
  padding: 0rem 2.4rem 2.4rem;
`;

export const ScrollContainer = styled.div`
  padding: 0rem 0rem 2.4rem;
  overflow: overlay;
  max-height: 380px;
  margin-right: 0.4rem;
  height: 380px;
`;

export const ButtonContainer = styled.div`
  border-top: 1px solid ${vars.colors.grey90};
  margin: 0rem 2.4rem;
  padding-top: 2.4rem;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  margin: 2.4rem 3.2rem 0rem;
  border-bottom: 1px solid ${vars.colors.grey90};
`;

export const Button = styled.button`
  background: ${vars.colors.grey100};
  width: 100%;
  padding: 0.8rem 3.2rem 1rem 3.2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  p {
    font-size: 1.4rem;
    color: ${vars.colors.grey30};
    font-weight: 500;
  }

  border: 0px solid transparent;

  &:hover {
    background: ${vars.colors.grey90};
    p {
      color: ${vars.colors.white};
    }
  }
`;

export const ColorDot = styled.span`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 1rem;
  transform: translateY(1px);
`;

export const OptionContainer = styled.div`
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    padding-left: 3.2rem;
  }

  label {
    width: 41.4rem;
    height: 3.6rem;
  }
  span {
    font-size: 1.4rem;
    color: ${vars.colors.grey30};
  }
`;

export const Helper = styled(HelperText)`
  margin-bottom: 2.4rem;
`;

export const SearchContainer = styled.div`
  margin: 0rem 3.2rem 1rem 3.2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${vars.colors.grey90};
`;

export const Search = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.4rem;

  caret-color: ${vars.colors.grey30};
  color: ${vars.colors.grey30};

  ::placeholder {
    color: ${vars.colors.grey70};
  }
`;
