import React, { Fragment, useState } from "react";

import Button from "components/Button/Button";
import Header from "components/Header/Header";
import Icon from "components/Icon/Icon";
import Pager from "components/Pager/Pager";
import PageWrap from "components/PageWrap/PageWrap";

import { Platform } from "models";

import * as Styled from "./AddAppPage.styles";
import { AddAppCreateApp } from "./views/CreateApp/AddAppCreateApp";
import { AddAppIntroPage } from "./views/IntroPage/AddAppIntroPage";
import { AddAppSelectPlatform } from "./views/SelectPlatform/AddAppSelectPlatform";

enum Pages {
  INTRO,
  PLATFORM,
  BUNDLE_ID,
}

export function AddAppPage() {
  const [selectedPage, setSelectedPage] = useState<number>(Pages.INTRO);
  const [platform, setPlatform] = useState<Platform>();

  const onPlatformSelected = (platform: Platform) => {
    setPlatform(platform);
    nextPage();
  };

  const nextPage = () => {
    if (selectedPage >= Object.keys(Pages).length / 2) return;
    setSelectedPage(selectedPage + 1);
  };

  const previousPage = () => {
    if (selectedPage <= 0) return;
    setSelectedPage(selectedPage - 1);
  };

  return (
    <Fragment>
      <Header>
        <Styled.HeaderWrap>
          {selectedPage > 0 && (
            <Button
              className={"align-left"}
              styling={"flat"}
              onClick={previousPage}
            >
              <Icon
                icon="caret-left"
                size={10}
              />
              {"Back"}
            </Button>
          )}
          <Pager
            pagesNumber={Object.keys(Pages).length / 2}
            selectedPage={selectedPage}
          />
        </Styled.HeaderWrap>
      </Header>
      <PageWrap
        style={{
          overflow: "scroll",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Styled.Container style={{ width: selectedPage === Pages.PLATFORM ? "82.5rem" : "62rem" }}>
          {(() => {
            switch (selectedPage) {
              case Pages.INTRO:
                return <AddAppIntroPage nextPage={nextPage} />;
              case Pages.PLATFORM:
                return <AddAppSelectPlatform onPlatformSelected={onPlatformSelected} />;
              case Pages.BUNDLE_ID:
                return <AddAppCreateApp platform={platform} />;
            }
            return undefined;
          })()}
        </Styled.Container>
      </PageWrap>
    </Fragment>
  );
}
