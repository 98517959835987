import React, { useEffect } from "react";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import { App } from "models";

import { useAddMappingFileFormConsumer } from "pages/Administration/consumers/useAddMappingFileFormConsumer";

import * as Styled from "./ModalBody.styles";

interface Props {
  selectedFile: File;
  closeModal: () => void;
  isModalActive?: boolean;
  selectedApp: App;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function AddMappingFileDataModalBody({
  selectedFile,
  closeModal,
  isModalActive,
  selectedApp,
  Modal,
  internalProps,
}: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useAddMappingFileFormConsumer(
    selectedApp,
    isModalActive ? closeModal : undefined,
  );

  const { versionName, versionCode } = formState;
  const { versionName: versionNameError, versionCode: versionCodeError } = formError;

  useEffect(() => {
    handleFormChange("file", selectedFile);
  }, [selectedFile, handleFormChange]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !versionCode || !versionName;

  return (
    <Modal
      {...internalProps}
      heading={`${selectedFile?.name}`}
      buttonElement={
        <Button
          disabled={isButtonDisabled || loading}
          size="small"
          type="submit"
          tooltip={{
            position: "top",
            text: "Please enter version name and code first",
            disabled: !isButtonDisabled,
          }}
          loadingText={loading && "Saving..."}
          onClick={handleFormSubmit}
        >
          Confirm
        </Button>
      }
    >
      <Styled.Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Input
          id="versionName"
          name="versionName"
          type="text"
          label="Version name"
          value={versionName}
          error={!!versionNameError}
          helper={versionNameError && versionNameError.message}
          placeholder="e.g. 1.4.12"
          onChange={handleChange}
          autoFocus
        />
        <Input
          name="versionCode"
          type="number"
          label="Version code"
          disabled={false}
          value={versionCode}
          error={!!versionCodeError}
          helper={versionCodeError && versionCodeError.message}
          onChange={handleChange}
          placeholder="e.g. 29"
        />
      </Styled.Form>
    </Modal>
  );
}
