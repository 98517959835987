import React from "react";

import * as Styled from "./Navigation.styles";

interface Props {
  elementId: string;
}

const Navigation: React.FC<Props> = ({ children, elementId }) => {
  return (
    <Styled.Navigation className="navigation">
      <ul id={elementId}>{children}</ul>
    </Styled.Navigation>
  );
};

interface NavItemProps {
  onClick?: () => void;
  withClick?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({ children, onClick, withClick = true }) => {
  return (
    <Styled.NavItem>
      <Styled.NavItemInner
        onClick={onClick}
        $withClick={withClick}
      >
        {children}
      </Styled.NavItemInner>
    </Styled.NavItem>
  );
};

export default Navigation;
