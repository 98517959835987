import React from "react";

import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { BlackboxChartType, BlackboxNetworkData } from "models/blackbox";

import { divideByConsecutiveValues, externalTooltipHandler } from "./helper";
import { getNetworkBlackBoxData, getOptions, getZeroData } from "./networkChartHelper";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface BlackboxChartProps {
  data: BlackboxNetworkData[];
  keyName: BlackboxChartType;
  keyValue?: number;
}

export default function BlackboxNetworkChart({ data, keyName, keyValue }: BlackboxChartProps) {
  const dividedData = divideByConsecutiveValues(getData(getNetworkBlackBoxData(data) ?? []));
  const data1: ChartData<"line"> = {
    labels: getNetworkBlackBoxData(data)?.map((rec) => rec.time_occurred),
    datasets: [
      {
        data: getData(getZeroData(keyName, getNetworkBlackBoxData(data) ?? [])),
        borderColor: "transparent",
        backgroundColor: "transparent",
        pointStyle: "circle",
        pointHitRadius: 2,
      },
    ].concat(getOptions(dividedData)),
  };

  return (
    <Line
      style={{ height: "3.3rem" }}
      options={getChartOptions(keyName, keyValue)}
      data={data1}
    />
  );
}

const getChartOptions = (name: BlackboxChartType, keyValue?: number) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    parsing: false,
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "x",
    },
    layout: {
      padding: {
        left: 12,
        right: 12,
      },
    },
    clip: false,
    indexAxis: "x",
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: "nearest",
        enabled: false,
        external: (a) => externalTooltipHandler(a, name, keyValue),
      },
    },
  } as ChartOptions<"line">;
};

const getData = (data: BlackboxNetworkData[]) => {
  if (!data || !data.length) return [];
  // eslint-disable-next-line
  const newData = new Array<any>();

  data.map((data: BlackboxNetworkData) => {
    if (data.prettified_type) {
      newData.push({
        y: 1,
        myProperty: data.prettified_type,
        x: data.time_occurred,
      });
    }
  });

  return newData;
};
