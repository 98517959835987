import styled, { css } from "styled-components";

import { vars } from "../../styles";

export const PagerContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  align-self: center;
`;

export const PagerDot = styled.div<{ $selected?: boolean }>`
  border-radius: 10rem;
  background-color: ${vars.colors.grey90};
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 2.2rem;

  ${(props) =>
    props.$selected &&
    css`
      background-color: ${vars.colors.grey50};
    `}
`;
