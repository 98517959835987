import React, { createContext, ReactNode, useContext } from "react";

import { UseModuleAttributes } from "../sharedTypes";

/// Reduces prop drilling in the Attributes component.

const context = createContext<UseModuleAttributes | undefined>(undefined);

export function AttributesProvider({
  children,
  moduleAttributes,
}: {
  children: ReactNode;
  moduleAttributes: UseModuleAttributes;
}) {
  return <context.Provider value={moduleAttributes}>{children}</context.Provider>;
}

export function useAttributesContext() {
  const value = useContext(context);
  if (!value) throw new Error("Missing attributes context");
  return value;
}
