import React from "react";

import { CenteredForm } from "components/CenteredForm/CenteredForm";
import { Spinner } from "components/Spinner/Spinner.styles";

import { useAppContext } from "context/App/App.context";

import WorkspaceCreateElement from "pages/WorkspaceCreate/components/WorkspaceCreateElement/WorkspaceCreateElement";

import * as Styled from "./WorkspaceCreateView.styles";

export default function WorkspaceCreateView() {
  const { workspacesLoading } = useAppContext();

  if (workspacesLoading)
    return (
      <Styled.CenteredContainer>
        <Spinner />
      </Styled.CenteredContainer>
    );

  return (
    <CenteredForm>
      <WorkspaceCreateElement />
    </CenteredForm>
  );
}
