import styled from "styled-components";

import { vars } from "styles";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 62rem;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .center {
    justify-content: center;
  }

  h1 {
    display: inline-block;
    text-align: center;
  }

  span > img {
    height: 2.3rem;
  }
`;

export const StepContent = styled.div`
  margin-left: 8.2rem;
  margin-bottom: 3.8rem;
`;

export const Chip = styled.div`
  display: inline;
  background-color: ${vars.colors.grey100};

  color: ${vars.colors.white};
  border-radius: 1rem;
  font-size: 1.3rem;
  font-family: Inconsolata, monospace;
  padding: 2px 8px 3px;
  margin-left: 6px;
  margin-right: 6px;
`;
