import styled, { css } from "styled-components";

import { ReactComponent as SearchIcon } from "assets/images/smart-search-icon.svg";

import BaseHeading from "components/Heading/Heading";
import Paragraph from "components/Paragraph/Paragraph";
import BaseTooltip from "components/Tooltip/Tooltip";

import { PlusIcon as BasePlusIcon } from "pages/shared/icons/icons";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

import { responsive } from "../../styles/abstracts/utils";

export const DetailsPane = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  cursor: default;
`;

export const Heading = styled(BaseHeading)`
  color: ${vars.colors.grey30};
  white-space: nowrap;
  margin-left: 0.8rem;
`;

export const EssentialsDetails = styled.div`
  margin: 0;
`;

export const DetailsPaneGroupElements = styled.div`
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
`;

export const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
`;

export const DetailsPaneColumn = styled.div<{
  $error?: boolean;
  $isButton?: boolean;
  $isDropdown?: boolean;
  $fullWidth?: boolean;
  $disabled?: boolean;
  $notClickable?: boolean;
  $blackbox?: boolean;
}>`
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  user-select: none;

  word-break: break-all;
  margin-bottom: 0.4rem;

  button {
    cursor: pointer;
    user-select: none;
  }

  ${(props) =>
    !props.$isButton &&
    css`
      width: 20.8rem;

      &:hover {
        background: ${vars.colors.grey90};
      }
    `}

  ${(props) =>
    !props.$isButton &&
    !props.$isDropdown &&
    css`
      margin-bottom: 0.8rem;
    `}

    ${(props) =>
    props.$error &&
    css`
      span {
        opacity: 1;
      }
    `}

    ${(props) =>
    !props.$disabled &&
    css`
      &:hover,
      &:active,
      &:focus {
        span {
          opacity: 1;
        }
      }
    `}

  ${(props) =>
    props.$blackbox &&
    css`
      position: relative;
      &:hover {
        background: transparent;
      }
    `}

  div:first-child {
    display: flex;
  }

  ${(props) =>
    props.$isDropdown &&
    css`
      div {
        width: 100%;
      }
    `}

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.$isButton &&
    css`
      height: 4rem;
      background: ${vars.colors.grey90};
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      border: 1px solid ${vars.colors.grey80};
      padding: 9px 20px 11px 20px;
      margin-top: 1.2rem;
      button {
        cursor: pointer;
        user-select: none;
      }
      &:hover {
        background: ${vars.colors.grey80};
      }
    `}

    ${(props) =>
    props.$isButton &&
    props.$disabled &&
    css`
      cursor: not-allowed;
      button {
        cursor: not-allowed;
      }

      &:hover {
        background: ${vars.colors.grey90};
      }
    `}


    ${(props) =>
    props.$error &&
    !props.$isButton &&
    css`
      background: ${vars.colors.redLighter};
      &:hover {
        background: ${vars.colors.redLighter};
      }
    `}

  ${(props) =>
    props.$disabled &&
    !props.$isButton &&
    css`
      &:hover {
        background: transparent;
      }
    `}

  ${(props) =>
    props.$notClickable &&
    css`
      margin-bottom: 0rem;
      button {
        cursor: default;
      }
    `}

  ${responsive.desktop} {
    flex: 100%;
  }

  ${responsive.tablet_portrait} {
    flex: 50%;
  }

  ${responsive.phone} {
    flex: 100%;
  }
`;

export const HeadingFlex = styled(BaseFlex)<{ $chipButtonBelow?: boolean; $noMargin?: boolean }>`
  margin-bottom: 1.6rem;
  ${(props) =>
    props.$chipButtonBelow &&
    css`
      margin-bottom: 0.8rem;
    `}
  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const PlusIcon = styled(BasePlusIcon)`
  margin-left: 0.8rem;

  width: 2rem;
  height: 2rem;
  path {
    stroke: ${vars.colors.grey70};
  }

  &:hover,
  &:active,
  &:focus {
    path {
      stroke: ${vars.colors.grey30};
    }
  }
`;

export const DetailsPaneSection = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${vars.colors.grey90};
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;
  }
`;

export const Label = styled.p`
  color: ${vars.colors.grey50};
  margin-right: 0.8rem;
  font-size: 1.4rem;
  min-width: 5rem;
`;

export const Value = styled.p`
  font-size: 1.4rem;
  color: ${vars.colors.white};
`;

export const Tooltip = styled(BaseTooltip)`
  width: 4rem;

  height: 2rem;
`;

export const Parent = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  img {
    width: 4.8rem;
    height: 4.8rem;
  }
  position: relative;
  top: 0;
  left: 0;
`;

export const Image1 = styled.img`
  position: relative;
  top: 0;
  left: 0;
`;

export const Image2 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  transition: transform 0.7s ease-in-out;

  animation: rotate 30s infinite linear;

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

export const Title = styled(Paragraph)<{ $chipButton?: boolean }>`
  margin-left: 0.8rem;

  ${(props) =>
    !props.$chipButton &&
    css`
      margin-top: 1.2rem;
    `}
`;

export const Search = styled(SearchIcon)`
  path {
    stroke: ${vars.colors.grey30} !important;
  }
`;
