import styled, { css } from "styled-components";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export const FormInputWrap = styled.div<{ $error?: boolean; $disabled?: boolean }>`
  position: relative;
  width: 100%;

  cursor: text;

  .helper {
    padding: 0 1.2rem;
    margin-top: 0.3rem;
    color: ${vars.colors.grey60};
    font-size: 1.2rem;
  }

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}

  /* error */

  ${(props) =>
    props.$error &&
    css`
      .helper {
        color: ${vars.colors.red};
      }

      .helper a {
        color: ${vars.colors.red};
        text-decoration: underline;
      }
    `}
  .counter {
    font-size: 1.2rem;
    color: ${vars.colors.grey60};
    position: absolute;
    top: 0.9rem;
    right: 1.2rem;
  }
`;

export const FormInput = styled.div<{
  $active: boolean;
  $error?: boolean;
  $withCounter?: boolean;
  $readOnly?: boolean;
  $disabled?: boolean;
  $editableTitle?: boolean;
  $hover?: boolean;
  $maxHeight?: string;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${vars.colors.blackDarker};
  padding: 0.9rem 1.2rem 1.1rem 1.2rem;
  border-radius: 1rem;
  transition: ${vars.transitions.hover};
  border: 1px solid ${vars.colors.grey90};
  outline: none;
  /* active */

  &:hover {
    border-color: ${vars.colors.grey80};
  }

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}

  ${(props) =>
    props.$maxHeight &&
    css`
      max-height: ${props.$maxHeight}px;
      overflow-y: auto;
      overflow-x: visible;

      &::-webkit-scrollbar {
        width: 0.8rem;
      }
    `}

  ${(props) =>
    props.$active &&
    !props.$readOnly &&
    css`
      border-color: ${vars.colors.purple};
      &:hover {
        border-color: ${vars.colors.purple};
      }
    `}
  /* error */
  ${(props) =>
    props.$error &&
    css`
      border-color: ${vars.colors.red};
    `}
    /* with counter */
  ${(props) =>
    props.$withCounter &&
    css`
      padding-right: 2.5rem;
    `}
    
  label {
    display: block;
    user-select: none;
    font-size: 1.2rem;
    color: ${vars.colors.grey60};
    line-height: 1.6rem;
    font-weight: normal;
    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
      `}
    cursor: text;
  }

  input,
  textarea {
    padding: 0;
    display: block;
    width: 100%;
    appearance: none;
    background-clip: padding-box;
    border: none;
    background: none;
    resize: none;
    overflow: hidden;
    font-weight: normal;
    font-size: 1.6rem;
    color: ${vars.colors.grey30};
    line-height: 2rem;

    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
        color: ${vars.colors.grey70};
      `}

    ::placeholder {
      color: ${vars.colors.grey70};
    }

    &:focus,
    &:active {
      background: none;
      outline: none;
    }

    ${(props) =>
      props.$editableTitle &&
      css`
        font-size: 2.4rem;
        line-height: 3.2rem;
        color: ${vars.colors.white};
        font-weight: 700;
      `}

    // these styles remove the default background color that is added when autofilling passwords
    // https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete?page=1&tab=scoredesc#tab-top
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100rem transparent inset;
      box-shadow: 0 0 0 100rem transparent inset;
      -webkit-text-fill-color: ${vars.colors.grey30};
      transition: background-color 5000s ease-in-out 0s;
      caret-color: ${vars.colors.grey30};
    }
  }

  ${(props) =>
    props.$editableTitle &&
    !props.$active &&
    !props.$hover &&
    css`
      background-color: ${vars.colors.black};
      border: 1px solid transparent;
      input,
      textarea {
        font-size: 2.4rem;
        line-height: 3.2rem;
        font-weight: 700;
      }
    `}

  @keyframes autofill {
    100% {
      background: transparent;
    }
  }

  @-webkit-keyframes autofill {
    100% {
      background: transparent;
    }
  }
`;

export const SendIcon = styled.div`
  margin-left: 1rem;
  align-self: flex-end;
  width: 2rem;
  height: 2rem;

  svg {
    width: 100% !important;
    color: ${vars.colors.grey60};
    cursor: pointer;
  }

  &:hover svg {
    color: ${vars.colors.white};
  }
`;

export const IconContainer = styled(Flex)`
  margin-right: 0.8rem;
`;

export const InputContainer = styled.div<{ $noMargin?: boolean }>`
  width: 100%;

  ${(props) =>
    !props.$noMargin &&
    css`
      margin-right: 1.2rem;
    `}
`;

export const Button = styled.button`
  background-color: ${vars.colors.purple};
  position: relative;
  right: 0;
  width: 8.3rem;
  height: 3.2rem;
  border-radius: 1rem;
  padding: 5px 12px 5px 12px;
  border: none;

  p {
    display: inline-flex;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
  }
`;
