import React from "react";

import Heading from "components/Heading/Heading";
import PageTitle from "components/PageTitle/PageTitle";
import PageWrap from "components/PageWrap/PageWrap";

import NotifyMajorReleases from "pages/Account/components/NotifyMajorReleases/NotifyMajorReleases";
import NotifyMeAboutCard from "pages/Account/components/NotifyMeAboutCard/NotifyMeAboutCard";

import identifiers from "util/identifiers.json";

import * as Styled from "./NotificationsView.styles";

export default function NotificationsView() {
  return (
    <>
      <PageTitle>
        <Heading
          as="h1"
          heading1
          testId={identifiers["myAccount.header.notifications"]}
        >
          Notifications
        </Heading>
        <p>Select where and when you&apos;ll be notified.</p>
      </PageTitle>
      <PageWrap>
        <Styled.NotificationsCardsContainer>
          <NotifyMeAboutCard />
          <NotifyMajorReleases />
        </Styled.NotificationsCardsContainer>
      </PageWrap>
    </>
  );
}
