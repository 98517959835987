import { StylesConfig } from "react-select";
import styled from "styled-components";

import { vars } from "styles";

import Input from "../Input";
import { InputWithTagsOption } from "./InputWithTags";

export const InputWithTagsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 4rem;
`;

export const SelectWrap = styled.span`
  position: relative;
`;

export const DropdownWrap = styled.div`
  z-index: 1;
  margin-top: 1rem;
  min-width: 24rem;
  position: absolute;
`;

export const DropdownIndicatorWrap = styled.div``;

export const DropdownIndicatorTagWrap = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  color: ${vars.colors.grey30};
  border: 1px solid ${vars.colors.grey90};
  margin: 0.4rem;
  background-color: ${vars.colors.grey100};
  padding: 0.3rem 0.8rem 0.5rem 0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:visited,
  &:active {
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
  }
`;

export const DropdownInputWrap = styled(Input)`
  > * {
    border: none;
    background-color: ${vars.colors.grey100};
    border: 1px solid ${vars.colors.grey90};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-radius: 1rem 1rem 0 0;
    padding: 1.3rem 1.6rem;
    max-height: 6.4rem;
    &:hover {
      border: 1px solid ${vars.colors.grey90};
    }
  }
`;

export const customSelectStyles: StylesConfig<InputWithTagsOption, false> = {
  menu: (base) => ({
    ...base,
    width: "24rem",
    border: `1px solid ${vars.colors.grey90}`,
    borderTop: `0px solid ${vars.colors.grey90}`,
    backgroundColor: vars.colors.grey100,
    color: vars.colors.grey30,
    margin: "0",
    borderRadius: "0 0 1rem 1rem",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    color: vars.colors.grey30,
  }),
  option: (base) => ({
    ...base,

    "&:hover": {
      backgroundColor: vars.colors.grey90,
      color: vars.colors.white,
    },
    color: vars.colors.grey30,
    backgroundColor: "transparent",
    padding: "1.3rem 1.6rem",
    fontSize: "1.4rem",

    "&:last-child": {
      borderRadius: "0 0 1rem 1rem",
      padding: "1.2rem 1.6rem 1.6rem",
    },

    // had to do it the hacky way since there is no other info that tells me if this is the 'create' option or not
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    /* borderTop: props.data.__isNew__ && props.options.length && `2px solid ${vars.colors.grey80}`, */
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: "1.2rem 1.6rem",
    textAlign: "left",
  }),
};

export const OptionContainer = styled.div`
  cursor: pointer;
  user-select: none;

  p {
    cursor: pointer;
    user-select: none;
  }
`;

export const ColorDot = styled.span`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  transform: translateY(1px);
`;
