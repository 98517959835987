import React, { PropsWithChildren, ReactNode } from "react";

import { TooltipProps } from "components/Tooltip/Tooltip";

import * as Styled from "./Button.styles";

export interface ButtonTooltip extends TooltipProps {
  disabled?: boolean;
  offset?: number;
}

export interface ButtonProps {
  disabled?: boolean;
  icon?: boolean;
  tooltip?: ButtonTooltip;
  size?: "small" | "extraSmall" | "full" | "tallerFull" | "tallerSmall" | "xxSmall";
  styling?: "outline" | "light" | "textual" | "flat" | "";
  color?: "red" | "grey";
  onClick?: (e?: React.MouseEvent) => void;
  type?: "button" | "submit" | "reset";
  className?: string;
  testId?: string;
  loadingText?: false | string;
}

const Button = ({
  children,
  disabled,
  size,
  icon,
  styling,
  color,
  onClick,
  tooltip,
  type,
  className,
  testId,
  loadingText,
}: PropsWithChildren<ButtonProps>) => {
  const TooltipWrap = ({ children }: { children: ReactNode }) => {
    return tooltip && !tooltip?.disabled ? (
      <Styled.Tooltip
        className={className}
        {...tooltip}
      >
        {children}
      </Styled.Tooltip>
    ) : (
      <>{children}</>
    );
  };

  return (
    <TooltipWrap>
      <Styled.ButtonWrap
        className={`button ${className}`}
        type={type}
        disabled={disabled || Boolean(loadingText)}
        onClick={onClick}
        $icon={icon}
        $size={size}
        $styling={styling}
        $color={color}
        data-testid={testId}
      >
        {loadingText ? loadingText : children}
      </Styled.ButtonWrap>
    </TooltipWrap>
  );
};

export default Button;
