import styled, { css } from "styled-components";

import BaseDropdown, { DropdownItem as BaseDropdownItem } from "components/Dropdown/Dropdown";

import * as Icon from "pages/shared/icons/icons";

import { vars } from "styles";

export const TicketDetailsBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.8rem;
  margin-bottom: 4rem;
  align-items: center;
`;

export const StatusPriorityDropdown = styled(BaseDropdown)`
  background: ${vars.colors.grey100};
  border: 1px solid ${vars.colors.grey90};
  border-radius: 1rem;

  > div:first-child {
    padding: 1rem;
  }

  span {
    text-color: ${vars.colors.grey30};
    white-space: nowrap;
  }
`;

export const AssigneeDropdown = styled(BaseDropdown)`
  span {
    margin-right: 0;
  }
`;

export const LockIcon = styled.div<{ $disabled?: boolean }>`
  margin-top: 0.3rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  width: 3.6rem;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const DropdownItem = styled(BaseDropdownItem)<{ $clicked?: boolean }>`
  ${(props) =>
    props.$clicked &&
    css`
      background: ${vars.colors.grey90};
    `}

  p {
    color: ${vars.colors.grey50};
    max-width: 22rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const dropdownScroll = `max-height: 25rem;`;

export const UnlockedIcon = styled(Icon.UnlockedIcon)<{ $disabled?: boolean }>`
  width: 2rem;
  height: 2rem;
  ${(props) =>
    !props.$disabled &&
    css`
      path {
        fill: ${vars.colors.grey60};
      }
      &:hover {
        path {
          fill: ${vars.colors.grey30};
        }
      }
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;

export const Checkmark = styled.div`
  margin-right: 0.8rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export const CheckmarkIcon = styled(Icon.CheckmarkIcon)`
  path {
    stroke: ${vars.colors.white};
  }
`;

export const DeleteMergeContainer = styled.div`
  height: 4rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  background: ${vars.colors.grey100};
  display: flex;
  align-items: center;
`;

export const DuplicateContainer = styled.div`
  cursor: pointer;

  svg {
    margin: 0.8rem;
    width: 2rem;
    height: 2rem;
  }
  position: relative;
`;

export const DeleteContainer = styled.div`
  justify-content: center;
  cursor: pointer;
`;

export const NewBanner = styled.div`
  border-radius: 1rem;
  background: rgba(194, 229, 80, 0.3);
  min-width: 3.6rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0.2rem;
  top: 3rem;
`;
