import { useEffect, useState } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useUserDataApiConsumer from "consumers/useUserDataApiConsumer";

import { setWorkspacesAction } from "context/App/App.actions";
import { useAppContext, useAppDispatch } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "router/config/routePaths";

import { useAdministrationDeps } from "../Administration";

export default function useGeneralViewApiConsumer() {
  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();
  const { administrationService } = useAdministrationDeps();
  const { selectedWorkspace } = useAppSelectionContext();
  const { workspaces } = useAppContext();
  const appDispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const updateWorkspaceName = async (name: string) => {
    try {
      if (!selectedWorkspace) throw new Error();

      const { data: teamData } = await administrationService.updateTeamSettings(selectedWorkspace.id, { name });

      const updatedWorkspaces = workspaces.map((w) => {
        if (w.id === teamData.id) {
          return { ...w, name: teamData.name };
        }
        return w;
      });

      appDispatch(setWorkspacesAction(updatedWorkspaces));

      displayToast({ title: "Fantastic!", content: "Workspace has been updated" });

      return teamData.name;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "There was an error while updating workspace" });
      return selectedWorkspace?.name || "";
    }
  };

  const updateWorkspaceLogo = async (file: File, toggle?: () => void) => {
    setUploading(true);
    try {
      if (!selectedWorkspace) throw new Error();

      const { data: teamData } = await administrationService.updateTeamLogo(selectedWorkspace.id, file);

      const updatedWorkspaces = workspaces.map((w) => {
        if (w.id === teamData.id) {
          return { ...w, logo_url: teamData.logo_url };
        }
        return w;
      });

      appDispatch(setWorkspacesAction(updatedWorkspaces));

      displayToast({ title: "Fantastic!", content: "Workspace has been updated" });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "There was an error while uploading workspace logo" });
      return selectedWorkspace?.name || "";
    } finally {
      toggle?.();
      setUploading(false);
    }
  };

  const deleteWorkspaceLogo = async (toggle: () => void) => {
    setUploading(true);
    try {
      if (!selectedWorkspace?.id) return;

      await administrationService.deleteTeamLogo(selectedWorkspace.id);

      const updatedWorkspaces = workspaces.map((w) => {
        if (w.id === selectedWorkspace.id) {
          return { ...w, logo_url: null };
        }
        return w;
      });

      appDispatch(setWorkspacesAction(updatedWorkspaces));

      displayToast({
        content: `Your workspace logo has been removed`,
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      toggle();
      setUploading(false);
    }
  };

  const deleteWorkspace = async () => {
    setLoading(true);
    try {
      if (!selectedWorkspace?.id) return;

      await administrationService.deleteTeamById(selectedWorkspace.id);

      navigate(generatePath(RoutePaths.ROOT));

      await fetchUserDataAndDispatch();

      displayToast({
        content: `Your workspace ${selectedWorkspace.name} has been removed`,
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  return {
    updateWorkspaceInfo: updateWorkspaceName,
    selectedWorkspace,
    deleteWorkspace,
    loading,
    updateWorkspaceLogo,
    deleteWorkspaceLogo,
    uploading,
  };
}
