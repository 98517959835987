import { useEffect, useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useForm } from "hooks/useForm";

import { CommonComment } from "models/CommonComment.model";

import { useAdministrationDeps } from "../Administration";

export interface CommonCommentFormState {
  title: string;
  message: string;
}

const CommonCommentInitialState: CommonCommentFormState = {
  title: "",
  message: "",
};

export const useCommonCommentsFormConsumer = (
  commonComment?: CommonComment,
  closeModal?: () => void,
  getCommonComments?: () => Promise<void>,
) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const handleAddCommonComment = async () => {
    if (!selectedWorkspace) return;
    setLoading(true);

    try {
      await administrationService.addCommonComment(selectedWorkspace.id, formState.title, formState.message);

      setLoading(false);
      getCommonComments?.();

      closeModal?.();

      displayToast({
        title: "Fantastic!",
        content: `Common comment has been added`,
      });
    } catch (error) {
      setLoading(false);
      closeModal?.();
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const handleUpdateCommonComment = async () => {
    if (!selectedWorkspace || !commonComment) return;
    setLoading(true);

    try {
      await administrationService.updateCommonComment(
        selectedWorkspace.id,
        commonComment?.id,
        formState.title,
        formState.message,
      );

      getCommonComments?.();

      setLoading(false);

      closeModal?.();

      displayToast({
        title: "Fantastic!",
        content: `Common comment has been updated`,
      });
    } catch (error) {
      setLoading(false);
      closeModal?.();
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError, clearForm } =
    useForm<CommonCommentFormState>({
      initialState: CommonCommentInitialState,
      onSubmit: commonComment ? handleUpdateCommonComment : handleAddCommonComment,
      validators: {},
    });

  useEffect(() => {
    if (commonComment) {
      handleFormChange("title", commonComment.title);
      handleFormChange("message", commonComment.message);
    } else {
      handleFormChange("title", "");
      handleFormChange("message", "");
    }
  }, [commonComment, handleFormChange]);

  return { formState, handleFormChange, handleFormSubmit, formError, setError, loading, clearForm };
};
