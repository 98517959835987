import styled, { css } from "styled-components";

import { pxToRem } from "../../styles/abstracts/utils";

export const Heading = styled.h1<{
  $heading1?: boolean;
  $heading2?: boolean;
  $heading3?: boolean;
  $heading4?: boolean;
  $lineHeight?: number;
  $marginBottom?: number;
  $fontWeight?: "normal" | 500 | "bold" | 400;
  $fontSize?: string;
}>`
  display: flex;
  align-items: center;
  font-size: ${(props) => (props.$fontSize ? pxToRem(props.$fontSize) : "3.2rem")};
  font-weight: ${(props) => props.$fontWeight ?? "bold"};
  line-height: ${(props) => props.$lineHeight ?? 1.2};
  margin-bottom: ${(props) =>
    props.$marginBottom || props.$marginBottom === 0 ? pxToRem(props.$marginBottom) : "0.8rem"};

  /* Heading1 */
  ${(props) =>
    props.$heading1 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "3.2rem"};
      line-height: ${props.$lineHeight ?? 1.13};
    `}
  /* Heading2 */
  ${(props) =>
    props.$heading2 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "2.4rem"};
      line-height: ${props.$lineHeight ?? 1.33};
    `}
    /* Heading3 */
  ${(props) =>
    props.$heading3 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "2rem"};
    `}
    /* Heading4 */
  ${(props) =>
    props.$heading4 &&
    css`
      font-size: ${props.$fontSize ? pxToRem(props.$fontSize) : "1.6rem"};
      font-weight: 500;
      line-height: 1.25;
      font-weight: ${props.$fontWeight ?? "normal"};
    `}
  .heading-icon {
    margin-right: 0.8rem;
    max-width: 2.8rem;
    height: auto;
  }
`;
