import React, { PropsWithChildren } from "react";

import { AnimatePresence } from "framer-motion";

import * as Styled from "./Tag.styles";
import Icon from "../Icon/Icon";

export interface TagProps {
  value: string;
  color?: string;
  border?: boolean;
  closeable?: boolean;
  onClear?: (value: string) => void;
  className?: string;
  testId?: string;
  closeIconTestId?: string;
  borderColor?: string;
}

const Tag = ({
  className,
  children,
  color,
  border,
  value,
  closeable,
  onClear,
  testId,
  closeIconTestId,
  borderColor,
}: PropsWithChildren<TagProps>) => {
  return (
    <AnimatePresence>
      <Styled.TagWrap
        key={1}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 0.0 }} // disabled for now
        exit={{ opacity: 0 }}
        className={`tag ${className}`}
        $border={border}
        $closeable={closeable}
        data-testid={testId}
        $borderColor={borderColor}
      >
        {color && (
          <span
            className="color"
            style={{ backgroundColor: color }}
          />
        )}

        {value ?? children}

        {closeable && (
          <Styled.TagClose
            onClick={() => onClear?.(value)}
            data-testid={closeIconTestId}
          >
            <Icon
              icon="close"
              size={8}
            />
          </Styled.TagClose>
        )}
      </Styled.TagWrap>
    </AnimatePresence>
  );
};

export default Tag;
