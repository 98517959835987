import React from "react";

import * as Styled from "./TabItem.styles";

interface Props {
  isSelected: boolean;
  onClick: () => void;
  text: string;
}

export default function TabItem({ isSelected, onClick, text }: Props) {
  return (
    <Styled.TabButton
      $isSelected={isSelected}
      onClick={onClick}
    >
      {text}
    </Styled.TabButton>
  );
}
