import React from "react";

import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { BlackboxChartType, BlackboxData } from "models/blackbox";

import { vars } from "styles";

import { divideByConsecutiveValues, externalTooltipHandler, getBlackBoxData, getZeroData } from "./helper";
import { DataType } from "./networkChartHelper";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface BlackboxChartProps {
  data: BlackboxData[];
  keyName: BlackboxChartType;
  keyValue?: number;
}

export default function BlackboxChart({ data, keyName, keyValue }: BlackboxChartProps) {
  const data1: ChartData<"line"> = {
    labels: getBlackBoxData(keyName, data)?.map((rec) => rec.time_occurred),
    datasets: getDatasets(keyName, data),
  };

  return (
    <Line
      style={{ height: "3.3rem" }}
      options={getChartOptions(keyName, keyValue)}
      data={data1}
    />
  );
}

const getChartOptions = (name: BlackboxChartType, keyValue?: number) => {
  return {
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: "nearest",
      axis: "x",
    },
    layout: {
      padding: {
        left: 12,
        right: 12,
      },
    },
    clip: false,
    indexAxis: "x",
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: "nearest",
        enabled: false,
        external: (a) => externalTooltipHandler(a, name, keyValue),
      },
    },
  } as ChartOptions<"line">;
};

const getData = (data: BlackboxData[], name?: BlackboxChartType) => {
  if (!data || !data.length) return [];
  // eslint-disable-next-line
  const newData = new Array<any>();

  if (name && name === BlackboxChartType.ORIENTATION) {
    data.map((data: BlackboxData) => {
      newData.push({
        y: data.value ? 1 : null,
        myProperty: data.value,
        x: data.time_occurred,
      });
    });
    return newData;
  } else if (name && name === BlackboxChartType.BATTERY) {
    data.map((data: BlackboxData) => {
      newData.push({
        y: data.battery_level,
        myProperty: data.battery_level,
        x: data.time_occurred,
      });
    });
    return newData;
  } else {
    data.map((data: BlackboxData) => {
      newData.push(data.value ? parseFloat(data.value) : null);
    });
    return newData;
  }
};

const getDatasets = (keyName: BlackboxChartType, data: BlackboxData[]) => {
  const zeroSet = {
    data: getData(getZeroData(keyName, getBlackBoxData(keyName, data) ?? [])),
    borderColor: "transparent",
    backgroundColor: "transparent",
    pointStyle: "circle",
    pointHitRadius: 2,
  };

  if (keyName === BlackboxChartType.ORIENTATION) {
    const dividedData = divideByConsecutiveValues(getData(getBlackBoxData(keyName, data) ?? [], keyName));
    // eslint-disable-next-line
    return [zeroSet].concat(getOptions(keyName, dividedData) as any);
  }

  // eslint-disable-next-line
  return [zeroSet].concat(getOptions(keyName, getData(getBlackBoxData(keyName, data) ?? [], keyName)) as any);
};

const getOptions = (keyName: BlackboxChartType, data: Array<DataType[]>) => {
  // eslint-disable-next-line
  const options = (data: any) => {
    return {
      data: data,
      fill: "start",
      pointRadius: function (context: ScriptableContext<"line">) {
        if (context.dataIndex === context.dataset.data.length - 1) {
          return 3;
        } else {
          return 0;
        }
      },
      pointBackgroundColor: vars.colors.lavender,
      pointBorderWidth: 0,
      pointHoverBackgroundColor: vars.colors.lavender,
      pointHoverBorderColor: vars.colors.grey100,
      pointHoverBorderWidth: 1,
      borderColor: (context: ScriptableContext<"line">) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(200, 0, 0, 0);
        gradient.addColorStop(0, "#B77DFF");
        gradient.addColorStop(1, "#E7A5FF");
        return gradient;
      },
      backgroundColor:
        keyName === BlackboxChartType.ORIENTATION
          ? "transparent"
          : (context: ScriptableContext<"line">) => {
              const ctx = context.chart.ctx;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(183, 125, 255, 0.2)");
              gradient.addColorStop(0.2, "rgba(183, 125, 255, 0)");

              return gradient;
            },
      tension: 0.6,
      pointStyle: "circle",
      pointHitRadius: 2,
      borderCapStyle: "round",
    };
  };
  // eslint-disable-next-line
  const orientationOptions = [] as any[];
  if (keyName === BlackboxChartType.ORIENTATION) {
    data.map((a) => orientationOptions.push(options(a)));

    return orientationOptions;
  } else return options(data);
};
