import { useCallback, useEffect, useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useAppContext } from "context/App/App.context";

import useAbortController from "hooks/useAbortController";

import { IssueChat, IssueChatRequest, IssueMessage, mapToMessage } from "models/issueTracking";
import { SocketEvents } from "models/webSocketEvents";

import { useCrashesDeps } from "../Crashes";

interface Props {
  selectedWorkspaceId?: string;
  selectedAppId?: string;
  crashEventId?: string;
}

export default function useCrashEventMessagesConsumer({ selectedWorkspaceId, selectedAppId, crashEventId }: Props) {
  const [messages, setMessages] = useState<IssueMessage[]>([]);
  const { crashesService } = useCrashesDeps();
  const { abortSignal } = useAbortController();
  const { socket } = useAppContext();

  const getCrashEventMessages = useCallback(() => {
    if (!selectedWorkspaceId || !selectedAppId || !crashEventId) return;

    crashesService
      .getCrashReportEventMessages(selectedWorkspaceId, selectedAppId, crashEventId, abortSignal)
      .then(({ data }) => data.map(mapToMessage))
      .then((messages) => setMessages(messages))
      .catch(() => {
        if (abortSignal.aborted) return;
        displayToast({ title: "Something went wrong", content: "Please try again." });
      });
  }, [selectedWorkspaceId, selectedAppId, crashEventId, crashesService, abortSignal]);

  useEffect(() => {
    getCrashEventMessages();

    const eventHandler = () => getCrashEventMessages();
    socket?.on(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);

    return () => {
      socket?.off(SocketEvents.ISSUE_CHAT_CHANGED, eventHandler);
    };
  }, [getCrashEventMessages, socket]);

  const sendMessage = (message: IssueChatRequest) => {
    if (!selectedWorkspaceId || !selectedAppId || !crashEventId) return;

    crashesService
      .sendCrashReportEventMessage(selectedWorkspaceId, selectedAppId, crashEventId, message)
      .then(({ data }) => data.map(mapToMessage))
      .then((messages) => setMessages(messages))
      .catch(() => {
        displayToast({ title: "Something went wrong", content: "Please try again." });
      });
  };

  const deleteMessage = useCallback(
    (message: IssueMessage) => {
      if (!selectedWorkspaceId || !selectedAppId) return;
      crashesService
        .deleteCrashIssueNote(selectedWorkspaceId, selectedAppId, message.id)
        .then(({ data }) => data.map(mapToMessage))
        .then((messages) => setMessages(messages))
        .then(() => {
          displayToast({ content: "Message deleted" });
        })
        .catch(() => {
          displayToast({ title: "Something went wrong", content: "Please try again." });
        });
    },
    [selectedWorkspaceId, selectedAppId, crashesService],
  );

  const editMessage = useCallback(
    (editedMessage: IssueChat) => {
      if (!selectedWorkspaceId || !selectedAppId) return;
      crashesService
        .editMessage(selectedWorkspaceId, selectedAppId, editedMessage.id, editedMessage.message)
        .then(({ data }) => data.map(mapToMessage))
        .then((messages) => setMessages(messages))
        .catch(() => {
          displayToast({ title: "Something went wrong", content: "Please try again." });
        });
    },
    [selectedWorkspaceId, selectedAppId, crashesService],
  );
  return { messages, sendMessage, deleteMessage, editMessage };
}
