import { App } from "./App.model";

export interface Platform {
  created: string;
  updated: string;
  id: string;
  name: PlatformName;
  os: PlatformOs;
  latest_version: string;
  active: boolean;
  sort_num?: number;
}

export enum PlatformName {
  ANDROID = "Android",
  IOS = "iOS",
  FLUTTER = "Flutter",
  REACT_NATIVE = "ReactNative",
  WEB = "Web",
}

export enum PlatformOs {
  ANDROID = "Android",
  IOS = "iOS",
  WEB = "Web",
}

export const supportedPlatformNames = [PlatformName.ANDROID, PlatformName.IOS];

export const isNativePlatform = (app?: App) => {
  return app?.platform.name === PlatformName.ANDROID || app?.platform.name === PlatformName.IOS;
};
