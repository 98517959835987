import styled from "styled-components";

import { CheckmarkIcon } from "pages/shared/icons/icons";

import { vars } from "styles";

export const MessageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:hover .message-dropdown,
  &:active .message-dropdown {
    opacity: 1;
  }

  border: 1px solid ${vars.colors.grey100};
  border-radius: 1rem;
  padding: 1rem 1.2rem;
`;

export const ImageWrap = styled.div`
  margin-right: 1rem;
`;

export const MessageContentWrap = styled.div`
  flex: 1;
`;

export const MessageMetadata = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const MessageSender = styled.div`
  font-size: 1.6rem;
  color: ${vars.colors.grey30};
`;

export const MessageDropdown = styled.div`
  margin-left: auto;
  opacity: 0;
  transition: ${vars.transitions.hover};
`;

export const MessageContent = styled.div`
  font-size: 1.4rem;
  color: ${vars.colors.grey50};
  padding: 0.5rem 0;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const MessageTimestamp = styled.span`
  font-size: 1.2rem;
  margin-left: 1.2rem;
  margin-bottom: 0.1rem;
  color: ${vars.colors.grey60};
  display: inline;
  text-align: left;
  word-break: keep-all;
`;

export const NotRead = styled(CheckmarkIcon)`
  path {
    stroke: ${vars.colors.grey70};
  }
`;
