import styled from "styled-components";

export const ScrollContainerWrap = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  position: relative;

  .inner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
  }

  .scroll-area {
    overflow-y: overlay;
    max-height: 100%;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      width: 0.8rem;
    }
  }
`;
