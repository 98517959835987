import { darken } from "polished";

import { Theme } from "./Theme.model";
import { vars } from "../abstracts/vars";

export const ThemeDark: Theme = {
  colorPrimary: vars.colors.purple,
  colorPrimaryDarken: darken(0.05, vars.colors.purple),
  colorSecondary: vars.colors.green,
  colorSecondaryDarken: darken(0.05, vars.colors.green),
  colorBackground: vars.colors.black,
  colorSurface: vars.colors.grey100,
  colorText: vars.colors.white,
  colorError: vars.colors.red,
};
