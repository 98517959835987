import React, { Fragment } from "react";

import DragDrop from "assets/images/infoModalImages/info-drag-and-drop@2x.png";
import MultiSelect from "assets/images/infoModalImages/info-multiselect@2x.png";
import Assign from "assets/images/infoModalImages/info-one-click-assign@2x.png";
import SpaceToPreview from "assets/images/infoModalImages/info-space-to-preview@2x.png";

import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

import { InfoElement, InfoModalContainer } from "./BaseTableStyles";

export function InfoModalBody({ type }: { type: "Users" | "feedback" | "crashes" }) {
  return (
    <InfoModalContainer>
      <InfoElement>
        <Element
          image={Assign}
          title="1 click assign"
          subtitle="Just click the status / priority / assignee icon to change them"
        />
      </InfoElement>
      {type === "feedback" && (
        <InfoElement>
          <Element
            image={DragDrop}
            title="Drag & drop"
            subtitle="Drag all duplicates into 1 primary ticket to minimize clutter"
          />
        </InfoElement>
      )}
      <InfoElement style={{ paddingTop: "2.4rem" }}>
        <Element
          image={MultiSelect}
          title="Multiselect"
          subtitle="Select many tickets → do an action on all of them"
        />
      </InfoElement>
      {type === "feedback" && (
        <InfoElement style={{ paddingTop: "2.4rem" }}>
          <Element
            image={SpaceToPreview}
            title="Space to preview"
            subtitle="Put a cursor over a ticket & press spacebar to glance into it"
          />
        </InfoElement>
      )}
    </InfoModalContainer>
  );
}

function Element({ image, title, subtitle }: { image: string; title: string; subtitle: string }) {
  return (
    <Fragment>
      <img
        src={image}
        width={106}
        height={66}
      />
      <Paragraph
        color={vars.colors.grey30}
        style={{ paddingTop: "2rem" }}
      >
        {title}
      </Paragraph>
      <Paragraph
        style={{ paddingTop: "0.4rem", textAlign: "center" }}
        fontSize={14}
      >
        {subtitle}
      </Paragraph>
    </Fragment>
  );
}
