import React, { Fragment } from "react";

import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import Arrow from "assets/images/logs-arrow.svg";

import { LogUnionRowItem } from "components/MasterTable/models/MasterTableModel";

import { resolveLogTimeString } from "models/helpers/time/creationTime";

import { BaseCell } from "../ui/BaseCell";
import { HeaderCell } from "../ui/BaseTableStyles";
import { useBaseTable } from "../useBaseTable";

type TableProps = {
  data: LogUnionRowItem[];
  onRowClick: (row: LogUnionRowItem) => void;
};

export function useActivityTable({ data, onRowClick }: TableProps) {
  const baseTableProps = useBaseTable({
    data,
    columns: defaultActivityColumns as unknown as ColumnDef<LogUnionRowItem>[], // TODO FIX TYPING
    selectable: true,
    hasMore: false,
    isLoading: false,
    isFetchingNext: false,
    loadMore: () => {
      return;
    },
    onRowClick,
    onPreviewRequest: () => {
      /// Space to preview
      return;
    },
    onDeleteRequest: () => {
      return;
    },
    withBorder: true,
  });

  return { ...baseTableProps };
}

const columnHelper = createColumnHelper<LogUnionRowItem>();

const defaultActivityColumns = [
  columnHelper.accessor("timestamp", {
    cell: (info) => (
      <BaseCell
        style={{
          height: "3.6rem",
          paddingTop: "0.8rem",
          paddingBottom: "0.8rem",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        withBorder={info.table.getSelectedRowModel()?.rows[0]?.index - 1 === info.row.index || info.row.getIsSelected()}
      >
        {resolveLogTimeString(info.getValue())}
      </BaseCell>
    ),
    header: (info) => (
      <HeaderCell $withBorder={info.table.getSelectedRowModel()?.rows[0]?.index === 0}>Time</HeaderCell>
    ),
    meta: {
      columnTemplate: "300px",
    },
  }),
  columnHelper.accessor("messageElement", {
    cell: ({ row, table }) => {
      const item = row.original;
      if (!item) return <Fragment />;
      return (
        <BaseCell
          centered
          style={{
            height: "3.6rem",
            paddingTop: "0.8rem",
            paddingBottom: "0.8rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            cursor: "pointer",
          }}
          withBorder={table.getSelectedRowModel()?.rows[0]?.index - 1 === row.index || row.getIsSelected()}
        >
          {item.logIcon && item.logIcon()}
          {item.messageElement && item?.messageElement()}
          {row.getIsSelected() && (
            <img
              src={Arrow}
              style={{
                position: "absolute",
                right: "-3601px",

                height: "3.6rem",
              }}
            />
          )}
        </BaseCell>
      );
    },
    header: ({ table }) => (
      <HeaderCell $withBorder={table.getSelectedRowModel()?.rows[0]?.index === 0}>Message</HeaderCell>
    ),
  }),
];
