interface TimeSince {
  interval: number;
  shortTimeframe: string;
  timeframe: string;
}

const SECONDS_IN_YEAR = 365 * 24 * 60 * 60;
const SECONDS_IN_MONTH = 30 * 24 * 60 * 60;
const SECONDS_IN_DAY = 24 * 60 * 60;
const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_MINUTE = 60;

const MILLIS_IN_SECOND = 1000;

export const getTimeSinceShort = (time: string): string => {
  const timeSinceObject = getTimeSince(time);
  return `${timeSinceObject.interval}${timeSinceObject.shortTimeframe}`;
};

export const getTimeSinceLong = (time: string): string => {
  const timeSinceObject = getTimeSince(time);
  if (!Boolean(timeSinceObject.interval)) {
    return timeSinceObject.timeframe;
  }
  return `${timeSinceObject.interval} ${timeSinceObject.timeframe}`;
};

const getTimeSince = (updated: string) => {
  const updatedDate = new Date(ensureUTC(updated));
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - updatedDate.getTime()) / MILLIS_IN_SECOND);

  if (timeDifferenceInSeconds > SECONDS_IN_YEAR) {
    return getYearsSince(updatedDate);
  }

  if (timeDifferenceInSeconds > SECONDS_IN_MONTH) {
    return getMonthsSince(updatedDate);
  }

  if (timeDifferenceInSeconds > SECONDS_IN_DAY) {
    return getDaysSince(updatedDate);
  }

  if (timeDifferenceInSeconds > SECONDS_IN_HOUR) {
    return getHoursSince(updatedDate);
  }

  if (timeDifferenceInSeconds > SECONDS_IN_MINUTE) {
    return getMinutesSince(updatedDate);
  }

  return getMomentsSince();
};

export const getYearsSince = (date: Date): TimeSince => {
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / MILLIS_IN_SECOND);
  const interval = Math.floor(timeDifferenceInSeconds / SECONDS_IN_YEAR);
  return { interval, shortTimeframe: "y", timeframe: `year${resolveSuffix(interval)} ago` };
};

export const getMonthsSince = (date: Date): TimeSince => {
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / MILLIS_IN_SECOND);
  const interval = Math.floor(timeDifferenceInSeconds / SECONDS_IN_MONTH);
  return { interval, shortTimeframe: "mo", timeframe: `month${resolveSuffix(interval)} ago` };
};

export const getDaysSince = (date: Date): TimeSince => {
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / MILLIS_IN_SECOND);
  const interval = Math.floor(timeDifferenceInSeconds / SECONDS_IN_DAY);
  return { interval, shortTimeframe: "d", timeframe: `day${resolveSuffix(interval)} ago` };
};

export const getHoursSince = (date: Date): TimeSince => {
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / MILLIS_IN_SECOND);
  const interval = Math.floor(timeDifferenceInSeconds / SECONDS_IN_HOUR);
  return { interval, shortTimeframe: "h", timeframe: `hour${resolveSuffix(interval)} ago` };
};

export const getMinutesSince = (date: Date): TimeSince => {
  const timeDifferenceInSeconds = Math.floor((new Date().getTime() - date.getTime()) / MILLIS_IN_SECOND);
  const interval = Math.floor(timeDifferenceInSeconds / SECONDS_IN_MINUTE);
  return { interval, shortTimeframe: "m", timeframe: `minute${resolveSuffix(interval)} ago` };
};

const getMomentsSince = (): TimeSince => {
  return { interval: 0, shortTimeframe: "Now", timeframe: "Now" };
};

const ensureUTC = (dateStr: string) => {
  // Check if the date string already has timezone information
  if (!/Z|[+-]\d{2}:?\d{2}$/.test(dateStr)) {
    // If no timezone info, assume it's UTC and append 'Z'
    return dateStr + "Z";
  }
  return dateStr;
};

const resolveSuffix = (amount: number) => (amount === 1 ? "" : "s");
