import { useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { Workspace } from "models";
import { IntegrationType, Zapier, ZapierIntegrationInfo, ZapierIntegrationUpdatePayload } from "models/integrations";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Zapier;
  selectedAppId: string;
  workspace: Workspace;
  onFinish: () => void;
}

interface FormState {
  customWebhookURL: string;
}

const validators = {
  customWebhookURL: (webhook: string) => {
    const urlRegex = new RegExp(
      "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
    );
    if (!webhook.trim().length || !webhook.match(urlRegex)) return new Error("Invalid webhook");
  },
};

export const useZapierIntegrationFormConsumer = ({ selectedAppId, integration, onFinish }: Props) => {
  const [loading, setLoading] = useState(false);
  const { integrate, updateIntegration } = useIntegrationFormConsumer<ZapierIntegrationInfo>({
    selectedAppId,
    type: IntegrationType.ZAPIER,
    noInfo: true,
  });

  const { formState, handleFormChange, handleFormSubmit, formError } = useForm<FormState>({
    initialState: {
      customWebhookURL: integration ? integration.webhook_url : "",
    },
    onSubmit: handleConnect,
    validators,
  });

  const { customWebhookURL } = formState;
  const { customWebhookURL: customWebhookURLErr } = formError;

  async function handleConnect() {
    setLoading(true);
    try {
      const payload: ZapierIntegrationUpdatePayload = {
        webhook_url: customWebhookURL,
      };

      const endpoint = !!integration ? updateIntegration : integrate;

      await endpoint<Zapier>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  const buttonTitle = !integration ? "Connect" : "Save changes";
  const loadingTitle = !integration ? "Connecting..." : "Saving...";

  return {
    buttonTitle,
    customWebhookURL,
    customWebhookURLErr,
    handleFormChange,
    handleFormSubmit,
    loading,
    loadingTitle,
  };
};
