import React, { useState } from "react";

import PendingIcon from "assets/images/shared-icons/pending-member.svg";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import RoundedCanvas from "components/RoundedCanvas/RoundedCanvas";
import Tooltip from "components/Tooltip/Tooltip";

import { getRoleAsText, Invitation } from "models/Member.model";

import { vars } from "styles/abstracts/vars";

import identifiers from "util/identifiers.json";

import * as Styled from "../TeamMembersCard/TeamMembersCard.styles";

interface Props {
  invitation: Invitation;
  currentUserIsAdmin: boolean;
  onCancelInvite: () => void;
  handleResendInvite: () => void;
}

export const TeamMemberInvitationRow = ({
  invitation,
  currentUserIsAdmin,
  onCancelInvite,
  handleResendInvite,
}: Props) => {
  const { email } = invitation;

  return (
    <>
      <CenteredCell gap={12}>
        <div
          style={{ flexShrink: 0 }}
          data-testid={identifiers["member.myTeam.user.pending.icon"]}
        >
          <RoundedCanvas
            image={PendingIcon}
            bgColor={vars.colors.grey80}
            imageSize={2}
            size="small"
          />
        </div>
        <Styled.Name data-testid={identifiers["member.myTeam.user.pending.name"]}>Pending</Styled.Name>
      </CenteredCell>

      <Tooltip
        position="top"
        text={email}
      >
        <CenteredCell>
          <Styled.Email data-testid={identifiers["member.myTeam.user.pending.email"]}>{email}</Styled.Email>
        </CenteredCell>
      </Tooltip>

      <CenteredCell>
        <Styled.Email data-testid={identifiers["member.myTeam.user.icon"]}>
          {getRoleAsText(invitation.role)}
        </Styled.Email>
      </CenteredCell>

      <DropdownButton
        currentUserIsAdmin={currentUserIsAdmin}
        onCancelInvite={onCancelInvite}
        handleResendInvite={handleResendInvite}
      />
    </>
  );
};

interface DropdownButtonProps {
  currentUserIsAdmin: boolean;
  handleResendInvite: () => void;
  onCancelInvite: () => void;
}

const DropdownButton = ({ currentUserIsAdmin, handleResendInvite, onCancelInvite }: DropdownButtonProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const resendInvite = () => {
    handleResendInvite();
    setIsModalOpened(false);
  };

  if (currentUserIsAdmin) {
    return (
      <CellEnd>
        <Dropdown
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          testId={identifiers["members.myTeam.user.pending.dropdownButton"]}
        >
          <DropdownItem
            onClick={resendInvite}
            testId={identifiers["members.myTeam.user.pending.dropdownButton.resend"]}
          >
            Resend invite
          </DropdownItem>
          <DropdownItem
            onClick={onCancelInvite}
            testId={identifiers["members.myTeam.user.pending.dropdownButton.customize"]}
            error
          >
            Cancel invite
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    );
  }

  return <CenteredCell />;
};
