import React from "react";

import styled from "styled-components";

import Button from "components/Button/Button";

import { t } from "util/translator";

interface Props {
  requestPasswordResetLink: () => Promise<void>;
  email: string;
}

const ResetCodeExpiredModalBodyWrapper = styled.div`
  p {
    margin: 0;
    padding-bottom: 2.4rem;
  }
`;

export default function LinkExpiredModalBody({ requestPasswordResetLink }: Props) {
  return (
    <ResetCodeExpiredModalBodyWrapper className="add-domains__body">
      <p>Want a new one?</p>
      <Button
        size="full"
        onClick={requestPasswordResetLink}
      >
        {t("signUp.linkExpired.button")}
      </Button>
    </ResetCodeExpiredModalBodyWrapper>
  );
}
