import { Option } from "components/SelectDropdown/SelectDropdown";

import { getRoleAsText, MemberRole } from "models/Member.model";

export function getRoleOptions(appsLength: number): Option[] {
  if (appsLength) {
    return [
      {
        label: getRoleAsText(MemberRole.ADMIN),
        value: MemberRole.ADMIN,
        subtitle:
          "Has all permissions — including the ability to connect integrations, archive apps, remove teammates and so on.",
      },
      {
        label: getRoleAsText(MemberRole.MEMBER),
        value: MemberRole.MEMBER,
        subtitle: "Has access to every app within this workspace — can see all the apps, tickets and other members.",
      },
      {
        label: getRoleAsText(MemberRole.GUEST),
        value: MemberRole.GUEST,
        subtitle: "Has access only to apps you hand-pick below.",
      },
    ];
  }
  return [
    {
      label: getRoleAsText(MemberRole.ADMIN),
      value: MemberRole.ADMIN,
      subtitle:
        "Has all permissions — including the ability to connect integrations, archive apps, remove teammates and so on.",
    },
    {
      label: getRoleAsText(MemberRole.MEMBER),
      value: MemberRole.MEMBER,
      subtitle: "Has access to every app within this workspace — can see all the apps, tickets and other members.",
    },
  ];
}
