import React, { useCallback, useEffect, useState } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { GridTable } from "components/GridTable/GridTable";
import { useModal } from "components/Modal/Modal";

import { App } from "models/App.model";
import { Rule } from "models/Rule.model";

import RulesCardRow from "./RuleCardRow";
import * as Styled from "./RulesCard.styles";
import { useShakingAnimation } from "../../../../../context/ShakingAnimation/ShakingAnimationContext";
import AddRuleModalBody from "../../ModalBody/Rules/AddRuleModalBody";

interface Props {
  selectedApp: App;
  loading: boolean;
  rules: Rule[];
  getRules: () => Promise<QueryObserverResult<Rule[] | undefined>>;
  deleteRule: (id: string) => Promise<void>;
}

export function RulesCard({ loading, rules, selectedApp, getRules, deleteRule }: Props) {
  const [selectedRule, setSelectedRule] = useState<Rule>();

  const navigate = useNavigate();
  const location = useLocation();
  const { triggerShake } = useShakingAnimation();

  const {
    Modal: AddRuleModal,
    modalProps: addRuleInternal,
    toggle: toggleAddRule,
    active: activeAdd,
  } = useModal({ size: "narrow" });
  const {
    Modal: RemoveModal,
    modalProps: removeInternal,
    toggle: toggleRemove,
    active: activeRemove,
  } = useModal({ size: "narrow" });

  const handleRemove = useCallback(
    (rule?: Rule) => {
      if (!rule) return;
      deleteRule(rule.id).finally(() => {
        toggleRemove();
      });
    },
    [deleteRule, toggleRemove],
  );

  useEffect(() => {
    if (location.hash === "#rules") {
      toggleAddRule();
      navigate(location.pathname);
    }
  }, [toggleAddRule, location, navigate]);

  return (
    <>
      <AdministrationCard
        title="Rules"
        subtitle="Save precious time and avoid repetitive tasks by creating custom automations here."
        topLeftAccessory={
          <Button
            size="small"
            onClick={() => {
              if (selectedApp.is_sample) {
                triggerShake("AddAppButton");
              } else {
                setSelectedRule(undefined);
                toggleAddRule();
              }
            }}
            disabled={loading || !selectedApp.active}
            tooltip={{
              text: selectedApp.is_sample ? "Sample app can't be modified" : "Rules can be added on active apps only",
              disabled: !selectedApp.is_sample && selectedApp.active,
              position: "top",
            }}
          >
            Add rule
          </Button>
        }
      >
        <GridTable gridTemplateColumns="repeat(3, auto)">
          {rules.map((rule) => {
            return (
              <RulesCardRow
                key={rule.id}
                rule={rule}
                setSelectedRule={setSelectedRule}
                toggleRemove={toggleRemove}
                modalsOpened={activeAdd || activeRemove}
                toggleRuleModal={toggleAddRule}
              />
            );
          })}
        </GridTable>
        <Styled.Helper>
          We are adding new automation options to Rules. Please{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://shk.sh/join-slack"
          >
            join the conversation
          </a>{" "}
          and let us know which option would you like our developers to add next?
        </Styled.Helper>
      </AdministrationCard>

      <AddRuleModalBody
        Modal={AddRuleModal}
        internalProps={addRuleInternal}
        loading={loading}
        selectedApp={selectedApp}
        toggle={toggleAddRule}
        getRules={getRules}
        rule={selectedRule}
      />

      <RemoveModal
        {...removeInternal}
        heading={`Remove ${selectedRule?.name}?`}
        subtitle={`If you're sure you want to remove rule ${selectedRule?.name}, just click the magic button below.`}
        buttonElement={
          <Button
            onClick={() => handleRemove(selectedRule)}
            size="small"
            color="red"
            disabled={loading}
            loadingText={loading && "Removing..."}
          >
            Yes, remove
          </Button>
        }
      />
    </>
  );
}
