import React, { Fragment } from "react";

import Paragraph from "components/Paragraph/Paragraph";
import { Spinner } from "components/Spinner/Spinner";

import { Plan } from "models/billing";

import { useUpcomingBillingConsumer } from "pages/Administration/consumers/useUpcomingBillingConsumer";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./SubscriptionsModalBody.styles";

interface Props {
  currentPlan?: Plan;
  newPlan?: Plan;
  currentAddOn?: Plan;
  newAddOn?: Plan;
  total?: string;
}

export const ChangeSubscriptionModalBody = ({ currentPlan, newPlan, currentAddOn, newAddOn }: Props) => {
  const { amount, loading } = useUpcomingBillingConsumer(newPlan, newAddOn);
  if (loading)
    return (
      <div style={{ height: "21.3rem" }}>
        <Spinner />
      </div>
    );
  return (
    <Fragment>
      <Flex $gap={3.2}>
        <Styled.PlanModalContainer>
          <Paragraph color={vars.colors.grey30}>Current subscription</Paragraph>
          {getRow("✕", vars.colors.red, `${currentPlan ? currentPlan.product_name : "Free"} plan`)}
          {getRow("✕", vars.colors.red, `${currentAddOn ? currentAddOn.product_name : "No"} add-on`)}
          {getRow(
            "✕",
            vars.colors.red,
            `${currentPlan ? currentPlan.nickname : currentAddOn ? currentAddOn.nickname : "No"} billing`,
          )}
          {getRow(
            "✕",
            vars.colors.red,
            `$${getAmount(currentPlan, currentAddOn)} per ${
              currentPlan ? currentPlan.interval : currentAddOn ? currentAddOn.interval : "month"
            } total`,
          )}
        </Styled.PlanModalContainer>
        <Styled.PlanModalContainer>
          <Paragraph color={vars.colors.grey30}>New subscription</Paragraph>
          {getRow("→", vars.colors.green, `${newPlan ? newPlan.product_name : "Free"} plan`)}
          {getRow("→", vars.colors.green, `${newAddOn ? newAddOn.product_name : "No"} add-on`)}
          {getRow(
            "→",
            vars.colors.green,
            `${newPlan ? newPlan.nickname : newAddOn ? newAddOn.nickname : "No"} billing`,
          )}
          {getRow(
            "→",
            vars.colors.green,
            `$${getAmount(newPlan, newAddOn)} per ${
              newPlan ? newPlan.interval : newAddOn ? newAddOn.interval : "month"
            } total`,
          )}
        </Styled.PlanModalContainer>
      </Flex>

      <Styled.HelperText>
        {amount && (
          <Paragraph
            fontSize={14}
            lineHeight={18}
          >{`Billed now $${amount.toString().slice(0, -2)}`}</Paragraph>
        )}
        <Paragraph
          style={{ marginTop: "0.2rem" }}
          fontSize={14}
          lineHeight={18}
        >{`You are billed now for the coming ${
          newPlan?.interval ? newPlan?.interval : newAddOn?.interval ? newAddOn.interval : ""
        }`}</Paragraph>
        <Paragraph
          fontSize={14}
          lineHeight={18}
          style={{ marginTop: "0.2rem" }}
        >
          Questions? <a href="javascript:Intercom('showNewMessage')">Live chat with us</a>
        </Paragraph>
      </Styled.HelperText>
    </Fragment>
  );
};

const getRow = (specialChar: string, color: string, text: string) => {
  return (
    <Flex
      $gap={1}
      style={{ marginTop: "0.4rem" }}
    >
      <Paragraph
        color={color}
        lineHeight={20}
      >
        {specialChar}
      </Paragraph>
      <Paragraph lineHeight={20}>{text}</Paragraph>
    </Flex>
  );
};

const getAmount = (plan?: Plan, addOn?: Plan) => {
  const planAmount = plan ? plan.amount : 0;
  const addOnAmount = addOn ? addOn.amount : 0;
  return (planAmount + addOnAmount).toString().slice(0, -2);
};
