import React, { useState } from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "./CopyButton.styles";

interface Props {
  copyText: string;
  style: CSSProperties;
}

export default function CopyButton({ style, copyText }: Props) {
  const [showCopied, setShowCopied] = useState(false);

  const copyToClipboard = () => {
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
    navigator.clipboard.writeText(copyText);
  };

  return (
    <Styled.CopyButtonContainer
      style={style}
      className={"copyButton"}
    >
      <Styled.CopyButton onClick={copyToClipboard}>{showCopied ? "Copied" : "Copy"}</Styled.CopyButton>
    </Styled.CopyButtonContainer>
  );
}
