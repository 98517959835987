import { AxiosResponse } from "axios";
import FormData from "form-data";

import environments from "environments";

import { UserDataResponse, UserToken } from "models";

import ApiService from "services/Api.service";

export default class AuthService {
  static instance: AuthService;
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  logout(userAccessToken: string): Promise<AxiosResponse<null>> {
    const form = new FormData();
    form.append("token", userAccessToken);
    form.append("client_id", "shake_dashboard");

    return this.apiService.postAuth({
      resource: "revoke",
      data: form,
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    });
  }

  getMe(): Promise<AxiosResponse<UserDataResponse>> {
    return this.apiService.get({ resource: "accounts/me" });
  }

  signIn(email: string, password: string): Promise<AxiosResponse<UserToken>> {
    const form = new FormData();
    form.append("username", email);
    form.append("password", password);
    form.append("grant_type", "password");
    form.append("client_id", "shake_dashboard");

    return this.apiService.postAuth<UserToken>({
      resource: "token",
      data: form,
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    });
  }

  demoSignIn(): Promise<AxiosResponse<UserToken>> {
    const form = new FormData();
    form.append("client_secret", environments.oAuthDemoClientSecret);
    form.append("grant_type", "client_credentials");
    form.append("client_id", environments.oAuthDemoClientId);

    return this.apiService.postAuth<UserToken>({
      resource: "token",
      data: form,
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    });
  }

  confirmInviteWithGoogle(invitationCode: string, googleCode: string, redirect_uri: string) {
    return this.apiService.post<UserToken>({
      resource: "accounts/teams/invite_activation_with_google",
      data: {
        client_id: environments.oAuthClientId,
        code: invitationCode,
        token: googleCode,
        redirect_uri,
      },
    });
  }
}

AuthService.getInstance();
