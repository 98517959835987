import {
  CommonPassthroughState,
  IntegrationType,
  OAuth2CallbackParams,
  OAuth2PassthroughState,
  SentryCallbackParams,
  SentryPasssthroughState,
  TrelloCallbackParams,
} from "models/integrations";

import { b64_to_utf8 } from "util/encode";

export const extractStringifiedState = (params: URLSearchParams, type: IntegrationType) => {
  const paramsObject = Object.fromEntries(params);
  if (!paramsObject) return undefined;
  switch (type) {
    case IntegrationType.TRELLO: {
      const stringifiedState = (paramsObject as Partial<TrelloCallbackParams>).state;
      return stringifiedState;
    }
    default: {
      const stringifiedState = (paramsObject as Partial<OAuth2CallbackParams>).state;
      return stringifiedState;
    }
  }
};

export const extractPassthroughState = (passthroughState: string, type: IntegrationType) => {
  let state: Partial<CommonPassthroughState> = {};

  try {
    state = JSON.parse(passthroughState);
  } catch (error) {}

  try {
    const jsonString = b64_to_utf8(passthroughState);
    state = JSON.parse(jsonString);
  } catch (error) {}

  switch (type) {
    case IntegrationType.CLICKUP:
    case IntegrationType.TRELLO: {
      const commonState = state as Partial<CommonPassthroughState>;
      if (!commonState.workspaceId || !commonState.workspaceSlug || !commonState.appId) return undefined;
      break;
    }
    case IntegrationType.SENTRY:
      const sentryState = state as Partial<SentryPasssthroughState>;
      if (!sentryState.workspaceId || !sentryState.workspaceSlug) return undefined;
      return { workspaceSlug: sentryState.workspaceSlug, workspaceId: sentryState.workspaceId, appId: "" };
    default: {
      const oauthState = state as Partial<OAuth2PassthroughState>;
      if (!oauthState.workspaceId || !oauthState.workspaceSlug || !oauthState.appId || !oauthState.stateId) {
        return undefined;
      }
      break;
    }
  }
  return state as CommonPassthroughState;
};

export const extractCallbackParams = (params: URLSearchParams, type: IntegrationType) => {
  const paramsObject = Object.fromEntries(params);
  switch (type) {
    case IntegrationType.TRELLO: {
      const trelloParams = paramsObject as Partial<TrelloCallbackParams>;
      if (!trelloParams.state || !trelloParams.token) return undefined;
      return trelloParams as TrelloCallbackParams;
    }
    case IntegrationType.SENTRY: {
      const sentryParams = paramsObject as Partial<SentryCallbackParams>;
      if (!sentryParams.code || !sentryParams.installationId) return undefined;
      return sentryParams as SentryCallbackParams;
    }
    default: {
      const oauthState = paramsObject as Partial<OAuth2CallbackParams>;
      if (!oauthState.code || !oauthState.state) return undefined;
      return oauthState as OAuth2CallbackParams;
    }
  }
};

export const typeToIntegrationType = (type: string | null): IntegrationType | undefined => {
  if (!type) return undefined;
  return Object.values(IntegrationType).find((value) => {
    return value.toLowerCase() === type.toLowerCase();
  });
};

export function getHashParams<T>(hash: string): T {
  return hash.split("#").reduce(function (res: T, item: string) {
    const parts = item.split("=");
    return {
      ...res,
      [parts[0]]: parts[1],
    };
  }, {} as T);
}
