export interface ServerToClientEvents {
  issue_chat_changed: (event: IssueChatChangedEvent) => void;
  issue_added: (event: IssueAddedEvent) => void;
  app_added: () => void;
  user_added: (event: UserAddedEvent) => void;
  crash_group_added: (event: CrashGroupAddedEvent) => void;
}

export enum SocketEvents {
  ISSUE_CHAT_CHANGED = "issue_chat_changed",
  ISSUE_ADDED = "issue_added",
  APP_ADDED = "app_added",
  CRASH_GROUP_ADDED = "crash_group_added",
  USER_ADDED = "user_added",
}

export interface IssueChatChangedEvent {
  id: string;
  user_id: string;
  team_id: string;
  app_key: string;
  issue_url: string;
  issue_title: string;
}

export interface IssueAddedEvent {
  id: string;
  app_id: string;
  app_key: string;
  team_id: string;
  bundle_id: string;
  issue_url: string;
  issue_title: string;
}

export interface UserAddedEvent {
  team_id: string;
}

export interface CrashGroupAddedEvent {
  id: string;
  app_id: string;
  app_key: string;
  team_id: string;
  bundle_id: string;
  issue_url: string;
  issue_title: string;
}
