import React from "react";

import { motion } from "framer-motion";
import { CSSProperties } from "styled-components";

interface Props {
  withSidebar?: boolean;
  withHeader?: boolean;
  detailsView?: boolean;
  style?: CSSProperties;
}

const PageWrap: React.FC<Props> = ({ children, style = { height: "100%" } }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeInOut",
        },
      }}
      exit={{ opacity: 0 }}
      style={{ cursor: "default", ...style }}
    >
      {children}
    </motion.div>
  );
};

export default PageWrap;
