import React from "react";

import { ForgotPasswordService } from "pages/ForgotPassword/services/ForgotPassword.service";

import dependencyInjection from "util/dependencyInjection";

import { ResetPasswordService } from "./services/ResetPassword.service";
import { ResetPasswordView } from "./views/ResetPasswordView";

interface Services {
  resetPasswordService: ResetPasswordService;
  forgotPasswordService: ForgotPasswordService;
}

const resetPasswordService = new ResetPasswordService();
const forgotPasswordService = new ForgotPasswordService();

const [ResetPasswordDependencyProvider, useResetPasswordDeps] = dependencyInjection<Services>({
  services: {
    resetPasswordService,
    forgotPasswordService,
  },
});

const ResetPasswordPage = () => (
  <ResetPasswordDependencyProvider>
    <ResetPasswordView />
  </ResetPasswordDependencyProvider>
);

export { ResetPasswordPage, useResetPasswordDeps };
