import { Reducer } from "react";

import { AppContextAction, AppContextActionType, AppState } from "./App.types";

export const AppReducer: Reducer<AppState, AppContextAction> = (state, action) => {
  switch (action.type) {
    case AppContextActionType.SET_WORKSPACES:
      return { ...state, workspaces: action.payload };
    case AppContextActionType.SET_WS_TEAM_MEMBERS:
      return { ...state, wsTeamMembers: action.payload };
    case AppContextActionType.SET_APPS:
      return { ...state, apps: action.payload };
    case AppContextActionType.SET_SOCKET_CONNECTION:
      return { ...state, socket: action.payload };
    case AppContextActionType.SET_LOADING:
      return { ...state, loading: action.payload };
    case AppContextActionType.SET_UPDATING_WORKSPACE:
      return { ...state, updatingSelectedWorkspace: action.payload };
    case AppContextActionType.SET_JOINABLE_WORKSPACES:
      return { ...state, joinableWorkspaces: action.payload };
    case AppContextActionType.SET_WORKSPACES_LOADING:
      return { ...state, workspacesLoading: action.payload };
    case AppContextActionType.SET_UNREAD_CHANGE_LOG_NOTIFICATIONS:
      return { ...state, unreadChangeLogNotifications: action.payload };
  }
};
