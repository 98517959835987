import styled from "styled-components";

import { vars } from "styles";

import BaseParagraph from "../../../../components/Paragraph/Paragraph";

export const Buttons = styled.div`
  margin-left: 0.4rem;
  width: 4.2rem;
  height: 2.4rem;
  flex-shrink: 0;
  border-radius: 3rem;
  border: 1px solid ${vars.colors.grey90};
  background: ${vars.colors.grey100};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    path {
      stroke: ${vars.colors.grey30};
    }
  }
`;

export const TruncatedParagraph = styled(BaseParagraph)`
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
