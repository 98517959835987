export interface Plan {
  currency: string;
  amount: number;
  nickname: string;
  interval: PlanInterval;
  created: string;
  updated: string;
  product_id: string;
  product_name: PlanProductName;
  id: string;
  active: boolean;
  type: PlanType;
  sort_number?: number;
}

export enum PlanType {
  PLAN = "plan",
  DEVICES = "devices",
}

export enum PlanInterval {
  MONTH = "month",
  YEAR = "year",
  DAY = "day",
  WEEK = "week",
}

export enum BillingCycle {
  MONTHLY = "Monthly",
  ANNUAL = "Annual",
}

export enum PlanProductName {
  STARTER = "Starter", //brisi
  PREMIUM = "Premium",
  PROFESSIONAL = "Professional", //brisi
  ORGANIZATION = "Organization",
}

export interface CheckoutRes {
  id: string;
  url: string;
  stripe_checkout_session_url: string;
}
