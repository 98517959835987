import React from "react";

import * as Styled from "./Attributes.styles";

export function OperatorChip({ operator }: { operator: string }) {
  return (
    <Styled.PillContainer
      $borderLeftNone={true}
      $borderRightNone={true}
    >
      <Styled.BorderReversedConnection>
        <Styled.PillConnection $reversed={true} />
        <Styled.AttributeFlex>
          <Styled.AttributeOperatorButton>{operator}</Styled.AttributeOperatorButton>
        </Styled.AttributeFlex>
        <Styled.PillConnection />
      </Styled.BorderReversedConnection>
    </Styled.PillContainer>
  );
}
