import styled, { keyframes } from "styled-components";

import { vars } from "styles";

const spin = keyframes`
to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  height: 3.5rem;
  width: 3.5rem;

  border: 3.5px solid transparent;
  border-radius: 50%;
  border-top-color: ${vars.colors.platinum};
  animation: spin 1s ease-in-out infinite;
  animation: ${spin} 1s ease-in-out infinite;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1 1 0%;
  cursor: default;
`;
