import React from "react";

import { FloatingOverlay, FloatingPortal } from "@floating-ui/react";

import { ReactComponent as InfoIcon } from "assets/images/info-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort-icon.svg";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import { DropdownItem } from "components/Dropdown/Dropdown.styles";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";

import { PopoverInput } from "hooks/filtering/ui/Attributes.styles";
import { usePopover } from "hooks/filtering/ui/SimplePopover";

import { CheckmarkIcon } from "pages/shared/icons/icons";

import { vars } from "styles";

import { HeaderButtons, HeaderCell, SVGButtonWrap } from "./BaseTableStyles";
import { InfoModalBody } from "./InfoModalBody";

export type TicketSortOption = "key" | "status" | "priority";

export type UserSortOption = "created" | "tickets";

export function SortHeaderCell({
  title,
  onSortRequest,
  selectedSortOption,
  options,
  type,
}: {
  title?: string;
  onSortRequest: (sortOption: TicketSortOption | UserSortOption) => void;
  selectedSortOption: TicketSortOption | UserSortOption;
  options: { value: TicketSortOption | UserSortOption; label: string }[];
  type: "Users" | "feedback" | "crashes";
}) {
  const tableOptionsPopover = usePopover({});
  const infoDialog = useBaseDialog({});

  return (
    <>
      <HeaderCell style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "center" }}>
        {title && <p>{title}</p>}
        <HeaderButtons>
          <SVGButtonWrap
            ref={tableOptionsPopover.reference}
            {...tableOptionsPopover.getReferenceProps()}
            $size={24}
            $visible={true}
          >
            <SortIcon />
          </SVGButtonWrap>

          {type !== "Users" && (
            <SVGButtonWrap
              $size={28}
              $visible={true}
              style={{ left: "-3px", position: "relative" }}
              onClick={() => infoDialog.setOpen(true)}
            >
              <InfoIcon />
            </SVGButtonWrap>
          )}
        </HeaderButtons>
      </HeaderCell>

      {infoDialog.open && (
        <BaseDialog
          {...infoDialog}
          size="normal"
        >
          <InfoModalBody type={type} />
          <ModalFooter toggle={() => infoDialog.setOpen(false)} />
        </BaseDialog>
      )}
      {tableOptionsPopover.open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: vars.zIndex.modalOverlay,
            }}
          >
            <div
              ref={tableOptionsPopover.floating}
              {...tableOptionsPopover.getFloatingProps()}
              style={{
                position: tableOptionsPopover.strategy,
                top: tableOptionsPopover.y ?? 0,
                left: tableOptionsPopover.x ?? 0,
                minHeight: "10rem",
              }}
            >
              <PopoverInput $widthPx={160}>
                <p
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 500,
                    color: vars.colors.grey60,
                    padding: "0 2rem",
                    marginBottom: "1rem",
                  }}
                >
                  Sort by
                </p>
                {options.map((option) => {
                  return (
                    <DropdownItem
                      key={option.value}
                      onClick={() => onSortRequest(option.value)}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        <CheckIconContainer selected={selectedSortOption === option.value} />
                        <p>{option.label}</p>
                      </div>
                    </DropdownItem>
                  );
                })}
              </PopoverInput>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
}

const CheckIconContainer = (props: { selected: boolean }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "2rem",
        height: "2rem",
        marginRight: "0.8rem",
      }}
    >
      {props.selected && (
        <CheckmarkIcon
          width={16}
          height={16}
          stroke={vars.colors.white}
        />
      )}
    </div>
  );
};
