import styled from "styled-components";

import { vars } from "styles";

export const Name = styled.p`
  padding-right: 2.8rem;
  color: ${vars.colors.grey30};
`;

export const Email = styled.p`
  padding-right: 3rem;
  color: ${vars.colors.grey60};
`;
