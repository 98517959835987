import React, { Fragment, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import Button from "components/Button/Button";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import SelectDropdown, { Option } from "components/SelectDropdown/SelectDropdown";

import { forwardingOptions } from "models/integrations";
import { Trello } from "models/integrations/Trello.model";

import { Form } from "../ModalBody.styles";
import { BoardOption, useTrelloIntegrationFormConsumer } from "./consumers/useTrelloIntegrationFormConsumer";
import { BaseIntegrationModalProps } from "./GithubIntegrationModalBody";

interface Props extends BaseIntegrationModalProps {
  integration?: Trello;
  selectedAppId: string;
}

export const TrelloIntegrationModalBody = ({ integration, selectedAppId, onFinish }: Props) => {
  const {
    disabled,
    loading,
    listOption,
    listOptions,
    boardOption,
    boardOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  } = useTrelloIntegrationFormConsumer({ integration, selectedAppId, onFinish });

  const [searchParams, setSearchParams] = useSearchParams();

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("appId")) {
      searchParams.delete("type");
      searchParams.delete("appId");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const isButtonDisabled = checkIsButtonDisabled(disabled, boardOption, listOption, forwardingOption, integration);

  return (
    <Fragment>
      <Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <SelectDropdown
          name="forwardingOption"
          value={forwardingOption}
          options={forwardingOptions}
          placeholder="Please choose"
          label="Forward tickets"
          onChange={optionDidChange}
          isSearchable={false}
          disabled={disabled || loading}
          hasSubtitles
        />
        <SelectDropdown
          name="boardOption"
          value={boardOption}
          options={boardOptions}
          placeholder="Please choose"
          label="Board"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || loading}
        />
        <SelectDropdown
          name="listOption"
          value={listOption}
          options={listOptions}
          placeholder="Please choose"
          label="List"
          onChange={optionDidChange}
          isSearchable={true}
          disabled={disabled || !boardOption || loading}
        />
      </Form>
      <ModalFooter
        toggle={onFinish}
        button={
          <Button
            size="small"
            tooltip={{
              position: "top",
              text: "No changes have been made",
              disabled: !isButtonDisabled || disabled,
            }}
            disabled={isButtonDisabled || loading}
            loadingText={loading && "Saving..."}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        }
      />
    </Fragment>
  );
};

const checkIsButtonDisabled = (
  disabled: boolean,
  boardOption: Option,
  listOption: Option,
  forwardingOption: Option,
  integration?: Trello,
) => {
  if (disabled) return true;
  if (!boardOption || !forwardingOption) return true;
  if (boardOption.value === "to_trello") return false;

  const parsedBoardOption = JSON.parse(boardOption.value) as BoardOption;
  if (
    integration &&
    listOption &&
    listOption.value !== "" &&
    (parsedBoardOption.boardId !== integration?.board_id ||
      !!forwardingOption.value !== integration?.automatic_forward ||
      listOption.value !== integration?.list_id)
  ) {
    return false;
  }
  return true;
};
