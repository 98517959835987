import React from "react";

import { FloatingPortal } from "@floating-ui/react";
import { Row, Table } from "@tanstack/react-table";
import { matchPath, useLocation } from "react-router-dom";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import { ConfirmDialogBody } from "components/Dialog/configurations/ConfirmDialogBody";
import { IssueTableRowItem } from "components/MasterTable/models/MasterTableModel";
import Paragraph from "components/Paragraph/Paragraph";
import { useKeyboardPress } from "components/ShakeTable/useKeyboardPress";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { usePopover } from "hooks/filtering/ui/SimplePopover";

import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { RoutePaths } from "router/config/routePaths";

import { vars } from "styles";

import * as Icon from "./icons";
import { AddTagsModalBody } from "./ModalBody/AddTagsModalBody";
import { MergeIntoModalBody } from "./ModalBody/MergeIntoModalBody";
import { NoteMessageModalBody } from "./ModalBody/NoteMessageModalBody";
import { SetAssigneeModalBody } from "./ModalBody/SetAssigneeModalBody";
import { SetStatusPriorityModalBody } from "./ModalBody/SetStatusPriorityModalBody";
import * as Styled from "./MultiactionsPopover.styles";
import displayToast from "../../../Toast/displayToast";

export function MultiactionsPopover<TData>({
  selectedRows,
  onDeleteRequest,
  onMarkAsReadRequest,
  onBulkChangePriority,
  onBulkChangeStatus,
  onBulkChangeAssignee,
  onBulkAddNoteSendChat,
  onBulkAddTags,
  selectAll,
  deselectAll,
  table,
  onMergeAction,
}: {
  selectedRows: Row<TData>[];
  table: Table<TData>;
  onDeleteRequest: (rows: TData[]) => void;
  onMarkAsReadRequest: (rows: TData[]) => void;
  onBulkChangeStatus: (rows: TData[], status: TicketStatus, resetSelection?: () => void) => void;
  onBulkChangePriority: (rows: TData[], status: TicketPriority, resetSelection?: () => void) => void;
  onBulkChangeAssignee: (rows: TData[], assigneeId: string | null, resetSelection?: () => void) => void;
  onBulkAddNoteSendChat?: (rows: TData[], message: string, isNote?: boolean) => void;
  onBulkAddTags: (rows: TData[], tags: string[]) => void;
  selectAll: () => void;
  deselectAll: () => void;
  onMergeAction?: (rows: TData[], mergeRow: TData) => void;
}) {
  const location = useLocation();
  const multiActionsPopover = usePopover({});
  const { selectedApp } = useAppSelectionContext();

  const isCrashesPage = Boolean(matchPath(RoutePaths.CRASH_REPORTS, location.pathname));

  const numOfSelectedTickets = selectedRows.length;

  const deleteTickets = useBaseDialog({});
  const markAsRead = useBaseDialog({});
  const setPriority = useBaseDialog({});
  const setStatus = useBaseDialog({});
  const setAssignee = useBaseDialog({});
  const addNote = useBaseDialog({});
  const sendMessage = useBaseDialog({});
  const addTag = useBaseDialog({});
  const merge = useBaseDialog({});

  const isAnyModalOpened =
    deleteTickets.open ||
    markAsRead.open ||
    setPriority.open ||
    setStatus.open ||
    setAssignee.open ||
    addNote.open ||
    sendMessage.open ||
    addTag.open ||
    merge.open;

  useKeyboardPress({
    keyboardKey: "Escape",
    onPress: () => table.resetRowSelection(),
    enabled: !isAnyModalOpened,
  });

  const numOfUsers = selectedRows.filter((row) => (row.original as unknown as IssueTableRowItem).app_user_id).length;

  return (
    <>
      <FloatingPortal>
        <div
          ref={multiActionsPopover.floating}
          {...multiActionsPopover.getFloatingProps()}
          style={{
            position: "fixed",
            bottom: 16,
            display: "flex",
            minHeight: "10rem",
            justifyContent: "center",
            width: "72.6rem",
            zIndex: 6,
            left: "50%",
            transform: "translate(-50%, 0)",
            margin: "0 auto",
          }}
        >
          <Styled.PopoverContainer>
            <Paragraph
              fontSize={12}
              style={{ marginLeft: "1rem" }}
            >
              {`${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} selected`}
            </Paragraph>
            <Styled.Popover>
              {Button(<Icon.SelectAllIcon />, "Select all", () => selectAll())}
              {Button(<Icon.EmptyIcon />, "Select none", () => deselectAll())}
              {!isCrashesPage && Button(<Icon.CheckmarkIcon />, "Mark as read", () => markAsRead.setOpen(true))}
              {Button(<Icon.UserIcon />, "Assign", () => setAssignee.setOpen(true))}
              {Button(<Icon.StatusIcon />, "Set status", () => setStatus.setOpen(true))}
              {Button(<Icon.PriorityIcon />, "Set priority", () => setPriority.setOpen(true))}
              {Button(<Icon.TagIcon />, "Add tag", () => addTag.setOpen(true))}
              {!isCrashesPage && Button(<Icon.PushNotifIcon />, "New message", () => sendMessage.setOpen(true))}
              {!isCrashesPage && Button(<Icon.AddNoteIcon />, "Add note", () => addNote.setOpen(true))}
              {!isCrashesPage && Button(<Icon.MergeIcon />, "Duplicate of", () => merge.setOpen(true))}
              {Button(
                <Icon.DeleteIcon />,
                "Delete",
                () => {
                  if (selectedApp?.is_sample) {
                    displayToast({ content: "Deleting sample tickets is not allowed." });
                    return;
                  }
                  deleteTickets.setOpen(true);
                },
                true,
              )}
            </Styled.Popover>
          </Styled.PopoverContainer>
        </div>
      </FloatingPortal>

      <BaseDialog {...deleteTickets}>
        <ConfirmDialogBody
          title={`Delete ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"}?`}
          subtitle={`Are you sure you want to delete ${numOfSelectedTickets} ${
            numOfSelectedTickets === 1 ? "ticket" : "tickets"
          }? This can't be undone.`}
          confirmAction={() => {
            onDeleteRequest(selectedRows.map((row) => row.original));
            table.resetRowSelection();
            deleteTickets.setOpen(false);
          }}
          buttonText="Yes, delete"
        />
      </BaseDialog>

      <BaseDialog {...markAsRead}>
        <ConfirmDialogBody
          title={`Mark ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} as read?`}
          subtitle={`Are you sure you want to mark ${numOfSelectedTickets} ${
            numOfSelectedTickets === 1 ? "ticket" : "tickets"
          } as read? This can't be undone.`}
          confirmAction={() => {
            onMarkAsReadRequest?.(selectedRows.map((row) => row.original));
            markAsRead.setOpen(false);
          }}
          buttonText="Yes, mark as read"
          notDelete
        />
      </BaseDialog>

      <BaseDialog
        {...setStatus}
        noPadding
      >
        <SetStatusPriorityModalBody
          selectedRows={selectedRows}
          title={`Set status of ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} to`}
          changeStatus={onBulkChangeStatus}
          close={() => setStatus.setOpen(false)}
          resetSelection={() => table.resetRowSelection()}
        />
      </BaseDialog>

      <BaseDialog
        {...setPriority}
        noPadding
      >
        <SetStatusPriorityModalBody
          priority
          title={`Set priority of ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} to`}
          changePriority={onBulkChangePriority}
          selectedRows={selectedRows}
          close={() => setPriority.setOpen(false)}
          resetSelection={() => table.resetRowSelection()}
        />
      </BaseDialog>

      <BaseDialog
        {...setAssignee}
        noPadding
      >
        <SetAssigneeModalBody
          title={`Assign ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} to`}
          confirmAction={onBulkChangeAssignee}
          close={() => setAssignee.setOpen(false)}
          selectedRows={selectedRows}
          resetSelection={() => table.resetRowSelection()}
        />
      </BaseDialog>

      {onBulkAddNoteSendChat && (
        <>
          <BaseDialog
            {...addNote}
            noPadding
          >
            <NoteMessageModalBody
              isNote
              title={`Add note to ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"}`}
              confirmAction={onBulkAddNoteSendChat}
              close={() => addNote.setOpen(false)}
              selectedRows={selectedRows}
            />
          </BaseDialog>

          <BaseDialog
            {...sendMessage}
            noPadding
          >
            <NoteMessageModalBody
              title={`Send message to ${numOfUsers} ${numOfUsers === 1 ? "app user" : "app users"}`}
              confirmAction={onBulkAddNoteSendChat}
              close={() => sendMessage.setOpen(false)}
              selectedRows={selectedRows}
              numOfAppUsers={numOfUsers}
            />
          </BaseDialog>
        </>
      )}

      <BaseDialog
        {...addTag}
        noPadding
      >
        <AddTagsModalBody
          title={`Add tags to ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"}`}
          confirmAction={onBulkAddTags}
          close={() => addTag.setOpen(false)}
          selectedRows={selectedRows}
        />
      </BaseDialog>

      {onMergeAction && (
        <BaseDialog
          {...merge}
          noPadding
        >
          <MergeIntoModalBody
            title={`Merge ${numOfSelectedTickets} ${numOfSelectedTickets === 1 ? "ticket" : "tickets"} into`}
            confirmAction={onMergeAction}
            close={() => merge.setOpen(false)}
            selectedRows={selectedRows.map((row) => row.original)}
            deselectRows={() => table.resetRowSelection()}
            //eslint-disable-next-line
            // @ts-ignore
            type={selectedRows?.[0]?.original?.row_type}
          />
        </BaseDialog>
      )}
    </>
  );
}

const Button = (icon: JSX.Element, text: string, onClick?: () => void, isDelete?: boolean) => {
  return (
    <Styled.Button
      $isDelete={isDelete}
      onClick={onClick}
    >
      {icon}
      <Paragraph
        color={vars.colors.grey30}
        fontSize={14}
      >
        {text}
      </Paragraph>
    </Styled.Button>
  );
};
