import React, { PropsWithChildren } from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "components/Tooltip/Tooltip.styles";

export interface TooltipProps {
  text: string;
  position?: "top" | "right" | "bottom" | "left" | "top-left" | "top-right";
  offset?: number;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  prewrap?: boolean;
}

export default function Tooltip({
  children,
  text,
  position = "right",
  offset = 1,
  disabled = false,
  className,
  style = {},
  prewrap,
}: PropsWithChildren<TooltipProps>) {
  return (
    <Styled.TooltipContainer
      className={className}
      $disabled={disabled}
      style={style}
    >
      <Styled.TooltipContent
        $position={position}
        $offset={offset}
        $prewrap={prewrap}
      >
        {text}
      </Styled.TooltipContent>
      {children}
    </Styled.TooltipContainer>
  );
}
