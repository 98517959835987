import styled from "styled-components";

import BaseButton from "components/Button/Button";
import BaseHeading from "components/Heading/Heading";

import { vars } from "styles";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  max-width: 68.8rem;
  margin: 0 auto;
  cursor: default;
`;

export const LeftOuterContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 34rem;
`;

export const LeftContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const CopyContainer = styled.div`
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  margin-left: 5.3rem;
`;

export const Heading = styled(BaseHeading)`
  color: ${vars.colors.grey30};
  white-space: nowrap;
`;

export const Button = styled(BaseButton)`
  margin-top: 0.8rem;
`;

export const Image = styled.img`
  width: 340px;
  aspect-ratio: 1/1;
`;
