import { useCallback, useEffect } from "react";

type Props = {
  loadMore: () => void;
  hasMore: boolean;
  bottomOffsetTriggerValue?: number;
  isLoading: boolean;
  containerRef: React.RefObject<HTMLDivElement | null>;
};

export function useInfiniteScrollDetector({
  loadMore,
  hasMore,
  bottomOffsetTriggerValue = 900,
  isLoading,
  containerRef,
}: Props) {
  const onContainerScroll = useCallback(() => {
    if (!containerRef.current) return;
    shouldTrigger(containerRef.current, bottomOffsetTriggerValue, hasMore, isLoading) && loadMore();
  }, [hasMore, bottomOffsetTriggerValue, isLoading, loadMore]);

  useEffect(() => {
    if (!containerRef.current) return;
    shouldTrigger(containerRef.current, bottomOffsetTriggerValue, hasMore, isLoading) && loadMore();
  }, [hasMore, bottomOffsetTriggerValue, isLoading, loadMore]);

  return { onContainerScroll };
}

function shouldTrigger(container: HTMLDivElement, bottomTrigger: number, hasMore: boolean, isLoading: boolean) {
  const { scrollHeight, scrollTop, clientHeight } = container;

  const bottomOffset = scrollHeight - scrollTop - clientHeight;

  return bottomOffset < bottomTrigger && hasMore && !isLoading;
}
