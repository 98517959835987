import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import { GridTable } from "components/GridTable/GridTable";
import { HelperText } from "components/HelperText/HelperText";
import { useModal } from "components/Modal/Modal";
import { ModalHeader } from "components/Modal/ModalHeader/ModalHeader";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { App } from "models";
import { getIntegrationTypeName, Integration, IntegrationType } from "models/integrations";

import { getIntegrationTypeLogo } from "pages/Administration/util/getIntegrationTypeLogo";

import { helpCenterLinks } from "util/helpCenterLinks";
import identifiers from "util/identifiers.json";

import * as Styled from "./AppsCard.styles";
import { integrationModalBodyMap } from "./UserFeedbackIntegrationsContent";
import { UserFeedbackIntegrationsRow } from "../UserFeedbackIntegrationsRow/UserFeedbackIntegrationsRow";

type Props = {
  app: App;
  disconnectIntegration: (integration: Integration) => void;
  disabled: boolean;
  loading: boolean;
  preSelectedIntegrationType: IntegrationType | undefined;
  clearPreselectedIntegrationType: () => void;
  tooltip: { text: string };
};

export function UserFeedbackIntegrationsCard({
  disconnectIntegration,
  app,
  disabled,
  loading,
  preSelectedIntegrationType,
  clearPreselectedIntegrationType,
  tooltip,
}: Props) {
  const { selectedWorkspace } = useAppSelectionContext();
  const { integrations } = app;

  const { fetchAllApps } = useWorkspaceAppsApiConsumer();

  const showJiraToggles =
    selectedWorkspace && new Date(selectedWorkspace.created) <= new Date("2023-04-14T00:00:00.000000");

  const IntegrationConfigurationModal = useBaseDialog({});

  const {
    Modal: DisconnectIntegrationModal,
    toggle: toggleDisconnect,
    modalProps: disconnectIntegrationInternal,
  } = useModal({ size: "narrow" });

  const [selectedIntegrationType, setSelectedIntegrationType] = useState<IntegrationType>();
  const selectedIntegration = useMemo(
    () => integrations.find(({ type }) => type === selectedIntegrationType),
    [selectedIntegrationType, integrations],
  );
  const hasJira = useMemo(() => integrations.some(({ type }) => type === IntegrationType.JIRA), [integrations]);

  const handleDisconnect = useCallback(async () => {
    if (!selectedIntegration) return;
    await disconnectIntegration(selectedIntegration);
    toggleDisconnect();
  }, [toggleDisconnect, disconnectIntegration, selectedIntegration]);

  const handleIntegrationFinish = () => {
    IntegrationConfigurationModal.setOpen(false);
  };

  const ModalBody = () => {
    if (!selectedIntegrationType || !selectedWorkspace) return null;

    const integrationBody = integrationModalBodyMap(selectedIntegrationType);

    const { component: Body, type } = integrationBody;

    switch (type) {
      case IntegrationType.GITHUB:
        return (
          <Body
            integration={app.github}
            selectedAppId={app.id}
            workspace={selectedWorkspace}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.ASANA:
        return (
          <Body
            integration={app.asana}
            selectedAppId={app.id}
            workspace={selectedWorkspace}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.CLICKUP:
        return (
          <Body
            integration={app.clickup}
            selectedAppId={app.id}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.TRELLO:
        return (
          <Body
            integration={app.trello}
            selectedAppId={app.id}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.SLACK:
        return (
          <Body
            integration={app.slack}
            selectedAppId={app.id}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.ZAPIER:
        return (
          <Body
            integration={app.zapier}
            selectedAppId={app.id}
            workspace={selectedWorkspace}
            onFinish={handleIntegrationFinish}
          />
        );
      case IntegrationType.AZURE_DEVOPS:
        return (
          <Body
            integration={app.azure}
            selectedAppId={app.id}
            workspace={selectedWorkspace}
            onFinish={handleIntegrationFinish}
            onAuthSuccess={async () => {
              try {
                await fetchAllApps(selectedWorkspace.id);
                setSelectedIntegrationType(IntegrationType.AZURE_DEVOPS);
              } catch (error) {}
            }}
          />
        );
      case IntegrationType.JIRA_SERVER:
      case IntegrationType.JIRA_CLOUD:
      case IntegrationType.JIRA:
        return (
          <Body
            integration={app.jira}
            selectedAppId={app.id}
            workspace={selectedWorkspace}
            onFinish={handleIntegrationFinish}
            showToggles={showJiraToggles ?? false}
          />
        );
      case IntegrationType.LINEAR:
        return (
          <Body
            integration={app.linear}
            selectedAppId={app.id}
            onFinish={handleIntegrationFinish}
            workspace={selectedWorkspace}
          />
        );
      case IntegrationType.WEBHOOK:
        return (
          <Body
            integration={app.webhook}
            selectedAppId={app.id}
            onFinish={handleIntegrationFinish}
          />
        );
      default:
        return <>TODO</>;
    }
  };

  const togglePreselectedConfigurationRef = useRef(() => {
    if (!preSelectedIntegrationType) return;

    setSelectedIntegrationType(preSelectedIntegrationType);
    IntegrationConfigurationModal.setOpen(true);
    clearPreselectedIntegrationType();
  });

  useEffect(() => {
    togglePreselectedConfigurationRef.current();
  }, [togglePreselectedConfigurationRef]);

  return (
    <AdministrationCard
      title="User feedback integrations"
      subtitle="Choose where to forward user feedback tickets."
      testId={identifiers["integrations.header"]}
    >
      {
        <GridTable gridTemplateColumns="repeat(2, minmax(10rem, 18rem)) repeat(1, auto)">
          {Object.values(IntegrationType)
            .sort((a, b) => a.localeCompare(b))
            .filter(
              (integrationType) =>
                integrationType !== IntegrationType.SENTRY &&
                (hasJira
                  ? integrationType !== IntegrationType.JIRA_CLOUD && integrationType !== IntegrationType.JIRA_SERVER
                  : integrationType !== IntegrationType.JIRA),
            )
            .map((integrationType) => {
              const integration = integrations.find((i) => i.type === integrationType) || null;
              return (
                <UserFeedbackIntegrationsRow
                  key={integrationType}
                  integration={integration}
                  type={integrationType}
                  onDisconnect={() => {
                    setSelectedIntegrationType(integrationType);
                    toggleDisconnect();
                  }}
                  onSettingsSelect={() => {
                    setSelectedIntegrationType(integrationType);
                    IntegrationConfigurationModal.setOpen(true);
                  }}
                  toggle={() => IntegrationConfigurationModal.setOpen(true)}
                  tooltip={tooltip.text}
                  disabled={disabled}
                  app={app}
                  workspace={selectedWorkspace}
                  setSelectedIntegrationType={setSelectedIntegrationType}
                />
              );
            })}
        </GridTable>
      }
      {getHelpers()}

      {selectedIntegrationType && (
        <>
          <DisconnectIntegrationModal
            {...disconnectIntegrationInternal}
            heading={`Disconnect ${getIntegrationTypeName(selectedIntegrationType)}?`}
            subtitle={`This will disable ${getIntegrationTypeName(
              selectedIntegrationType,
            )} and return it to its default settings.`}
            buttonElement={
              <Button
                onClick={handleDisconnect}
                size="small"
                color="red"
                disabled={loading}
                loadingText={loading && "Disconnecting..."}
              >
                Disconnect
              </Button>
            }
          />

          <BaseDialog
            {...IntegrationConfigurationModal}
            size="normal"
          >
            <Card noPadding>
              <ModalHeader
                heading={`${getIntegrationTypeName(selectedIntegrationType)} integration`}
                iconPath={getIntegrationTypeLogo(selectedIntegrationType)}
                hasBorderBottom
                testId={identifiers["integrations.modal.header.integrationType"]}
              />

              <ModalBody />
            </Card>
          </BaseDialog>
        </>
      )}
    </AdministrationCard>
  );
}

const getHelpers = () => {
  return (
    <>
      <Styled.HelperText $bottomMargin>
        In case you ever get stuck, here are TOP 4 troubleshooting Help Center articles.
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href={helpCenterLinks.integrationStoppedWorking}
        >
          An integration stopped working
        </a>
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href={helpCenterLinks.slackStoppedWorking}
        >
          Slack integration stopped working
        </a>
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href={helpCenterLinks.jiraDoesntAcceptTickets}
        >
          Shake connects to Jira but Jira then doesn&apos;t accept tickets
        </a>
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href={helpCenterLinks.jiraNoAttachments}
        >
          Jira integration works but the screenshot and other files aren&apos;t attached
        </a>
      </Styled.HelperText>

      <HelperText>
        Found a bug? Which integration should we build next?
        <br />
        Join other Shake customers in the conversation&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://shk.sh/join-slack"
        >
          on our Slack community
        </a>
        .
      </HelperText>
    </>
  );
};
