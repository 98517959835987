import React from "react";

import { Table } from "@tanstack/react-table";
import CountUp from "react-countup";

import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { HeaderCell } from "./BaseTableStyles";
import { MultiactionsPopover } from "./MultiactionsPopover/MultiactionsPopover";

export function SelectionHeaderCell<TData>({
  title,
  total,
  table,
  onDeleteRequest,
  onMarkAsReadRequest,
  onBulkChangePriority,
  onBulkChangeStatus,
  onBulkChangeAssignee,
  onBulkAddNoteSendChat,
  onBulkAddTags,
  onMergeAction,
}: {
  title?: string;
  total?: number;
  table: Table<TData>;
  onDeleteRequest: (rows: TData[]) => void;
  onMarkAsReadRequest: (rows: TData[]) => void;
  onBulkChangePriority: (rows: TData[], priority: TicketPriority) => void;
  onBulkChangeStatus: (rows: TData[], status: TicketStatus) => void;
  onBulkChangeAssignee: (rows: TData[], assigneeId: string | null) => void;
  onBulkAddNoteSendChat?: (rows: TData[], message: string, isNote?: boolean) => void;
  onBulkAddTags: (rows: TData[], tags: string[]) => void;
  onMergeAction?: (rows: TData[], mergeRow: TData) => void;
}) {
  const selectedRows = table.getSelectedRowModel().rows;

  return (
    <>
      <HeaderCell style={{ display: "flex", alignItems: "center", gap: "1rem", paddingLeft: "1rem" }}>
        <p>
          {total ? (
            <>
              <CountUp
                end={total}
                duration={1}
              />{" "}
              {total === 1 ? "result" : "results"}
            </>
          ) : (
            title ?? ""
          )}
        </p>
      </HeaderCell>

      {Boolean(selectedRows.length) && (
        <MultiactionsPopover
          table={table}
          selectedRows={selectedRows}
          onDeleteRequest={onDeleteRequest}
          selectAll={() => table.toggleAllRowsSelected(true)}
          deselectAll={() => table.toggleAllRowsSelected(false)}
          onMarkAsReadRequest={onMarkAsReadRequest}
          onBulkChangePriority={onBulkChangePriority}
          onBulkChangeStatus={onBulkChangeStatus}
          onBulkChangeAssignee={onBulkChangeAssignee}
          onBulkAddNoteSendChat={onBulkAddNoteSendChat}
          onBulkAddTags={onBulkAddTags}
          onMergeAction={onMergeAction}
        />
      )}
    </>
  );
}
