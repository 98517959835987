import { useEffect, useState } from "react";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useForm } from "hooks/useForm";

import { useUpdateSelectedWorkspace } from "layouts/views/WorkspaceRootLayout";

import { Customer, Plan, PlanType } from "models/billing";

import { useAdministrationDeps } from "../Administration";
import { getPlanSubtitle } from "../components/SubscriptionCards/YourSubscriptionCard/YourSubscriptionCard";

export interface SubscriptionFormState {
  plan: Option;
  addOn: Option;
}

const defaultPlanAddOnValue = {
  label: "Up to 10k app installs",
  value: "",
  subtitle: "$0 forever",
} as Option;

const SubscriptionInitialState: SubscriptionFormState = {
  plan: { label: "Free plan", value: "", subtitle: "$0 forever" },
  addOn: defaultPlanAddOnValue,
};

interface Props {
  customer?: Customer;
  toggleChangeSubscription: () => void;
  plans: Plan[];
  addOns: Plan[];
}

export const useSubscriptionFormConsumer = ({ customer, toggleChangeSubscription, plans, addOns }: Props) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  const upgradeSubscription = async () => {
    if (!selectedWorkspace) return;

    try {
      setLoading(true);

      const plans =
        formState.addOn.value && formState.plan.value
          ? [formState.plan.value, formState.addOn.value]
          : formState.plan.value && !formState.addOn.value
          ? [formState.plan.value]
          : [formState.addOn.value];
      const res = await administrationService.createCheckoutSession(selectedWorkspace.id, plans);

      window.location.replace(res.data.stripe_checkout_session_url);

      await updateSelectedWorkspace(selectedWorkspace);
    } catch (error) {
      handleFormChange("plan", SubscriptionInitialState.plan);
      handleFormChange("addOn", SubscriptionInitialState.addOn);

      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const changeSubscription = async () => {
    if (!selectedWorkspace || !customer) return;

    try {
      setLoading(true);
      const plans =
        formState.addOn.value && formState.plan.value
          ? [formState.plan.value, formState.addOn.value]
          : formState.plan.value && !formState.addOn.value
          ? [formState.plan.value]
          : [formState.addOn.value];

      await administrationService.changeSubscription(selectedWorkspace.id, plans);

      await updateSelectedWorkspace(selectedWorkspace);

      displayToast({
        title: "Thank you for switching subscription",
        content: "You're in — now it's time to make the most out of Shake.",
      });

      toggleChangeSubscription();
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const { handleFormChange, formState, handleFormSubmit } = useForm<SubscriptionFormState>({
    initialState: SubscriptionInitialState,
    onSubmit: !Boolean(customer?.subscription) ? upgradeSubscription : changeSubscription,
    validators: {},
  });

  useEffect(() => {
    if (customer && customer.subscription?.items) {
      setLoading(true);

      const currentPlan = customer.subscription?.items.find((item) => item.plan.type === PlanType.PLAN)?.plan;

      if (currentPlan && plans.find((plan) => plan.id === currentPlan.id)) {
        handleFormChange("plan", getPlanAsOption(currentPlan));
      }

      const currentAddOn = customer.subscription?.items.find((item) => item.plan.type === PlanType.DEVICES)?.plan;
      if (!currentAddOn || !addOns.find((addOn) => addOn.id === currentAddOn.id)) {
        handleFormChange("addOn", defaultPlanAddOnValue);
      }
      if (currentAddOn) handleFormChange("addOn", getPlanAsOption(currentAddOn));

      setLoading(false);
    }
  }, [handleFormChange, customer, plans, addOns]);

  return { formState, handleFormChange, handleFormSubmit, loading };
};

const getPlanAsOption = (plan: Plan) => {
  return {
    label: plan.product_name,
    value: plan.id,
    subtitle: getPlanSubtitle(plan),
  } as Option;
};
