import React, { useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { useAuthContext } from "context/Auth/Auth.context";

import { RoutePaths } from "router/config/routePaths";

interface Props {
  children: JSX.Element;
}

export default function RedirectIfAuthenticated({ children }: Props) {
  const { userToken } = useAuthContext();
  const navigate = useNavigate();

  const navigateToRootRef = useRef(() => {
    if (userToken) {
      displayToast({ content: "Please sign out before accessing this page." });
      navigate(RoutePaths.ROOT, { replace: true });
    }
  });

  useEffect(() => {
    navigateToRootRef.current();
  }, []);

  return <>{children}</>;
}
