import { useState } from "react";

import { useAppDeps } from "App.dependencies";

import displayToast from "components/Toast/displayToast";

import useUserDataApiConsumer from "consumers/useUserDataApiConsumer";
import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";
import useWorkspaceDataApiConsumer from "consumers/useWorkspaceDataApiConsumer";

import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import { BasicAppNotificationSettings, Workspace } from "models";
import { UserNotificationsRequest, UserReleasesNotificationsRequest } from "models/notifications";

import {
  buildFinalNotificationsRequestBody,
  buildHowToNotifyYouSettingsFromRequest,
  buildReleasesRequestBody,
  resolveNotifyMeAboutSettings,
} from "pages/Account/util/notificationSettingsUtil";

enum DesktopNotificationPermissions {
  GRANTED = "granted",
  DENIED = "denied",
  DEFAULT = "default",
}

export default function useNotificationSettingsApiConsumer() {
  const { userData } = useAuthContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const { appsService, notificationsService } = useAppDeps();

  const { fetchWorkspaceDataAndDispatch } = useWorkspaceDataApiConsumer();
  const { fetchUserDataAndDispatch } = useUserDataApiConsumer();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();

  const [loading, setLoading] = useState(false);

  const updateUserNotificationSettings = async (updateData: UserNotificationsRequest) => {
    setLoading(true);
    try {
      if (!selectedWorkspace || !userData) return;

      if (notificationPreferencesNotPresentWhenUpdatingHowToNotifyYou(selectedWorkspace, updateData)) {
        displayToast({ content: "Choose what you want to be notified about first." });
        return;
      }

      if (updateData.notify_desktop) {
        const permission = await resolveDesktopNotificationPermissions();
        if (permission !== DesktopNotificationPermissions.GRANTED) return;
      }

      const notificationsRequestBody = buildFinalNotificationsRequestBody(selectedWorkspace, updateData);
      await notificationsService.updateUserNotificationSettings(
        userData.id,
        selectedWorkspace.id,
        notificationsRequestBody,
      );

      await fetchWorkspaceDataAndDispatch();

      renderUserNotificationUpdateToast(notificationsRequestBody);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  };

  const updateUserMajorReleasesNotificationSettings = async (updateData: UserReleasesNotificationsRequest) => {
    setLoading(true);
    try {
      if (!userData) return;

      const notificationsRequestBody = buildReleasesRequestBody(userData, updateData);
      await notificationsService.changeUserNotifications(userData.id, notificationsRequestBody);

      await fetchUserDataAndDispatch();

      displayToast({ content: "Notification preferences successfully updated." });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  };

  const updateAppsNotificationSettings = async (notificationSettingsToUpdate: BasicAppNotificationSettings[]) => {
    try {
      setLoading(true);
      if (!selectedWorkspace) return;

      for (const setting of notificationSettingsToUpdate) {
        await appsService.updateApp(selectedWorkspace.id, setting.id, { follow_app: setting.follow_app });
      }

      if (notificationSettingsToUpdate.length) {
        await fetchAllApps(selectedWorkspace.id);
        displayToast({ content: "App notification settings successfully updated." });
      }
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return {
    updateUserNotificationSettings,
    updateAppsNotificationSettings,
    loading,
    updateUserMajorReleasesNotificationSettings,
  };
}

const notificationPreferencesNotPresentWhenUpdatingHowToNotifyYou = (
  selectedWorkspace: Workspace,
  updateRequest: UserNotificationsRequest,
) => {
  const notifyMeAboutSettings = resolveNotifyMeAboutSettings(selectedWorkspace, updateRequest);
  const allNotifyMeAboutSettingsDisabled = Object.values(notifyMeAboutSettings).every((setting) => !setting);

  const howToNotifyYouSettings = buildHowToNotifyYouSettingsFromRequest(updateRequest);
  const someHowToNotifyYouSettingEnabled = Object.values(howToNotifyYouSettings).some((setting) => setting);

  return allNotifyMeAboutSettingsDisabled && someHowToNotifyYouSettingEnabled;
};

const resolveDesktopNotificationPermissions = async () => {
  // permission previously denied
  if (Notification.permission === DesktopNotificationPermissions.DENIED) {
    displayToast({ content: "Please go to your browser settings and enable desktop notifications first." });
    return Notification.permission;
  }

  // request desktop notifications permission
  const permission = await Notification.requestPermission();
  if (permission === DesktopNotificationPermissions.DENIED) {
    displayToast({
      content: "You need to enable desktop notifications within your browser settings to be able to receive them.",
    });
  }

  return permission;
};

const renderUserNotificationUpdateToast = (userNotificationsRequest: UserNotificationsRequest) => {
  const allNotificationsDisabled = Object.values(userNotificationsRequest).every((setting) => !setting);

  if (allNotificationsDisabled) {
    displayToast({ content: "All right, no notifications it is." });
    return;
  }

  displayToast({ content: "Notification preferences successfully updated." });
};
