import React from "react";

import { QueryObserverResult } from "@tanstack/react-query";

import { ReactComponent as For } from "assets/images/rules/for-arrow.svg";
import { ReactComponent as If } from "assets/images/rules/if-arrow.svg";
import { ReactComponent as Then } from "assets/images/rules/then-arrow.svg";
import { ReactComponent as When } from "assets/images/rules/when-arrow.svg";

import Button from "components/Button/Button";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";
import Paragraph from "components/Paragraph/Paragraph";
import { ToggleButton } from "components/ToggleButton/ToggleButton";

import { useRulesAttributes } from "hooks/filtering/modules/useRulesAttributes";

import { App } from "models";
import { Rule } from "models/Rule.model";

import { useAddRuleFormConsumer } from "pages/Administration/consumers/useAddRuleFormConsumer";
import { useAvailableRulesConsumer } from "pages/Administration/consumers/useAvailableRulesConsumer";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { Form as StyledForm } from "../ModalBody.styles";
import * as Styled from "./RuleModalBody.styles";
import { eventOptions, moduleOptions } from "./rulesHelper";

interface Props {
  rule?: Rule;
  loading: boolean;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
  selectedApp: App;
  toggle?: () => void;
  getRules: () => Promise<QueryObserverResult<Rule[] | undefined>>;
}

export default function AddRuleModalBody({
  rule,
  loading,
  Modal,
  internalProps,
  selectedApp,
  toggle,
  getRules,
}: Props) {
  const {
    availableRules,
    loading: loadingAvailable,
    predefinedCondAttributes,
    predefinedActionAttributes,
  } = useAvailableRulesConsumer(rule);
  const conditionsAttributeState = useRulesAttributes(
    availableRules?.condition_types,
    selectedApp,
    predefinedCondAttributes,
  );
  const actionsAttributeState = useRulesAttributes(
    availableRules?.action_types,
    selectedApp,
    predefinedActionAttributes,
  );

  const {
    formState,
    handleFormChange,
    handleFormSubmit,
    loading: loadingAdd,
  } = useAddRuleFormConsumer(
    selectedApp,
    getRules,
    rule,
    toggle,
    availableRules,
    conditionsAttributeState.validAttributes,
    actionsAttributeState.validAttributes,
  );

  const { name, isActive, module, trigger } = formState;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled =
    !name ||
    !Boolean(conditionsAttributeState.validAttributes.length) ||
    !Boolean(actionsAttributeState.validAttributes.length) ||
    conditionsAttributeState.validAttributes.length !== conditionsAttributeState.attributes.length ||
    actionsAttributeState.validAttributes.length !== actionsAttributeState.attributes.length;

  return (
    <Modal
      {...internalProps}
      heading={rule ? "Edit rule" : "Add new rule"}
      size="fuller"
      buttonElement={
        <Button
          disabled={isButtonDisabled || loading}
          size="small"
          type="submit"
          tooltip={{
            position: "top",
            text:
              conditionsAttributeState.validAttributes.length !== conditionsAttributeState.attributes.length ||
              actionsAttributeState.validAttributes.length !== actionsAttributeState.attributes.length
                ? "Please enter valid clause first"
                : "Please enter needed data first",
            disabled: !isButtonDisabled || loading || loadingAdd || loadingAvailable,
          }}
          loadingText={loadingAdd && "Adding..."}
          onClick={handleFormSubmit}
        >
          {rule ? "Save changes" : "Add rule"}
        </Button>
      }
    >
      <StyledForm
        noValidate
        onSubmit={(event) => event.preventDefault()}
        style={{ position: "relative", zIndex: 1 }}
      >
        <Flex
          $gap={3.2}
          style={{ width: "100%", marginTop: "1.6rem" }}
        >
          <Styled.RuleName
            id="name"
            name="name"
            type="text"
            label="Rule name"
            value={name}
            placeholder="If ticket tag is @improvement, assign to Bob the Builder"
            onChange={handleChange}
            autoFocus
          />

          <Styled.Toggle
            $alignItems="center"
            $gap={1.2}
          >
            <ToggleButton
              id="isActive"
              name="isActive"
              checked={isActive}
              onChange={() => handleFormChange("isActive", !isActive)}
            />
            <Paragraph
              fontSize={12}
              color={vars.colors.grey50}
            >
              {isActive ? "Live" : "Paused"}
            </Paragraph>
          </Styled.Toggle>
        </Flex>
        <Styled.RulesBlock
          $column
          $gap={2.4}
        >
          <Flex
            $gap={2.2}
            $alignItems="center"
          >
            <For />
            <Styled.SelectDropdown
              disabled
              name="module"
              label="Choose module"
              value={module}
              isSearchable={false}
              options={moduleOptions}
              defaultValue={moduleOptions[0]}
            />
          </Flex>
          <Flex
            $gap={2.2}
            $alignItems="center"
          >
            <When />
            <Styled.SelectDropdown
              disabled
              name="trigger"
              label="Choose an event"
              isSearchable={false}
              value={trigger}
              options={eventOptions}
              defaultValue={eventOptions[0]}
              hasSubtitles
            />
          </Flex>

          <Flex $alignItems="center">
            <If />
            <Styled.AttributesContainer>
              <Styled.Attributes
                $top
                {...conditionsAttributeState}
                tooltipPosition="top"
              />
            </Styled.AttributesContainer>
          </Flex>

          <Flex $alignItems="center">
            <Then />
            <Styled.AttributesContainer>
              <Styled.Attributes
                {...actionsAttributeState}
                tooltipPosition="top"
                hideOperator
              />
            </Styled.AttributesContainer>
          </Flex>
        </Styled.RulesBlock>
      </StyledForm>
    </Modal>
  );
}
