import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import FormData from "form-data";

import { USER_DATA_LOCAL_STORAGE_KEY, USER_TOKEN_LOCAL_STORAGE_KEY } from "context/Auth/Auth.types";

import environments from "environments";

import { UserToken } from "models";

import { getAndParseItemFromLocalStorage } from "util/localStorage";

interface InterceptorRequestConfig extends AxiosRequestConfig {
  _retry: boolean;
}

interface ApiParams {
  resource: string;
  data?: unknown;
  config?: AxiosRequestConfig;
}

interface Handlers {
  renewTokenHandler: (token: UserToken) => void;
  handle402Error: () => void;
}

export default class ApiService {
  static serviceInstance: ApiService;
  instance: AxiosInstance;
  instanceAuth: AxiosInstance;

  private handlers: Partial<Handlers> = {};

  constructor() {
    axios.defaults.withCredentials = false;

    this.instance = axios.create({
      baseURL: environments.apiHost,
    });

    this.instanceAuth = axios.create({
      baseURL: environments.oAuthHost,
    });

    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }

  public static getInstance(): ApiService {
    if (!ApiService.serviceInstance) {
      ApiService.serviceInstance = new ApiService();
    }
    return ApiService.serviceInstance;
  }

  addTokenToRequest(request: AxiosRequestConfig): AxiosRequestConfig {
    const currentUser = getAndParseItemFromLocalStorage(USER_TOKEN_LOCAL_STORAGE_KEY);

    if (currentUser && currentUser.access_token && !request?.url?.startsWith("http://localhost:5000/auth/oauth2/")) {
      if (request.headers) {
        request.headers.authorization = `${currentUser.token_type} ${currentUser.access_token}`;
      }
    }

    return request;
  }

  refreshToken(): Promise<AxiosResponse<UserToken>> {
    const form = new FormData();
    const refreshToken = getAndParseItemFromLocalStorage(USER_TOKEN_LOCAL_STORAGE_KEY)?.refresh_token;
    form.append("refresh_token", refreshToken);
    form.append("grant_type", "refresh_token");
    form.append("client_id", "shake_dashboard");

    return this.postAuth<UserToken>({
      resource: `token`,
      data: form,
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    }).then((token: AxiosResponse<UserToken>) => {
      // refresh successful if there's a jwt token in the response
      if (token && token.data && token.data.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
      }
      return token;
    });
  }

  handle401Error(request: AxiosRequestConfig): Promise<AxiosResponse<UserToken> | unknown> | undefined {
    if (this.hasTokensInStorageOrIsDemo()) {
      return this.refreshToken()
        .then((user: AxiosResponse<UserToken>) => {
          if (user) {
            localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(user.data));
            this.handlers.renewTokenHandler?.(user.data);
            return this.addTokenToRequest(request);
          }
          return new Promise(() => {
            localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
            localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
            location.reload();
          });
        })
        .catch(() => {
          // If we refresh token and the next request throws error we shouldnt log user out
          const currentUser = localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY);
          if (currentUser != null) {
            return new Promise(() => {
              localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
              localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
              location.reload();
            });
          } else {
            return new Promise(() => {
              localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
              localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
              location.reload();
            });
          }
        });
    }
  }

  injectRenewTokenHandler(handler: (token: UserToken) => void) {
    this.handlers = { ...this.handlers, renewTokenHandler: handler };
  }

  inject402ErrorHandler(handler: () => void) {
    this.handlers = { ...this.handlers, handle402Error: handler };
  }

  hasTokensInStorageOrIsDemo(isDemo?: boolean): boolean {
    if (isDemo) return true;

    return !!localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY);
  }

  setRequestInterceptors(): void {
    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        this.addTokenToRequest(config);
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );

    this.instanceAuth.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      },
    );
  }

  setResponseInterceptors(): void {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (err: AxiosError) => {
        const originalRequest = err.config as InterceptorRequestConfig;
        if (
          originalRequest &&
          !originalRequest._retry &&
          err.response?.status === 401 &&
          originalRequest.url !== `${process.env.oAuthHost}token` &&
          originalRequest.url !== `${process.env.apiHost}accounts/users/activation` &&
          originalRequest.url !== `${process.env.apiHost}accounts/teams/check_invite_code` &&
          originalRequest.url !== `${process.env.apiHost}accounts/teams/invite_activation` &&
          originalRequest.url !== `${process.env.apiHost}accounts/teams/invite_activation_with_google`
        ) {
          originalRequest._retry = true;
          return this.handle401Error(originalRequest)?.then(() => {
            return this.instance(originalRequest);
          });
        }
        if (originalRequest && !originalRequest._retry && err.response?.status === 402) {
          return this.handlers.handle402Error?.();
        } else {
          return Promise.reject(err);
        }
      },
    );
  }

  get<T>({ resource, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instance.get(resource, config);
  }

  post<T>({ resource, data, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instance.post(resource, data, config);
  }

  postAuth<T>({ resource, data, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instanceAuth.post(resource, data, config);
  }

  patch<T>({ resource, data, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instance.patch(resource, this.formatPatchData(data), config);
  }

  put<T>({ resource, data, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instance.put(resource, data, config);
  }

  delete<T>({ resource, config }: ApiParams): Promise<AxiosResponse<T>> {
    return this.instance.delete(resource, config);
  }

  // RFC standard for PATCH request body
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatPatchData(data: any) {
    return Object.keys(data).map((value) => ({
      op: "replace",
      path: "/" + value,
      value: data[value],
    }));
  }
}

ApiService.getInstance();
