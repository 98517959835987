import styled, { css } from "styled-components";

import { vars } from "styles";

export const AdministrationCardWrap = styled.div<{ $danger?: boolean }>`
  padding: 3.2rem;
  border-radius: 2rem;
  background-color: ${vars.colors.grey100};
  margin-bottom: 1.2rem;

  ${(props) =>
    props.$danger &&
    css`
      border: 1px solid ${vars.colors.red};

      path {
        stroke: ${vars.colors.red};
      }

      h1,
      h2,
      h3,
      h4,
      p {
        color: ${vars.colors.red};
      }
    `}
`;

export const SubtitleWrap = styled.p<{ $isEmpty?: boolean }>`
  color: ${vars.colors.grey60};
  padding-bottom: 2.4rem;
  font-weight: normal;
  ${(props) =>
    props.$isEmpty &&
    css`
      padding-bottom: 0;
    `}
`;

export const TopLeftAccessoryWrap = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 2.4rem;
  padding-left: 4rem;
`;

export const HeadingWrap = styled.div`
  color: ${vars.colors.grey30};
`;
