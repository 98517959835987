import React from "react";

import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";

import * as Styled from "./Toast.styles";

interface Props {
  enableMultiContainer?: boolean;
  autoClose?: number | false;
  closeButton?: boolean;
  closeOnClick?: boolean;
  draggable?: boolean;
  hideProgressBar?: boolean;
  pauseOnHover?: boolean;
  containerId?: string;
}

export default function Toast({
  containerId,
  autoClose = 4000,
  closeButton = true,
  closeOnClick = false,
  draggable = false,
  hideProgressBar = true,
  pauseOnHover = true,
}: Props) {
  return (
    <Styled.Toast>
      <ToastContainer
        enableMultiContainer={true}
        autoClose={autoClose}
        closeButton={closeButton}
        closeOnClick={closeOnClick}
        containerId={containerId}
        draggable={draggable}
        hideProgressBar={hideProgressBar}
        pauseOnHover={pauseOnHover}
        position={toast.POSITION.BOTTOM_RIGHT}
        theme="dark"
      />
    </Styled.Toast>
  );
}
