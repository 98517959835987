import React, { Fragment, useRef, useState } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import AppPlaceholderPhoto from "assets/images/shared-icons/app-icon-placeholder.svg";

import { DropdownItem } from "components/Dropdown/Dropdown";
import * as StyledDropdown from "components/Dropdown/Dropdown.styles";
import Paragraph from "components/Paragraph/Paragraph";

import useNotificationsApiConsumer from "consumers/useNotificationsApiConsumer";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import useOnClickOutside from "hooks/useOnClickOutside";

import { App } from "models/App.model";
import { isAdmin, isMember } from "models/Member.model";

import { RoutePaths } from "router/config/routePaths";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getOSImage } from "util/contentResolvers";
import identifiers from "util/identifiers.json";
import { t } from "util/translator";

import * as StyledAppList from "./AppListDropdown.styles";

interface AppListDropdownProps {
  appList: Array<App>;
  onAppSwitch?: (app: App) => void;
}

export default function AppListDropdown({ appList, onAppSwitch }: AppListDropdownProps) {
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();
  const { unreadNotifications } = useNotificationsApiConsumer();
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const { appKey } = useParams();

  const optionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(optionsRef, () => isDropdownMenuOpen && setIsDropdownMenuOpen(false));

  const handleSelectedAppClick = () => {
    setIsDropdownMenuOpen(!isDropdownMenuOpen);
  };

  return (
    <>
      <StyledAppList.AppListDropdown>
        <Flex
          $alignItems="center"
          style={{ height: "100%" }}
        >
          <StyledAppList.IconButton
            ref={optionsRef}
            onClick={handleSelectedAppClick}
            $active={isDropdownMenuOpen}
            data-testid={identifiers["navbar.selectedApp.options.button"]}
          >
            <StyledAppList.More />

            <StyledDropdown.DropdownMenu
              $active={isDropdownMenuOpen}
              scroll={scroll}
              $position="left"
              $wide
              $button={false}
              className="dropdown-menu"
            >
              <StyledDropdown.DropdownScroll
                cx={StyledAppList.dropdownScroll}
                data-testid={identifiers["navbar.selectedApp.dropdown.list"]}
              >
                <DropdownItem
                  onClick={() =>
                    navigate(
                      generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL_KEY, {
                        workspaceSlug: selectedWorkspace?.slug ?? null,
                        appKey: appKey ?? null,
                      }),
                      { replace: true },
                    )
                  }
                  testId={identifiers["navbar.selectedApp.dropdown.appSettings"]}
                >
                  <Flex
                    $gap={1.2}
                    $alignItems="center"
                  >
                    <StyledAppList.PermissionIcon />
                    App settings
                  </Flex>
                </DropdownItem>

                {Boolean(appList.length) && (isAdmin(selectedWorkspace?.role) || isMember(selectedWorkspace?.role)) && (
                  <Fragment>
                    <StyledDropdown.DropdownDivider />

                    <StyledDropdown.SwitchAppDropdownHeading>
                      {t("header.app.dropdown.switchApp")}
                    </StyledDropdown.SwitchAppDropdownHeading>
                  </Fragment>
                )}

                {Boolean(appList.length) && (
                  <Fragment>
                    {appList.map((app) => {
                      return (
                        <DropdownItem
                          key={app.id}
                          onClick={() => {
                            setIsDropdownMenuOpen(!isDropdownMenuOpen);
                            onAppSwitch?.(app);
                          }}
                        >
                          <Flex $alignItems="center">
                            <img
                              src={app.logo_url ?? AppPlaceholderPhoto}
                              width={28}
                              height={28}
                              style={{
                                borderRadius: "0.8rem",
                                marginRight: "0.8rem",
                                objectFit: "cover",
                              }}
                            />
                            <StyledAppList.Name>{app.name}</StyledAppList.Name>
                            <img
                              src={getOSImage(app.platform)}
                              width={20}
                              height={20}
                            />
                            {unreadNotifications[app.id] && unreadNotifications[app.id].length && (
                              <StyledAppList.NotificationIndicator>
                                <Paragraph
                                  color={vars.colors.white}
                                  fontSize={12}
                                >
                                  {unreadNotifications[app.id].length > 99 ? "99" : unreadNotifications[app.id].length}
                                </Paragraph>
                              </StyledAppList.NotificationIndicator>
                            )}
                          </Flex>
                        </DropdownItem>
                      );
                    })}
                  </Fragment>
                )}
              </StyledDropdown.DropdownScroll>
            </StyledDropdown.DropdownMenu>
          </StyledAppList.IconButton>
        </Flex>
      </StyledAppList.AppListDropdown>
      <StyledAppList.Overlay
        active={isDropdownMenuOpen}
        onClick={() => setIsDropdownMenuOpen(false)}
      />
    </>
  );
}
