import React, { Fragment, useEffect } from "react";

import { generatePath } from "react-router-dom";

import { useModal } from "components/Modal/Modal";
import RoundedCanvas from "components/RoundedCanvas/RoundedCanvas";

import { Workspace } from "models";

import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";
import WorkspaceCreateElement from "pages/WorkspaceCreate/components/WorkspaceCreateElement/WorkspaceCreateElement";
import WorkspaceJoinElement from "pages/WorkspaceJoin/components/WorkspaceJoinElement/WorkspaceJoinElement";

import { RoutePaths } from "router/config/routePaths";

import identifiers from "util/identifiers.json";

import * as Styled from "./Sidebar.styles";
import SidebarMenuItem from "./SidebarMenuItem";

interface SidebarMenuWorkspaceProps {
  active: boolean;
  setIsActive: (active: boolean) => void;
  selectedWorkspace: Workspace;
  workspaces: Workspace[];
  toggleVisibility: () => void;
  handleWorkspaceChange: (workspace: Workspace) => void;
}

export default function SidebarMenuWorkspace(props: SidebarMenuWorkspaceProps) {
  const handleWorkspaceChange = (workspace: Workspace) => {
    props.handleWorkspaceChange(workspace);
    props.toggleVisibility();
  };

  const {
    Modal: CreateWorkspaceModal,
    toggle: toggleCreateWs,
    modalProps: createWsModalProps,
  } = useModal({ size: "narrow" });
  const { Modal: JoinWorspaceModal, toggle: toggleJoinWs, modalProps: joinWsModalProps } = useModal({ size: "narrow" });

  useEffect(() => {
    if ((joinWsModalProps.active || createWsModalProps.active) && props.active) {
      props.setIsActive?.(false);
    }
  }, [joinWsModalProps, createWsModalProps, props]);

  return (
    <Fragment>
      <Styled.SidebarMenu $active={props.active}>
        <Styled.SidebarMenuHeading>{props.selectedWorkspace.name}</Styled.SidebarMenuHeading>
        <SidebarMenuItem
          text="Workspace administration"
          to={generatePath(RoutePaths.ADMINISTRATION_GENERAL, { workspaceSlug: props.selectedWorkspace.slug })}
          onClick={props.toggleVisibility}
          testId={identifiers["main.link.workspaceAdministration"]}
        />
        <Styled.SidebarMenuDivider />

        {props.workspaces.length > 0 && (
          <Fragment>
            <Styled.SidebarMenuHeading>Switch to</Styled.SidebarMenuHeading>
            <Styled.SidebarMenuScroll>
              {props.workspaces
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((workspace) => (
                  <SidebarMenuItem
                    key={workspace.id}
                    onClick={() => handleWorkspaceChange(workspace)}
                  >
                    <RoundedCanvas
                      letter={workspace.name[0]?.toLocaleUpperCase() || ""}
                      image={workspace?.logo_url ?? undefined}
                      bgColor={getRandomColor(workspace?.id).background}
                      txtColor={getRandomColor(workspace?.id).text}
                    />
                    <Styled.SidebarMenuItemText>{workspace.name}</Styled.SidebarMenuItemText>
                  </SidebarMenuItem>
                ))}
            </Styled.SidebarMenuScroll>
            <Styled.SidebarMenuDivider />
          </Fragment>
        )}

        <SidebarMenuItem
          text="Join workspace"
          onClick={toggleJoinWs}
          testId={identifiers["main.link.joinWorkspace"]}
        />
        <SidebarMenuItem
          text="Create workspace"
          onClick={toggleCreateWs}
          testId={identifiers["main.link.createWorkspace"]}
        />
      </Styled.SidebarMenu>

      <WorkspaceCreateElement
        isModal
        toggleCreateWs={toggleCreateWs}
        toggleJoinWs={toggleJoinWs}
        Modal={CreateWorkspaceModal}
        internalProps={createWsModalProps}
      />

      <WorkspaceJoinElement
        isModal
        toggleCreateWs={toggleCreateWs}
        toggleJoinWs={toggleJoinWs}
        Modal={JoinWorspaceModal}
        internalProps={joinWsModalProps}
      />
    </Fragment>
  );
}
