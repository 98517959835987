import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useWindowTriggerWidth } from "hooks/useWindowMinWidth";

function useSpaceToPreviewKeyboardListener(onPress: (isEsc?: boolean) => void) {
  const enabledRef = useRef(false);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === " " || event.key === "Escape") {
        onPress(event.key === "Escape" ? true : false);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onPress]);

  const setEnabled = useCallback((enabled: boolean) => {
    enabledRef.current = enabled;
  }, []);

  return { setEnabled };
}

export function useSpaceToPreview({
  onPreviewRequest,
  onPreviewClosed,
}: {
  onPreviewRequest: (index: number) => void;
  onPreviewClosed: () => void;
}) {
  const isSmaller = useWindowTriggerWidth({
    triggerWidthPx: 1200,
  });

  const [featureEnabled, setFeatureEnabled] = useState(isSmaller == false);

  const previewTimeoutRef = useRef<NodeJS.Timeout>();

  const indexTracker = useRef(0);
  const previewingRef = useRef(false);
  const ref = useRef<Element | null>(null);

  const onPress = useCallback(
    (isEsc?: boolean) => {
      previewingRef.current = !previewingRef.current;

      if (ref.current !== document.activeElement) {
        if (isEsc) {
          previewingRef.current = false;
        }
        onPreviewClosed();
        return;
      }

      if (isEsc) {
        onPreviewClosed();

        previewingRef.current = false;
        return;
      }

      previewTimeoutRef.current && clearTimeout(previewTimeoutRef.current);

      previewingRef.current && onPreviewRequest(indexTracker.current);

      (!previewingRef.current || isEsc) && onPreviewClosed();
    },
    [onPreviewRequest, onPreviewClosed],
  );

  const { setEnabled } = useSpaceToPreviewKeyboardListener(onPress);

  const onMouseOverRow = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
      if (!featureEnabled) return;

      /// Duplicate event
      if (previewTimeoutRef.current && rowIndex == indexTracker.current) return;

      ref.current = document.activeElement;

      /// Store last hovered state but don't update
      indexTracker.current = rowIndex;

      previewTimeoutRef.current && onPreviewClosed();

      previewTimeoutRef.current && clearTimeout(previewTimeoutRef.current);
      previewTimeoutRef.current = setTimeout(() => {
        /// Only start pushing events if enabled
        previewingRef.current && onPreviewRequest(rowIndex);
      }, 0); // <- Floating Time
    },
    [featureEnabled, onPreviewClosed, onPreviewRequest],
  );

  const onMouseLeaveContainer = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent> | React.FocusEvent<HTMLElement>) => {
      if (!featureEnabled) return;

      const previewContainer = document.getElementById("__preview_container");
      const relatedTarget = e.relatedTarget as HTMLElement | null;

      function containsPreviewContainer() {
        let contains = false;
        try {
          contains = previewContainer?.contains(relatedTarget) || relatedTarget?.id === "__preview_container";
        } catch {}
        return contains;
      }

      if (previewContainer && relatedTarget && containsPreviewContainer() && ref.current !== document.activeElement) {
        return;
      }

      setEnabled(false);
      previewingRef.current = false;
      ref.current = null;
      indexTracker.current = -1;
      onPreviewClosed();
    },
    [featureEnabled, onPreviewClosed, setEnabled],
  );

  const onMouseEnterContainer = useCallback(() => {
    if (!featureEnabled) return;

    setEnabled(true);
  }, [featureEnabled, setEnabled]);

  useEffect(() => {
    setFeatureEnabled(isSmaller == false);

    if (isSmaller) {
      previewTimeoutRef.current && clearTimeout(previewTimeoutRef.current);
      onPreviewClosed();
    }
  }, [isSmaller, onPreviewClosed]);

  return { onMouseOverRow, onMouseLeaveContainer, onMouseEnterContainer, featureEnabled };
}

const previewContext = React.createContext<ReturnType<typeof useSpaceToPreview>>({
  onMouseEnterContainer: () => {
    return;
  },
  onMouseLeaveContainer: () => {
    return;
  },
  onMouseOverRow: () => {
    return;
  },
  featureEnabled: false,
});

export function SpaceToPreviewMetaProvider({
  children,
  ...rest
}: ReturnType<typeof useSpaceToPreview> & { children: ReactNode }) {
  return <previewContext.Provider value={rest}>{children}</previewContext.Provider>;
}

export function useSpaceToPreviewContext() {
  return React.useContext(previewContext);
}
