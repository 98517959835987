import React from "react";

import { generatePath, Link } from "react-router-dom";

import { HelperText } from "components/HelperText/HelperText";
import PageWrap from "components/PageWrap/PageWrap";

import { Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";

import * as Styled from "../views/AppsView/AppsView.styles";

interface Props {
  selectedWorkspace?: Workspace;
}

export default function NoAppsView({ selectedWorkspace }: Props) {
  return (
    <PageWrap>
      <Styled.NoAppsCard>
        <div>
          <HelperText>
            There are no apps in your workspace. Add the first one by visiting the&nbsp;
            <Link
              to={generatePath(RoutePaths.USER_FEEDBACK_MODULE_ROOT, {
                workspaceSlug: selectedWorkspace?.slug ?? null,
              })}
            >
              Dashboard
            </Link>{" "}
            and clicking the &quot;Add new app&quot; button.
          </HelperText>
        </div>
      </Styled.NoAppsCard>
    </PageWrap>
  );
}
