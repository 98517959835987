import { useEffect, useState } from "react";

export function useWindowTriggerWidth({ triggerWidthPx }: { triggerWidthPx: number }) {
  const [isSmaller, setIsSmaller] = useState(window.innerWidth < triggerWidthPx);

  useEffect(() => {
    const maxMatch = window.matchMedia(`(max-width: ${triggerWidthPx}px)`);
    const minMatch = window.matchMedia(`(min-width: ${triggerWidthPx}px)`);

    function handler() {
      setIsSmaller(maxMatch.matches);
    }

    maxMatch.addEventListener("change", handler);
    minMatch.addEventListener("change", handler);

    return () => {
      maxMatch.removeEventListener("change", handler);
      minMatch.removeEventListener("change", handler);
    };
  }, [triggerWidthPx]);

  return isSmaller;
}
