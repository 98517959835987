import { useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { IntegrationType, Slack, SlackIntegrationPayload } from "models/integrations";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Slack;
  selectedAppId: string;
  onFinish: () => void;
}

interface FormState {
  webhook: string;
  channel: string;
}

const validators = {
  webhook: (webhook: string) => {
    if (!webhook.trim().length) return new Error("Invalid webhook");
  },
  channel: (channel: string) => {
    if (!channel.trim().length) return new Error("Invalid channel");
    if (channel.charAt(0) !== "#") return new Error("Missing channel prefix #");
  },
};

export const useSlackIntegrationFormConsumer = ({ selectedAppId, onFinish, integration }: Props) => {
  const { integrate, updateIntegration } = useIntegrationFormConsumer({
    selectedAppId,
    type: IntegrationType.SLACK,
    noInfo: true,
  });

  const { formState, formError, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: {
      channel: integration ? integration.channel_name : "",
      webhook: integration ? integration.webhook_url : "",
    },
    validators,
    onSubmit: handleConnect,
  });

  const [loading, setLoading] = useState(false);

  async function handleConnect() {
    try {
      setLoading(true);
      const payload: SlackIntegrationPayload = {
        channel_name: formState.channel,
        webhook_url: formState.webhook,
        user: "Shake",
      };
      const endpoint = !!integration ? updateIntegration : integrate;

      await endpoint<Slack>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      onFinish();
      setLoading(false);
    }
  }

  return { formState, formError, handleFormChange, handleFormSubmit, disabled: loading };
};
