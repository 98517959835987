import React from "react";

import StepIcon from "assets/images/shared-icons/step@2x.svg";

import Heading from "components/Heading/Heading";

import * as Styled from "./SetupStep.styles";

interface Props {
  number: number;
  title: string;
}

export function SetupStep({ number, title }: Props) {
  return (
    <Styled.Container>
      <Styled.LeftContainer>
        <Styled.StepNumberContainer>
          <img
            src={StepIcon}
            alt="step-number"
          />
          <Styled.StepNumberText>{number}</Styled.StepNumberText>
        </Styled.StepNumberContainer>
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Heading
          marginBottom={0}
          heading3
        >
          {title}
        </Heading>
      </Styled.RightContainer>
    </Styled.Container>
  );
}
