import React, { Fragment, useState } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import Accordion from "components/Accordion/Accordion";
import Button from "components/Button/Button";
import { DetailsPane } from "components/DetailsPane/DetailsPane";
import {
  getAppUserInfoElements,
  getClockIcon,
  getDetails,
  GroupedTicketDetailsElement,
  readMetadata,
} from "components/DetailsPane/helper";
import Heading from "components/Heading/Heading";
import EditableTitle from "components/Input/EditableTitle/EditableTitle";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCrashesAttributes } from "hooks/filtering/modules/useCrashesAttributes";
import { useCustomElementInjection } from "hooks/useCustomElementInjection";

import NavigationCustomElement from "layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "layouts/components/PageNavigation/PageNavigation";

import { PlatformName, PlatformOs } from "models";
import { CrashFrame } from "models/crashReporting";

import AttachmentsContainer from "pages/shared/components/Attachments/AttachmentsContainer";
import { TicketDetailsContainer } from "pages/shared/components/TicketDetails/TicketDetailsContainer";
import { TicketContainer } from "pages/shared/components/TicketDetails/TicketDetailsContainer.styles";
import { TicketInfoContainer } from "pages/shared/components/TicketDetails/TicketInfoContainer";

import { RoutePaths } from "router/config/routePaths";

import { docLink } from "util/docs";
import identifiers from "util/identifiers.json";

import * as Styled from "./Crashes.styles";
import CustomFields from "../../shared/components/CustomFields/CustomFields";
import MessageContainer from "../../shared/components/Messages/MessageContainer";
import CrashesPageNavigation from "../components/PageNavigation/CrashesPageNavigation";
import CrashEventPageNavigationContent from "../components/PageNavigation/CrashEvent/CrashEventPageNavigationContent";
import useCrashEventApiConsumer from "../consumers/useCrashEventApiConsumer";
import useCrashEventMessagesConsumer from "../consumers/useCrashEventMessagesConsumer";
import useCrashOverviewApiConsumer from "../consumers/useCrashOverviewApiConsumer";

export default function CrashEventPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const params = useParams<{ crashReportKey: string; crashReportEventId: string }>();
  const navigate = useNavigate();
  const [showAllThreads, setShowAllThreads] = useState(false);
  const [detailDropdownOpened, setDetailDropdownOpened] = useState<string | undefined>(undefined);

  const platform = Object.values(PlatformName).find((value) => value === selectedApp?.platform.name);

  const { addExternal } = useCrashesAttributes(true);

  const crashReportEventConsumer = useCrashEventApiConsumer({
    teamId: selectedWorkspace?.id,
    appId: selectedApp?.id,
    crashReportKey: params?.crashReportKey,
    crashReportEventId: params?.crashReportEventId,
  });

  const crashOverviewConsumer = useCrashOverviewApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
  });

  const { messages, sendMessage, deleteMessage, editMessage } = useCrashEventMessagesConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    crashEventId: crashReportEventConsumer.crashEvent?.id,
  });

  const CustomElement = (
    <NavigationCustomElement>
      <CrashesPageNavigation crashTitle={crashOverviewConsumer.crashOverview?.title ?? ""}>
        <CrashEventPageNavigationContent
          crashEvent={crashReportEventConsumer.crashEvent}
          crashReportKey={params.crashReportKey}
          selectedAppKey={selectedApp?.key}
          selectedWorkspaceSlug={selectedWorkspace?.slug}
          navigate={navigate}
        />
      </CrashesPageNavigation>
    </NavigationCustomElement>
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (!crashReportEventConsumer.crashEvent || !params.crashReportKey) {
    return <Spinner />;
  }

  const addToFilterObject = {
    addToFilter: addExternal,
    redirectURL: generatePath(RoutePaths.CRASH_REPORTS, {
      workspaceSlug: selectedWorkspace?.slug ?? null,
      appKey: selectedApp?.key ?? null,
    }),
  };

  const infoElements = [
    {
      title: "Crash event details",
      infoElements: (
        [
          {
            infoElements: [
              {
                iconComponent: getClockIcon(),
                text: "Activity history",
                subtitle: "Timeline of network traffic, taps and screen visits",
                fullwidth: true,
                onElementClick: () => {
                  navigate(
                    generatePath(RoutePaths.CRASH_REPORT_LOGS, {
                      workspaceSlug: selectedWorkspace?.slug ?? null,
                      appKey: selectedApp?.key ?? null,
                      crashReportKey: params.crashReportKey ?? null,
                      crashReportEventId: crashReportEventConsumer.crashEvent?.id ?? null,
                    }),
                  );
                },
                testId: identifiers["details.pane.activityHistory.button"],
              },
            ],
          },
        ] as (GroupedTicketDetailsElement | undefined)[]
      ).concat(
        getDetails(
          crashReportEventConsumer.crashEvent,
          detailDropdownOpened,
          setDetailDropdownOpened,
          selectedApp,
          addToFilterObject,
          crashReportEventConsumer.blackbox,
        ),
      ),
    },
    {
      title: "Ticket metadata",
      infoElements: [
        {
          infoElements: readMetadata(
            crashReportEventConsumer.crashEvent.metadata_,
            [],
            detailDropdownOpened,
            setDetailDropdownOpened,
          ),
        },
      ],
      link: {
        tooltipText: "Set ticket metadata",
        link: docLink(platform).addTicketMetadata,
        isExternal: true,
        testId: identifiers["details.pane.metadata.link.add"],
      },
    },
    {
      title: "App user details",
      infoElements: getAppUserInfoElements(crashReportEventConsumer.crashEvent?.app_user, () => {
        navigate(
          generatePath(RoutePaths.USER_DETAILS, {
            workspaceSlug: selectedWorkspace?.slug ?? null,
            appKey: selectedApp?.key ?? null,
            userId: crashReportEventConsumer.crashEvent?.app_user?.id ?? null,
          }),
        );
      }),
      link: {
        tooltipText: "Register app user",
        link: docLink(platform).registerAppUser,
        isExternal: true,
      },
    },
  ];

  return (
    <PageWrap>
      <TicketContainer>
        <TicketInfoContainer>
          <div style={{ marginLeft: "-1.2rem" }}>
            <Heading
              as="h2"
              heading2
              marginBottom={40}
            >
              <EditableTitle
                value={crashReportEventConsumer.crashEvent?.pretty_title}
                defaultValue="No description"
                onEditConfirm={(title?: string) => crashReportEventConsumer.updateCrashEvent({ title })}
                testId={identifiers["central.column.crash.title"]}
              />
            </Heading>
          </div>
          <CustomFields customFields={crashReportEventConsumer.crashEvent.custom_fields} />
          <Styled.Threads>
            {crashReportEventConsumer.crashEvent.threads.map((thread) => {
              if (!showAllThreads && !thread.is_blamed) return;
              return thread.exceptions.map((exception) => {
                const title = exception.exception_type ? exception.exception_type : thread.thread_name;
                return (
                  <Accordion
                    testId={identifiers["central.column.crash.thread.heading"]}
                    key={exception.id}
                    title={title}
                    content={getContent(exception.frames, selectedApp?.platform.os)}
                    subtitle={exception.exception_message}
                    isOpen={!showAllThreads ? true : undefined}
                  />
                );
              });
            })}
          </Styled.Threads>
          <Styled.Flex>
            <Button
              styling="textual"
              onClick={() => setShowAllThreads(!showAllThreads)}
              testId={identifiers["central.column.crash.link.allThreads"]}
            >
              {showAllThreads ? "Hide all threads" : "Show all threads"}
            </Button>

            {/*  <Button
              onClick={() =>
                displayToast({

                  content: "TODO - Implement download functionality",
                })
              }
              styling="textual"
            >
              Download
            </Button> */}
            {/*  <Button
              onClick={() =>
                displayToast({

                  content: "TODO - Implement copy stack trace functionality",
                })
              }
              styling="textual"
            >
              Copy stack trace
            </Button> */}
          </Styled.Flex>
          <AttachmentsContainer
            deviceOrientation={crashReportEventConsumer.crashEvent.pretty_device_orientation}
            attachments={crashReportEventConsumer.attachments.sort((a, b) => a.num - b.num)}
          />
          <MessageContainer
            messages={messages}
            onMessageSend={sendMessage}
            onMessageDelete={deleteMessage}
            onMessageEdit={editMessage}
            appUser={crashReportEventConsumer.crashEvent.app_user}
          />
        </TicketInfoContainer>
        <TicketDetailsContainer>
          <DetailsPane
            info={infoElements}
            headerTestId={identifiers["crash.reports.details.pane.header"]}
          />
        </TicketDetailsContainer>
      </TicketContainer>
    </PageWrap>
  );
}

const getContent = (frames: CrashFrame[], os?: PlatformOs) => {
  if (!os) return <Fragment />;
  return (
    <Fragment>
      {frames.map((frame) => {
        return (
          <Styled.Row
            key={frame.id}
            $isBlamedApp={frame.is_blamed && frame.is_application}
            $isApp={frame.is_application && !frame.is_blamed}
          >
            <Styled.Column>{frame.index}</Styled.Column>
            <Styled.Column>{getSecondColumn(frame, os)}</Styled.Column>
            <Styled.Column>{getThirdColumn(frame, os)}</Styled.Column>
          </Styled.Row>
        );
      })}
    </Fragment>
  );
};

const getSecondColumn = (frame: CrashFrame, os: PlatformOs) => {
  if (os === PlatformOs.ANDROID) {
    return `${frame.symbol_name ? frame.symbol_name : frame.method}`;
  }

  return `${frame.object_name}`;
};

const getThirdColumn = (frame: CrashFrame, os: PlatformOs) => {
  if (os === PlatformOs.ANDROID) {
    return `${frame.file}: ${frame.line}`;
  }

  if (frame.method) {
    return `${frame.file ? frame.file : ""}
    ${frame.file && frame.line ? " - Line " + frame.line + " " : ""}
    ${frame.method + " + " + frame.line}`;
  }

  return `${frame.instruction_addr}
        ${frame.object_addr + " + " + (frame.instruction_addr - frame.object_addr)}
        ${frame.symbol_name ? frame.symbol_name + " + " + frame.offset : ""}`;
};
