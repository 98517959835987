import React, { useCallback, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { GridTable } from "components/GridTable/GridTable";
import { useModal } from "components/Modal/Modal";

import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import { WorkspaceData } from "models";
import { Invitation, isAdmin, Member, MemberRole } from "models/Member.model";

import identifiers from "util/identifiers.json";

import CustomizeTeamMemberModalBody from "../ModalBody/CustomizeTeamMember";
import InviteMembersModalBody from "../ModalBody/InviteMembersModalBody";
import { TeamMemberInvitationRow } from "../TeamMemberRows/TeamMemberInvitationRow";
import { TeamMemberRow } from "../TeamMemberRows/TeamMemberRow";

export interface Props {
  teamData: WorkspaceData | undefined;
  deleteMember: (userID: string) => Promise<void>;
  deleteMemberInvitation: (invitation: Invitation) => Promise<void>;
  inviteMembers: (emails: string[], role: MemberRole, appIds?: string[]) => Promise<void>;
  resendInvite: (invite: Invitation) => Promise<void>;
  customizeTeamMember: (role: MemberRole, selectedMember: Member, apps: string[]) => Promise<void>;
  deleteCurrentMember: (userID: string) => Promise<void>;
  loading: boolean;
}

export default function TeamMembersCard({
  teamData,
  deleteMember,
  deleteMemberInvitation,
  inviteMembers,
  resendInvite,
  customizeTeamMember,
  deleteCurrentMember,
  loading,
}: Props) {
  const {
    Modal: InviteMembersModal,
    toggle: toggleInvite,
    modalProps: inviteMembersInternal,
  } = useModal({ size: "full" });
  const {
    Modal: CustomizeRoleModal,
    toggle: toggleCustomize,
    modalProps: customizeRoleInternal,
  } = useModal({ size: "full" });
  const {
    Modal: DeleteMemberModal,
    toggle: toggleDelete,
    modalProps: deleteMemberInternal,
  } = useModal({ size: "narrow" });
  const {
    Modal: CancelInviteModal,
    toggle: toggleCancelInvite,
    modalProps: cancelInviteInternal,
  } = useModal({ size: "narrow" });

  const { userData } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();
  const isUserAdmin = isAdmin(selectedWorkspace?.role);

  const [selectedMember, setSelectedMember] = useState<Member>();
  const [selectedInvite, setSelectedInvite] = useState<Invitation>();

  useEffect(() => {
    if (location.hash === "#invite" && isUserAdmin) {
      if (isUserAdmin) {
        toggleInvite();
      }
      navigate(location.pathname);
    }
  }, [toggleInvite, isUserAdmin, location, navigate]);

  // todo this needs to be refactored in the scope of some other task
  const intl = useIntl();
  const t = (id: string) => {
    return intl.formatMessage({ id });
  };

  const didPressDeleteMember = (member: Member) => {
    setSelectedMember(member);
    toggleDelete();
  };

  const handleDeleteMember = (member: Member) => {
    const isCurrentUser = userData?.id === member.user.id;
    if (isCurrentUser) {
      deleteCurrentMember(member.user.id).then(() => {
        toggleDelete();
      });
    } else {
      deleteMember(member.user.id).then(() => {
        toggleDelete();
      });
    }
  };

  const didPressCustomizeMember = (member: Member) => {
    setSelectedMember(member);
    toggleCustomize();
  };

  const handleCustomizeTeamMember = useCallback(
    (role: MemberRole, apps: string[]) => {
      if (!selectedMember) return;

      customizeTeamMember(role, selectedMember, apps);
      toggleCustomize();
      setSelectedMember(undefined);
    },
    [customizeTeamMember, toggleCustomize, selectedMember],
  );

  const didPressCancelInvite = (invite: Invitation) => {
    setSelectedInvite(invite);
    toggleCancelInvite();
  };

  const handleDeleteMemberInvitation = useCallback(
    (invitation: Invitation) => {
      deleteMemberInvitation(invitation).then(() => {
        toggleCancelInvite();
      });
    },
    [deleteMemberInvitation, toggleCancelInvite],
  );

  const handleResendInvite = async (invite: Invitation) => {
    resendInvite(invite);
  };

  return (
    <>
      <AdministrationCard
        title={"My team members"}
        subtitle={"Manage admins and users from here. All seats are free."}
        testId={identifiers["members.myTeam.header"]}
        topLeftAccessory={
          <Button
            onClick={toggleInvite}
            size="small"
            disabled={!isUserAdmin}
            tooltip={{
              text: t("administration.members.teamMembers.button_tooltip"),
              position: "top",
              disabled: isUserAdmin,
            }}
            testId={identifiers["members.myTeam.button.invite"]}
          >
            {t("administration.members.teamMembers.button")}
          </Button>
        }
      >
        {teamData && selectedWorkspace && userData && (
          <GridTable gridTemplateColumns="repeat(1, minmax(20rem, auto)) repeat(1, minmax(10rem, auto)) repeat(2, auto)">
            {getTeamMembers(selectedWorkspace?.role, teamData.members, userData.id)
              .sort((a, b) => {
                if (a.user.id === userData?.id) return -1;
                if (b.user.id === userData?.id) return 1;
                return Number(isAdmin(b.role)) - Number(isAdmin(a.role));
              })
              .map((member) => (
                <TeamMemberRow
                  key={member.id}
                  member={member}
                  currentUserIsAdmin={isUserAdmin}
                  currentUserId={userData?.id || ""}
                  onSelectDeleteMember={() => didPressDeleteMember(member)}
                  onSelectCustomizeMember={() => didPressCustomizeMember(member)}
                />
              ))}
            {userData &&
              selectedWorkspace?.role !== MemberRole.GUEST &&
              teamData.invitations.map((invite) => (
                <TeamMemberInvitationRow
                  key={invite.id}
                  invitation={invite}
                  currentUserIsAdmin={isUserAdmin}
                  onCancelInvite={() => didPressCancelInvite(invite)}
                  handleResendInvite={() => handleResendInvite(invite)}
                />
              ))}
          </GridTable>
        )}
      </AdministrationCard>

      <InviteMembersModalBody
        handleInvite={inviteMembers}
        loading={loading}
        toggle={toggleInvite}
        Modal={InviteMembersModal}
        internalProps={inviteMembersInternal}
      />

      {selectedMember && (
        <>
          <CustomizeTeamMemberModalBody
            handleCustomizeTeamMember={handleCustomizeTeamMember}
            memberName={selectedMember.user.name ?? ""}
            currentMemberRole={selectedMember?.role}
            loading={loading}
            selectedApps={selectedMember?.apps}
            Modal={CustomizeRoleModal}
            internalProps={customizeRoleInternal}
          />

          <DeleteMemberModal
            {...deleteMemberInternal}
            heading={resolveDeleteMemberModalTitle(selectedMember, userData?.id || "", selectedWorkspace?.name || "")}
            subtitle={resolveDeleteMemberModalSubtitle(
              selectedMember,
              userData?.id || "",
              selectedWorkspace?.name || "",
            )}
            testId={identifiers["members.approvedDomain.dropdownButton.remove.header"]}
            buttonElement={
              <Button
                onClick={() => handleDeleteMember(selectedMember)}
                size="small"
                color="red"
                disabled={loading}
                testId={identifiers["members.approvedDomain.dropdownButton.remove.button"]}
                loadingText={loading && "Removing..."}
              >
                Yes, remove
              </Button>
            }
          />
        </>
      )}
      {selectedInvite && (
        <CancelInviteModal
          {...cancelInviteInternal}
          heading="Cancel invite?"
          subtitle={`Are your sure you want to cancel invite to ${selectedInvite.email}? Don't worry, Shake won't notify them.`}
          testId={identifiers["members.myTeam.user.pending.dropdownButton.customize.header"]}
          buttonElement={
            <Button
              onClick={() => handleDeleteMemberInvitation(selectedInvite)}
              size="small"
              disabled={loading}
              color="red"
              testId={identifiers["members.myTeam.user.pending.dropdownButton.customize.button"]}
              loadingText={loading && "Canceling invite..."}
            >
              Yes, cancel invite
            </Button>
          }
        />
      )}
    </>
  );
}

function resolveDeleteMemberModalTitle(member: Member, currentUserId: string, workspaceName: string) {
  return member.user.id === currentUserId ? "Just checkin'" : `Remove ${member.user.name} from ${workspaceName}`;
}

function resolveDeleteMemberModalSubtitle(member: Member, currentUserId: string, workspaceName: string) {
  return member.user.id === currentUserId
    ? `Are you sure you want to leave the ${workspaceName} workspace?`
    : `If you're sure you want to remove ${member.user.email} from this workspace, just click the magic button below.`;
}

function getTeamMembers(userRole: MemberRole, members: Member[], currentUserId: string) {
  if (userRole === MemberRole.GUEST) {
    return sortMembersByRole(
      members.filter(
        (member) =>
          member.role !== MemberRole.GUEST || (member.role === MemberRole.GUEST && member.user.id === currentUserId),
      ),
    );
  }
  return sortMembersByRole(members);
}

const sortMembersByRole = (members: Member[]) => {
  const order = Object.values(MemberRole);
  return members.sort((a, b) => order.indexOf(a.role) - order.indexOf(b.role));
};
