import React from "react";

import dependencyInjection from "util/dependencyInjection";

import { ConfirmSignupService } from "./services/ConfirmSignUpService";
import { ConfirmSignUpView } from "./views/ConfirmSignUpView";

interface Service {
  confirmSignUpService: ConfirmSignupService;
}

const confirmSignUpService = new ConfirmSignupService();

const [ConfirmSignUpDepProvider, useConfirmSignUpDeps] = dependencyInjection<Service>({
  services: { confirmSignUpService },
});

const ConfirmSignUpPage = () => (
  <ConfirmSignUpDepProvider>
    <ConfirmSignUpView />
  </ConfirmSignUpDepProvider>
);

export { ConfirmSignUpPage, useConfirmSignUpDeps };
