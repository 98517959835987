import styled, { css } from "styled-components";

import { vars } from "styles";

export const Button = styled.div`
  position: relative;
  width: 3.6rem;
  height: 2.4rem;
  background: ${vars.colors.grey60};
  border-radius: 1.2rem;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 1.2rem;
    left: 0.4rem;
    right: 0.4rem;
    top: 50%;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  cursor: pointer;
  display: none;

  &:checked + ${Button} {
    background: ${vars.colors.greenDarken};

    &:before {
      transform: translate(1rem, -50%);
    }
  }
  &:disabled + ${Button} {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const Label = styled.label<{ $disabled?: boolean }>`
  user-select: none;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  gap: 1rem;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}
`;
