import React, { PropsWithChildren } from "react";

import SentryIllustration from "assets/images/extensions/sentry-illustration.png";

import Heading from "components/Heading/Heading";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./ExtensionsCards.styles";
import { ToggleButton } from "../../../../components/ToggleButton/ToggleButton";

type SentryCardProps = {
  isSentryActive: boolean;
  handleActiveChange: () => void;
  disabled: boolean;
};

export const SentryCard = ({ isSentryActive, handleActiveChange, disabled }: PropsWithChildren<SentryCardProps>) => {
  return (
    <Styled.AdministrationCardWrap>
      <Flex $justifyContent="space-between">
        <div>
          <Styled.HeadingWrap>
            <ToggleButton
              checked={isSentryActive}
              onChange={handleActiveChange}
              disabled={disabled}
            />
            <Heading
              as="h3"
              heading3
              marginBottom={0}
            >
              Sentry
            </Heading>
          </Styled.HeadingWrap>
          <Styled.SubtitleWrap>See related Sentry logs for every Shake ticket.</Styled.SubtitleWrap>
        </div>
      </Flex>
      <Styled.Image
        src={SentryIllustration}
        alt="screenshot"
      />
    </Styled.AdministrationCardWrap>
  );
};
