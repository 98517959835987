import React, { useEffect, useRef, useState } from "react";

import { components, GroupBase, OptionProps } from "react-select";
import CreatableSelect from "react-select/creatable";

import Tooltip from "components/Tooltip/Tooltip";

import useOnClickOutside from "hooks/useOnClickOutside";

import { Tag as TagType } from "models/Tag.model";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import Tag from "../../Tag/Tag";
import { InputElementType } from "../Input";
import * as Styled from "./InputWithTags.styles";

const EMPTY_STATE_MESSAGE = "No tags available";

interface Props {
  commonTags: TagType[];
  selectedTags: TagType[];
  onAddTag: (tag: TagType) => void;
  onRemoveTag: (tagId: string) => void;
  testId?: string;
}

export interface InputWithTagsOption extends TagType {
  label: string;
  value: string;
}

export default function InputWithTags(props: Props) {
  const [selectedTags, setSelectedTags] = useState<TagType[]>(props.selectedTags);
  useEffect(() => {
    setSelectedTags(props.selectedTags);
  }, [props.selectedTags]);

  const [tagOptions, setTagOptions] = useState<InputWithTagsOption[]>(
    mapCommonTagsToOptions(props.commonTags, selectedTags),
  );
  useEffect(() => {
    const tagOptions = mapCommonTagsToOptions(props.commonTags, selectedTags).filter((option) => !option.active);
    setTagOptions(tagOptions);
  }, [props.commonTags, selectedTags]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const selectMenuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(selectMenuRef, () => clearInputAndSetIsMenuState(false));
  const toggleIsMenuOpen = () => clearInputAndSetIsMenuState(!isMenuOpen);

  function clearInputAndSetIsMenuState(isMenuOpen: boolean) {
    setInputValue("");
    setIsMenuOpen(isMenuOpen);
  }

  const handleSelectTag = (option: InputWithTagsOption | null) => {
    option && addTagAndUpdateState({ ...option, name: option.value, active: true });
  };

  function addTagAndUpdateState(newTag: TagType) {
    setSelectedTags([...selectedTags, newTag]);
    setTagOptions([...tagOptions, mapTagToOption(newTag)]);
    props.onAddTag(newTag);
    setIsMenuOpen(false);
  }

  const handleCreateTag = (inputValue: string) => {
    addTagAndUpdateState({ name: inputValue, active: true });
    setIsMenuOpen(false);
  };

  const handleDeleteTag = (tagName: string) => {
    setSelectedTags(selectedTags.filter((tag) => tag.name !== tagName));
    setTagOptions(tagOptions.filter((option) => option.name !== tagName));
    props.onRemoveTag(tagName);
  };

  return (
    <Styled.InputWithTagsWrap>
      {sortTags(selectedTags).map((tag) => (
        <Tag
          testId={identifiers["central.column.tag.active"]}
          color={getTagColor(tag.name)}
          key={tag.name}
          value={tag.name}
          onClear={handleDeleteTag}
          border
          closeable
          closeIconTestId={identifiers["central.column.tag.icon.delete"]}
        />
      ))}

      <Styled.SelectWrap ref={selectMenuRef}>
        <Tooltip
          text="Add more tags"
          position="top"
        >
          <Styled.DropdownIndicatorWrap onClick={toggleIsMenuOpen}>
            <Styled.DropdownIndicatorTagWrap test-id={identifiers["central.column.tag.button.add"]}>
              +
            </Styled.DropdownIndicatorTagWrap>
          </Styled.DropdownIndicatorWrap>
        </Tooltip>

        {isMenuOpen && (
          <Styled.DropdownWrap>
            <Styled.DropdownInputWrap
              elementType={InputElementType.INPUT}
              autoFocus
              placeholder="Search..."
              value={inputValue}
              data-testid={identifiers["central.column.tag.input.search"]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
            />
            <CreatableSelect
              menuIsOpen
              components={{ Control: () => null, Option }}
              styles={Styled.customSelectStyles}
              noOptionsMessage={() => EMPTY_STATE_MESSAGE}
              formatCreateLabel={(inputValue) => `Add new tag '${inputValue}'`}
              options={tagOptions}
              onChange={handleSelectTag}
              inputValue={inputValue}
              onCreateOption={handleCreateTag}
            />
          </Styled.DropdownWrap>
        )}
      </Styled.SelectWrap>
    </Styled.InputWithTagsWrap>
  );
}

function sortTags(tags: TagType[]) {
  return tags.sort((prev, next) => prev.name.localeCompare(next.name));
}

function mapCommonTagsToOptions(commonTags: { name: string }[], selectedTags: TagType[]): InputWithTagsOption[] {
  return commonTags
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name }) => ({
      name,
      label: name,
      value: name,
      active: selectedTags.some((selectedTag) => selectedTag.name === name),
    }));
}

function mapTagToOption(tag: TagType): InputWithTagsOption {
  return {
    ...tag,
    label: tag.name,
    value: tag.name,
  };
}

export const getTagColor = (tagName: string) => {
  switch (tagName) {
    case "bug":
      return vars.colors.red;
    case "suggestion":
      return vars.colors.mint;
    case "question":
      return vars.colors.sky;
    case "chat":
      return vars.colors.lemon;
    case "silent":
      return vars.colors.orange;
    default:
      return vars.colors.lavender;
  }
};

export const Option = (props: OptionProps<InputWithTagsOption, false, GroupBase<InputWithTagsOption>>) => {
  const { label } = props.data as InputWithTagsOption;

  return (
    <components.Option {...props}>
      <OptionComponent label={label} />
    </components.Option>
  );
};

const OptionComponent = ({ label }: { label: string }) => {
  return (
    <Styled.OptionContainer>
      <Flex
        $alignItems="center"
        $gap={0.8}
      >
        <Styled.ColorDot style={{ backgroundColor: getTagColor(label) }} />
        <span>{label}</span>
      </Flex>
    </Styled.OptionContainer>
  );
};
