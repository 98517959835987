import styled, { css } from "styled-components";

import { vars } from "styles";

export const PopoverContainer = styled.div`
  width: 72.6rem;
  /* height: 12.8rem; */

  padding: 1.6rem 0.4rem 0.6rem 0.4rem;

  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  background: ${vars.colors.grey100};

  box-shadow: 0px 2px 10px 0px #1c1c1e;
  z-index: 505;
`;

export const Popover = styled.div`
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.4rem;
`;

export const Button = styled.button<{ $isDelete?: boolean }>`
  width: 14rem;
  height: 3.6rem;
  display: flex;
  gap: 0.6rem;
  padding: 0.8rem 1rem;

  border: 1px solid transparent;
  background: ${vars.colors.grey100};

  p {
    cursor: pointer;
    user-select: none;

    ${(props) =>
      props.$isDelete &&
      css`
        color: ${vars.colors.red};
      `}
  }

  svg {
    width: 2rem;
    height: 2rem;
    path {
      stroke: ${vars.colors.grey30};
      ${(props) =>
        props.$isDelete &&
        css`
          stroke: ${vars.colors.red};
        `}
    }
  }

  &:hover {
    border-radius: 0.6rem;
    border: 1px solid ${vars.colors.grey80};
    background: ${vars.colors.grey90};

    p {
      color: ${vars.colors.white};
      ${(props) =>
        props.$isDelete &&
        css`
          color: ${vars.colors.red};
        `}
    }

    svg {
      path {
        stroke: ${vars.colors.white};
      }
    }

    ${(props) =>
      props.$isDelete &&
      css`
        border: 1px solid transparent;
        background: ${vars.colors.redLighter};
        svg {
          path {
            stroke: ${vars.colors.red};
          }
        }
      `};
  }
`;
