import { Validators } from "util/Validators";

export const validateVersionName = (name: string) => {
  if (!Validators.required(name)) {
    return new Error("Version name is required");
  }
};

export const validateVersionCode = (value: string) => {
  if (!Validators.required(value)) {
    return new Error("Version code is required");
  }
};
