import { useState } from "react";

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingProps,
  useInteractions,
} from "@floating-ui/react";

type Props = {
  initialOpen?: boolean;
  placement?: UseFloatingProps["placement"];
  middleware?: UseFloatingProps["middleware"];
  useClickInteraction?: boolean;
};
export function usePopover({
  placement = "bottom-start",
  middleware = [
    offset(5),
    shift({
      padding: 20,
    }),
    flip(),
  ],
  initialOpen = false,
  useClickInteraction = true,
}: Props) {
  const [open, setOpen] = useState(initialOpen);

  const useFloatingProps = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: internalOpenChanged,
    placement,
    middleware,
  });

  function internalOpenChanged(open: boolean) {
    setOpen(open);
    !open && useFloatingProps.context.events.emit("didClose");
  }

  const dismiss = useDismiss(useFloatingProps.context); // This accepts various dismissal options as second argument
  const click = useClick(useFloatingProps.context); // Buttons don't have to set the state manually

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, useClickInteraction ? click : undefined]);

  return {
    ...useFloatingProps,
    getReferenceProps,
    getFloatingProps,
    open,
    setOpen,
  };
}
