import styled, { css } from "styled-components";

import { vars } from "styles";

export const LargeButton = styled.button<{ $small?: boolean }>`
  display: flex;
  margin: 0.4rem;
  border-radius: 1rem;
  padding: 2.8rem 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  text-decoration: none;
  user-select: none;
  flex: 1 1 32%; /*grow | shrink | basis */

  ${(props) =>
    props.$small &&
    css`
      flex: none;
      width: 24%;
    `}

  &:active,
  &:focus-within,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    background: ${vars.colors.grey100};
  }

  p {
    cursor: pointer;
    user-select: none;
  }
`;

export const ButtonImage = styled.img`
  margin-bottom: 2.1rem;
  height: 3.6rem;
`;
