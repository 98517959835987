import React from "react";

import background from "assets/images/empty-state/users-module-empty-heading.png";

import CodeBlock, { Tab } from "components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useFlutterTabController,
  useIosTabController,
  useReactNativeTabController,
  useWebTabController,
} from "components/CodeBlock/useCodeTabsController";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";

import { UsersModuleDescriptionBox } from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import {
  androidUsersSetupSteps,
  flutterUsersSetupSteps,
  iosUsersSetupSteps,
  rnUsersSetupSteps,
  webUsersSetupSteps,
} from "pages/shared/components/ModuleEmptyState/helpers/usersSetupSteps";

import * as Styled from "./ModuleEmptyState.styles";
import { SetupStep } from "../SetupStep/SetupStep";

export const UsersModuleEmptyState = () => {
  const { selectedApp } = useAppSelectionContext();

  const androidTabController = useAndroidTabController();
  const iosTabController = useIosTabController();
  const reactNativeTabController = useReactNativeTabController();
  const flutterTabController = useFlutterTabController();
  const webTabController = useWebTabController();

  switch (selectedApp?.platform?.name) {
    case PlatformName.ANDROID:
      return (
        <UserModuleEmptyView
          codeBlockTabs={androidUsersSetupSteps.registerUserCode}
          tabController={androidTabController}
        />
      );
    case PlatformName.IOS:
      return (
        <UserModuleEmptyView
          codeBlockTabs={iosUsersSetupSteps.registerUserCode}
          tabController={iosTabController}
        />
      );
    case PlatformName.REACT_NATIVE:
      return (
        <UserModuleEmptyView
          codeBlockTabs={rnUsersSetupSteps.registerUserCode}
          tabController={reactNativeTabController}
        />
      );
    case PlatformName.FLUTTER:
      return (
        <UserModuleEmptyView
          codeBlockTabs={flutterUsersSetupSteps.registerUserCode}
          tabController={flutterTabController}
        />
      );
    case PlatformName.WEB:
      return (
        <UserModuleEmptyView
          codeBlockTabs={webUsersSetupSteps.registerUserCode}
          tabController={webTabController}
        />
      );
    default:
      return null;
  }
};

export interface Props {
  codeBlockTabs: Tab[];
  tabController: TabController;
}

const UserModuleEmptyView = ({ codeBlockTabs, tabController }: Props) => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <UsersModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Register app users"}
        />
        <Styled.StepContent>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlockTabs}
          />
          <Paragraph
            lineHeight={26}
            marginTop={12}
            marginBottom={60}
          >
            {"And that’s it — your app users will start appearing here as soon as they sign into your app."}
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};
