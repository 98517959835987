import { AxiosResponse } from "axios";

import { BasicWorkspace } from "models";

import ApiService from "services/Api.service";

export class WorkspaceCreateService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  createWorkspace(name: string): Promise<AxiosResponse<BasicWorkspace>> {
    return this.apiService.post({
      resource: "accounts/teams",
      data: { name },
    });
  }
}
