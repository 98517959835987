import styled from "styled-components";

import SelectDropdown from "components/SelectDropdown/SelectDropdown";

import { vars } from "styles";

export const CustomSelectDropdownContainer = styled.div`
  margin-bottom: 2rem;
  width: 49rem;
`;

export const CustomSelectDropdown = styled(SelectDropdown)`
  .react-select__menu {
    background-color: ${vars.colors.grey100};
  }
  .react-select__menu-list {
    max-height: 23.1rem;
  }
  .react-select__option {
    background-color: ${vars.colors.grey100};
    padding-left: 1.2rem;
    font-size: 1.4rem;
    font-weight: normal,
    color: ${vars.colors.grey30};
  }
  .react-select__group:not(:last-child) {
    border-bottom: 0.1rem solid ${vars.colors.grey90};
  }
`;

export const NoAppsCard = styled.div`
  padding: 3.2rem;
  border-radius: 2rem;
  background-color: ${vars.colors.grey100};
  margin-bottom: 1.2rem;
`;
