import { AxiosResponse } from "axios";

import { PaginationParameters, Tag } from "models";

import ApiService from "./Api.service";

export default class TagsService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getCommonTags(
    teamId: string,
    paginationParameters?: PaginationParameters,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<Tag[]>> {
    return this.apiService.get({
      resource: `issue_tracking/${teamId}/tags/common`,
      config: {
        params: paginationParameters,
        signal: abortSignal,
      },
    });
  }
}
