import React, { Fragment } from "react";

import { generatePath, Link, useNavigate } from "react-router-dom";

import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "router/config/routePaths";

import * as Styled from "./WebBanner.styles";

export default function WebBanner() {
  const { selectedWorkspace } = useAppSelectionContext();

  const navigate = useNavigate();

  if (!selectedWorkspace) return <Fragment />;

  return (
    <Styled.WebBanner
      onClick={() => {
        navigate(
          generatePath(RoutePaths.ADD_APP, {
            workspaceSlug: selectedWorkspace?.slug,
          }),
        );
      }}
    >
      <Paragraph>Shake for websites is out. Click here to add it to your website — free!</Paragraph>
    </Styled.WebBanner>
  );
}
