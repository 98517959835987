import React, { useEffect } from "react";

import { generatePath, Link } from "react-router-dom";

import { CenteredForm } from "components/CenteredForm/CenteredForm";
import { useModal } from "components/Modal/Modal";

import ResetPasswordForm from "pages/ResetPassword/components/ResetPasswordForm";
import { useResetPasswordApiConsumer } from "pages/ResetPassword/consumers/useResetPasswordApiConsumer";
import CheckInbox from "pages/shared/components/Forms/CheckInbox/CheckInbox";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import LinkExpiredModalBody from "../components/LinkExpiredModalBody";

export const ResetPasswordView = () => {
  const { requestPasswordResetLink, email, linkExpired, linkSent, resetCode } = useResetPasswordApiConsumer();

  const {
    Modal: LinkExpiredModal,
    setActive,
    modalProps,
  } = useModal({
    size: "narrow",
  });

  useEffect(() => {
    setActive(linkExpired && !linkSent);
  }, [linkExpired, linkSent, setActive]);

  return (
    <>
      <CenteredForm>
        {linkSent ? (
          <CheckInbox
            resendEmail={() => requestPasswordResetLink(true)}
            email={email}
            subtitleText={`We've sent an email to ${email} with instructions on how to reset your password.`}
            footerLinkContent={
              <>
                I don&apos;t want to do anything here, go back to{" "}
                <Link to={generatePath(RoutePaths.SIGNIN)}>sign in</Link> page.
              </>
            }
          />
        ) : (
          <ResetPasswordForm
            resetCode={resetCode}
            shouldAllowSubmit={() => {
              if (linkExpired) {
                setActive(true);
                return false;
              }
              return true;
            }}
          />
        )}
      </CenteredForm>

      <LinkExpiredModal
        {...modalProps}
        heading={t("resetPassword.modal.heading")}
        subtitle={`For your safety, links which could change your password are set to be valid only for 1 day or 1 use. The one you clicked on from ${email} has expired.`}
        className="invite-members"
      >
        <LinkExpiredModalBody
          requestPasswordResetLink={() => requestPasswordResetLink(false)}
          email={email}
        />
      </LinkExpiredModal>
    </>
  );
};
