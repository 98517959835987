import React, { Fragment } from "react";

import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import PushNotificationsSettingsCard from "pages/Administration/components/AppsCards/PushNotificationsSettingsCard";
import NoAppsView from "pages/Administration/components/NoAppsView";
import useAppsUsersViewConsumer from "pages/Administration/consumers/useAppsUsersViewConsumer";

export default function AppsUsersView(): JSX.Element {
  const { loading: appsLoading } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const { allApps, localSelectedApp, certificate, refetchCertificate, deleteCertificate } = useAppsUsersViewConsumer();

  if (!appsLoading && allApps.length === 0) {
    <NoAppsView selectedWorkspace={selectedWorkspace} />;
  }

  if (!localSelectedApp) {
    return <Spinner />;
  }

  return (
    <PageWrap style={{ marginTop: "4rem" }}>
      <Fragment>
        <PushNotificationsSettingsCard
          selectedDropdownApp={localSelectedApp}
          certificate={certificate}
          getCertificate={refetchCertificate}
          deleteCertificate={deleteCertificate}
          isSample={localSelectedApp.is_sample}
        />
      </Fragment>
    </PageWrap>
  );
}
