import { useCallback, useMemo, useState } from "react";

import { LogUnionRowItem, RowType } from "components/MasterTable/models/MasterTableModel";
import { useActivityTable } from "components/ShakeTable/modules/useActivityTable";

import { Attribute, UseModuleAttributes } from "hooks/filtering/sharedTypes";
import { useShakePagination } from "hooks/useShakePaginaton";

import { generateSortedLogs, LogType, LogUnionType } from "models/eventTracking";

import { useCrashesDeps } from "pages/Crashes/Crashes";
import { useUserFeedbackDeps } from "pages/UserFeedback/UserFeedback";

import {
  getStringLogMessage,
  iconSrcForLog,
  messageForLog,
} from "../components/ActivityHistoryTable/ActivityHistoryHelpers";

interface Props {
  teamId?: string;
  appId?: string;
  ticketKey?: string;
  type: TicketType;
  hasLogs?: boolean;
  attributesProps: UseModuleAttributes;
}

type TicketType = "crash" | "user_feedback";

interface LogMap {
  idToLogMap: Map<string, LogUnionType>;
  idToIndexMap: Map<string, number>;
  indexToIdMap: Map<number, string>;
}

export function useActivityHistoryApiConsumer({ teamId, appId, ticketKey, type, hasLogs, attributesProps }: Props) {
  const { userFeedbackService } = useUserFeedbackDeps();
  const { crashesService } = useCrashesDeps();
  const [detailLog, setDetailLog] = useState<LogUnionType>();

  const pagination = useShakePagination<LogUnionType, LogUnionRowItem>({
    deps: [teamId, appId, ticketKey, type, hasLogs],
    fetchFn: ({ signal, deps }) => {
      /// TODO pagination not ready on server

      const workspaceID = deps[0] as string;
      const appID = deps[1] as string;
      const ticketKey = deps[2] as string;

      const type = deps[3] as TicketType;

      switch (type) {
        case "crash":
          return crashesService
            .getActivityHistory(workspaceID, appID, ticketKey, signal)
            .then((res) => generateSortedLogs(res.data).reverse())
            .then((logs) => logs.map(mapLogToTableRowItem))
            .then((data) => {
              return { total: 0, items: data };
            });

        case "user_feedback":
          return userFeedbackService
            .getActivityHistory(workspaceID, appID, ticketKey, signal)
            .then((res) => generateSortedLogs(res.data).reverse())
            .then((logs) => logs.map(mapLogToTableRowItem))
            .then((data) => {
              return { total: 0, items: data };
            });
      }
    },
    enabled: (deps) => deps.every((d) => Boolean(d)),
    queryKey: `activityHistory ${appId}`,
  });

  const finalItems = useMemo(() => {
    if (!Boolean(attributesProps.validAttributes.length)) {
      return pagination.data && !pagination.data[0] ? undefined : pagination.data;
    }

    // eslint-disable-next-line
    return getFilteredData(attributesProps.validAttributes, pagination?.data as any);
  }, [attributesProps.validAttributes, pagination.data]);

  const activityTableProps = useActivityTable({
    // eslint-disable-next-line
    data: (finalItems as any) ?? [],
    onRowClick: (item) => setDetailLog(getLogWithID(item.id)),
  });

  const logMap = useMemo<LogMap>(() => {
    if (!finalItems) {
      return {
        idToLogMap: new Map(),
        idToIndexMap: new Map(),
        indexToIdMap: new Map(),
      };
    }
    return {
      idToLogMap: new Map(finalItems.map((log) => [log?.id, log])),
      idToIndexMap: new Map(finalItems.map((log, index) => [log?.id, index])),
      indexToIdMap: new Map(finalItems.map((log, index) => [index, log?.id])),
    };
  }, [finalItems]);

  const getLogWithID = useCallback(
    (logId: string) => {
      return logMap.idToLogMap.get(logId);
    },
    [logMap],
  );

  const getNextLog = useCallback(
    (currentLogId: string) => {
      const currentLogIndex = logMap.idToIndexMap.get(currentLogId);

      const nextLogIndex =
        currentLogIndex !== undefined && logMap.idToIndexMap.size > currentLogIndex && currentLogIndex + 1;

      const nextLogId = nextLogIndex && logMap.indexToIdMap.get(nextLogIndex);

      return nextLogId && logMap.idToLogMap.get(nextLogId);
    },
    [logMap],
  );

  const getPreviousLog = useCallback(
    (currentLogId: string) => {
      const currentLogIndex = logMap.idToIndexMap.get(currentLogId);

      const previousLogIndex = currentLogIndex !== undefined && logMap.idToIndexMap.size - 1 > 0 && currentLogIndex - 1;

      const previousLogId =
        previousLogIndex !== undefined && previousLogIndex !== false && logMap.indexToIdMap.get(previousLogIndex);

      return previousLogId && logMap.idToLogMap.get(previousLogId);
    },
    [logMap],
  );

  return {
    getLogWithID,
    getNextLog,
    getPreviousLog,
    items: finalItems,
    loading: pagination.isLoading,
    loadNext: pagination.loadNext,
    hasMore: pagination.hasMore,
    activityTableProps,
    detailLog,
    setDetailLog,
    error: pagination.isError,
  };
}

const mapLogToTableRowItem = (log: LogUnionType): LogUnionRowItem => {
  return {
    ...log,
    timestamp: log.timestamp,
    logIcon: () => iconSrcForLog(log),
    messageElement: () => messageForLog(log),
    row_type: RowType.LOG_UNION,
    response_body: log.instance_type === LogType.NETWORK_REQUEST ? log.response_body : "",
    request_body: log.instance_type === LogType.NETWORK_REQUEST ? log.request_body : "",
  };
};

const getFilteredData = (validAttributes: Array<Required<Attribute>>, items?: LogUnionRowItem[]) => {
  const filteredDataByType = getFilteredDataByLogType(validAttributes, items);
  const filteredDataByTitle = getFilteredDataByTitle(validAttributes, filteredDataByType);
  const filteredDataByResponse = getFilteredDataByResponseBody(validAttributes, filteredDataByTitle);
  const filteredData = getFilteredDataByRequestBody(validAttributes, filteredDataByResponse);

  return filteredData;
};

const getFilteredDataByLogType = (validAttributes: Array<Required<Attribute>>, items?: LogUnionRowItem[]) => {
  const filteredDataByType = [] as LogUnionRowItem[];
  if (!Boolean(validAttributes.find((attribute) => attribute.field_name === "log_type"))) return items;
  items?.map((item) =>
    validAttributes
      .filter((attribute) => attribute.field_name === "log_type")
      .map((attribute) => {
        if (item?.instance_type === attribute.filter?.fieldValue) {
          filteredDataByType.push(item);
        }
      }),
  );

  if (Boolean(filteredDataByType.length)) return filteredDataByType;
  return [];
};

const getFilteredDataByTitle = (validAttributes: Array<Required<Attribute>>, data?: LogUnionRowItem[]) => {
  const filteredData = [] as LogUnionRowItem[];
  if (!Boolean(validAttributes.find((attribute) => attribute.field_name === "message"))) {
    return data;
  } else {
    data?.map((item) =>
      validAttributes
        .filter((attribute) => attribute.field_name === "message")
        .map((attribute) => {
          if (
            attribute.filter?.fieldValue &&
            getStringLogMessage(item).toLocaleLowerCase().includes(attribute.filter?.fieldValue.toLocaleLowerCase())
          ) {
            filteredData.push(item);
          }
        }),
    );
  }
  return filteredData;
};

const getFilteredDataByResponseBody = (validAttributes: Array<Required<Attribute>>, data?: LogUnionRowItem[]) => {
  const filteredData = [] as LogUnionRowItem[];
  if (!Boolean(validAttributes.find((attribute) => attribute.field_name === "response_body"))) {
    return data;
  } else {
    data?.map((item) =>
      validAttributes
        .filter((attribute) => attribute.field_name === "response_body")
        .map((attribute) => {
          if (
            attribute.filter?.fieldValue &&
            item.response_body?.toLocaleLowerCase().includes(attribute.filter?.fieldValue.toLocaleLowerCase())
          ) {
            filteredData.push(item);
          }
        }),
    );
  }
  return filteredData;
};

const getFilteredDataByRequestBody = (validAttributes: Array<Required<Attribute>>, data?: LogUnionRowItem[]) => {
  const filteredData = [] as LogUnionRowItem[];
  if (!Boolean(validAttributes.find((attribute) => attribute.field_name === "request_body"))) {
    return data;
  } else {
    data?.map((item) =>
      validAttributes
        .filter((attribute) => attribute.field_name === "request_body")
        .map((attribute) => {
          if (
            attribute.filter?.fieldValue &&
            item.request_body?.toLocaleLowerCase().includes(attribute.filter?.fieldValue.toLocaleLowerCase())
          ) {
            filteredData.push(item);
          }
        }),
    );
  }
  return filteredData;
};
