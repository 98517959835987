import { css } from "styled-components";

import { vars } from "../abstracts/vars";
import { Theme } from "../themes/Theme.model";
import "./fonts.css";

export const typography = css<{ theme: Theme }>`
  body {
    color: ${vars.colors.white};
    font-family: ${vars.fonts.fontFamily};
    font-size: ${vars.fonts.fontSize};
    font-weight: ${vars.fonts.fontWeight};
    line-height: ${vars.fonts.lineHeight};
    -webkit-font-smoothing: antialiased;
  }

  input,
  select,
  textarea,
  button,
  pre {
    font-family: inherit; // form elements don't automatically inherit font-family
  }

  a,
  p {
    color: ${vars.colors.grey40};
  }

  a,
  button {
    color: ${vars.colors.white};
    text-decoration: none;
    cursor: pointer;
    transition: ${vars.transitions.hover};
  }

  a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
`;
