import { useState } from "react";

import { useAppDeps } from "App.dependencies";
import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useForm } from "hooks/useForm";

import { useUpdateSelectedWorkspace } from "layouts/views/WorkspaceRootLayout";

import { Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";

import { validateBundleId } from "util/ValidatorFunctions";

enum ErrorStatusCodes {
  BUNDLE_ID_ALREADY_IN_USE = 409,
}

interface AddAppFormState {
  appName: string;
  bundleId: string | null;
}

const AddAppInitialState: AddAppFormState = {
  appName: "",
  bundleId: null,
};

const validators = {
  bundleId: validateBundleId,
};

interface Props {
  platformId: string;
}

export const useAddAppConsumer = ({ platformId }: Props) => {
  const { appsService } = useAppDeps();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  const [loading, setLoading] = useState(false);

  const addApp = async () => {
    if (!selectedWorkspace || !platformId) return;

    setLoading(true);

    try {
      const addedApp = await appsService.addApp(
        (selectedWorkspace as Workspace).id,
        formState.appName,
        formState.bundleId,
        platformId,
      );

      /// Will cause a duplicate request. Kept in case of sockets stop
      await updateSelectedWorkspace(selectedWorkspace);

      navigate(
        generatePath(RoutePaths.USER_FEEDBACK, {
          workspaceSlug: selectedWorkspace.slug,
          appKey: addedApp.key,
        }),
      );
    } catch (error) {
      if (error.response && error.response.status === ErrorStatusCodes.BUNDLE_ID_ALREADY_IN_USE) {
        setError({ bundleId: Error("Please choose another bundle ID, this one already exist.") });
        return;
      }

      displayToast({
        title: "Something went wrong",
        content:
          error?.response?.data?.message.json && error?.response?.data?.message.json.bundle_id
            ? error?.response?.data?.message.json.bundle_id
            : error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AddAppFormState>({
    initialState: AddAppInitialState,
    onSubmit: addApp,
    validators,
  });

  return { addApp, loading, handleFormChange, formState, formError, handleFormSubmit, setError };
};
