import styled from "styled-components";

import Button from "components/Button/Button";

import { Form, FormLinks } from "pages/shared/styles/Form.styles";

export const GoogleSignUpButton = styled(Button)`
  margin: 3rem 0;

  img {
    margin-right: 0.5rem;
  }
`;

export const SignUpForm = styled(Form)``;

export const SignUpFormLinks = styled(FormLinks)``;
