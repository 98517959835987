import { Link as BaseLink } from "react-router-dom";
import styled from "styled-components";

import BaseCard from "components/Card/Card";
import BaseParagraph from "components/Paragraph/Paragraph";
import TagComponent from "components/Tag/Tag";

import { RemoveBackground as BaseRemoveBackground } from "hooks/filtering/ui/Attributes.styles";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export const UserTicketCards = styled.div`
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Card = styled(BaseCard)`
  cursor: pointer;
  width: 54rem;
  height: 12.8rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  padding: 2rem;
  text-decoration: none;

  > div {
    margin: 0rem;
    padding: 0rem;
  }

  p {
    cursor: pointer;
    user-select: none;
  }

  img {
    width: 4.1rem;
    height: 8.8rem;
    object-fit: cover;
    border-radius: 0.4rem;
  }
`;

export const Paragraph = styled(BaseParagraph)`
  width: 35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NotificationContainer = styled(Flex)`
  width: 2rem;
  margin-right: 2rem;
`;

export const NotificationIndicator = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background-color: ${vars.colors.red};

  transform: translate(-50%, -50%);
`;

export const Tag = styled(TagComponent)`
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
`;

export const Tags = styled(Flex)`
  height: 2.4rem;
  max-width: 45rem;
  overflow: hidden;
`;

export const Link = styled(BaseLink)`
  width: 54rem;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus-within {
    text-decoration: none;
  }
`;

export const RemoveBackground = styled(BaseRemoveBackground)`
  right: 6.1rem;
  top: inherit;
`;
