import React from "react";

import { Navigate } from "react-router-dom";

import { useAuthContext } from "context/Auth/Auth.context";

import { RoutePaths } from "router/config/routePaths";

export interface ReturnUrlState {
  returnUrl?: string;
}

interface Props {
  children: JSX.Element;
}

export default function AuthProtected({ children }: Props) {
  const { userToken } = useAuthContext();

  const currentPath = location.pathname;

  if (!userToken) {
    return (
      <Navigate
        to={RoutePaths.SIGNIN}
        state={{ returnUrl: currentPath } as ReturnUrlState}
      />
    );
  }

  return <>{children}</>;
}
