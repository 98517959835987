import React from "react";

import Paragraph from "components/Paragraph/Paragraph";
import Tooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";

import * as Styled from "./Sidebar.styles";

interface Props {
  to: string;
  tooltipText?: string;
  icon: JSX.Element;
  testId?: string;
  disabled?: boolean;
  banner?: boolean;
  label?: string;
}

export default function SidebarLink({ to, tooltipText, icon, testId, disabled, banner, label }: Props) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) return e.preventDefault();
  };

  return (
    <Tooltip
      position="right"
      offset={1.4}
      text={tooltipText ? tooltipText : ""}
      disabled={!tooltipText}
    >
      <Styled.NavLink
        $disabled={disabled}
        to={to}
        data-testid={testId}
        onClick={handleClick}
      >
        <li>
          {icon}
          <Styled.Label>{label}</Styled.Label>
        </li>

        {banner && (
          <Styled.NewBanner>
            <Paragraph
              color={vars.colors.black}
              fontSize={12}
            >
              New
            </Paragraph>
          </Styled.NewBanner>
        )}
      </Styled.NavLink>
    </Tooltip>
  );
}
