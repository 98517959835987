import React, { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { useModal } from "components/Modal/Modal";

import { App, Workspace } from "models";

import { DomainRow } from "./DomainRow";
import { GridTable } from "../../../../../components/GridTable/GridTable";
import { useShakingAnimation } from "../../../../../context/ShakingAnimation/ShakingAnimationContext";
import { Domain } from "../../../../../models/Domain.model";
import { isAdmin } from "../../../../../models/Member.model";
import AddAppDomainsModalBody from "../../ModalBody/AddAppDomainsModalBody";
import DeleteDomainModalBody from "../../ModalBody/DeleteDomainModalBody";

interface DomainsCardProps {
  app: App;
  loading: boolean;
  addDomains: (domains: string[]) => Promise<void>;
  deleteDomain: (id: string) => Promise<void>;
  selectedWorkspace?: Workspace;
}

export default function DomainsCard({
  loading,
  app,
  addDomains,
  deleteDomain,
  selectedWorkspace,
}: DomainsCardProps): JSX.Element {
  const { domains } = app;
  const { triggerShake } = useShakingAnimation();
  const navigate = useNavigate();
  const [selectedDomain, setSelectedDomain] = useState<Domain>();
  const { Modal: DeleteDomainModal, toggle, modalProps } = useModal({ size: "narrow" });
  const { Modal: AddDomainModal, toggle: toggleAddDomain, modalProps: addDomainInternal } = useModal({ size: "full" });

  useEffect(() => {
    if (location.hash === "#addUrls" && isAdmin(selectedWorkspace?.role)) {
      if (isAdmin(selectedWorkspace?.role)) {
        toggleAddDomain();
      }
      navigate(location.pathname);
    }
  }, [toggleAddDomain, navigate, selectedWorkspace?.role]);

  const handleDomainDelete = async () => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    if (!selectedDomain) return;
    await deleteDomain(selectedDomain.id);
    toggle();
  };

  const handleDomainCreate = async (domains: string[]) => {
    if (app.is_sample) {
      triggerShake("AddAppButton");
      return;
    }
    await addDomains(domains);
  };

  const tooltipDisabled = app.active && isAdmin(selectedWorkspace?.role) && !app.is_sample;
  const tooltipText = app.is_sample
    ? "Sample app can't be modified"
    : !isAdmin(selectedWorkspace?.role)
    ? "Only admins can add Domains"
    : "Domains can be added on active apps only";

  return (
    <Fragment>
      <AdministrationCard
        title={"Permitted URLs"}
        subtitle={"Only tickets from the specified URLs will be accepted. Leave blank to accept tickets from any URL."}
        topLeftAccessory={
          <Button
            size="small"
            onClick={() => (app.is_sample ? triggerShake("AddAppButton") : toggleAddDomain())}
            disabled={loading || !app.active || !isAdmin(selectedWorkspace?.role)}
            loadingText={loading && "Adding..."}
            tooltip={{
              text: tooltipText,
              disabled: tooltipDisabled,
              position: "top",
            }}
          >
            Add URLs
          </Button>
        }
      >
        {domains && (
          <GridTable gridTemplateColumns="repeat(2, auto)">
            {domains.map((domain) => {
              return (
                <DomainRow
                  key={domain.id}
                  setSelectedDomain={setSelectedDomain}
                  toggleDelete={toggle}
                  domain={domain}
                  isAdmin={isAdmin(selectedWorkspace?.role)}
                />
              );
            })}
          </GridTable>
        )}
      </AdministrationCard>

      <AddAppDomainsModalBody
        handleAdd={handleDomainCreate}
        loading={loading}
        toggle={toggleAddDomain}
        Modal={AddDomainModal}
        internalProps={addDomainInternal}
      />

      <DeleteDomainModalBody
        deleteApiKey={handleDomainDelete}
        loading={loading}
        Modal={DeleteDomainModal}
        internalProps={modalProps}
      />
    </Fragment>
  );
}
