import React, { useState } from "react";

import { Row } from "@tanstack/react-table";

import Button from "components/Button/Button";
import MessageInput from "components/Input/MessageInput/MessageInput";
import { ModalHeader } from "components/Modal/ModalHeader/ModalHeader";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import { docLink } from "util/docs";

import * as Styled from "./ModalBody.styles";

export function NoteMessageModalBody<TData>({
  title,
  confirmAction,
  isNote,
  close,
  selectedRows,
  numOfAppUsers,
}: {
  title: string;
  confirmAction: (rows: TData[], message: string, isNote?: boolean) => void;
  isNote?: boolean;
  close: () => void;
  selectedRows: Row<TData>[];
  numOfAppUsers?: number;
}) {
  const { selectedApp } = useAppSelectionContext();
  const platform = Object.values(PlatformName).find((value) => value === selectedApp?.platform.name);
  const [message, setMessage] = useState<string>("");

  return (
    <Flex $column>
      <Styled.HeaderContainer>
        <ModalHeader
          heading={title}
          noContent
        />
      </Styled.HeaderContainer>
      <Styled.Container>
        <Styled.MessageContainer>
          {!isNote && (
            <Styled.Helper>
              This message will be sent for selected tickets that have App users{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={docLink(platform).registerAppUser}
              >
                registered
              </a>
              .
            </Styled.Helper>
          )}
          <MessageInput
            withoutSendButton={true}
            placeholder={
              !isNote
                ? "Reply to the app user"
                : `Leave an internal note — such as "Is this ready for the 2.1.5 release?" or "Check with the backend team first" — visible only to you and your teammates`
            }
            onChange={setMessage}
            sendButtonText={!isNote ? "Send" : "Add note"}
          />
        </Styled.MessageContainer>
        <Styled.ButtonContainer>
          <Button
            type="submit"
            size="small"
            onClick={() => {
              confirmAction(
                selectedRows.map((row) => row.original),
                message,
                isNote,
              );
              close();
            }}
            tooltip={{
              text: numOfAppUsers === 0 ? "No users to send a message to" : "Please enter message first",
              position: "top",
              disabled: Boolean(message) && Boolean(!numOfAppUsers || (numOfAppUsers && numOfAppUsers > 0)),
            }}
            disabled={!message || numOfAppUsers === 0}
          >
            {isNote ? "Add note" : "Send message"}
          </Button>
        </Styled.ButtonContainer>
      </Styled.Container>
    </Flex>
  );
}
