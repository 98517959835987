import React from "react";

import * as Styled from "./ToggleButton.styles";

interface ToggleButtonProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  testId?: string;
  name?: string;
  className?: string;
  id?: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  checked,
  onChange,
  disabled,
  testId,
  name,
  className,
}) => {
  return (
    <Styled.Label
      $disabled={disabled}
      className={className}
    >
      <Styled.Input
        id={id}
        name={name}
        checked={checked}
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
      />
      <Styled.Button data-testid={testId} />
    </Styled.Label>
  );
};
