import React from "react";

import dependencyInjection from "util/dependencyInjection";

import { ForgotPasswordService } from "./services/ForgotPassword.service";
import { ForgotPasswordView } from "./views/ForgotPasswordView";

interface Services {
  forgotPasswordService: ForgotPasswordService;
}

const forgotPasswordService = new ForgotPasswordService();

const [ForgotPasswordDependencyProvider, useForgotPasswordDeps] = dependencyInjection<Services>({
  services: { forgotPasswordService },
});

const ForgotPasswordPage = () => (
  <ForgotPasswordDependencyProvider>
    <ForgotPasswordView />
  </ForgotPasswordDependencyProvider>
);

export { ForgotPasswordPage, useForgotPasswordDeps };
