export enum ThemeMode {
  DARK = "Dark",
  LIGHT = "Light",
}

export interface Theme {
  colorPrimary: string;
  colorPrimaryDarken: string;
  colorSecondary: string;
  colorSecondaryDarken: string;
  colorBackground: string;
  colorSurface: string;
  colorText: string;
  colorError: string;
}
