import React from "react";

import { generatePath, Link } from "react-router-dom";

import Button from "components/Button/Button";
import Input from "components/Input/Input";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import * as Styled from "./ForgotPasswordForm.styles";
import identifiers from "../../../util/identifiers.json";
import { useForgotPasswordFormConsumer } from "../consumers/useForgotPasswordFormConsumer";

interface Props {
  setResetLinkState: (state: { email: string; isLinkSent: boolean }) => void;
}

export default function ForgotPasswordForm({ setResetLinkState }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useForgotPasswordFormConsumer({
    setResetLinkState,
  });

  const { email } = formState;
  const { email: emailError } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }
  const isButtonDisabled = loading || !email;

  return (
    <>
      <FormTitle
        title={t("forgotPassword.form.heading.title")}
        subtitle={t("forgotPassword.form.subtitle")}
        titleTestId={identifiers["forgotPassword.header.text"]}
        subtitleTestId={identifiers["forgotPassword.header.paragraph"]}
      />

      <Styled.ForgotPasswordForm
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Input
          name="email"
          label="Email"
          type="email"
          disabled={loading}
          value={email}
          error={!!emailError}
          helper={emailError && emailError.message}
          onChange={handleChange}
          testId={identifiers["forgotPassword.input.email"]}
          autoFocus
        />
        <Button
          type="submit"
          disabled={isButtonDisabled}
          size="full"
          testId={identifiers["forgotPassword.button.requestResetLink"]}
          tooltip={{
            position: "top",
            text: "Please enter your email first",
            disabled: Boolean(email),
          }}
          loadingText={loading && "Reseting..."}
        >
          {t("forgotPassword.form.button.reset")}
        </Button>
      </Styled.ForgotPasswordForm>

      <Styled.ForgotPasswordFormLinks>
        <p>
          {t("forgotPassword.form.span.signIn")}&nbsp;
          <Link
            to={generatePath(RoutePaths.SIGNIN)}
            data-testid={identifiers["forgotPassword.link.signIn"]}
          >
            {t("forgotPassword.form.link.signIn")}
          </Link>
          .
        </p>
      </Styled.ForgotPasswordFormLinks>
    </>
  );
}
