import React, { useEffect, useState } from "react";

import { generatePath } from "react-router-dom";

import AddAppIcon from "assets/images/useful-links/add-new-app-icon@2x.png";
import AskAIIcon from "assets/images/useful-links/ask-ai-icon@2x.png";
import HelpCenter from "assets/images/useful-links/help-center-icon@2x.png";
import LiveChat from "assets/images/useful-links/private-live-chat-icon@2x.png";
import Roadmap from "assets/images/useful-links/roadmap-icon@2x.png";
import Docs from "assets/images/useful-links/sdk-docs-icon@2x.png";
import Slack from "assets/images/useful-links/slack-community-icon@2x.png";
import StatusPage from "assets/images/useful-links/status-page-icon@2x.png";

import Paragraph from "components/Paragraph/Paragraph";

import { UsefulLink } from "models/UsefulLink.model";

import * as Styled from "pages/Home/views/UsefulLinks/UsefulLinks.styles";
import { useWorkspaceAppParams } from "pages/IntegrationActivation/helpers/useWorkspaceAppParams";

import { RoutePaths } from "router/config/routePaths";

import { vars } from "styles";

export default function UsefulLinks() {
  const { workspaceSlug } = useWorkspaceAppParams();
  const [usefulLinks, setUsefulLinks] = useState<UsefulLink[]>([]);

  useEffect(() => {
    const addAppRoute = generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null });
    setUsefulLinks(createUsefulLinks(addAppRoute));
  }, [workspaceSlug]);

  return (
    <Styled.UsefulLinksContainer>
      {usefulLinks.map((link, index) => {
        return (
          <Styled.UsefulLink
            key={index}
            href={link.link}
            target={link.newTab ? "_blank" : undefined}
            rel="noreferrer"
          >
            <img
              width={36}
              height={36}
              src={link.image}
            />
            <Paragraph
              color={vars.colors.grey30}
              style={{ paddingTop: "2rem", paddingBottom: "0.4rem" }}
            >
              {link.title}
            </Paragraph>
            <Paragraph
              fontSize={14}
              style={{ maxWidth: "19.7rem", textAlign: "center" }}
            >
              {link.subtitle}
            </Paragraph>
          </Styled.UsefulLink>
        );
      })}
    </Styled.UsefulLinksContainer>
  );
}

const createUsefulLinks = (addAppRoute: string) => {
  return [
    {
      image: AskAIIcon,
      title: "Ask AI",
      subtitle: "Our chatbot knows everything from docs and more",
      link: "javascript:window.openInkeep()",
      newTab: false,
    },
    {
      image: Docs,
      title: "SDK docs",
      subtitle: "Installation and all customizations in one place",
      link: "https://docs.shakebugs.com/docs/",
      newTab: true,
    },
    {
      image: HelpCenter,
      title: "Help center",
      subtitle: "Answers to 90 most commonly asked questions",
      link: "https://help.shakebugs.com/en/",
      newTab: true,
    },
    {
      image: Roadmap,
      title: "Roadmap",
      subtitle: "Vote because we prioritize based on users’ wishes",
      link: "https://feedback.shakebugs.com/",
      newTab: true,
    },
    {
      image: Slack,
      title: "Slack community",
      subtitle: "Come say hi, suggest a feature or just see what’s shaking",
      link: "https://join.slack.com/t/shakebugs/shared_invite/zt-1q0p1dd8h-OrkYobRWZw1N_hJSvklD_w",
      newTab: true,
    },
    {
      image: LiveChat,
      title: "Private live chat",
      subtitle: "Our support engineers are here for you",
      link: "javascript:Intercom('showNewMessage')",
      newTab: false,
    },
    {
      image: StatusPage,
      title: "Status page",
      subtitle: "All services are online. Or are they?",
      link: "https://status.shakebugs.com/",
      newTab: true,
    },
    {
      image: AddAppIcon,
      title: "Add new app",
      subtitle: "We’ve put this button here too just in case",
      link: addAppRoute,
      newTab: false,
    },
  ] as UsefulLink[];
};
