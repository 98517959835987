import React from "react";

import { vars } from "styles";

import { useSpaceToPreviewContext } from "../useSpaceToPreview";

export function SpaceToPreviewIndicator({ value }: { value: string }) {
  const { featureEnabled } = useSpaceToPreviewContext();

  return (
    <div style={{ position: "relative" }}>
      {value}
      {featureEnabled && (
        <div
          className="__spaceToPreview"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: vars.colors.grey100,
          }}
        >
          <span
            style={{
              marginRight: "0.4rem",
              border: `1px solid ${vars.colors.grey80}`,
              padding: "0.2rem 0.4rem",
              fontSize: "1.2rem",
              color: vars.colors.grey30,
              borderRadius: "0.4rem",
            }}
          >
            Space
          </span>
          <span style={{ color: vars.colors.grey60 }}>to preview</span>
        </div>
      )}
    </div>
  );
}
