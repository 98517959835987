import { useEffect } from "react";

type Props = {
  keyboardKey: string;
  onPress: (event: KeyboardEvent) => void;
  enabled: boolean;
  meta?: boolean;
};

export function useKeyboardPress({ keyboardKey, onPress, enabled, meta }: Props) {
  useEffect(() => {
    if (!enabled) return;

    function eventCallback(keyboardEvent: KeyboardEvent) {
      if (keyboardEvent.key == keyboardKey && !meta) {
        onPress(keyboardEvent);
        return;
      }
      if (
        meta &&
        keyboardEvent.key == keyboardKey &&
        (keyboardEvent.metaKey || keyboardEvent.ctrlKey || keyboardEvent.shiftKey)
      ) {
        onPress(keyboardEvent);
        return;
      }
    }
    document.addEventListener("keydown", eventCallback);
    return () => {
      document.removeEventListener("keydown", eventCallback);
    };
  }, [keyboardKey, onPress, enabled, meta]);
}

type PropsListen = {
  keyboardKey: string;
  onChange: (pressed: boolean) => void;
  enabled: boolean;
};

export function useListenKeyboardPress({ keyboardKey, onChange, enabled }: PropsListen) {
  useEffect(() => {
    if (!enabled) return;

    function onKeyDown(keyboardEvent: KeyboardEvent) {
      if (keyboardEvent.key == keyboardKey) {
        keyboardEvent.preventDefault();
        onChange(true);
      }
    }

    function onKeyUp(keyboardEvent: KeyboardEvent) {
      if (keyboardEvent.key == keyboardKey) {
        keyboardEvent.preventDefault();
        onChange(false);
      }
    }
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [keyboardKey, onChange, enabled]);
}
