import styled, { css } from "styled-components";

import slashIcon from "assets/images/slash-icon.svg";

import { vars } from "styles";

export const Navigation = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    li {
      position: relative;
      height: 3.6rem;

      .dropdown {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
      }

      &:not(:last-of-type) {
        padding-right: 3.6rem;

        .dropdown {
          right: 3.5rem;
        }

        &::after {
          content: "";
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.4rem;
          height: 2.4rem;
          margin: auto;
          background-image: url(${slashIcon});
          background-repeat: no-repeat;
          background-position: center;
          opacity: 0.35;
          position: absolute;
          top: 0;
          right: 0.6rem;
          bottom: 0;
        }
      }
    }
  }
`;

export const NavItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 10rem;
  max-width: 38rem;
  user-select: none;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;

    img {
      max-width: 100%;
      height: auto;
      transform: translateZ(0);
    }
  }
`;

export const NavItemInner = styled.div<{ $withClick?: boolean }>`
  height: 100%;
  display: flex;
  max-width: 36rem;
  align-items: center;
  border-radius: 1rem;
  margin-right: 0.8rem;

  > span {
    max-width: 21.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  > * {
    display: inline-block;
  }

  ${(props) =>
    props.$withClick &&
    css`
      &:hover {
        background-color: ${vars.colors.grey100};
        cursor: pointer;

        .dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    `}

}
`;
