import React from "react";

import * as Styled from "./Header.styles";

interface Props {
  bottomBorder?: boolean;
}
const Header: React.FC<Props> = ({ children, bottomBorder = true }) => {
  return (
    <Styled.Header
      $bottomBorder={bottomBorder}
      className="header"
    >
      {children}
    </Styled.Header>
  );
};

export default Header;
