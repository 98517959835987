import React, { Fragment, useEffect, useMemo, useState } from "react";

import { generatePath, Link, useLocation } from "react-router-dom";

import Button from "components/Button/Button";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";
import Paragraph from "components/Paragraph/Paragraph";

import useSignOutApiConsumer from "consumers/useSignOutApiConsumer";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { useAuthContext } from "context/Auth/Auth.context";

import { BasicWorkspace } from "models";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";
import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";
import useWorkspaceJoinApiConsumer from "pages/WorkspaceJoin/consumers/useWorkspaceJoinApiConsumer";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import * as Styled from "./WorkspaceJoinElement.styles";

interface Props {
  isModal?: boolean;
  toggleCreateWs?: () => void;
  toggleJoinWs?: () => void;
  Modal?: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function WorkspaceJoinElement({ isModal, toggleCreateWs, toggleJoinWs, Modal, internalProps }: Props) {
  const { signOut } = useSignOutApiConsumer();
  const { userData } = useAuthContext();
  const { workspaces, joinableWorkspaces } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();
  const location = useLocation();

  const domainName = useMemo(() => resolveDomainName(userData?.email), [userData?.email]);

  const { joinWorkspaces, loading } = useWorkspaceJoinApiConsumer({ toggle: toggleJoinWs });

  const [checkedWorkspaces, setCheckedWorkspaces] = useState<BasicWorkspace[]>([]);
  useEffect(() => setCheckedWorkspaces(joinableWorkspaces ?? []), [joinableWorkspaces]);

  const handleWorkspaceCheck = (e: React.ChangeEvent<HTMLInputElement>, workspace: BasicWorkspace) => {
    e.target.checked
      ? setCheckedWorkspaces([...checkedWorkspaces, workspace])
      : setCheckedWorkspaces(checkedWorkspaces.filter((el) => el.id !== workspace.id));
  };

  const handleJoinWorkspaces = (event?: React.MouseEvent<Element, MouseEvent>) => {
    event?.preventDefault();
    joinWorkspaces(checkedWorkspaces);
  };

  const generateCancelLink = () => {
    if (selectedWorkspace) {
      return generatePath(RoutePaths.HOME_ROOT, { workspaceSlug: selectedWorkspace.slug });
    }
    return generatePath(RoutePaths.ROOT);
  };

  const handleOpenCreateWs = () => {
    if (toggleJoinWs && toggleCreateWs) {
      toggleJoinWs();
      toggleCreateWs();
    }
  };

  const hasJoinableWorkspaces = joinableWorkspaces && joinableWorkspaces.length > 0;
  const createText = hasJoinableWorkspaces ? "create new workspace" : "Create new workspace";

  const form = () => {
    return (
      <Fragment>
        {!isModal && (
          <FormTitle
            title="Join your team"
            subtitle={
              <>
                These Shake workspaces allow anyone with <strong>@{domainName}</strong> email to join.
              </>
            }
          />
        )}

        <Styled.WorkspaceJoinForm
          onSubmit={(event) => event.preventDefault()}
          noValidate
          $isModal={isModal}
        >
          {hasJoinableWorkspaces ? (
            <Styled.WorkspaceJoinListWrap $isModal={isModal}>
              {joinableWorkspaces?.map((workspace) => {
                return (
                  <Styled.Option
                    key={workspace.id}
                    checked={checkedWorkspaces.includes(workspace)}
                    label={workspace.name}
                    letter={workspace.name[0]?.toLocaleUpperCase()}
                    backgroundColor={getRandomColor(workspace?.id).background}
                    txtColor={getRandomColor(workspace?.id).text}
                    canvasImage={workspace?.logo_url ?? undefined}
                    handleCheck={(e) => handleWorkspaceCheck(e, workspace)}
                    $isModal={isModal}
                  />
                );
              })}
            </Styled.WorkspaceJoinListWrap>
          ) : (
            <Paragraph>No joinable workspaces.</Paragraph>
          )}

          {!isModal && hasJoinableWorkspaces && (
            <Button
              size="full"
              type="submit"
              disabled={!Boolean(checkedWorkspaces.length) || loading}
              tooltip={{
                position: "top",
                text: "Please choose at least one workspace",
                disabled: Boolean(checkedWorkspaces.length) || loading,
              }}
              loadingText={loading && "Joining..."}
              onClick={(e) => handleJoinWorkspaces(e)}
            >
              Join
            </Button>
          )}
        </Styled.WorkspaceJoinForm>

        <Styled.WorkspaceJoinFormLinks>
          <p>
            {hasJoinableWorkspaces && <Fragment>Or,&nbsp;</Fragment>}
            {isModal ? (
              <Styled.Link onClick={handleOpenCreateWs}>{createText}</Styled.Link>
            ) : (
              <Link
                state={location.state}
                to={generatePath(RoutePaths.CREATE_WORKSPACE)}
              >
                {t("workspaceJoin.link.createNewWorkspace")}
              </Link>
            )}
          </p>

          {!isModal && (
            <p>
              I don&apos;t want to do anything here,&nbsp;
              {workspaces.length === 0 ? (
                <a onClick={() => signOut()}>Sign me out</a>
              ) : (
                <Fragment>
                  {toggleJoinWs ? <a onClick={toggleJoinWs}>cancel</a> : <Link to={generateCancelLink()}>cancel</Link>}
                </Fragment>
              )}
            </p>
          )}
        </Styled.WorkspaceJoinFormLinks>
      </Fragment>
    );
  };

  if (isModal && Modal) {
    return (
      <Modal
        {...internalProps}
        heading="Join your team"
        subtitle={`These Shake workspaces allow anyone with @${domainName} email to join.`}
        buttonElement={
          <Button
            size="small"
            type="submit"
            disabled={!Boolean(checkedWorkspaces.length) || loading}
            onClick={(e) => handleJoinWorkspaces(e)}
            tooltip={{
              position: "top",
              text: "Please choose at least one workspace",
              disabled: Boolean(checkedWorkspaces.length) || loading,
            }}
            loadingText={loading && "Joining..."}
          >
            Join
          </Button>
        }
      >
        {form()}
      </Modal>
    );
  }

  return form();
}

const resolveDomainName = (email?: string) => {
  return email && email.substring(email.lastIndexOf("@") + 1);
};
