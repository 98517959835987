export interface Analytics {
  value: number;
  time: string;
  cum_value: number;
}

export interface AnalyticsData {
  data: Analytics[];
}

export interface CrashesAnalyticsPayload {
  group?: string;
  start_date: string;
  end_date: string;
  app_version?: string;
}

export interface FeedbacksAnalyticsPayload {
  group?: string;
  start_date: string;
  end_date: string;
  tag?: string;
  app_version?: string;
}

export interface UsersAnalyticsPayload {
  group?: string;
  start_date: string;
  end_date: string;
}

export interface UsersFilters {
  time: TimeRange;
  group: TimeGroup;
}

export interface CrashesFilters {
  time: TimeRange;
  group: TimeGroup;
  app_version?: string;
}

export interface FeedbackFilters {
  time: TimeRange;
  group: TimeGroup;
  app_version?: string;
  tag?: string;
}

export enum TimeGroup {
  WEEK = "Week",
  DAY = "Day",
  MONTH = "Month",
}

export enum TimeRange {
  SEVEN = "Last 7 days",
  TWENTY_EIGHT = "Last 28 days",
  NINETY = "Last 90 days",
  YEAR = "Last year",
}

export type FilterType = "group" | "time" | "app_version" | "tag";
