import { useCallback, useEffect, useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { CommonComment } from "models/CommonComment.model";

import { useAdministrationDeps } from "../Administration";

export const useCommonCommentsConsumer = () => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [commonComments, setCommonComments] = useState<CommonComment[]>([]);
  const [loading, setLoading] = useState(false);

  const getCommonComments = useCallback(async () => {
    if (!selectedWorkspace) return;
    setLoading(true);
    try {
      const { data } = await administrationService.getCommonComments(selectedWorkspace.id);
      setCommonComments(data);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while fetching common comments",
      });
    } finally {
      setLoading(false);
    }
  }, [administrationService, selectedWorkspace]);

  const deleteCommonComment = async (id: string) => {
    setLoading(true);
    try {
      if (!selectedWorkspace) return;

      await administrationService.deleteCommonComment(selectedWorkspace.id, id);

      setCommonComments((comments) => {
        return comments.filter((comment) => comment.id !== id);
      });

      displayToast({
        content: "Common comment removed",
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommonComments();
  }, [getCommonComments]);

  return {
    loading,
    commonComments,
    getCommonComments,
    deleteCommonComment,
  };
};
