import { rgba } from "polished";
import styled, { css } from "styled-components";

import { vars } from "styles";

export const ContentContainer = styled.div<{
  $fullWidth?: boolean;
  $open: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;

  height: 100vh;
  width: 640px;
  border: 1px solid ${vars.colors.grey100};

  background-color: ${vars.colors.black};
  overflow-y: scroll;
  transition: ${vars.transitions.drawer};

  z-index: ${vars.zIndex.drawer};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: calc(100% - 9.6rem);
    `}

  ${({ $open }) =>
    $open &&
    css`
      transform: translateX(0%);
    `}

  ${({ $open }) =>
    !$open &&
    css`
      transform: translateX(100%);
    `}
`;

export const BackdropView = styled.div<{
  $open: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  transition: ${vars.transitions.drawer};
  z-index: ${vars.zIndex.drawer};

  ${({ $open }) =>
    $open &&
    css`
      background-color: ${rgba(vars.colors.pureBlack, 0.4)};
    `}

  ${({ $open }) =>
    !$open &&
    css`
      background-color: ${rgba(vars.colors.pureBlack, 0.0)};
    `}
`;

export const ChildrenContainer = styled.div`
  height: 100%;
  padding: 2rem 2.4rem 2rem 2.8rem;
`;
