export const androidCrashesSetup = {
  enableCrashesCode: [
    {
      title: "Kotlin",
      file: "App.kt",
      language: "kotlin",
      code: "Shake.setCrashReportingEnabled(true)",
      highlightedLines: [0],
    },
    {
      title: "Java",
      file: "App.java",
      language: "java",
      code: "Shake.setCrashReportingEnabled(true);",
      highlightedLines: [0],
    },
  ],
  testCrashesCode: [
    {
      title: "Kotlin",
      file: "App.kt",
      language: "kotlin",
      code: `public class MainActivity : Activity {
    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)
        setContentView(R.layout.activity_main)

        val buttonCrash: Button = findViewById(R.id.button_crash)
        buttonCrash.setOnClickListener {
            val array = arrayOf(1, 2, 3)
            val result = array[5]
        }
    }
}`,
      highlightedLines: [5, 6, 7, 8, 9],
    },
    {
      title: "Java",
      file: "App.java",
      language: "java",
      code: `public class MainActivity extends Activity {
    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        Button buttonCrash = findViewById(R.id.button_crash);
        buttonCrash.setOnClickListener(new View.OnClickListener() {
            @Override
            public void onClick(View v) {
                int[] array = new int[]{1, 2, 3};
                int result = array[5];
            }
        });
    }
}`,
      highlightedLines: [6, 7, 8, 9, 10, 11, 12, 13],
    },
  ],
};

export const iosCrashesSetup = {
  enableCrashesCode: [
    {
      title: "Swift",
      file: "AppDelegate.swift",
      language: "swift",
      code: "Shake.configuration.isCrashReportingEnabled = true",
      highlightedLines: [0],
    },
    {
      title: "Objective-C",
      file: "AppDelegate.m",
      language: "objectivec",
      code: "SHKShake.configuration.isCrashReportingEnabled = YES;",
      highlightedLines: [0],
    },
  ],
  testCrashesCode: [
    {
      title: "Swift",
      file: "AppDelegate.swift",
      language: "swift",
      code: `override func viewDidLoad() {
    super.viewDidLoad()

    let crashButton = UIButton(type: .roundedRect)
    crashButton.setTitle("Crash me", for: .normal)
    crashButton.frame = .init(x: 0, y: 0, width: 100, height: 50)
    crashButton.center = self.view.center

    self.view.addSubview(crashButton)
    crashButton.addTarget(self, action: #selector(crashButtonHandler), for: .touchUpInside)
}

@objc func crashButtonHandler() {
    let arr = [1, 2, 3]
    let myVar = arr[5]
}`,
      highlightedLines: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    {
      title: "Objective-C",
      file: "AppDelegate.m",
      language: "objectivec",
      code: `- (void)viewDidLoad {
    [super viewDidLoad];
    
    UIButton *crashButton = [UIButton buttonWithType:UIButtonTypeRoundedRect];
    [crashButton setTitle:@"Crash me" forState:UIControlStateNormal];
    crashButton.frame = CGRectMake(0, 0, 100, 50);
    crashButton.center = self.view.center;

    [self.view addSubview:crashButton];
    [crashButton addTarget:self action:@selector(crashButtonHandler) forControlEvents:UIControlEventTouchUpInside];
}

- (void)crashButtonHandler {
    NSArray *arr = @[@1, @2, @3];
    id myVar = arr[5];
}`,
      highlightedLines: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
  ],
};
