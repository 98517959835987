import React, { useState } from "react";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import identifiers from "util/identifiers.json";

import * as Styled from "../../components/ModalBody/ModalBody.styles";

interface Props {
  selectedWorkspaceName?: string;
  deleteWorkspace: () => void;
  loading: boolean;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function DeleteWorkspaceModalBody({
  selectedWorkspaceName,
  deleteWorkspace,
  loading,
  Modal,
  internalProps,
}: Props) {
  const [workspaceName, setWorkspaceName] = useState<string>("");

  const isButtonDisabled = workspaceName !== selectedWorkspaceName;

  const handleDeleteWorkspace = (e?: React.MouseEvent<Element, MouseEvent>) => {
    e?.preventDefault();
    deleteWorkspace();
  };

  return (
    <Modal
      {...internalProps}
      testId={identifiers["general.modal.header.deleting"]}
      heading={`Deleting ${selectedWorkspaceName}`}
      buttonElement={
        <Button
          type="button"
          size="small"
          color="red"
          disabled={isButtonDisabled || loading}
          testId={identifiers["general.modal.button.deleteWorkspace"]}
          tooltip={{
            position: "top",
            text: "Please enter correct workspace name to enable deleting",
            disabled: !isButtonDisabled || loading,
          }}
          loadingText={loading && "Deleting..."}
          onClick={(e) => handleDeleteWorkspace(e)}
        >
          Delete this workspace
        </Button>
      }
    >
      <Styled.Subtitle>
        Permanently deleting this workspace will also delete all of its apps and tickets. This action cannot be undone.
        Please type in the workspace name to confirm.
      </Styled.Subtitle>

      <Styled.Form onSubmit={(event) => event.preventDefault()}>
        <Input
          type="text"
          label="Workspace name"
          value={workspaceName}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setWorkspaceName(e.currentTarget.value)}
          testId={identifiers["general.modal.input.workspaceName"]}
          autoFocus
        />
      </Styled.Form>
    </Modal>
  );
}
