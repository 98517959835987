import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { vars } from "styles";

export const TagWrap = styled(motion.div)<{
  $border?: boolean;
  $borderColor?: string;
  $closeable?: boolean;
}>`
  position: relative;
  user-select: none;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.3rem 0.8rem 0.5rem 0.8rem;
  margin: 0.2rem;
  border-radius: 1.2rem;
  background-color: rgba(70, 211, 154, 0);
  border: 1px solid transparent;

  .color {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.4rem;
    transform: translateY(1px);
  }

  /* border */
  ${(props) =>
    props.$border &&
    css`
      border-color: ${vars.colors.grey100};
    `}
  ${(props) =>
    props.$borderColor &&
    props.$border &&
    css`
      border-color: ${props.$borderColor};
    `}
   /* with close */ ${(props) =>
    props.$closeable &&
    css`
      padding-right: 2rem;
    `}
`;

export const TagClose = styled.button`
  position: absolute;
  top: 0;
  right: 0.6rem;
  bottom: 0;
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${vars.transitions.hover};
  box-shadow: none;
  border: none;
  background: none;
  color: ${vars.colors.grey80};

  &:hover {
    color: ${vars.colors.grey30};
  }
`;
