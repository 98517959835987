export enum RoutePaths {
  // root paths
  ROOT = "/",
  WORKSPACE_ROOT = "/:workspaceSlug",

  // user feedback paths
  USER_FEEDBACK_MODULE_ROOT = "/:workspaceSlug/user-feedback",
  USER_FEEDBACK = "/:workspaceSlug/user-feedback/:appKey",
  USER_FEEDBACK_DETAILS = "/:workspaceSlug/user-feedback/:appKey/:userFeedbackKey",
  USER_FEEDBACK_LOGS = "/:workspaceSlug/user-feedback/:appKey/:userFeedbackKey/activity-history",

  // crashes paths
  CRASH_MODULE_ROOT = "/:workspaceSlug/crash-reports",
  CRASH_REPORTS = "/:workspaceSlug/crash-reports/:appKey",
  CRASH_REPORT_ROOT = "/:workspaceSlug/crash-reports/:appKey/:crashReportKey",
  CRASH_REPORT_OVERVIEW = "/:workspaceSlug/crash-reports/:appKey/:crashReportKey/overview",
  CRASH_REPORT_EVENTS = "/:workspaceSlug/crash-reports/:appKey/:crashReportKey/events",
  CRASH_REPORT_EVENT = "/:workspaceSlug/crash-reports/:appKey/:crashReportKey/events/:crashReportEventId",
  CRASH_REPORT_LOGS = "/:workspaceSlug/crash-reports/:appKey/:crashReportKey/events/:crashReportEventId/activity-history",

  // users paths
  USERS_MODULE_ROOT = "/:workspaceSlug/users",
  USERS = "/:workspaceSlug/users/:appKey",
  USER_DETAILS = "/:workspaceSlug/users/:appKey/:userId",

  // stats paths
  STATS_MODULE_ROOT = "/:workspaceSlug/stats",
  STATS = "/:workspaceSlug/stats/:appKey",

  // add app paths
  ADD_APP = "/:workspaceSlug/add-app",

  // administration paths
  ADMINISTRATION_ROOT = "/:workspaceSlug/administration",
  ADMINISTRATION_GENERAL = "/:workspaceSlug/administration/general",
  ADMINISTRATION_EXTENSIONS = "/:workspaceSlug/administration/extensions",
  ADMINISTRATION_WHITE_LABELING = "/:workspaceSlug/administration/white-labeling",
  ADMINISTRATION_MEMBERS = "/:workspaceSlug/administration/members",
  ADMINISTRATION_APPS = "/:workspaceSlug/administration/apps",
  ADMINISTRATION_APPS_GENERAL = "/:workspaceSlug/administration/apps/general",
  ADMINISTRATION_APPS_USER_FEEDBACK = "/:workspaceSlug/administration/apps/feedback",
  ADMINISTRATION_APPS_CRASHES = "/:workspaceSlug/administration/apps/crashes",
  ADMINISTRATION_APPS_USERS = "/:workspaceSlug/administration/apps/users",
  ADMINISTRATION_APPS_GENERAL_KEY = "/:workspaceSlug/administration/apps/general/:appKey",
  ADMINISTRATION_APPS_USER_FEEDBACK_KEY = "/:workspaceSlug/administration/apps/feedback/:appKey",
  ADMINISTRATION_APPS_CRASHES_KEY = "/:workspaceSlug/administration/apps/crashes/:appKey",
  ADMINISTRATION_APPS_USERS_KEY = "/:workspaceSlug/administration/apps/users/:appKey",

  ADMINISTRATION_BILLING = "/:workspaceSlug/administration/billing",

  // home paths
  HOME_ROOT = "/:workspaceSlug/home",
  HOW_TO_ROOT = "/:workspaceSlug/home/how-to",
  HOME_PRODUCT_MANAGERS = "/:workspaceSlug/home/how-to/product-managers",
  HOME_APP_DEVELOPERS = "/:workspaceSlug/home/how-to/app-developers",
  HOME_APP_TESTERS = "/:workspaceSlug/home/how-to/app-testers",
  HOME_CUSTOMER_SUPORT = "/:workspaceSlug/home/how-to/customer-support",
  HOME_ALL = "/:workspaceSlug/home/how-to/all",
  FEATURES_ROOT = "/:workspaceSlug/home/features",
  FEATURES_LATEST = "/:workspaceSlug/home/features/latest",
  FEATURES_POPULAR = "/:workspaceSlug/home/features/popular",
  USEFUL_LINKS = "/:workspaceSlug/home/useful-links",
  APP_DEVELOPMENT = "/:workspaceSlug/home/app-development",

  //common comments
  ADMINISTRATION_COMMON_COMMENTS = "/:workspaceSlug/administration/common-comments",

  // no subscription
  NO_SUBSCRIPTION = "/:workspaceSlug/no-subscription",

  // account paths
  ACCOUNT_ROOT = "/:workspaceSlug/account",
  ACCOUNT_PERSONAL_INFO = "/:workspaceSlug/account/info",
  ACCOUNT_NOTIFICATIONS = "/:workspaceSlug/account/notifications",

  // create / join workspace paths
  CREATE_WORKSPACE = "/create-workspace",
  JOIN_WORKSPACE = "/join-workspace",

  // link confirmation paths
  ADMINISTRATION_DOMAIN_VERIFICATION = "/verify-domain",
  WORKSPACE_JOIN_INVITATION = "/invitation",
  CHANGE_EMAIL_CONFIRMATION = "/change-email-confirmation",
  CANNY_AUTH = "/canny-auth",

  // public paths
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  CONFIRM_SIGNUP = "/confirmation",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",

  // integration activation
  INTEGRATION_ACTIVATION = "/integration-activation",

  // not found path
  NOT_FOUND = "*",
}
