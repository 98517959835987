import styled, { css } from "styled-components";

import BaseDropdown from "components/Dropdown/Dropdown";
import BaseParagraph from "components/Paragraph/Paragraph";
import BaseTooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export const Filters = styled(Flex)`
  margin-bottom: 2rem;
`;

export const Dropdown = styled(BaseDropdown)<{ $disabled?: boolean }>`
  height: 100%;
  background: ${vars.colors.grey100};
  border: 1px solid ${vars.colors.grey90};
  border-radius: 1rem;

  > div:first-child {
    padding: 0.4rem 0.8rem 0.4rem 1.2rem;
  }

  span {
    font-size: 14px;
    color: ${vars.colors.grey30};
    white-space: nowrap;
    max-width: 8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 1;
      cursor: default;
      span {
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
    `}
`;

export const Paragraph = styled(BaseParagraph)`
  margin-left: 2rem;
`;

export const Tooltip = styled(BaseTooltip)`
  width: inherit;
`;
