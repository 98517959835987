import { useCallback, useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { AzureAuthorizePayload, IntegrationType } from "models/integrations";
import { getIntegrationTypeName } from "models/integrations";

import { useAdministrationDeps } from "pages/Administration/Administration";

interface FormState {
  projectUrl: string;
  token: string;
}

interface Props {
  workspaceId: string;
  selectedAppId: string;
  onFinish: () => void;
}

const azureProjectValidator = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return new Error("Whoops, that doesn't look like a link to an Azure DevOps project");
  }

  const protocolValid = url.protocol === "http:" || url.protocol === "https:";
  if (url.host === "dev.azure.com" && protocolValid) return;

  return new Error("Whoops, that doesn't look like a link to an Azure DevOps project");
};

const validators = {
  projectUrl: azureProjectValidator,
  token: (token: string) => {
    if (token.trim().length === 0) return new Error("Invalid token.");
  },
};

export const useAzureAuthorizationFormConsumer = ({ workspaceId, onFinish, selectedAppId }: Props) => {
  const { administrationService } = useAdministrationDeps();

  const { formState, formError, handleFormChange, handleFormSubmit, setError } = useForm<FormState>({
    initialState: { projectUrl: "", token: "" },
    validators,
    onSubmit: connectToAzure,
  });

  const { projectUrl, token } = formState;

  const [disabled, setDisabled] = useState(false);

  const handleTokensLinkClick = useCallback(() => {
    try {
      const isError = azureProjectValidator(projectUrl);

      if (isError) {
        setError({ projectUrl: isError });
        return;
      }

      const projectURL = new URL(projectUrl.trim());

      const oraganizationName = projectURL.pathname.split("/").slice(-2, -1)[0];
      if (!oraganizationName) return;

      const tokensURL = `https://dev.azure.com//${oraganizationName}/_usersSettings/tokens`;

      window.open(tokensURL, "_blank");
    } catch (error) {}
  }, [projectUrl, setError]);

  async function connectToAzure() {
    try {
      if (!workspaceId || !selectedAppId) throw new Error("Something went wrong.");
      setDisabled(true);

      const projectURL = new URL(projectUrl.trim());

      const projectName = projectURL.pathname.split("/").pop();
      if (!projectName) throw new Error("Check your project url");

      const organizationUrl = projectUrl.replace(`/${projectName}`, "");

      const payload: AzureAuthorizePayload = {
        project_name: decodeURI(projectName), // Don't send URL encoded string
        organization_url: organizationUrl,
        access_token: token,
      };

      await administrationService.authorizeAzure(workspaceId, selectedAppId, payload);

      displayToast({
        title: "Fantastic!",
        content: `${getIntegrationTypeName(IntegrationType.AZURE_DEVOPS)} has been connected`,
      });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      onFinish();
      setDisabled(false);
    }
  }

  return {
    formState,
    formError,
    handleFormChange,
    handleFormSubmit,
    disabled,
    handleTokensLinkClick,
  };
};
