import React, { useMemo } from "react";

import { useLocation } from "react-router-dom";

import useCannyAuthApiConsumer from "../consumer/useCannyAuthApiConsumer";

export default function CannyAuthPageView() {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  useCannyAuthApiConsumer({ cannyRedirectUrl: params.get("redirect") || "" });

  return <>Loading...</>;
}
