import { useEffect } from "react";

import { Socket } from "socket.io-client";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useUpdateSelectedWorkspace } from "layouts/views/WorkspaceRootLayout";

import { ServerToClientEvents, SocketEvents } from "models/webSocketEvents";

export const useAppsSocketConsumer = (socket?: Socket<ServerToClientEvents, never>) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();

  useEffect(() => {
    if (!selectedWorkspace || !socket) return;

    const eventHandler = () => {
      updateSelectedWorkspace(selectedWorkspace);
    };

    socket.on(SocketEvents.APP_ADDED, eventHandler);

    return () => {
      socket.off(SocketEvents.APP_ADDED, eventHandler);
    };
  }, [selectedWorkspace, socket, updateSelectedWorkspace]);
};
