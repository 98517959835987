import React, { Fragment } from "react";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import Paragraph from "components/Paragraph/Paragraph";

import { CreditCard, Customer, mapCreditCardBrandToLogo } from "models/billing";
import { dateStringForMappingFiles } from "models/helpers/time/creationTime";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import * as Styled from "./BillingCards.styles";

interface CreditCardDetailsCardProps {
  customer: Customer;
  creditCard?: CreditCard;
  updateCreditCard: () => Promise<void>;
}

export default function CreditCardDetailsCard({
  customer,
  creditCard,
  updateCreditCard,
}: CreditCardDetailsCardProps): JSX.Element {
  return (
    <AdministrationCard
      title="Credit card details"
      subtitle="All payments are secure and encrypted by Stripe."
      topLeftAccessory={
        <Button
          size="small"
          onClick={() => updateCreditCard()}
          testId={identifiers["billing.button.update.card"]}
        >
          Update credit card
        </Button>
      }
      testId={identifiers["billing.header.card.details"]}
    >
      {cardDisplay(customer, creditCard)}
    </AdministrationCard>
  );
}

const cardDisplay = (customer: Customer, creditCard?: CreditCard) => {
  if (!creditCard) return <Fragment />;

  return (
    <Flex>
      <Styled.Flex $alignItems="center">
        <Styled.CardImage src={mapCreditCardBrandToLogo(creditCard.brand)} />
        <Flex $alignItems="center">
          <Styled.Dots />
          <Styled.Dots />
          <Styled.Dots />
          <p>{creditCard.last4}</p>
        </Flex>
      </Styled.Flex>
      {getNextInvoice(customer)}
    </Flex>
  );
};

const getNextInvoice = (customer: Customer) => {
  let price = 0;
  if (!customer.subscription) return <Fragment />;

  customer.subscription.items.map((item) => {
    return (price = price + item.plan.amount);
  });

  if (price)
    return (
      <Paragraph
        color={vars.colors.grey30}
        fontWeight={500}
        data-testid={identifiers["billing.info.next.invoice"]}
      >
        {`Next invoice is $${price.toString().slice(0, -2)} on ${dateStringForMappingFiles(
          new Date(customer.subscription.current_period_end),
        )}`}
      </Paragraph>
    );
};
