import { IssueUser } from "./issueTracking";
import { TicketPriority } from "./TicketPriority.model";
import { TicketStatus } from "./TicketStatus.model";

export type AppUserTickets = {
  tickets: AppUserTicket[];
  users: IssueUser[];
};
export interface AppUser {
  id: string;
  created: string;
  updated: string;
  end_user_id: string;
  metadata_: { [key: string]: string } & { first_name?: string; last_name?: string };
  tickets: AppUserTicket[];
  banned: boolean;
  city: string;
  country: string;
  signedUp: string;
  tickets_count: number;
}

export interface AppUserShared {
  id: string;
  created: string;
  updated: string;
  end_user_id: string;
  permalink: string;
  metadata_: { [key: string]: string };
  banned: boolean;
  city: string;
  country: string;
  signedUp: string;
  tickets_count?: number;
}

export interface AppUserCustom {
  id: string;
  firstName: string;
  lastName: string;
  endUserId: string;
}

export enum APP_USER_TICKET_TYPE {
  FEEDBACK = "feedback",
  CRASH = "crash",
}

export interface AppUserTicket {
  id: string;
  title: string | null;
  screenshot_url: string | null;
  reported_time: string;
  type: APP_USER_TICKET_TYPE;
  status: TicketStatus;
  priority: TicketPriority;
  tags: string[];
  app_version: string;
  key: string;
}

export interface AppUsersResponse {
  items: AppUser[];
  total: number;
}

export const getUserName = (user?: AppUserShared | AppUser) => {
  if (!user || !user.metadata_) return "Not available";

  if (user?.metadata_.first_name !== "Not available" && user?.metadata_.last_name !== "Not available") {
    return `${user?.metadata_.first_name} ${user?.metadata_.last_name}`;
  }
  if (user?.metadata_.first_name !== "Not available" && user?.metadata_.last_name === "Not available") {
    return `${user?.metadata_.first_name} `;
  }
  if (user?.metadata_.first_name === "Not available" && user?.metadata_.last_name !== "Not available") {
    return `${user?.metadata_.last_name} `;
  }
  return "Not available";
};

export const getUserInitials = (userName?: string) => {
  if (!userName) return undefined;

  return `${userName.trimStart()[0].toUpperCase()}${
    userName.trimStart().split(" ")[1] ? userName.trimStart().split(" ")[1][0].toUpperCase() : ""
  }`;
};
