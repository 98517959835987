import React, { Fragment, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import { useModal } from "components/Modal/Modal";

import { App, PlatformName } from "models";

import { useImportTicketsConsumer } from "pages/shared/consumers/useImportTicketsConsumer";

import { docLink } from "util/docs";
import identifiers from "util/identifiers.json";

import UploadFileModalBody from "../ModalBody/UploadFileModalBody";

type Props = {
  app: App;
  disabled: boolean;
};

export default function ImportCard({ app, disabled }: Props) {
  const { uploadImportFile, loading } = useImportTicketsConsumer(app);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    Modal: UploadFileModal,
    toggle: toggleUpload,
    modalProps: uploadMappingInternal,
  } = useModal({ size: "narrow" });

  useEffect(() => {
    if (location.hash === "#import") {
      toggleUpload();
      navigate(location.pathname);
    }
  }, [toggleUpload, location, navigate]);

  const platform = Object.values(PlatformName).find((value) => value === app.platform.name);

  const onImportFileSelected = (file: File) => {
    toggleUpload(); // Hide upload dialog

    uploadImportFile(file);
  };

  const onImportButtonPressed = () => {
    toggleUpload(); // Show upload dialog
  };

  if (!platform) return <Fragment />;

  return (
    <AdministrationCard
      title="Import tickets"
      subtitle="Easy and effortless migration from Instabug to Shake."
      testId={identifiers["import.header"]}
      topLeftAccessory={
        <Button
          onClick={onImportButtonPressed}
          disabled={loading || disabled || !app.active}
          size="small"
          testId={identifiers["import.button.import"]}
          tooltip={{
            text: "Tickets can be imported in active apps only",
            disabled: disabled || app.active,
            position: "top",
          }}
        >
          {"Choose a file"}
        </Button>
      }
    >
      <div style={{ marginTop: "2rem" }}>
        <HelperText>
          Read how to upload your .csv files on&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={docLink(platform).import}
          >
            Import from Instabug
          </a>
          .
        </HelperText>
      </div>

      <UploadFileModal
        {...uploadMappingInternal}
        heading={"Import tickets"}
        testId={identifiers["import.modal.header"]}
      >
        <UploadFileModalBody
          handleSelectedFile={onImportFileSelected}
          acceptedFileTypes={{ "text/csv": [".csv"] }}
          bottomText={"Maximum file size 100MB."}
        />
      </UploadFileModal>
    </AdministrationCard>
  );
}
