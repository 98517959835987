import React, { Fragment, useState } from "react";

import { format } from "date-fns";

import { ReactComponent as ClientSecret } from "assets/images/client-secret-icon.svg";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

import displayToast from "../../../../components/Toast/displayToast";
import { ApiKey } from "../../../../models/ApiKey.model";
import identifiers from "../../../../util/identifiers.json";

interface Props {
  setSelectedApiKey: (apiKey: ApiKey) => void;
  toggleDelete: () => void;
  apiKey: ApiKey;
  isAdmin: boolean;
}

export const ApiKeyRow = ({ setSelectedApiKey, toggleDelete, apiKey, isAdmin }: Props) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const handleClick = () => {
    if (!apiKey) return;
    navigator.clipboard.writeText(apiKey.key);
    displayToast({
      content: "API key copied to clipboard",
    });
    setIsDropdownOpened(false);
  };

  return (
    <Fragment>
      <CenteredCell
        minWidth="400"
        maxWidth="400"
      >
        <ClientSecret />
        <span onDoubleClick={handleClick}>
          <Paragraph color={vars.colors.grey30}>{apiKey.key}</Paragraph>
        </span>
      </CenteredCell>
      <CenteredCell>
        <Paragraph>Created {format(new Date(apiKey.created), "MMM dd, yyyy")}</Paragraph>
      </CenteredCell>
      <CellEnd>
        <Dropdown
          isOpened={isDropdownOpened}
          setIsOpened={setIsDropdownOpened}
        >
          <DropdownItem
            onClick={handleClick}
            testId={identifiers["general.dropdownButton.copy"]}
          >
            Copy
          </DropdownItem>
          {isAdmin && (
            <DropdownItem
              onClick={() => {
                setSelectedApiKey(apiKey);
                toggleDelete();
              }}
              error
            >
              Delete
            </DropdownItem>
          )}
        </Dropdown>
      </CellEnd>
    </Fragment>
  );
};
