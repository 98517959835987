import React, { useState } from "react";

import * as Styled from "./EnvironmentRibbon.styles";
import Environment from "../../environments";

const EnvironmentRibbon: React.FC = () => {
  const environment = Environment.name;

  const [visible, setVisible] = useState<boolean>(environment !== "production" && environment !== "demo");

  const hideRibbon = () => setVisible(false);

  return (
    <>
      {visible && (
        <Styled.EnvironmentRibbonWrap className={`environment-${environment}`}>
          <a
            onClick={hideRibbon}
            className="environment-label"
          >
            {environment}
          </a>
        </Styled.EnvironmentRibbonWrap>
      )}
    </>
  );
};

export default EnvironmentRibbon;
