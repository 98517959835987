import React from "react";

import dependencyInjection from "util/dependencyInjection";

import { CannyAuthService } from "./services/CannyAuth.service";
import CannyAuthPageView from "./views/CannyAuthPageView";

interface Services {
  cannyAuthService: CannyAuthService;
}

const cannyAuthService = new CannyAuthService();

const [CannyAuthDependencyProvider, useCannyAuthDeps] = dependencyInjection<Services>({
  services: {
    cannyAuthService,
  },
});

export function CannyAuthPage(): JSX.Element {
  return (
    <CannyAuthDependencyProvider>
      <CannyAuthPageView />
    </CannyAuthDependencyProvider>
  );
}

export { useCannyAuthDeps };
