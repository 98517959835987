import { ConsoleLogEventRes } from "./ConsoleLogEvent.model";
import { CustomLogEventRes } from "./CustomLogEvent.model";
import { NetworkRequestRes } from "./NetworkRequest.model";
import { NotificationEventRes } from "./NotificationEvent.model";
import { ScreenEventRes } from "./ScreenEvent.model";
import { SystemEventRes } from "./SystemEvent.model";
import { UserEventRes } from "./UserEvent.model";

import {
  ConsoleLogEvent,
  CustomLogEvent,
  NetworkRequest,
  NotificationEvent,
  ScreenEvent,
  SystemEvent,
  UserEvent,
} from "./index";

export enum LogType {
  USER_EVENT = "user_event",
  NETWORK_REQUEST = "network_request",
  NOTIFICATION = "notification_event",
  SYSTEM_EVENT = "system_event",
  SCREEN_EVENT = "screen_event",
  CONSOLE_LOG = "console_log",
  CUSTOM_LOG = "custom_log",
}

export type LogUnionType =
  | NetworkRequest
  | UserEvent
  | NotificationEvent
  | SystemEvent
  | ScreenEvent
  | ConsoleLogEvent
  | CustomLogEvent;

export interface Log {
  id: string;
  created: string;
  updated: string;
  pretty_user_events: Array<UserEventRes>;
  pretty_view_controller_history_events: Array<ScreenEventRes>;
  pretty_system_events: Array<SystemEventRes>;
  pretty_network_requests: Array<NetworkRequestRes>;
  pretty_notification_events: Array<NotificationEventRes>;
  pretty_custom_log_events: Array<CustomLogEventRes>;
  pretty_console_log_events: Array<ConsoleLogEventRes>;
  joined_events: Array<LogUnionType>;
  logs_count: number;
}

export const generateSortedLogs = (log: Log): Array<LogUnionType> => {
  const network: Array<NetworkRequest> = log.pretty_network_requests.map((log) => ({
    ...log,
    instance_type: LogType.NETWORK_REQUEST,
  }));

  const users: Array<UserEvent> = log.pretty_user_events.map((log) => ({ ...log, instance_type: LogType.USER_EVENT }));

  const screenEvents: Array<ScreenEvent> = log.pretty_view_controller_history_events.map((log) => ({
    ...log,
    instance_type: LogType.SCREEN_EVENT,
  }));

  const systemEvents: Array<SystemEvent> = log.pretty_system_events.map((log) => ({
    ...log,
    instance_type: LogType.SYSTEM_EVENT,
  }));

  const notificationEvents: Array<NotificationEvent> = log.pretty_notification_events.map((log) => ({
    ...log,
    instance_type: LogType.NOTIFICATION,
  }));

  const customLogEvents: Array<CustomLogEvent> = log.pretty_custom_log_events.map((log) => ({
    ...log,
    instance_type: LogType.CUSTOM_LOG,
  }));

  const consoleLogEvents: Array<ConsoleLogEvent> = log.pretty_console_log_events.map((log) => ({
    ...log,
    instance_type: LogType.CONSOLE_LOG,
  }));

  return [
    ...network,
    ...users,
    ...screenEvents,
    ...systemEvents,
    ...notificationEvents,
    ...customLogEvents,
    ...consoleLogEvents,
  ].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
};
