import { useEffect, useMemo } from "react";

import { io, Socket } from "socket.io-client";

import { setSocketConnectionAction } from "context/App/App.actions";
import { useAppDispatch } from "context/App/App.context";

import environments from "environments";

export default function useSocket<U extends EventSourceEventMap, V extends EventSourceEventMap>(teamId?: string) {
  const socket: Socket<U, V> | undefined = useMemo(() => createSocketConnection(teamId), [teamId]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSocketConnectionAction(socket));

    return () => {
      socket?.disconnect();
      dispatch(setSocketConnectionAction(undefined));
    };
  }, [dispatch, socket]);

  return { socket };
}

function createSocketConnection(teamId?: string) {
  if (!teamId) return;

  return io(`${environments.socketioHost}/dashboard`, {
    upgrade: false,
    transports: ["websocket"],
    query: { team_id: teamId },
  });
}
