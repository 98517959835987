import React, { ReactNode } from "react";

import { toast } from "react-toastify";
import { UpdateOptions } from "react-toastify/dist/types";

import * as Styled from "./Toast.styles";

interface DisplayToastProps {
  id?: string;
  icon?: string;
  title?: string;
  content: string | React.ReactNode;
  duration?: number | false;
  progress?: number;
  hideProgress?: boolean;
  closeButton?: boolean;
  containerId?: string;
}

export default function displayToast({
  id,
  icon,
  title,
  content,
  duration,
  progress = 0,
  hideProgress = true,
  closeButton = true,
  containerId,
}: DisplayToastProps) {
  return toast(
    <ToastContent
      icon={icon}
      title={title}
      content={content}
    />,
    {
      toastId: id,
      autoClose: duration,
      progress,
      hideProgressBar: hideProgress,
      closeButton,
      containerId,
    },
  );
}

interface UpdateToastProps {
  id: number | string;
  options?: UpdateOptions;
}

const updateToast = ({ id, options }: UpdateToastProps) => {
  toast.update(id, options);
};

interface DismissToastProps {
  id: number | string;
}

const dismissToast = ({ id }: DismissToastProps) => {
  toast.dismiss(id);
};

interface ToastContentProps {
  icon?: string;
  title?: string;
  content: string | ReactNode;
}

export const ToastContent = ({ icon, title, content }: ToastContentProps) => {
  return (
    <Styled.ToastContent>
      {icon && (
        <Styled.ToastIcon
          alt="toast icon"
          src={icon}
        />
      )}
      <div>
        {title && <Styled.ToastTitle>{title}</Styled.ToastTitle>}
        <p>{content}</p>
      </div>
    </Styled.ToastContent>
  );
};

export { updateToast, dismissToast };
