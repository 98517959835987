import React, { Fragment } from "react";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Tooltip from "components/Tooltip/Tooltip";

import { MappingFile, PlatformOs } from "models";

import { cell, getContent } from "./MappingFilesRowContent";

interface Props {
  onDeleteFile: (fileId: string) => void;
  mappingFile: MappingFile;
  os: PlatformOs;
  isAppActive: boolean;
}

export const MappingFilesRow = ({ onDeleteFile, mappingFile, os, isAppActive }: Props) => {
  const DropdownButton = () => {
    if (!isAppActive) {
      return <CenteredCell />;
    }

    return (
      <CellEnd>
        <Dropdown>
          <DropdownItem
            onClick={() => onDeleteFile(mappingFile.id)}
            error
          >
            Remove
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    );
  };
  const device = Object.values(PlatformOs).find((value) => value === os);
  const content = getContent(mappingFile, <DropdownButton />);

  if (!device) return <Fragment />;

  return (
    <Fragment>
      {device &&
        device !== PlatformOs.WEB &&
        content[device].map((item, index) => {
          return (
            <Tooltip
              key={index}
              text={item.tooltip ?? ""}
              position="top"
              offset={0}
              disabled={!item.tooltip}
            >
              {cell[item.position as "center" | "end"](item.component)}
            </Tooltip>
          );
        })}
    </Fragment>
  );
};
