import styled, { css } from "styled-components";

import { vars } from "../../styles";

export const TabButton = styled.button<{ $isSelected?: boolean }>`
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 15px;
  background: none;
  color: ${vars.colors.grey70};
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
  padding-bottom: 0.5rem;

  &:hover {
    color: ${vars.colors.grey10};
  }

  ${(props) =>
    props.$isSelected &&
    css`
      color: ${vars.colors.grey10};
      border-bottom: 1px dotted white;
    `}
`;
