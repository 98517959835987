import { CSSObjectWithLabel, GroupBase, StylesConfig } from "react-select";
import styled, { css } from "styled-components";

import { vars } from "styles";

export const SelectDropdownWrap = styled.div<{
  $label: string;
  $disabled?: boolean;
  $searchable?: boolean;
  $isFocused?: boolean;
}>`
  position: relative;
  width: 100%;
  border: 1px solid ${vars.colors.grey80};
  border-radius: 1rem;

  /* label */
  ${(props) =>
    props.$label &&
    css`
      .react-select__control {
        padding-top: 2.8rem;
      }
    `}

  ${(props) =>
    props.$isFocused &&
    props.$searchable &&
    css`
      .react-select__single-value {
        visibility: hidden;
      }
    `}
  label {
    display: block;
    font-size: 1.2rem;
    color: ${vars.colors.grey60};
    line-height: 1.33;
    margin-bottom: 0.3rem;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    z-index: 2;
    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  ${(props) =>
    !props.$disabled &&
    css`
      &:active,
      &:focus-within {
        border: 1px solid ${vars.colors.grey70};
        border-radius: 1rem;
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      border: 1px solid ${vars.colors.grey80};
      border-radius: 1rem;
      background-color: ${vars.colors.grey90};
      opacity: 0.3;
      &:active,
      &:focus-within {
        border: 1px solid transparent;
        border-radius: 1rem;
      }
    `}

  h4 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0;
    color: ${vars.colors.grey30};
  }

  p {
    font-size: 1.4rem;
    color: ${vars.colors.grey50};
    line-height: 1.29;
  }

  input {
    position: relative;
    ${(props) =>
      !props.$searchable &&
      css`
        min-height: 3.6rem;
        top: 0.7rem;
      `}
    color: ${vars.colors.grey30} !important;
  }
`;

export const customStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  placeholder: (base: CSSObjectWithLabel, state: any) => ({
    ...base,
    color: vars.colors.grey30,
    display: state.isFocused && "none",
    top: "2px",
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    color: vars.colors.grey30,
    fontSize: "1.6rem",
    marginRight: "4rem",
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    border: "none",
  }),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
    borderRadius: "1rem",
    backgroundColor: vars.colors.grey90,
    padding: "1.2rem 1.2rem 1.2rem 1.2rem",
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    color: vars.colors.grey30,
  }),
  indicatorsContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    display: "block",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 20,
    display: "flex",
    alignItems: "center",

    color: vars.colors.grey50,
    ":hover": {
      color: vars.colors.grey50,
    },
  }),
  groupHeading: (base: CSSObjectWithLabel) => ({
    ...base,
    margin: 0,
    paddingLeft: "1.2rem",
    paddingBottom: "1rem",
    textTransform: "unset",
    fontSize: "1.2rem",
    fontWeight: "normal",
    color: vars.colors.grey60,
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex: 9999,
    fontFamily: vars.fonts.fontFamily,
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: "1rem",
    backgroundColor: vars.colors.grey100,
    marginTop: "0.5rem",
    zIndex: vars.zIndex.selectDropdownMenu,
    boxShadow: "0 0.2rem 1rem 0 #1c1c1e",
    border: `1px solid ${vars.colors.grey90}`,
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: "1rem 0",
  }),
  noOptionsMessage: (base: CSSObjectWithLabel) => ({
    ...base,
    color: vars.colors.grey50,
    fontSize: "1.4rem",
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: vars.colors.grey100,
    cursor: "pointer",
    transition: vars.transitions.hover,
    ":hover": {
      backgroundColor: vars.colors.grey90,
      color: vars.colors.white,
    },
    color: vars.colors.grey50,
    padding: "1rem 2rem 1rem 1.2rem",
    width: "inherit",
    fontSize: "1.4rem",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  }),
};

export const OptionContainer = styled.div<{ $withSubtitle?: boolean }>`
  cursor: pointer;

  span {
    whitespace: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.$withSubtitle &&
    css`
      height: initial;
    `}

  p {
    cursor: pointer;
  }
`;

export const Subtitle = styled.p<{ $singleValue?: boolean }>`
  word-break: break-word;
  white-space: pre-line;
  font-size: 1.4rem;

  ${(props) =>
    props.$singleValue &&
    css`
      padding-right: 2.4rem;
    `}
`;
