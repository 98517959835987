import { useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { Workspace } from "models";
import {
  BaseActivationChallengeRes,
  IntegrationType,
  JiraServerStartIntegrationPayload,
  OAuth2PassthroughState,
} from "models/integrations";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

import { RoutePaths } from "router/config/routePaths";

import { utf8_to_b64 } from "util/encode";
import { Validators } from "util/Validators";

import { BaseIntegrationModalProps } from "../GithubIntegrationModalBody";

interface FormState {
  clientId: string;
  clientSecret: string;
  baseURL: string;
}

const initialFormState: FormState = {
  clientId: "",
  clientSecret: "",
  baseURL: "",
};

const validators = {
  clientId: (value: string) => {
    if (!Validators.required(value)) return new Error("Enter clientID");
  },
  clientSecret: (value: string) => {
    if (!Validators.required(value)) return new Error("Enter client secret");
  },
  baseURL: (value: string) => {
    if (!Validators.required(value)) return new Error("Enter base url");
  },
};

interface Props extends BaseIntegrationModalProps {
  selectedAppId: string;
  workspace: Workspace;
}
export const useJiraServerIntegrationFormConsumer = ({ selectedAppId, workspace, onFinish }: Props) => {
  const { startIntegration } = useIntegrationFormConsumer({ selectedAppId, type: IntegrationType.JIRA, noInfo: true });
  const [loading, setLoading] = useState(false);

  const { formState, formError, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators,
  });

  async function handleConnect() {
    const redirectURL = new URL(RoutePaths.INTEGRATION_ACTIVATION, window.location.origin);
    redirectURL.searchParams.append("type", IntegrationType.JIRA.toLowerCase());

    const payload = {
      client_id: formState.clientId,
      client_secret: formState.clientSecret,
      redirect_uri: redirectURL.href,
      server_url: formState.baseURL,
    };
    try {
      setLoading(true);

      const data = await startIntegration<BaseActivationChallengeRes, JiraServerStartIntegrationPayload>(
        payload,
        IntegrationType.JIRA,
      );

      setLoading(false);

      if (!data) return;

      const stateParam: OAuth2PassthroughState = {
        stateId: data.state,
        workspaceId: workspace.id,
        workspaceSlug: workspace.slug,
        appId: selectedAppId,
      };

      const authURL = new URL(`${formState.baseURL}/rest/oauth2/latest/authorize`);

      const encodedState = utf8_to_b64(JSON.stringify(stateParam));

      authURL.searchParams.set("client_id", formState.clientId);
      authURL.searchParams.set("scope", "ADMIN");
      authURL.searchParams.set("redirect_uri", redirectURL.href);
      authURL.searchParams.set("state", encodedState);
      authURL.searchParams.set("response_type", "code");
      authURL.searchParams.set("prompt", "consent");

      window.location.href = authURL.href;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  return { formState, formError, handleFormChange, handleFormSubmit, loading };
};
