import React, { createContext, Dispatch, Reducer, useContext, useReducer } from "react";

export default function makeContextStore<Action, State>(
  reducer: Reducer<State, Action>,
  initialState: State,
): [React.FC, () => State, () => Dispatch<Action>] {
  const StoreContext = createContext<State>({} as State);
  const DispatchContext = createContext<Dispatch<Action>>({} as Dispatch<Action>);

  const StoreProvider: React.FC = ({ children }) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
      <DispatchContext.Provider value={dispatch}>
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
      </DispatchContext.Provider>
    );
  };

  function useStore() {
    return useContext(StoreContext);
  }

  function useDispatch() {
    return useContext(DispatchContext);
  }

  return [StoreProvider, useStore, useDispatch];
}
