import styled from "styled-components";

import { vars } from "styles";

export const Link = styled.a`
  display: flex;
  align-items: flex-end;
  color: ${vars.colors.grey70};
  font-size: 12px;

  &:active,
  &:focus-within {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    color: ${vars.colors.white};

    svg {
      path {
        stroke: ${vars.colors.white};
      }
    }
  }

  svg {
    height: 1.4rem;
    width: 1.4rem;
    path {
      stroke: ${vars.colors.grey70};
    }
  }
`;

export const Button = styled.button`
  border-radius: 3rem;
  border: 1px solid ${vars.colors.grey90};
  background: ${vars.colors.grey100};
  width: 101px;
  height: 24px;
  color: ${vars.colors.grey30};
  font-size: 1.4rem;
  margin-top: 0.4rem;
`;
