import React from "react";

import { generatePath, Navigate } from "react-router-dom";

import { Spinner } from "components/Spinner/Spinner";

interface RedirectProps {
  path: string;
  dynamicValues: Record<string, string | undefined>;
}

export default function RedirectWithLoading({ path, dynamicValues }: RedirectProps) {
  const allDynamicValuesDefined = Object.values(dynamicValues).every((value) => !!value);

  if (!allDynamicValuesDefined) {
    return <Spinner />;
  }

  const to = generatePath(path, dynamicValues);
  return (
    <Navigate
      to={to}
      replace
    />
  );
}
