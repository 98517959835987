import { useParams, useSearchParams } from "react-router-dom";

import { CommonPassthroughState, TrelloCallbackParams } from "models/integrations";

import { b64_to_utf8 } from "util/encode";

interface Return {
  workspaceSlug: string | undefined;
  appKey: string | undefined;
}

export const useWorkspaceAppParams = (): Return => {
  const [searchParams] = useSearchParams();
  const params = useParams();

  return {
    workspaceSlug: params.workspaceSlug || extractCommonParams(searchParams).stateSlug,
    appKey: params.appKey || searchParams.get("returnTo"),
  } as Return;
};

const extractCommonParams = (params: URLSearchParams): Partial<{ stateSlug: string }> => {
  const callback = extractCallbackParamsState(params);
  if (!callback) return {};

  const passthroughState = extractCommonPassthroughStateWorkspaceSlug(callback.state);
  if (!passthroughState) return {};

  return { stateSlug: passthroughState.workspaceSlug };
};

export const extractCommonPassthroughStateWorkspaceSlug = (
  passthroughState: string,
): { workspaceSlug: string } | undefined => {
  let state: Partial<CommonPassthroughState> = {};

  try {
    state = JSON.parse(passthroughState) as Partial<CommonPassthroughState>;
  } catch (error) {}

  try {
    const jsonString = b64_to_utf8(passthroughState);
    state = JSON.parse(jsonString);
  } catch (error) {}

  if (!state.workspaceSlug) return undefined;
  return { workspaceSlug: state.workspaceSlug };
};

export const extractCallbackParamsState = (params: URLSearchParams): { state: string } | undefined => {
  const { state } = Object.fromEntries(params) as unknown as Partial<TrelloCallbackParams>;
  if (!state) return undefined;

  return { state };
};
