import styled from "styled-components";

import Button from "components/Button/Button";
import Option from "components/Option/Option";

import { vars } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48rem;
`;

export const InputWrapper = styled.div`
  margin-bottom: 2.4rem;
  width: 100%;
`;

export const CreateButton = styled(Button)`
  width: 100%;
`;

export const CheckboxOption = styled(Option)`
  color: ${vars.colors.grey60};
`;
