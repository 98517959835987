import React from "react";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./ModalHeader.styles";

export type ModalHeaderProps = {
  heading?: string;
  subtitle?: string;
  className?: string;
  iconPath?: string;
  testId?: string;
  hasBorderBottom?: boolean;
  noContent?: boolean;
};

export const ModalHeader = ({ heading, subtitle, iconPath, testId, hasBorderBottom, noContent }: ModalHeaderProps) => {
  return (
    <Styled.Flex
      $column
      $gap={2.4}
      $hasBorderBottom={hasBorderBottom}
      $noContent={noContent}
    >
      <Flex $gap={1.4}>
        {iconPath && <Styled.Logo src={iconPath} />}
        <Styled.Title
          heading3
          data-testid={testId}
          marginBottom={0}
        >
          {heading}
        </Styled.Title>
      </Flex>
      {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
    </Styled.Flex>
  );
};
