import React, { ChangeEvent, useEffect, useState } from "react";

import * as Styled from "./Option.styles";
import RoundedCanvas from "../RoundedCanvas/RoundedCanvas";

interface Props {
  className?: string;
  name?: string;
  radio?: boolean;
  checked?: boolean;
  label?: string;
  icon?: string;
  letter?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCheck?: (e: ChangeEvent<HTMLInputElement>) => void;
  invisibleUntilHovered?: boolean;
  testId?: string;
  backgroundColor?: string;
  txtColor?: string;
  canvasImage?: string;
  image?: string;
  iconElement?: JSX.Element;
}

const _uniqueId = (prefix: string): string => {
  let b = "";
  let a = 0;
  for (; a++ < 36; b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : "-");
  return prefix + b;
};

const Option: React.FC<Props> = ({
  className,
  name,
  radio,
  checked,
  label,
  icon,
  letter,
  disabled,
  handleCheck,
  invisibleUntilHovered,
  testId,
  backgroundColor,
  txtColor,
  canvasImage,
  image,
  iconElement,
}): JSX.Element => {
  const [id] = useState(_uniqueId("option-"));

  const [isChecked, setChecked] = useState<boolean | undefined>(false);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const check = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    if (handleCheck) {
      handleCheck(e);
    }
  };

  return (
    <Styled.Option
      $checked={isChecked}
      $withIcon={Boolean(icon || iconElement)}
      $withCanvas={!!letter}
      $invisibleUntilHovered={invisibleUntilHovered}
      className={`option ${className}`}
      data-testid={testId}
      $disabled={disabled}
    >
      <input
        id={id}
        type={radio ? "radio" : "checkbox"}
        name={name}
        checked={isChecked}
        onChange={check}
        disabled={disabled}
        readOnly
      />
      <label htmlFor={id}>
        {icon && (
          <div className="image-wrap">
            <img
              src={icon}
              alt="Icon"
            />
          </div>
        )}

        {iconElement}

        {!icon && (letter || canvasImage) && (
          <div className="canvas-wrap">
            <RoundedCanvas
              letter={letter}
              bgColor={backgroundColor}
              txtColor={txtColor}
              image={canvasImage}
            />
          </div>
        )}

        {image && <Styled.Image src={image} />}

        <span>{label}</span>
      </label>
    </Styled.Option>
  );
};

export default Option;
