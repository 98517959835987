import asana from "assets/images/integrations/asana-icon.svg";
import azure from "assets/images/integrations/azure-icon.svg";
import clickup from "assets/images/integrations/clickup-icon.svg";
import github from "assets/images/integrations/github-icon.svg";
import jira from "assets/images/integrations/jira-icon.svg";
import linear from "assets/images/integrations/linear-icon.svg";
import sentry from "assets/images/integrations/sentry-icon.svg";
import slack from "assets/images/integrations/slack-icon.svg";
import trello from "assets/images/integrations/trello-icon.svg";
import webhook from "assets/images/integrations/webhook-icon.svg";
import zapier from "assets/images/integrations/zapier-icon.svg";

import { IntegrationType } from "models/integrations";

export const getIntegrationTypeLogo = (integrationType: IntegrationType) => {
  switch (integrationType) {
    case IntegrationType.ASANA:
      return asana;
    case IntegrationType.AZURE_DEVOPS:
      return azure;
    case IntegrationType.CLICKUP:
      return clickup;
    case IntegrationType.GITHUB:
      return github;
    case IntegrationType.JIRA:
    case IntegrationType.JIRA_CLOUD:
    case IntegrationType.JIRA_SERVER:
      return jira;
    case IntegrationType.SLACK:
      return slack;
    case IntegrationType.TRELLO:
      return trello;
    case IntegrationType.LINEAR:
      return linear;
    case IntegrationType.ZAPIER:
      return zapier;
    case IntegrationType.SENTRY:
      return sentry;
    default:
      return webhook;
  }
};
