import { AxiosResponse } from "axios";

import ApiService from "services/Api.service";

export class CannyAuthService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  public authenticateCanny(): Promise<AxiosResponse<{ token: string }>> {
    return this.apiService.post<{ token: string }>({
      resource: "accounts/users/auth_canny",
    });
  }
}
