import { useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import usePersonalInfoApiConsumer from "pages/Account/consumers/api/usePersonalInfoApiConsumer";

import { validatePassword } from "util/ValidatorFunctions";

interface HookParams {
  onSubmitCallback?: () => void;
}

interface SetPasswordFormState {
  newPassword: string;
}

const validators = {
  newPassword: validatePassword,
};

enum ErrorStatusCodes {
  PASSWORD_ALREADY_SET = 403,
}

export default function usePersonalInfoSetPasswordFormConsumer(params?: HookParams) {
  const { setPassword } = usePersonalInfoApiConsumer();

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<SetPasswordFormState>({
    initialState: { newPassword: "" },
    onSubmit: handleSetPassword,
    validators,
  });

  const [loading, setLoading] = useState(false);

  async function handleSetPassword() {
    try {
      setLoading(true);

      await setPassword({ new_password: formState.newPassword });

      displayToast({ title: "Fantastic!", content: "Password set successfully" });

      await params?.onSubmitCallback?.();
    } catch (error) {
      if (error.response.status === ErrorStatusCodes.PASSWORD_ALREADY_SET) {
        setError({ newPassword: new Error("Password is already set for this account") });
      }
    } finally {
      setLoading(false);
    }
  }

  return { handleFormChange, handleFormSubmit, formState, formError, loading };
}
