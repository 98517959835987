import { format } from "date-fns";

import { getYearsSince } from "./timeSince";

enum CreationTimeType {
  TODAY,
  YESTERDAY,
  LESS_THAN_A_YEAR_AGO,
  MORE_THAN_A_YEAR_AGO,
}

const MILLIS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

export const resolveIssueCreatedTime = (timestamp: string) => {
  return format(Date.parse(timestamp), "LLL d, HH:mm:ss");
};

export const resolveCreationTimeString = (reportedTimestamp: string): string => {
  const reportedDate: Date = new Date(`${reportedTimestamp}+00:00`);

  switch (resolveCreationTimeType(reportedDate)) {
    case CreationTimeType.TODAY:
      return `Today ${printEnUsLocaleDate(reportedDate, { hour: "numeric", minute: "numeric", hour12: false })}`;
    case CreationTimeType.YESTERDAY:
      return `Yesterday ${printEnUsLocaleDate(reportedDate, { hour: "numeric", minute: "numeric", hour12: false })}`;
    case CreationTimeType.LESS_THAN_A_YEAR_AGO:
      return printEnUsLocaleDate(reportedDate, {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
    case CreationTimeType.MORE_THAN_A_YEAR_AGO:
      return printEnUsLocaleDate(reportedDate, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
  }
};

export const resolveLogTimeString = (reportedTimestamp: string): string => {
  const reportedDate = new Date(reportedTimestamp);

  return printEnUsLocaleDate(reportedDate, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
    month: "short",
    day: "numeric",
    year: "numeric",
    hour12: false,
  });
};

export const resolveDateString = (reportedTimestamp: string): string => {
  const reportedDate = new Date(reportedTimestamp);

  return printEnUsLocaleDate(reportedDate, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const resolveBlackboxChartTime = (timestamp: string) => {
  return format(Date.parse(timestamp), "HH:mm:ss");
};

export const convertMillisecondsToString = (ms: number): string => {
  let result = "";
  if (ms >= 0 && ms < 1000) {
    result = `${ms}ms`;
  } else if (ms >= 1000) {
    const seconds: number = ms / 1000;
    result = `${seconds}s`;
  }
  return result;
};

const resolveCreationTimeType = (reportedDate: Date): CreationTimeType => {
  if (isToday(reportedDate)) {
    return CreationTimeType.TODAY;
  }

  if (isYesterday(reportedDate)) {
    return CreationTimeType.YESTERDAY;
  }

  if (isLessThanAYearAgo(reportedDate)) {
    return CreationTimeType.LESS_THAN_A_YEAR_AGO;
  }

  return CreationTimeType.MORE_THAN_A_YEAR_AGO;
};

const isToday = (date: Date): boolean => {
  const startOfToday = new Date().setHours(0, 0, 0, 0);
  const startOfDateReported = new Date(date).setHours(0, 0, 0, 0);
  return startOfToday === startOfDateReported;
};

const isYesterday = (date: Date): boolean => {
  const startOfToday = new Date().setHours(0, 0, 0, 0);
  const startOfDateReported = new Date(date).setHours(0, 0, 0, 0);
  return startOfToday - startOfDateReported === MILLIS_IN_ONE_DAY;
};

const isLessThanAYearAgo = (date: Date): boolean => {
  return getYearsSince(date).interval === 0;
};

const printEnUsLocaleDate = (date: Date, options?: Intl.DateTimeFormatOptions): string => {
  return date.toLocaleString("en-us", options);
};

export function dateStringForMappingFiles(date: Date): string {
  const monthName = date.toLocaleString("default", { month: "short" });

  return `${monthName} ${date.getDate()}, ${date.getUTCFullYear()}`;
}
