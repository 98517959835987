import { useEffect, useRef, useState } from "react";

import { FilterHit } from "./sharedTypes";

export type FetchFNResult = Array<FilterHit>;
export type FetchFNParams<TDeps> = { attributeName: string; query: string; signal: AbortSignal; deps: Required<TDeps> };

type Props<TDeps> = {
  query: string;
  deps: TDeps;
  attributeName: string;
  fetchFN?: (_: FetchFNParams<TDeps>) => Promise<FetchFNResult>;
};

type State = {
  availableFilterHits: FetchFNResult;
  loading: boolean;
  error: boolean;
};

/**
 * Get filter hits for attributeName and typed query
 */
export function useFilterHitsQuery<TDeps extends Array<string | undefined>>({
  attributeName,
  query,
  fetchFN,
  deps,
}: Props<TDeps>) {
  const fetchFNRef = useRef(fetchFN);

  const [state, setState] = useState<State>({
    availableFilterHits: [],
    loading: false,
    error: false,
  });

  useEffect(() => {
    if (!fetchFNRef || !fetchFNRef.current || !deps || !Boolean(deps[0])) return;

    const abortController = new AbortController();

    setState({
      loading: true,
      error: false,
      availableFilterHits: [],
    });

    fetchFNRef
      .current({
        query: query ?? "",
        signal: abortController.signal,
        attributeName,
        deps: deps as Required<TDeps>,
      })
      .then((availableFilters) => {
        if (abortController.signal.aborted) return;
        setState({
          loading: false,
          error: false,
          availableFilterHits: availableFilters,
        });
      })
      .catch((e) => {
        if (abortController.signal.aborted) return;
        setState({
          loading: false,
          error: !!e,
          availableFilterHits: [],
        });
      });

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, attributeName, ...deps]);

  return {
    ...state,
  };
}

// maybe needed later
/* function useDebounceValue<T>(value: T, ms = 1000) {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);

  useEffect(() => {
    const nodeTimeout = setTimeout(() => {
      setDebouncedValue(value);
    }, ms);
    return () => {
      clearTimeout(nodeTimeout);
    };
  }, [value]);

  return debouncedValue;
} */
