import React from "react";

import { CustomFieldType, IssueCustomField } from "models/issueTracking";

import { CustomFieldEmailIcon, CustomFieldPickerIcon, CustomFieldTextIcon } from "pages/shared/icons/icons";

import * as Styled from "./CustomFields.styles";

interface Props {
  customFields?: IssueCustomField[] | null;
  marginTop?: number;
  marginBottom?: number;
}

export default function CustomFields({ customFields = [] }: Props) {
  const filteredFields = customFields?.filter((field) => field.type !== CustomFieldType.TITLE);

  if (!filteredFields || filteredFields?.length == 0) {
    return null;
  }

  return (
    <Styled.CustomFieldsContainer>
      {filteredFields?.map((customField) => renderCustomFieldItem(customField))}
    </Styled.CustomFieldsContainer>
  );
}

const renderCustomFieldItem = (item: IssueCustomField) => (
  <Styled.Figure key={item.label}>
    <Styled.LeftBox>
      <Styled.Tooltip
        position="top"
        text={getCustomFieldTooltip(item.type)}
      >
        {getCustomFieldIcon(item.type)}
      </Styled.Tooltip>
      <Styled.Label>{item.label}</Styled.Label>
    </Styled.LeftBox>
    <Styled.RightBox>
      <Styled.Value>{item.value}</Styled.Value>
    </Styled.RightBox>
  </Styled.Figure>
);

export const getCustomFieldIcon = (type: CustomFieldType) => {
  switch (type) {
    case CustomFieldType.TEXT_INPUT:
      return <CustomFieldTextIcon />;
    case CustomFieldType.EMAIL:
      return <CustomFieldEmailIcon />;
    case CustomFieldType.PICKER:
      return <CustomFieldPickerIcon />;
    default:
      return null;
  }
};

const getCustomFieldTooltip = (type: CustomFieldType) => {
  switch (type) {
    case CustomFieldType.TEXT_INPUT:
      return "Freeform text";
    case CustomFieldType.EMAIL:
      return "Email";
    case CustomFieldType.PICKER:
      return "Picker";
    default:
      return "";
  }
};
