import React from "react";

import RedirectIfAuthenticated from "pages/shared/components/RedirectIfAuthenticated";
import { SignInService } from "pages/SignIn/services/SignInService";

import dependencyInjection from "util/dependencyInjection";

import { SignUpService } from "./services/SignUpService";
import { SignUpView } from "./views/SignUpView";

interface Services {
  signUpService: SignUpService;
  signInService: SignInService;
}

const signUpService = new SignUpService();
const signInService = new SignInService();

const [SignUpDependencyProvider, useSignUpDeps] = dependencyInjection<Services>({
  services: { signUpService, signInService },
});

const SignUpPage = () => (
  <RedirectIfAuthenticated>
    <SignUpDependencyProvider>
      <SignUpView />
    </SignUpDependencyProvider>
  </RedirectIfAuthenticated>
);

export { useSignUpDeps, SignUpPage };
