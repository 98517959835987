import styled, { css } from "styled-components";

import { vars } from "styles";

export const CardWrap = styled.div<{ $noPadding?: boolean }>`
  position: relative;
  ${(props) =>
    !props.$noPadding &&
    css`
      padding: 2.4rem 0rem;
    `}
  border-radius: 1.5rem;
  background-color: ${vars.colors.grey100};
`;

export const CardScroll = styled.div<{ $height?: number; $noPadding?: boolean }>`
  ${(props) =>
    !props.$noPadding &&
    css`
      padding: 0 2rem;
      margin: 0 1.2rem;
    `}
  ${(props) =>
    props.$height &&
    css`
      max-height: ${props.$height}px;
      overflow-y: overlay;
    `};
`;
