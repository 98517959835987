import React from "react";

import { useNavigate, useParams } from "react-router-dom";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import { DetailsBar } from "components/DetailsBar/DetailsBar";
import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import Heading from "components/Heading/Heading";
import InputWithTags from "components/Input/InputWithTags/InputWithTags";
import { RowType } from "components/MasterTable/models/MasterTableModel";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCustomElementInjection } from "hooks/useCustomElementInjection";

import NavigationCustomElement from "layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID, NAV_LIST } from "layouts/components/PageNavigation/PageNavigation";

import LinkedTicketsTable from "pages/shared/components/LinkedTicketsTable/LinkedTicketsTable";
import MessageContainer from "pages/shared/components/Messages/MessageContainer";
import { TicketContainer } from "pages/shared/components/TicketDetails/TicketDetailsContainer.styles";
import { TicketInfoContainer } from "pages/shared/components/TicketDetails/TicketInfoContainer";

import identifiers from "util/identifiers.json";

import useCommonTagsApiConsumer from "../../../consumers/useCommonTagsApiConsumer";
import CrashesPageNavigation from "../components/PageNavigation/CrashesPageNavigation";
import CrashReportPageNavigationContent from "../components/PageNavigation/CrashReport/CrashReportPageNavigationContent";
import useCrashOverviewApiConsumer from "../consumers/useCrashOverviewApiConsumer";

export default function CrashOverviewPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const navigate = useNavigate();
  const params = useParams<{ workspaceSlug: string; appKey: string; crashReportKey: string }>();
  const consumer = useCrashOverviewApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
  });

  const { commonTags } = useCommonTagsApiConsumer({ selectedWorkspaceId: selectedWorkspace?.id });

  const CustomElement = (
    <NavigationCustomElement>
      <CrashesPageNavigation crashTitle={consumer.crashOverview ? consumer.crashOverview.title : ""}>
        <CrashReportPageNavigationContent
          crashReport={consumer.crashOverview}
          selectedAppKey={selectedApp?.key}
          selectedWorkspaceSlug={selectedWorkspace?.slug}
          navigate={navigate}
        />
      </CrashesPageNavigation>
    </NavigationCustomElement>
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading crash overview. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!consumer.crashOverview) {
    return <Spinner />;
  }

  return (
    <PageWrap>
      <TicketContainer>
        <TicketInfoContainer>
          <Heading
            as="h2"
            heading2
            testId={identifiers["crash.reports.details.header"]}
            fontWeight="bold"
          >
            {consumer.crashOverview?.title}
          </Heading>
          <Heading
            as="h4"
            heading4
          >
            {consumer.crashOverview?.subtitle}
          </Heading>

          <DetailsBar
            details={consumer.crashOverview}
            changeAssignee={consumer.changeAssignee}
            changePriority={consumer.changePriority}
            changeStatus={consumer.changeStatus}
            deleteTicket={consumer.deleteCrashReport}
            showLock
            type={RowType.CRASH_REPORT}
            onMergeAction={consumer.onMergeAction}
            clearMerge={consumer.onUnMergeAction}
          />

          <LinkedTicketsTable linkedTickets={consumer.linkedIssues} />

          <InputWithTags
            selectedTags={consumer.crashOverview.tags}
            commonTags={commonTags}
            onAddTag={consumer.createCrashReportTag}
            onRemoveTag={consumer.deleteCrashReportTag}
          />

          <MessageContainer activity={consumer.crashActivity} />
        </TicketInfoContainer>
      </TicketContainer>
    </PageWrap>
  );
}
