import React, { PropsWithChildren } from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "./GridTable.styles";

export type GridTableProps = {
  className?: string;
  gridTemplateColumns: string;
  style?: CSSProperties;
};

export type CellProps = {
  color?: string;
  className?: string;
  minWidth?: string;
  gap?: number;
  maxWidth?: string;
  style?: CSSProperties;
};

export const GridTable = ({ gridTemplateColumns, children, className, style }: PropsWithChildren<GridTableProps>) => {
  return (
    <Styled.Grid
      style={style}
      $gridTemplateColumns={gridTemplateColumns}
      className={className}
    >
      {children}
    </Styled.Grid>
  );
};

export const CenteredCell = ({
  color,
  children,
  minWidth,
  maxWidth,
  gap,
  className,
  style,
}: PropsWithChildren<CellProps>) => {
  return (
    <Styled.CenteredCell
      $color={color}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      className={className}
      $gap={gap}
      style={style}
    >
      {children}
    </Styled.CenteredCell>
  );
};

export const CellEnd = ({ children, className }: PropsWithChildren<CellProps>) => {
  return <Styled.CellEnd className={className}>{children}</Styled.CellEnd>;
};
