import styled, { css } from "styled-components";

import { HelperText as BaseHelperText } from "components/HelperText/HelperText";
import BaseSelectDropdown from "components/SelectDropdown/SelectDropdown";
import BaseTooltip from "components/Tooltip/Tooltip";

export const Tooltip = styled(BaseTooltip)`
  margin-top: 2rem;
  width: auto;
`;

export const Logo = styled.img`
  width: 2rem;
`;

export const HelperText = styled(BaseHelperText)<{ $bottomMargin?: boolean }>`
  margin-top: 2rem;
  ${(props) =>
    props.$bottomMargin &&
    css`
      margin-bottom: 0.8rem;
    `}
`;

export const SelectDropdown = styled(BaseSelectDropdown)`
  margin-top: 2.4rem;
`;
