import styled, { css } from "styled-components";

import BaseDropdown, { DropdownItem as BaseDropdownItem } from "components/Dropdown/Dropdown";

import * as Icon from "pages/shared/icons/icons";

import { vars } from "styles";

export const AssigneeDropdown = styled(BaseDropdown)`
  span {
    margin-right: 0;
  }
`;

export const LockIcon = styled.div<{ $disabled?: boolean }>`
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const DropdownItem = styled(BaseDropdownItem)<{ $clicked?: boolean }>`
  ${(props) =>
    props.$clicked &&
    css`
      background: ${vars.colors.grey90};
    `}

  p {
    color: ${vars.colors.grey50};
    max-width: 22rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const dropdownScroll = `max-height: 25rem;`;

export const UnlockedIcon = styled(Icon.UnlockedIcon)<{ $disabled?: boolean }>`
  ${(props) =>
    !props.$disabled &&
    css`
      path {
        fill: ${vars.colors.grey60};
      }
      &:hover {
        path {
          fill: ${vars.colors.grey30};
        }
      }
    `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;

export const Checkmark = styled.div`
  margin-right: 0.8rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export const CheckmarkIcon = styled(Icon.CheckmarkIcon)`
  path {
    stroke: ${vars.colors.white};
  }
`;
