import styled from "styled-components";

import BaseTooltip from "../../../../components/Tooltip/Tooltip";
import { vars } from "../../../../styles";

export const Logo = styled.img`
  width: 2.4rem;
`;

export const ConnectedIcon = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${vars.colors.mint};
  border-radius: 50%;
`;

export const DisconnectedIcon = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${vars.colors.red};
  border-radius: 50%;
`;

export const Connected = styled.p`
  color: ${vars.colors.grey30};
`;

export const Disconnected = styled.p`
  color: ${vars.colors.grey60};
`;

export const Tooltip = styled(BaseTooltip)`
  width: auto;
`;
