import React, { Fragment } from "react";

import Button from "components/Button/Button";
import { HelperText } from "components/HelperText/HelperText";
import Input from "components/Input/Input";
import { ModalFooter } from "components/Modal/ModalFooter/ModalFooter";
import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { Form } from "../ModalBody.styles";
import { IntegrationHelperText } from "./components/IntegrationHelperText";
import { useAzureAuthorizationFormConsumer } from "./consumers/useAzureAuthorizationFormConsumer";

interface Props {
  workspaceId: string;
  selectedAppId: string;
  onFinish: () => void;
  toggle: () => void;
}

export const AuthorizeAzureBody = ({ workspaceId, selectedAppId, onFinish, toggle }: Props) => {
  const { formState, formError, handleFormChange, handleFormSubmit, disabled, handleTokensLinkClick } =
    useAzureAuthorizationFormConsumer({
      workspaceId,
      selectedAppId,
      onFinish,
    });
  const { projectUrl, token } = formState;
  const { projectUrl: projectUrlErr, token: tokenErr } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !projectUrl || !token || !!projectUrlErr;

  return (
    <Fragment>
      <div style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}>
        <Form
          noValidate
          onSubmit={(event) => event.preventDefault()}
        >
          <HelperText>
            In another tab, open the Azure DevOps project you want to forward tickets into. Paste the project&apos;s URL
            here.
          </HelperText>

          <Input
            name="projectUrl"
            label="Project URL"
            value={projectUrl}
            placeholder="e.g. https://dev.azure.com/organization/yourproject"
            onChange={handleChange}
            error={!!projectUrlErr}
            helper={projectUrlErr && projectUrlErr.message}
            disabled={disabled}
            autoFocus
          />

          <IntegrationHelperText
            arrowColor={vars.colors.azureBlue}
            titleComponent={
              <Fragment>
                Now click this link to open&nbsp;
                {
                  <a
                    onClick={handleTokensLinkClick}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Azure Personal Access Tokens
                  </a>
                }
              </Fragment>
            }
            textElements={[
              <div key="1">
                <Paragraph>Click the + New Token button and fill in these details</Paragraph>
                <Flex>
                  <Paragraph fontSize={14}>Name</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    &nbsp;&nbsp;Shake
                  </Paragraph>
                </Flex>
                <Paragraph fontSize={14}>Organization &nbsp;• &nbsp;Keep the current one</Paragraph>
                <Flex>
                  <Paragraph fontSize={14}>Expiration</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    &nbsp;&nbsp;Custom Defined › 1 year
                  </Paragraph>
                </Flex>
                <Flex>
                  <Paragraph fontSize={14}>Scopes</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    &nbsp;&nbsp;Custom Defined
                  </Paragraph>
                </Flex>

                <Flex>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.azureBlue}
                  >
                    -&nbsp;&nbsp;
                  </Paragraph>
                  <Paragraph fontSize={14}>Work items</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    &nbsp;&nbsp;Read, write and manage
                  </Paragraph>
                </Flex>
                <Flex>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.azureBlue}
                  >
                    -&nbsp;&nbsp;
                  </Paragraph>
                  <Paragraph fontSize={14}>Project and team</Paragraph>
                  <Paragraph
                    fontSize={14}
                    color={vars.colors.grey30}
                  >
                    &nbsp;&nbsp;Read
                  </Paragraph>
                </Flex>
              </div>,
              <Paragraph key="2">Click Create and paste the generated token below.</Paragraph>,
            ]}
          />

          <Input
            name="token"
            disabled={disabled}
            value={token}
            label="Token"
            error={!!tokenErr}
            helper={tokenErr && tokenErr.message}
            onChange={handleChange}
            placeholder="e.g. eacq7aywekp45wscen"
          />
        </Form>
      </div>
      <ModalFooter
        toggle={toggle}
        button={
          <Button
            size="small"
            disabled={isButtonDisabled || disabled}
            tooltip={{
              position: "top",
              text: "Please enter token and project URL first",
              disabled: !isButtonDisabled,
            }}
            loadingText={disabled && "Connecting..."}
            onClick={handleFormSubmit}
          >
            Connect
          </Button>
        }
      />
    </Fragment>
  );
};
