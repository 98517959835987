import { Validators } from "util/Validators";

export const validateUserInfoName = (name: string) => {
  if (!Validators.required(name)) {
    return new Error("Name is required");
  }

  if (!Validators.maxLength(name, 256)) {
    return new Error("Name exceeds the maximum length of 256 characters");
  }
};

export const validateCurrentPassword = (value: string): Error | undefined => {
  if (!Validators.required(value)) {
    return new Error("Please enter your current password");
  }
  if (!Validators.minLength(value, 8)) {
    return new Error("Password must contain at least 8 characters.");
  }
};
