import React, { useEffect, useState } from "react";

import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";

import AddAppIcon from "assets/images/floating-menu/mini-icon-add-new-app@2x.png";
import AskAiIcon from "assets/images/floating-menu/mini-icon-ask-ai@2x.png";
import SendFeedbackIcon from "assets/images/floating-menu/mini-icon-send-us-feedback@2x.png";
import { ReactComponent as ArrowDownIcon } from "assets/images/rotating-caret.svg";

import Paragraph from "components/Paragraph/Paragraph";

import { useAuthContext } from "context/Auth/Auth.context";

import { useLocalStorage } from "hooks/filtering/useLocalStorage";

import { RoutePaths } from "router/config/routePaths";

import { isModuleRoute } from "util/moduleRouteHelper";
import { showShake } from "util/shakeSdkUtils";

import * as Styled from "./QuickActionsMenu.styles";

export function QuickActionsMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams();
  const { userData } = useAuthContext();
  const [isOpened, setOpened] = useState(false);

  const { state: wasOpened, setState: setWasOpened } = useLocalStorage<boolean | undefined>(
    "shakebugs.quickMenuWasOpened",
    false,
  );

  useEffect(() => {
    if (isModuleRoute(location.pathname) && !wasOpened) {
      setOpened(true);
      setWasOpened(true);
    }
  }, [location.pathname, setOpened, setWasOpened, wasOpened]);

  const onAddNewAppClick = () => {
    navigate(generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null }));
    setOpened(false);
  };

  const onAskAIClick = () => {
    window.openInkeep();
    setOpened(false);
  };

  const onSendUsFeedbackClick = () => {
    showShake();
    setOpened(false);
  };

  const toggleMenu = () => {
    if (isOpened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };

  return (
    <Styled.FloatingMenuContainer>
      {isOpened ? (
        <Styled.FloatingMenu>
          <Styled.FloatingMenuInner>
            {userData ? (
              <FloatingMenuAction
                onClick={onAddNewAppClick}
                image={AddAppIcon}
                text={"Add new app"}
              />
            ) : null}
            <FloatingMenuAction
              onClick={onAskAIClick}
              image={AskAiIcon}
              text={"Ask AI"}
            />
            <FloatingMenuAction
              onClick={onSendUsFeedbackClick}
              image={SendFeedbackIcon}
              text={"Send us feedback"}
            />
          </Styled.FloatingMenuInner>
        </Styled.FloatingMenu>
      ) : null}
      <FloatingMenuButton
        onClick={toggleMenu}
        isOpened={isOpened}
      />
    </Styled.FloatingMenuContainer>
  );
}

interface FloatingMenuButtonProps {
  onClick: () => void;
  isOpened: boolean;
}

export const FloatingMenuButton = ({ onClick, isOpened }: FloatingMenuButtonProps) => {
  const style = {
    transform: isOpened ? "" : "rotate(180deg)",
    transition: "transform 500ms ease",
  };

  return (
    <Styled.FloatingMenuButton onClick={onClick}>
      <Styled.FloatingMenuButtonInner>
        <ArrowDownIcon style={style} />
      </Styled.FloatingMenuButtonInner>
    </Styled.FloatingMenuButton>
  );
};

interface FloatingMenuActionProps {
  onClick: () => void;
  image: string;
  text: string;
}

export const FloatingMenuAction = ({ onClick, text, image }: FloatingMenuActionProps) => {
  return (
    <Styled.FloatingMenuAction onClick={onClick}>
      <Styled.FloatingMenuActionIcon src={image} />
      <Paragraph p2>{text}</Paragraph>
    </Styled.FloatingMenuAction>
  );
};
