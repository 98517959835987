import styled, { css } from "styled-components";

import { vars } from "styles";

export const CrashNavigationLinksWrapper = styled.div`
  margin-left: 5rem;
`;

export const CrashNavigationLinkWrapper = styled.a<{
  $active?: boolean;
}>`
  margin: 0 1rem;
  color: ${vars.colors.grey70};
  font-weight: normal;
  &:hover,
  &:focus,
  &:active {
    color: ${vars.colors.grey20};
    border-bottom: 0.1rem dashed ${vars.colors.grey20};
    text-decoration: none;
  }
  ${(props) =>
    props.$active &&
    css`
      color: ${vars.colors.grey20};
      border-bottom: 0.1rem dashed ${vars.colors.grey20};
      text-decoration: none;
    `}
`;
