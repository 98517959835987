import React, { Fragment } from "react";

import { Tab } from "components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useCocoapodTabController,
  useFlutterTabController,
  useGradleTabController,
  useIosTabController,
  useReactNativeTabController,
  useTerminalTabController,
  useWebTabController,
} from "components/CodeBlock/useCodeTabsController";
import Paragraph from "components/Paragraph/Paragraph";

import { Platform, PlatformOs } from "models";

import { vars } from "styles";

import { Chip } from "../SDKSetupGuide.styles";

export interface SetupStep {
  title: string;
  subtitle?: string;
  controller: TabController;
  codeBlock: Tab[];
  footer?: JSX.Element;
}

export interface SdkSetupSteps {
  steps: SetupStep[];
}

export const useAndroidSdkSetup = (apiKey: string, lastVersion: string) => ({
  steps: [
    {
      title: "Add Shake as a dependency",
      controller: useGradleTabController(),
      codeBlock: [
        {
          title: "Groovy",
          file: "build.gradle",
          language: "groovy",
          code: `dependencies {
    implementation 'com.shakebugs:shake:${lastVersion}'
}`,
          highlightedLines: [1],
        },
      ],
    },
    {
      title: "Then initialize Shake",
      subtitle: "Initialize Shake in the onCreate callback of your Application:",
      controller: useAndroidTabController(),
      codeBlock: [
        {
          title: "Kotlin",
          file: "App.kt",
          language: "kotlin",
          code: `import android.app.Application
import com.shakebugs.shake.Shake

class App : Application() {
    override fun onCreate() {
        super.onCreate()
        Shake.start(this, "${apiKey}")
    }
}`,
          highlightedLines: [1, 6],
        },
        {
          title: "Java",
          file: "App.java",
          language: "java",
          code: `import android.app.Application;
import com.shakebugs.shake.Shake;

public class App extends Application {
    @Override
    public void onCreate() {
        super.onCreate();
        Shake.start(this, "${apiKey}");
    }
}`,
          highlightedLines: [1, 7],
        },
      ],
      footer: (
        <Fragment>
          Build and run your project by selecting
          <Paragraph
            color={vars.colors.white}
            style={{ display: "inline", margin: "0 6px" }}
          >
            Run → Run
          </Paragraph>
          in the menu bar.
        </Fragment>
      ),
    },
  ],
});

export const useIosSdkSetup = (apiKey: string) => ({
  steps: [
    {
      title: "Add Shake as a dependency",
      controller: useCocoapodTabController(),
      codeBlock: [
        {
          title: "SwiftPM ",
          file: "Xcode",
          language: "shell",
          code: `https://github.com/shakebugs/shake-ios`,
          highlightedLines: [0],
        },
        {
          title: "CocoaPods",
          file: "Xcode",
          language: "shell",
          code: `pod 'Shake'`,
          highlightedLines: [0],
        },
      ],
    },
    {
      title: "Then initialize Shake",
      subtitle: "Initialize Shake in the didFinishLaunchingWithOptions callback of your AppDelegate:",
      controller: useIosTabController(),
      codeBlock: [
        {
          title: "Swift",
          file: "AppDelegate.swift",
          language: "swift",
          code: `import UIKit
import Shake

@UIApplicationMain
class AppDelegate: UIResponder, UIApplicationDelegate {
    func application(
      _ application: UIApplication,
      didFinishLaunchingWithOptions launchOptions:
      [UIApplicationLaunchOptionsKey: Any]?) -> Bool {
      Shake.start(apiKey: "${apiKey}")
      return true
    }
}`,
          highlightedLines: [1, 9],
        },
        {
          title: "Objective-C",
          file: "AppDelegate.m",
          language: "objectivec",
          code: `#import "AppDelegate.h"
@import Shake;

@implementation AppDelegate
- (BOOL)application:(UIApplication *)application
didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    [SHKShake startWithApiKey: @"${apiKey}"];
    return YES;
}
@end`,
          highlightedLines: [1, 6],
        },
      ],
      footer: (
        <Fragment>
          Build and run your project by selecting
          <Paragraph
            color={vars.colors.white}
            style={{ display: "inline", margin: "0 6px" }}
          >
            Product → Run
          </Paragraph>
          in the menu bar.
        </Fragment>
      ),
    },
  ],
});

export const useReactNativeSdkSetup = (platform: Platform | undefined, apiKey: string) => {
  const steps = {
    steps: [
      {
        title: "Add Shake as a dependency",
        controller: useTerminalTabController(),
        codeBlock: [
          {
            title: "Terminal",
            file: "Terminal",
            language: "shell",
            code:
              platform?.os === PlatformOs.IOS
                ? `npm install @shakebugs/react-native-shake
                cd ios && pod install && cd ..`
                : `npm install @shakebugs/react-native-shake`,
            highlightedLines: platform?.os === PlatformOs.IOS ? [0, 1] : [0],
          },
        ],
      },
      {
        title: "Then initialize Shake",
        subtitle: "Initialize Shake in the index.js file of your Application:",
        controller: useReactNativeTabController(),
        codeBlock: [
          {
            title: "Javascript",
            file: "index.js",
            language: "jsx",
            code: `import { AppRegistry } from 'react-native';
import App from './src/App';
import { name as appName } from './app.json';
import Shake from '@shakebugs/react-native-shake';

AppRegistry.registerComponent(appName, () => App);

Shake.start('${apiKey}');`,
            highlightedLines: [3, 7],
          },
        ],
        footer: (
          <>
            Build and run your project by running <Chip>react-native start</Chip> command in the terminal.{" "}
            <>
              {platform?.os === PlatformOs.IOS ? (
                <Fragment>
                  Use the
                  <Chip>react-native run-ios</Chip> command to run your app on the iOS device.
                </Fragment>
              ) : (
                <Fragment>
                  Use the
                  <Chip>react-native run-android</Chip> command to run your app on the Android device.
                </Fragment>
              )}
            </>
          </>
        ),
      },
    ],
  };
  return steps;
};

export const useFlutterSdkSetup = (apiKey: string) => ({
  steps: [
    {
      title: "Add Shake as a dependency",
      controller: useTerminalTabController(),
      codeBlock: [
        {
          title: "Terminal",
          file: "Terminal",
          language: "shell",
          code: `flutter pub add shake_flutter`,
          highlightedLines: [0],
        },
      ],
    },
    {
      title: "Then initialize Shake",
      subtitle: "Initialize Shake in the main.dart file of your app:",
      controller: useFlutterTabController(),
      codeBlock: [
        {
          title: "Dart",
          file: "main.dart",
          language: "dart",
          code: `import 'package:shake_flutter/shake_flutter.dart';

void main() {
    WidgetsFlutterBinding.ensureInitialized();
    Shake.start('${apiKey}');

    runApp(Home());
}`,
          highlightedLines: [0, 3, 4],
        },
      ],
      footer: (
        <>
          Build and run your project by selecting
          <Paragraph
            color={vars.colors.white}
            style={{ display: "inline", margin: "0 6px" }}
          >
            Run → Run
          </Paragraph>
          in the menu bar.
        </>
      ),
    },
  ],
});

export const useWebSdkSetup = (apiKey: string) => {
  const tabController = useWebTabController();
  const steps = {
    steps: [
      {
        title: "Add Shake as a dependency",
        controller: tabController,
        codeBlock: [
          {
            title: "npm",
            file: "Terminal",
            language: "shell",
            code: `npm install @shakebugs/browser`,
            highlightedLines: [0],
          },
          {
            title: "CDN",
            file: "index.html",
            language: "javascript",
            code: `<script type="module">
      import Shake from 'https://cdn.jsdelivr.net/npm/@shakebugs/browser@latest/+esm'
      window.Shake = Shake;
</script>`,
            highlightedLines: [0, 1, 2, 3],
          },
        ],
      },
      {
        title: "Then initialize Shake",
        subtitle: "Initialize Shake in your Application:",
        controller: tabController,
        codeBlock: [
          {
            title: "npm",
            file: "index.js",
            language: "javascript",
            code: `import Shake from '@shakebugs/browser';

                      Shake.start('${apiKey}');`,
            highlightedLines: [0, 2],
          },
          {
            title: "CDN",
            file: "index.html",
            language: "javascript",
            code: `<script type="module">
                      window.Shake.start('${apiKey}');
</script>`,
            highlightedLines: [1],
          },
        ],
        footer: <>Now build and run your project. Shake should be working, as simple as that.</>,
      },
    ],
  };
  return steps;
};
