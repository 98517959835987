import React, { ReactNode } from "react";

import PageWrap from "components/PageWrap/PageWrap";

import { CenteredFormWrap } from "./CenteredForm.styles";

export const CenteredForm = ({ children }: { children: ReactNode }) => {
  return (
    <PageWrap>
      <CenteredFormWrap>
        <div className="inner-container">{children}</div>
      </CenteredFormWrap>
    </PageWrap>
  );
};
