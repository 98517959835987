import { useEffect, useState } from "react";

import { QueryObserverResult } from "@tanstack/react-query";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Attribute } from "hooks/filtering/sharedTypes";
import { useForm } from "hooks/useForm";

import { App } from "models";
import { AttributeRuleForRequest, AvailableRule, Rule, RulesModule } from "models/Rule.model";

import { eventOptions, moduleOptions } from "pages/Administration/components/ModalBody/Rules/rulesHelper";

import { useAdministrationDeps } from "../Administration";

export interface AddRuleFormState {
  name: string;
  isActive: boolean;
  module: Option;
  trigger: Option & { id?: string };
}

const AddRuleInitialState: AddRuleFormState = {
  name: "",
  isActive: false,
  module: moduleOptions[0],
  trigger: eventOptions[0],
};

export const useAddRuleFormConsumer = (
  selectedApp: App,
  getRules: () => Promise<QueryObserverResult<Rule[] | undefined>>,
  rule?: Rule,
  closeModal?: () => void,
  availableRules?: AvailableRule,
  condAttrs?: Attribute[],
  actionAttrs?: Attribute[],
) => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [loading, setLoading] = useState(false);

  const handleAddRule = async () => {
    if (!selectedWorkspace || !selectedApp) return;
    setLoading(true);

    try {
      await administrationService.addRule(
        selectedWorkspace.id,
        selectedApp.id,
        formState.name,
        formState.isActive,
        formState.module.value as RulesModule,
        { type_id: formState.trigger.value },
        prepareConditionsForRequest(condAttrs ?? [], availableRules),
        prepareActionsForRequest(actionAttrs ?? [], availableRules),
      );

      setLoading(false);

      closeModal?.();

      getRules();

      displayToast({
        title: "Fantastic!",
        content: `Rule has been added`,
      });
    } catch (error) {
      setLoading(false);
      closeModal?.();
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const handleUpdateRule = async () => {
    if (!selectedWorkspace || !selectedApp || !rule) return;
    setLoading(true);

    try {
      await administrationService.updateRule(
        selectedWorkspace.id,
        selectedApp.id,
        rule.id,
        formState.name,
        formState.isActive,
        formState.module.value as RulesModule,
        { type_id: formState.trigger.value, id: formState.trigger.id },
        prepareConditionsForRequest(condAttrs ?? [], availableRules, rule),
        prepareActionsForRequest(actionAttrs ?? [], availableRules, rule),
      );

      setLoading(false);

      closeModal?.();

      getRules();

      displayToast({
        title: "Fantastic!",
        content: `Rule has been updated`,
      });
    } catch (error) {
      setLoading(false);
      closeModal?.();
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  };

  const { handleFormChange, formState, formError, handleFormSubmit, setError } = useForm<AddRuleFormState>({
    initialState: AddRuleInitialState,
    onSubmit: rule ? handleUpdateRule : handleAddRule,
    validators: {},
  });

  useEffect(() => {
    if (rule && availableRules) {
      handleFormChange("name", rule.name);
      handleFormChange("isActive", rule.active);
      handleFormChange("module", {
        value: rule.module,
        label: moduleOptions.find((option) => (option.value as RulesModule) === rule.module)?.label ?? "",
      });
      const trigger = availableRules?.trigger_types.find((type) => type.id === rule.trigger.type);

      handleFormChange("trigger", {
        value: rule?.trigger.type ?? "",
        id: rule?.trigger.id ?? "",
        label: trigger?.name ?? "",
        subtitle: trigger?.description ?? "",
      });

      return;
    }
    if (availableRules && !rule) {
      handleFormChange("name", "");
      handleFormChange("module", {
        value: availableRules.modules[0],
        label: moduleOptions.find((option) => (option.value as RulesModule) === availableRules.modules[0])?.label ?? "",
      });
      handleFormChange("trigger", {
        value: availableRules?.trigger_types[0].id ?? "",
        label: availableRules?.trigger_types[0].name,
        subtitle: availableRules?.trigger_types[0].description,
      });
    }
  }, [availableRules, handleFormChange, rule]);

  return { formState, handleFormChange, handleFormSubmit, formError, setError, loading };
};

const prepareActionsForRequest = (attrs: Attribute[], availableRules?: AvailableRule, rule?: Rule) => {
  if (!rule) {
    return attrs.map((attr) => {
      return {
        type_id: availableRules?.action_types.find((rule) => rule.method === attr.field_name)?.id,
        args: { value: attr.filter?.fieldValue },
      } as AttributeRuleForRequest;
    });
  } else {
    return attrs.map((attr) => {
      const action = availableRules?.action_types.find((rule) => rule.method === attr.field_name);
      const ruleAction = rule.actions.find((act) => act.type === action?.id);
      if (ruleAction) {
        return {
          type_id: ruleAction.type,
          id: ruleAction.id,
          args: { value: attr.filter?.fieldValue },
        } as AttributeRuleForRequest;
      } else {
        return {
          type_id: availableRules?.action_types.find((rule) => rule.method === attr.field_name)?.id,
          args: { value: attr.filter?.fieldValue },
        } as AttributeRuleForRequest;
      }
    });
  }
};

const prepareConditionsForRequest = (attrs: Attribute[], availableRules?: AvailableRule, rule?: Rule) => {
  if (!rule) {
    return attrs.map((attr) => {
      return {
        type_id: availableRules?.condition_types.find((rule) => rule.method === attr.field_name)?.id,
        args: { value: attr.filter?.fieldValue },
      } as AttributeRuleForRequest;
    });
  } else {
    return attrs.map((attr) => {
      const condition = availableRules?.condition_types.find((rule) => rule.method === attr.field_name);
      const ruleCondition = rule.conditions.find((cond) => cond.type === condition?.id);
      if (ruleCondition) {
        return {
          type_id: ruleCondition.type,
          id: ruleCondition.id,
          args: { value: attr.filter?.fieldValue },
        } as AttributeRuleForRequest;
      } else {
        return {
          type_id: availableRules?.condition_types.find((rule) => rule.method === attr.field_name)?.id,
          args: { value: attr.filter?.fieldValue },
        } as AttributeRuleForRequest;
      }
    });
  }
};
