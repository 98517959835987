import { App } from "./App.model";
import { UserInfo } from "./WorkspaceData.model";

export interface Invitation {
  id: string;
  created: string;
  expires_at: string;
  email: string;
  role: MemberRole;
}

export interface Member {
  id: string;
  team: {
    id: string;
    slug: string;
    name: string;
  };
  role: MemberRole;
  user: UserInfo;
  apps: App[];
}

export enum MemberRole {
  ADMIN = "admin",
  MEMBER = "member",
  GUEST = "guest",
}

export const getRoleAsText = (role: MemberRole) => {
  switch (role) {
    case MemberRole.ADMIN:
      return "Admin";
    case MemberRole.GUEST:
      return "Guest";
    default:
      return "Member";
  }
};

export const isAdmin = (role?: MemberRole) => {
  if (role === MemberRole.ADMIN) return true;
  return false;
};

export const isMember = (role?: MemberRole) => {
  if (role === MemberRole.MEMBER) return true;
  return false;
};

export const isGuest = (role?: MemberRole) => {
  if (role === MemberRole.GUEST) return true;
  return false;
};
