import { BlackboxData } from "./BlackboxData.model";
import { BlackboxNetworkData } from "./BlackboxNetworkData.model";

export interface Blackbox {
  id: string;
  created: string;
  updated: string;
  active: boolean;
  cpu_usage_history: Array<BlackboxData>;
  application_memory_usage_history: Array<BlackboxData>;
  memory_usage_history: Array<BlackboxData>;
  disk_space_usage_history: Array<BlackboxData>;
  network_usage_history: Array<BlackboxNetworkData>;
  battery_usage_history: Array<BlackboxData>;
  device_orientation_history: Array<BlackboxData>;
}

export enum BlackboxChartType {
  CPU = "CPU",
  APP_MEMORY = "App memory",
  MEMORY = "Memory",
  STORAGE = "Storage",
  NETWORK = "Network",
  BATTERY = "Battery",
  ORIENTATION = "Orientation",
}
