import React, { Fragment } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";

export interface CustomElementInjectionProps {
  parentElementId: string;
  element: JSX.Element;
  containerId: string;
  tagName: string;
}

export const useCustomElementInjection = ({
  parentElementId,
  element,
  containerId,
  tagName,
}: CustomElementInjectionProps) => {
  const [container] = React.useState(() => {
    const elementContainer = document.createElement(tagName) as HTMLElement;
    elementContainer.id = containerId;
    return elementContainer;
  });

  useEffect(() => {
    const parentElement = document.getElementById(parentElementId) as HTMLElement;
    if (!parentElement) return;

    parentElement.insertAdjacentElement("beforeend", container);

    container && ReactDOM.render(<Fragment key={element.key}>{element}</Fragment>, container);

    return () => {
      document.getElementById(containerId)?.remove();
    };
  }, [container, element, containerId, parentElementId]);
};
