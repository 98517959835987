export interface AvailableRule {
  modules: RulesModule[];
  trigger_types: TriggerType[];
  action_types: ConditionType[];
  condition_types: ConditionType[];
}

export interface TriggerType {
  active: boolean;
  updated: string;
  module: RulesModule;
  description: string;
  name: string;
  method: string;
  created: string;
  id: string;
  type: string;
}

export interface ActionType {
  active: boolean;
  updated: string;
  module: RulesModule;
  description: string;
  name: string;
  method: string;
  created: string;
  id: string;
}

export interface ConditionType {
  active: boolean;
  updated: string;
  module: RulesModule;
  description: string;
  name: string;
  method: string;
  created: string;
  id: string;
}

export enum RulesModule {
  USER_FEEDBACK = "issue_tracking",
}

export interface Condition {
  updated: string;
  args: { value: string };
  type: string;
  created: string;
  rule: string;
  id: string;
}

export interface Rule {
  active: boolean;
  updated: string;
  module: RulesModule;
  conditions: Condition[];
  app: string;
  actions: Condition[];
  name: string;
  created: string;
  id: string;
  trigger: Condition;
}

export interface AttributeRuleForRequest {
  type_id: string;
  args: { value: string };
}

export interface TriggerForRequest {
  type_id: string;
  id?: string;
}
