import React from "react";

import Heading from "components/Heading/Heading";
import LargeButton from "components/LargeButton/LargeButton";
import { LargeButtonGroup } from "components/LargeButton/LargeButtonGroup.styles";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";

import { Platform } from "models";

import { usePlatformsConsumer } from "pages/AddApp/consumers/usePlatformsConsumer";

import { getPlatformImage, getPlatformName } from "util/contentResolvers";

import * as Styled from "./AddAppSelectPlatform.styles";

interface Props {
  onPlatformSelected: (platform: Platform) => void;
}

export function AddAppSelectPlatform({ onPlatformSelected }: Props) {
  const { platforms } = usePlatformsConsumer();

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {"Choose your platform"}
        </Heading>
        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {"So we can show you the exact installation instructions."}
        </Paragraph>
        <LargeButtonGroup>
          {platforms?.map((platform) => {
            return (
              <LargeButton
                small={true}
                key={platform.id}
                image={getPlatformImage(platform)}
                title={getPlatformName(platform)}
                onClick={() => {
                  onPlatformSelected(platform);
                }}
              />
            );
          })}
        </LargeButtonGroup>
      </Styled.Container>
    </PageWrap>
  );
}
