import styled, { css } from "styled-components";

import BaseParagraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

export const DetailsPaneInfoElement = styled.button<{ $error?: boolean; $isButton?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  cursor: default;

  span {
    width: 100%;
    text-align: start;
    font-size: 1.4rem;
    font-weight: 500;
  }

  // *** Temporary until design is specified
  font-size: 1.4rem;
  font-weight: 500;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  text-decoration: none;
  // ***

  svg {
    width: 2rem;
    height: 2rem;
  }

  ${(props) =>
    !props.$isButton &&
    css`
      padding: 0.4rem 0.8rem;
      cursor: default;
    `}

  ${(props) =>
    props.$error &&
    css`
      span,
      .icon {
        color: ${vars.colors.red};
      }

      svg {
        path {
          stroke: ${vars.colors.red};
        }
      }
    `}

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .icon {
    flex-shrink: 0;
    margin-right: 1.2rem;
  }
`;

export const IconContainer = styled.div<{ $notClickable?: boolean }>`
  margin-right: 1.2rem;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.$notClickable &&
    css`
      cursor: default;
    `}
`;

export const DropdownInfoElement = styled.div<{ $error?: boolean }>`
  span {
    font-size: 1.4rem;
    font-weight: 500;
    word-break: break-word;
  }

  ${(props) =>
    props.$error &&
    css`
      div {
        div {
          span,
          .icon {
            color: ${vars.colors.red};
          }
          svg {
            path {
              stroke: ${vars.colors.red};
            }
          }
        }
      }
    `}
`;

export const InfoContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const InfoContentTitle = styled(BaseParagraph)<{ $notClickable?: boolean }>`
  display: flex;
  justify-content: flex-start;
  color: ${vars.colors.grey30};
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.$notClickable &&
    css`
      cursor: default;
    `}
`;

export const InfoContentSubtitle = styled(BaseParagraph)`
  display: flex;
  justify-content: flex-start;
  font-size: 1.2rem;
  color: ${vars.colors.grey60};
  font-weight: 500;
  cursor: pointer;
  user-select: none;
`;
