import React from "react";

import { generatePath, useNavigate } from "react-router-dom";

import GmailIcon from "assets/images/shared-icons/gmail.svg";
import TerminalIcon from "assets/images/shared-icons/terminal.svg";

import Heading from "components/Heading/Heading";
import LargeButton from "components/LargeButton/LargeButton";
import { LargeButtonGroup } from "components/LargeButton/LargeButtonGroup.styles";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "router/config/routePaths";

import * as Styled from "./AddAppIntroPage.styles";

interface Props {
  nextPage: () => void;
}

export function AddAppIntroPage({ nextPage }: Props) {
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();

  const onAddMyselfClick = () => {
    nextPage();
  };

  const onInviteClick = () => {
    navigate(generatePath(RoutePaths.ADMINISTRATION_MEMBERS, { workspaceSlug: selectedWorkspace?.slug ?? null }));
  };

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {"Let’s add the SDK to your app"}
        </Heading>

        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {"Do you know how to code — or do you want to invite your colleague to do this?"}
        </Paragraph>
        <LargeButtonGroup>
          <LargeButton
            image={TerminalIcon}
            title={"Add Shake SDK myself"}
            subtitle={"I love coding"}
            onClick={onAddMyselfClick}
          />
          <LargeButton
            image={GmailIcon}
            title={"Invite an app dev"}
            subtitle={"Tech team to the rescue"}
            onClick={onInviteClick}
          />
        </LargeButtonGroup>
      </Styled.Container>
    </PageWrap>
  );
}
