import amex from "assets/images/billing/credit-cards/card-amex.png";
import diners from "assets/images/billing/credit-cards/card-diners.png";
import discover from "assets/images/billing/credit-cards/card-discover.png";
import jcb from "assets/images/billing/credit-cards/card-jcb.png";
import mastercard from "assets/images/billing/credit-cards/card-mastercard.png";
import other from "assets/images/billing/credit-cards/card-other.png";
import visa from "assets/images/billing/credit-cards/card-visa.png";

export interface CreditCard {
  brand: CreditCardBrand;
  brand_image: string;
  exp_month: number;
  exp_year: number;
  last4: number;
  created: string;
  updated: string;
  active: boolean;
  id: string;
  customer: string;
}

export enum CreditCardBrand {
  VISA = "Visa",
  AMERICAN_EXPRESS = "American Express",
  DINERS_CLUB = "Diners Club",
  DISCOVER = "Discover",
  JCB = "JCB",
  MASTERCARD = "MasterCard",
  UNION_PAY = "UnionPay",
}

export const mapCreditCardBrandToLogo = (brand: CreditCardBrand) => {
  switch (brand) {
    case CreditCardBrand.VISA:
      return visa;
    case CreditCardBrand.AMERICAN_EXPRESS:
      return amex;
    case CreditCardBrand.DINERS_CLUB:
      return diners;
    case CreditCardBrand.DISCOVER:
      return discover;
    case CreditCardBrand.JCB:
      return jcb;
    case CreditCardBrand.MASTERCARD:
      return mastercard;
    case CreditCardBrand.UNION_PAY:
      return other;
    default:
      return other;
  }
};
