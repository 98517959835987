import { useEffect, useState } from "react";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import {
  automaticForwardOption,
  Azure,
  AzureIntegrationInfo,
  AzureIntegrationPayload,
  IntegrationType,
  manualForwardOption,
} from "models/integrations";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Azure;
  selectedAppId: string;
  onFinish: () => void;
}

interface FormState {
  projectOption: Option;
  forwardingOption: Option;
}

export interface AzureOption {
  projectKey: string;
  projectName: string;
  integrationId: string;
  organizationUrl: string;
}

const initialFormState = {
  projectOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
};

export const useAzureIntegrationFormConsumer = ({ selectedAppId, onFinish, integration }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<AzureIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.AZURE_DEVOPS,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);

  const [projectOptions, setProjectOptions] = useState<Option[]>([]);

  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { projectOption, forwardingOption } = formState;

  async function handleConnect() {
    try {
      if (!integrationInfo) throw new Error();

      setLoading(true);

      const selectedAzureOption = JSON.parse(projectOption.value) as AzureOption;

      const payload = {
        app_id: selectedAppId,
        automatic_forward: Boolean(formState.forwardingOption.value),
        integration_id: selectedAzureOption.integrationId,
        key: selectedAzureOption.projectKey,
      } as AzureIntegrationPayload;

      const endpoint = Boolean(integration) ? updateIntegration : integrate;

      await endpoint<Azure>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);

      onFinish();
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setProjectOptions([]);
      handleFormChange("projectOption", null);
      return;
    }

    const azureOptions = integrationInfo.flatMap((info) => {
      return info.projects.map((project) => {
        const azureOption: AzureOption = {
          projectKey: project.key,
          projectName: project.name,
          integrationId: project.integration_id,
          organizationUrl: info.url,
        };
        return { value: JSON.stringify(azureOption), label: project.name };
      });
    });

    setProjectOptions([...azureOptions]);

    const preSelectedProject = integration
      ? azureOptions.find((option) => {
          const azureOption = JSON.parse(option.value) as AzureOption;
          return azureOption.projectName === integration.project_name && azureOption.integrationId === integration.id;
        }) || null
      : null;

    handleFormChange("projectOption", preSelectedProject);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    projectOption,
    projectOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
    disabled,
    loading,
  };
};
