import React from "react";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import RoundedCanvas from "components/RoundedCanvas/RoundedCanvas";
import Tooltip from "components/Tooltip/Tooltip";

import { getUserInitials } from "models";
import { getRoleAsText, Member } from "models/Member.model";

import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";

import identifiers from "util/identifiers.json";

import * as Styled from "../TeamMembersCard/TeamMembersCard.styles";

export const TeamMemberRow = ({
  member,
  currentUserIsAdmin,
  currentUserId,
  onSelectDeleteMember,
  onSelectCustomizeMember,
}: {
  member: Member;
  currentUserIsAdmin: boolean;
  currentUserId: string;
  onSelectDeleteMember: () => void;
  onSelectCustomizeMember: () => void;
}) => {
  const { user } = member;
  const { id: userId, email, name } = user;

  const isRowCurrentUser = currentUserId === userId;

  const DropdownButton = () => {
    if (currentUserIsAdmin || isRowCurrentUser) {
      return (
        <CellEnd>
          <Dropdown testId={identifiers["members.myTeam.user.dropdownButton"]}>
            {currentUserIsAdmin && (
              <DropdownItem
                onClick={onSelectCustomizeMember}
                testId={identifiers["members.myTeam.user.dropdownButton.customize"]}
              >
                Customize
              </DropdownItem>
            )}
            <DropdownItem
              onClick={onSelectDeleteMember}
              testId={identifiers["members.myTeam.user.dropdownButton.remove"]}
              error
            >
              Remove
            </DropdownItem>
          </Dropdown>
        </CellEnd>
      );
    }

    return <CenteredCell />;
  };

  return (
    <>
      <CenteredCell gap={12}>
        <div
          style={{ flexShrink: 0 }}
          data-testid={identifiers["member.myTeam.user.icon"]}
        >
          <RoundedCanvas
            letter={getUserInitials(name)}
            bgColor={getRandomColor(userId) && getRandomColor(userId).background}
            txtColor={getRandomColor(userId) && getRandomColor(userId).text}
            size="small"
            image={member.user.picture ?? undefined}
          />
        </div>
        <Styled.Name data-testid={identifiers["member.myTeam.user.name"]}>{name}</Styled.Name>
      </CenteredCell>

      <Tooltip
        position="top"
        text={email}
      >
        <CenteredCell>
          <Styled.Email data-testid={identifiers["member.myTeam.user.email"]}>{email}</Styled.Email>
        </CenteredCell>
      </Tooltip>

      <CenteredCell>
        <Styled.Email data-testid={identifiers["member.myTeam.user.icon"]}>{getRoleAsText(member.role)}</Styled.Email>
      </CenteredCell>

      <DropdownButton />
    </>
  );
};
