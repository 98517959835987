import { UserData, UserToken } from "models";

export const USER_DATA_LOCAL_STORAGE_KEY = "userData";
export const USER_TOKEN_LOCAL_STORAGE_KEY = "userToken";

export interface AuthState {
  userData?: UserData;
  userToken?: UserToken;
}

export enum AuthContextActionType {
  SET_USER_DATA = "SET_USER_DATA",
  SET_USER_TOKEN = "SET_USER_TOKEN",
}

export interface SetUserData {
  type: AuthContextActionType.SET_USER_DATA;
  payload?: UserData;
}

export interface SetUserToken {
  type: AuthContextActionType.SET_USER_TOKEN;
  payload?: UserToken;
}

export type AuthContextAction = SetUserData | SetUserToken;
