import { LogType } from "./Log.model";

export interface ConsoleLogEventRes {
  id: string;
  timestamp: string;
  message: string;
  type?: ConsoleLogType;
}

export interface ConsoleLogEvent extends ConsoleLogEventRes {
  instance_type: LogType.CONSOLE_LOG;
}

export enum ConsoleLogType {
  VERBOSE = "verbose",
  DEBUG = "debug",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
