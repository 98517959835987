import styled from "styled-components";

import { vars } from "styles";

export const EnvironmentRibbonWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${vars.zIndex.envRibbon};
  color: white;
  text-align: center;
  line-height: 1.2rem;
  height: 0.5rem;
  border-top: 0.5rem solid ${vars.colors.green};

  .environment-label {
    display: block;
    width: 16rem;
    padding: 0.25rem 0.8rem 0.8rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    cursor: pointer;
    background-color: ${vars.colors.green};
    margin: 0 auto;
    color: ${vars.colors.white};
  }

  &.environment-staging {
    border-top: 0.5rem solid ${vars.colors.red};

    .environment-label {
      background-color: ${vars.colors.red};
    }
  }
`;
