import React from "react";

import ReactPlayer from "react-player";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import Paragraph from "components/Paragraph/Paragraph";
import Tooltip from "components/Tooltip/Tooltip";

import { vars } from "styles";

import * as Styled from "./Video.styles";

interface Props {
  thumbnailUrl: string;
  url: string;
  title: string;
  duration: string;
  hasControls?: boolean;
  wide?: boolean;
}

export default function Video({ thumbnailUrl, url, title, duration, hasControls, wide }: Props) {
  const modal = useBaseDialog({});
  const showModal = () => {
    modal.setOpen(true);
  };
  const hideModal = () => {
    modal.setOpen(false);
  };

  return (
    <div>
      <Tooltip
        text="Play video"
        position="top"
      >
        <Styled.Video onClick={() => showModal()}>
          <Styled.Play />
          <img
            src={thumbnailUrl}
            width="280"
            height="158"
          />
        </Styled.Video>
      </Tooltip>

      <Paragraph
        style={{
          marginTop: "0.8rem",
          maxWidth: "28rem",
          wordBreak: "break-word",
        }}
        color={vars.colors.grey50}
      >
        {title}
      </Paragraph>
      <Paragraph
        fontSize={12}
        color={vars.colors.grey80}
      >
        {duration}
      </Paragraph>

      <BaseDialog
        {...modal}
        noBackground
        fullHeight
        size="wide"
        noPadding
      >
        <Styled.Flex
          $alignItems="center"
          $justifyContent="center"
          onClick={() => hideModal()}
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ReactPlayer
              url={url}
              style={{ cursor: "pointer", borderRadius: "2rem", objectFit: "cover", overflow: "hidden" }}
              playing={true}
              type="video/mp4"
              controls={hasControls ?? false}
              showInfo={false}
              width={wide ? "900px" : undefined}
              height={wide ? "506px" : undefined}
            />
          }
        </Styled.Flex>
      </BaseDialog>
    </div>
  );
}
