import { useEffect } from "react";

import { Socket } from "socket.io-client";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { ServerToClientEvents, SocketEvents, UserAddedEvent } from "models/webSocketEvents";

interface Params {
  refetchData?: () => void;
  socket?: Socket<ServerToClientEvents, never>;
}

export const useUserEventSocketConsumer = ({ refetchData, socket }: Params) => {
  const { selectedWorkspace } = useAppSelectionContext();

  useEffect(() => {
    if (!selectedWorkspace || !socket) return;

    const eventHandler = (event: UserAddedEvent) => {
      selectedWorkspace.id == event.team_id && refetchData?.();
    };

    socket.on(SocketEvents.USER_ADDED, eventHandler);
    return () => {
      socket.off(SocketEvents.USER_ADDED, eventHandler);
    };
  }, [socket, selectedWorkspace, refetchData]);
};
