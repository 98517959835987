import { useEffect, useState } from "react";

type Return<T> = {
  setState: React.Dispatch<React.SetStateAction<T>>;
  state: T;
};

export function useLocalStorage<T>(key?: string): Return<T | undefined>;
export function useLocalStorage<T>(key?: string, initial?: T): Return<T>;

export function useLocalStorage<T>(key?: string, initial?: T) {
  const [state, setState] = useState<T | undefined>(() => {
    if (!key) return;
    const stored = localStorage.getItem(key);
    if (!stored) return initial;
    let state;
    try {
      state = JSON.parse(stored) as T;
    } catch (error) {
      return initial;
    }
    return state;
  });

  useEffect(() => {
    if (!key) return;
    if (!state) {
      localStorage.removeItem(key);
      return;
    }

    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      localStorage.removeItem(key);
    }
  }, [state, key]);

  return {
    state,
    setState,
  };
}
