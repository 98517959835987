import { AxiosResponse } from "axios";

import ApiService from "services/Api.service";

export class ChangeEmailConfirmationService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  changeEmail(token: string): Promise<AxiosResponse<void>> {
    return this.apiService.post({
      resource: "accounts/users/change_email",
      data: { code: token },
    });
  }
}
