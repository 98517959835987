import React from "react";

import * as Styled from "./Pager.styles";

interface Props {
  pagesNumber: number;
  selectedPage: number;
  onDotClick?: (index: number) => void;
}

export default function Pager({ pagesNumber, selectedPage, onDotClick }: Props) {
  return (
    <Styled.PagerContainer>
      {Array.from(" ".repeat(pagesNumber)).map((item, index) => {
        return (
          <Styled.PagerDot
            onClick={() => {
              onDotClick?.(index);
            }}
            key={index}
            $selected={index == selectedPage}
          />
        );
      })}
    </Styled.PagerContainer>
  );
}
