import styled, { css } from "styled-components";

import BaseInput from "components/Input/Input";
import Paragraph from "components/Paragraph/Paragraph";
import BaseTooltip from "components/Tooltip/Tooltip";

import { PlusIcon } from "pages/shared/icons/icons";
import { CheckmarkIcon as CheckIcon } from "pages/shared/icons/icons";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const AttributesContainer = styled.div`
  margin-left: 2.8rem;
  margin-right: 2.8rem;

  background-color: ${vars.colors.black};
`;

export const AttributePill = styled.div`
  display: flex;
  align-items: stretch;
`;

export const AttributesSelectorDropdownScroll = styled.div`
  overflow-y: overlay;
  max-height: calc(100vh - 20rem);

  &::-webkit-scrollbar {
    width: 0.8rem;
  }
`;

export const PillContainer = styled.div<{
  $borderLeftNone?: boolean;
  $borderRightNone?: boolean;
  $borderRightSolidNoRadius?: boolean;
  $borderLeftSolidNoRadius?: boolean;
  $hasLeftMargin?: boolean;
  $minWidth?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${vars.colors.grey90};
  background-color: ${vars.colors.grey100};

  height: 4rem;
  position: relative;
  border-radius: 1rem;

  ${(props) =>
    props.$minWidth &&
    css`
      min-width: 4rem;
    `}

  ${(props) =>
    props.$borderLeftNone &&
    css`
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    `}

  ${(props) =>
    props.$borderRightNone &&
    css`
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `}

    ${(props) =>
    props.$borderRightSolidNoRadius &&
    css`
      border-right: 1px solid ${vars.colors.grey90};
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `}

    ${(props) =>
    props.$borderLeftSolidNoRadius &&
    css`
      border-left: 1px solid ${vars.colors.grey90};
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    `}

    ${(props) =>
    props.$hasLeftMargin &&
    css`
      margin-left: 0.3rem;
    `}
`;

export const BorderConnection = styled.span`
  filter: drop-shadow(-1.5px 0px 0px ${vars.colors.grey90});
  z-index: -1;
`;

export const BorderReversedConnection = styled.span`
  filter: drop-shadow(1.5px 0px 0px ${vars.colors.grey90});
  z-index: 0;
`;

export const PillConnection = styled.div<{ $reversed?: boolean }>`
  user-select: none;
  pointer-events: none;

  position: absolute;
  box-sizing: content-box;
  background-color: ${vars.colors.grey100};

  top: 0;
  bottom: 0;
  margin: auto;

  height: 4rem;
  aspect-ratio: 1;

  z-index: 0;

  &::after {
    content: "";
    display: block;

    top: 0;
    bottom: 0;
    margin: auto;

    height: 4rem;
    aspect-ratio: 1;

    z-index: 0;
    background-color: ${vars.colors.grey100};
  }

  ${(props) =>
    !props.$reversed &&
    css`
      right: 0;
      transform: translate(99%, 0);
      clip-path: polygon(0 0, 50% 50%, 0 100%);

      &::after {
        clip-path: polygon(0 0, 50% 50%, 0 100%);
        transform: translateX(-3%);
      }
    `}

  ${(props) =>
    props.$reversed &&
    css`
      left: 0;
      transform: translate(-99%, 0);
      clip-path: polygon(100% 0, 50% 50%, 100% 100%);

      &::after {
        clip-path: polygon(100% 0, 50% 50%, 100% 100%);
        transform: translateX(3%);
      }
    `}
`;

export const AddFilterFlexButton = styled.button<{ $aspect?: number; $disabled?: boolean; $borderLeft?: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: transparent;
  padding: 0.3rem 0.4rem;
  border: none;
  color: ${vars.colors.grey70};

  div {
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    color: ${vars.colors.grey30};
  }
  ${(props) =>
    props.$disabled &&
    css`
      &:hover {
        color: ${vars.colors.grey70};
        cursor: not-allowed;
      }
    `}
  ${(props) =>
    props.$aspect &&
    css`
      aspect-ratio: ${props.$aspect};
      justify-content: center;
    `}

  ${(props) =>
    props.$borderLeft &&
    css`
      border-left: 1px solid ${vars.colors.grey90};
      width: 3.2rem;
    `}

  ${(props) =>
    !props.$borderLeft &&
    css`
      padding: 0.3rem 1rem;
    `}
`;

export const AttributeFlex = styled.div`
  display: flex;
  align-items: center;
  height: 3.8rem;
  position: relative;
`;

export const AspectButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  aspect-ratio: 1;
  border: none;
  background-color: transparent;
  color: ${vars.colors.grey70};
  &:hover {
    color: ${vars.colors.grey30};
  }
`;

export const RemoveBackground = styled.div<{
  $removeHover: boolean;
  $gradientColor?: string;
  $heightPercent?: number;
  $gradientReachPercent?: number;
  $right?: number;
  $noZIndex?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40%;
  border-radius: 0.5rem;

  display: none;
  ${(props) =>
    props.$removeHover &&
    css`
      display: block;
      background: linear-gradient(
        90deg,
        transparent 0%,
        ${props.$gradientColor ? props.$gradientColor : "rgba(44, 44, 46, 1)"}
          ${props.$gradientReachPercent ? `${props.$gradientReachPercent}%` : "70%"}
      );
    `}

  ${({ $heightPercent }) =>
    $heightPercent &&
    css`
      height: ${$heightPercent}%;
    `}

  ${({ $right }) =>
    $right &&
    css`
      right: ${$right}px;
    `}

    ${({ $noZIndex }) =>
    !$noZIndex &&
    css`
      z-index: 5;
    `}
`;

export const RemoveButton = styled(AspectButton)<{ $hover?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;

  opacity: 0;
  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.$hover &&
    css`
      opacity: 1;
      color: ${vars.colors.grey30};
    `}

  z-index: 6;
`;

export const IconContainer = styled.div<{ $isError?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1;
  border-radius: 0.9rem;

  svg {
    width: 2rem;
    height: 2rem;
    path {
      stroke: ${vars.colors.grey30};
      ${(props) =>
        props.$isError &&
        css`
          stroke: ${vars.colors.red};
        `}
    }
  }
`;

export const AttributeEditButton = styled.button<{ $noBorderLeft?: boolean }>`
  border: none;
  background-color: transparent;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0.3rem 1rem;
  user-select: none;
  z-index: 3;

  ${(props) =>
    !props.$noBorderLeft &&
    css`
      border-left: 1px solid ${vars.colors.grey90};
    `}
`;

export const AttributeTextFlex = styled.div`
  user-select: none;
  font-size: 1.4rem;
  white-space: nowrap;
  display: flex;
  gap: 0.4rem;
  margin-left: 0.6rem;
`;

export const AttributeParagraph = styled.p<{ $isError?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
  color: ${vars.colors.grey30};
  font-size: 1.6rem;
  font-weight: 500;

  ${(props) =>
    props.$isError &&
    css`
      color: ${vars.colors.red};
    `}
`;

export const AttributesContainerFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  row-gap: 0.5rem;
`;

export const AttributeOperatorButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  aspect-ratio: 1;
  border: none;
  user-select: none;
  font-size: 1.6rem;
  font-weight: 500;

  padding-left: 6px;
  padding-right: 6px;
  cursor: default; // change when clickable

  color: ${vars.colors.grey60};
  background-color: ${vars.colors.grey100};

  border-left: 1px solid ${vars.colors.grey90};
  border-right: 1px solid ${vars.colors.grey90};

  z-index: 3;
`;

export const PopoverMenu = styled.div`
  min-width: 24rem;
  border-radius: 1rem;
  border: 2px solid ${vars.colors.grey100};
  box-shadow: 0 0.2rem 1rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  padding: 1rem 0;
  margin: 0;
  cursor: default;
  position: relative;
  z-index: ${vars.zIndex.modalContent};

  &:focus-visible {
    border: 2px solid ${vars.colors.grey100};
    outline: transparent;
  }
`;

export const Flex = styled(BaseFlex)`
  z-index: 500;
  width: 100%;
`;

export const Input = styled(BaseInput)`
  > div {
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid ${vars.colors.grey90};
    margin-bottom: 0.5rem;
    &:active,
    &:hover {
      border-bottom: 1px solid ${vars.colors.grey90};
    }
    background: transparent;
    > div {
      padding: 0rem 0.8rem;
    }
  }
`;

export const Helper = styled.p`
  font-size: 1rem;
  color: ${vars.colors.grey60};
  padding: 0rem 1.8rem;
  user-select: none;
`;

export const PopoverInput = styled.div<{ $widthPx?: number }>`
  position: relative;
  width: 24rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey90};
  box-shadow: 0 0.2rem 1rem 0 #1c1c1e;
  background-color: ${vars.colors.grey100};
  padding: 1rem 0;
  margin: 0;
  cursor: default;
  z-index: ${vars.zIndex.modalContent};
  &:focus-visible {
    border: 1px solid ${vars.colors.grey90};
    outline: transparent;
  }

  ${({ $widthPx }) =>
    $widthPx &&
    css`
      width: ${$widthPx}px;
    `}
`;

export const AddFilter = styled(Paragraph)`
  user-select: none;
  font-weight: 500;
  white-space: nowrap;
  color: ${vars.colors.grey30};
  cursor: pointer;
  user-select: none;
  min-width: 4rem;
`;

export const FieldName = styled.div`
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  margin-left: 0.8rem;
`;

export const Checkmark = styled.div`
  margin-right: 0.8rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

export const FieldGroupTitle = styled.div`
  margin: 0rem 2rem;
  margin-bottom: 0.8rem;
  p {
    &:last-child {
      padding-top: 0.5rem;
    }
    font-size: 1.2rem;
    font-weight: 500;
    color: ${vars.colors.grey60};
  }
`;

export const HitsContainer = styled.div`
  max-height: 90vh;
  overflow: overlay;
`;

export const HitsTextContainer = styled.div`
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

export const Label = styled.div`
  max-width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HitsText = styled.div<{ $isError?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 1.4rem 1.8rem;
  color: ${vars.colors.grey50};
  ${(props) =>
    props.$isError &&
    css`
      color: ${vars.colors.red};
    `}
`;

export const AttributesSelector = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${vars.colors.grey90};
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const Tooltip = styled(BaseTooltip)`
  span {
    z-index: 10;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const Plus = styled(PlusIcon)`
  width: 2rem;
  height: 2rem;
`;

export const CheckmarkIcon = styled(CheckIcon)`
  path {
    stroke: ${vars.colors.white};
  }
`;

export const ColorDot = styled.span`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  transform: translateY(1px);
  margin-right: 0.8rem;
`;

export const DeprecatedBanner = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 0.6rem 0.2rem;
  gap: 1rem;

  position: absolute;
  height: 1.8rem;
  right: 1.4rem;

  color: ${vars.colors.white};

  background-color: ${vars.colors.red};
  border-radius: 6px;
`;
