import React from "react";

import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import { useAuthContext } from "context/Auth/Auth.context";

import ChangePasswordForm from "pages/Account/components/shared/forms/ChangePasswordForm";
import SetPasswordForm from "pages/Account/components/shared/forms/SetPasswordForm";

interface Props {
  toggle: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function SecurityCardModalForm({ toggle, Modal, internalProps }: Props) {
  const { userData } = useAuthContext();

  if (userData?.hasPassword) {
    return (
      <ChangePasswordForm
        onSubmitCallback={toggle}
        Modal={Modal}
        internalProps={internalProps}
      />
    );
  }

  return (
    <SetPasswordForm
      onSubmitCallback={toggle}
      Modal={Modal}
      internalProps={internalProps}
      heading={userData?.hasPassword ? "Change password" : "Set account password"}
    >
      <p>
        You&apos;re signed in with Google so <i>you don&apos;t need</i> a Shake password.
      </p>

      <p>
        However, if you want to be able to use a traditional email plus password combination to sign into Shake as well,
        you can set your password below.
      </p>
    </SetPasswordForm>
  );
}
