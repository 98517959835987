import styled from "styled-components";

import Button from "components/Button/Button";

import { vars } from "styles";

export const GoogleSignInButton = styled(Button)`
  margin: 3rem 0;

  img {
    margin-right: 0.5rem;
  }
`;

export const GoogleError = styled.p`
  color: ${vars.colors.red};
`;

export const FormSeparatorText = styled.p`
  margin: 2rem 0;
  text-align: center;
`;
