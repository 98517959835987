import React from "react";

import { generatePath, useNavigate } from "react-router-dom";

import analyticsBackground from "assets/images/empty-state/analytics-module-empty-heading.png";
import crashesBackground from "assets/images/empty-state/crashes-module-empty-heading.png";
import feedbackBackground from "assets/images/empty-state/feedback-module-empty-heading.png";
import usersBackground from "assets/images/empty-state/users-module-empty-heading.png";

import PageWrap from "components/PageWrap/PageWrap";

import { useWorkspaceAppParams } from "pages/IntegrationActivation/helpers/useWorkspaceAppParams";
import {
  AnalyticsModuleDescriptionBox,
  CrashesModuleDescriptionBox,
  FeedbackModuleDescriptionBox,
  UsersModuleDescriptionBox,
} from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";

import { RoutePaths } from "router/config/routePaths";

import * as Styled from "./ZeroAppsView.styles";
import { AddAppButton } from "../../../../components/AddAppButton/AddAppButton";

interface Props {
  heading: React.ReactNode;
  background: string;
}

const ZeroAppsView = ({ heading, background }: Props) => {
  const { workspaceSlug } = useWorkspaceAppParams();

  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>{heading}</Styled.HeadingBoxWrapper>
        <Styled.AddButtonBackground>
          <AddAppButton
            navigateTo={generatePath(RoutePaths.ADD_APP, { workspaceSlug: workspaceSlug ?? null })}
            wiggle={true}
          />
        </Styled.AddButtonBackground>
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};

export function ZeroAppsFeedbackView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<FeedbackModuleDescriptionBox />}
        background={feedbackBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsCrashesView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<CrashesModuleDescriptionBox />}
        background={crashesBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsUsersView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<UsersModuleDescriptionBox />}
        background={usersBackground}
      />
    </PageWrap>
  );
}

export function ZeroAppsAnalyticsView() {
  return (
    <PageWrap>
      <ZeroAppsView
        heading={<AnalyticsModuleDescriptionBox />}
        background={analyticsBackground}
      />
    </PageWrap>
  );
}
