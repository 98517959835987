import BugTriage from "assets/images/video-thumbnails/automate-bug-triage.jpg";
import Jira from "assets/images/video-thumbnails/connect-jira-with-shake.jpg";
import ShakeSDK from "assets/images/video-thumbnails/how-to-set-up-shake-sdk.jpg";
import Chat from "assets/images/video-thumbnails/know-and-chat-with-users.jpg";
import CRM from "assets/images/video-thumbnails/mini-crm-for-your-app.jpg";
import TestBash from "assets/images/video-thumbnails/organize-internal-test-bash.jpg";
import SensitiveData from "assets/images/video-thumbnails/protect-sensitive-data.jpg";
import ShakeDevelopers from "assets/images/video-thumbnails/shake-for-developers.jpg";
import ShakePM from "assets/images/video-thumbnails/shake-for-product-managers.jpg";
import ShakeStartup from "assets/images/video-thumbnails/shake-for-startup-founders.jpg";
import ShakeTesters from "assets/images/video-thumbnails/shake-for-testers.jpg";
import SupportHub from "assets/images/video-thumbnails/support-hub-for-mobile-app-issues.png";
import RightTech from "assets/images/video-thumbnails/the-right-tech-data-for-app-support.jpg";
import CustomerSupport from "assets/images/video-thumbnails/using-shake-for-customer-support.jpg";
import Dogfooding from "assets/images/video-thumbnails/using-shake-for-dogfooding.jpg";
import BetaTesting from "assets/images/video-thumbnails/using-shake-in-beta-testing.jpg";
import Production from "assets/images/video-thumbnails/using-shake-in-production.jpg";
import Internally from "assets/images/video-thumbnails/using-shake-internally.jpg";
import LiveChat from "assets/images/video-thumbnails/using-shake-live-chat.jpg";

export interface VideoType {
  url: string;
  title: string;
  duration: string;
  thumbnailUrl: string;
}

export const all: VideoType[] = [
  {
    thumbnailUrl: ShakeStartup,
    url: "https://www.youtube.com/embed/P4q5Dzvn5HU?si=RKwIq7urY4rLoDN-",
    title: "Shake for start-up founders",
    duration: "0:48",
  },
];

export const productManagersVideos: VideoType[] = [
  {
    thumbnailUrl: ShakePM,
    url: "https://www.youtube.com/embed/pamMWwElYac?si=r1b1yeuGu2AYEr-M",
    title: "Shake for product managers",
    duration: "0:56",
  },
  {
    thumbnailUrl: TestBash,
    url: "https://www.youtube.com/embed/eUnMfBHWeq0?si=RpsLtlfdWdkjzVxz",
    title: "Organize internal test bash with Shake",
    duration: "0:51",
  },
  {
    thumbnailUrl: Dogfooding,
    url: "https://www.youtube.com/embed/tJcd2AJvwz0?si=iUPmXA0Jc1ZZgquj",
    title: "Dogfood your app with Shake",
    duration: "1:41",
  },
  {
    thumbnailUrl: Production,
    url: "https://www.youtube.com/embed/C6GpQ4ObwoQ?si=vWUQrKTKUJxJRTK2",
    title: "Using Shake in production",
    duration: "0:50",
  },
  {
    thumbnailUrl: Chat,
    url: "https://www.youtube.com/embed/C6GpQ4ObwoQ?si=vWUQrKTKUJxJRTK2",
    title: "Know and chat with your app users in Shake",
    duration: "0:50",
  },
  {
    thumbnailUrl: BugTriage,
    url: "https://www.youtube.com/embed/I90LsV5DBM4?si=G3jB6t34FMDak27d",
    title: "Automate your bug triage with Shake",
    duration: "1:05",
  },
  {
    thumbnailUrl: Jira,
    url: "https://www.youtube.com/embed/hzOEw-9lypQ?si=T0jX4G8pTViYU1bI",
    title: "Connect Jira with Shake",
    duration: "0:39",
  },
];

export const developerVideos: VideoType[] = [
  {
    thumbnailUrl: ShakeSDK,
    url: "https://www.youtube.com/embed/SnjriV0I5RI?si=DtoQqd4eFeZ-lvnv",
    title: "How to set up Shake SDK",
    duration: "0:48",
  },
  {
    thumbnailUrl: ShakeDevelopers,
    url: "https://www.youtube.com/embed/_3tItyLHXbc?si=DMFRUuECpJmEaeV0",
    title: "Shake for developers",
    duration: "0:43",
  },
  {
    thumbnailUrl: Internally,
    url: "https://www.youtube.com/embed/hXewyZZruek?si=ZehFleRKysk65m2A",
    title: "Using Shake in internal app development",
    duration: "0:33",
  },
  {
    thumbnailUrl: BetaTesting,
    url: "https://www.youtube.com/embed/n3Auc9CvxpU?si=V1FRIBMw_tGpjEkn",
    title: "Beta testing your mobile app with Shake",
    duration: "0:43",
  },
  {
    thumbnailUrl: Jira,
    url: "https://www.youtube.com/embed/hzOEw-9lypQ?si=T0jX4G8pTViYU1bI",
    title: "Connect Jira with Shake",
    duration: "0:39",
  },
  {
    thumbnailUrl: SensitiveData,
    url: "https://www.youtube.com/embed/d7uUGlzACro?si=IgUbSv4Csl6wusJ8",
    title: "Protect user's sensitive data with Shake",
    duration: "0:41",
  },
];

export const testerVideos: VideoType[] = [
  {
    thumbnailUrl: ShakeTesters,
    url: "https://www.youtube.com/embed/YaVpaIQOkW0?si=3djuQSYwXzMX9oHv",
    title: "Shake for testers",
    duration: "0:47",
  },
  {
    thumbnailUrl: Internally,
    url: "https://www.youtube.com/embed/hXewyZZruek?si=ZehFleRKysk65m2A",
    title: "Using Shake in internal app development",
    duration: "0:33",
  },
  {
    thumbnailUrl: BetaTesting,
    url: "https://www.youtube.com/embed/n3Auc9CvxpU?si=V1FRIBMw_tGpjEkn",
    title: "Beta testing your mobile app with Shake",
    duration: "0:43",
  },
];

export const customerSupportVideos: VideoType[] = [
  {
    thumbnailUrl: CustomerSupport,
    url: "https://www.youtube.com/embed/pOPb_999YBo?si=ACY9kKJtj0Elq-0V",
    title: "Shake for customer support",
    duration: "1:27",
  },
  {
    thumbnailUrl: RightTech,
    url: "https://www.youtube.com/embed/RW_Nelj37qk?si=ZsgkWv2HaXS5uxIa",
    title: "The right tech data for mobile app support",
    duration: "1:00",
  },
  {
    thumbnailUrl: CRM,
    url: "https://www.youtube.com/embed/BAYg6iO437U?si=VFueppluTwzAcA03",
    title: "Shake as a mini CRM for your mobile app",
    duration: "1:00",
  },
  {
    thumbnailUrl: SupportHub,
    url: "https://www.youtube.com/embed/A8HN9BW93XM?si=QUVYDeH09VFu2X35",
    title: "Support hub for your mobile app issues",
    duration: "1:11",
  },
  {
    thumbnailUrl: BugTriage,
    url: "https://www.youtube.com/embed/I90LsV5DBM4?si=G3jB6t34FMDak27d",
    title: "Automate your bug triage with Shake",
    duration: "1:05",
  },
  {
    thumbnailUrl: Production,
    url: "https://www.youtube.com/embed/C6GpQ4ObwoQ?si=vWUQrKTKUJxJRTK2",
    title: "Using Shake in production",
    duration: "0:50",
  },
  {
    thumbnailUrl: LiveChat,
    url: "https://www.youtube.com/embed/lw-qpqKM2oU?si=XyJAJG_bin2HwSI-",
    title: "Using Shake's live chat",
    duration: "0:40",
  },
];
