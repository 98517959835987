import React, { ReactNode } from "react";

import { ScrollContainerWrap } from "./ScrollContainer.styles";

interface Props {
  children: ReactNode;
}
export const ScrollContainer = ({ children }: Props) => {
  return (
    <ScrollContainerWrap>
      <div className="inner-container">
        <div className="scroll-area">{children}</div>
      </div>
    </ScrollContainerWrap>
  );
};
