import { useCallback, useEffect, useState } from "react";

import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import useAbortController from "hooks/useAbortController";

import { ApprovedDomain } from "models/organization";

import { useAdministrationDeps } from "../Administration";

export const useApprovedDomainsConsumer = () => {
  const { abortSignal } = useAbortController();
  const { selectedWorkspace } = useAppSelectionContext();
  const { administrationService } = useAdministrationDeps();
  const [domains, setDomains] = useState<ApprovedDomain[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const getApprovedDomains = useCallback(async () => {
    try {
      if (!selectedWorkspace) return;

      const { data } = await administrationService.getApprovedDomains(selectedWorkspace.id, abortSignal);

      setDomains(data);
      setInitialLoading(false);
    } catch (error) {
      if (abortSignal.aborted) return;

      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    }
  }, [abortSignal, administrationService, selectedWorkspace]);

  const addApprovedDomain = async (domain: string, verificationEmail: string) => {
    setLoading(true);
    try {
      if (!selectedWorkspace) return;

      const { data } = await administrationService.addApprovedDomain(selectedWorkspace.id, domain, verificationEmail);
      setDomains((domains) => {
        return [...domains, data];
      });

      displayToast({
        title: "Check that email",
        content: `Verification email has been sent to ${verificationEmail}`,
      });
    } catch (error) {
      displayToast({
        title: "Domain not added",
        content: `Domain ${domain} has already been added to the approved domains list.`,
      });

      return Promise.reject();
    } finally {
      setLoading(false);
    }
  };

  const deleteApprovedDomain = async (domain: ApprovedDomain) => {
    setLoading(true);
    try {
      if (!selectedWorkspace) return;

      await administrationService.deleteApprovedDomain(selectedWorkspace.id, domain.id);

      setDomains((domains) => {
        return domains.filter((approvedDomain) => approvedDomain.id !== domain.id);
      });

      displayToast({
        title: "Domain removed",
        content: `${domain.name} is not an approved domain anymore.`,
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApprovedDomains();
  }, [getApprovedDomains]);

  return {
    addApprovedDomain,
    deleteApprovedDomain,
    initialLoading,
    domains,
    loading,
  };
};
