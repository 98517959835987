import React, { Fragment, useEffect, useState } from "react";

import { QueryObserverResult } from "@tanstack/react-query";
import { Accept } from "react-dropzone";

import { ReactComponent as PushNotifIcon } from "assets/images/push-notif-icon.svg";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell, GridTable } from "components/GridTable/GridTable";
import { useModal } from "components/Modal/Modal";
import Paragraph from "components/Paragraph/Paragraph";
import { Option } from "components/SelectDropdown/SelectDropdown";

import { useShakingAnimation } from "context/ShakingAnimation/ShakingAnimationContext";

import { App, PlatformOs } from "models";
import { Certificate } from "models/Certificate.model";
import { resolveDateString } from "models/helpers/time/creationTime";

import UploadFileModalBody from "pages/Administration/components/ModalBody/UploadFileModalBody";
import {
  environmentOptions,
  useAddCertificateFormConsumer,
} from "pages/Administration/consumers/useAddCertificateFormConsumer";

import { vars } from "styles";

import { helpCenterLinks } from "util/helpCenterLinks";

import * as Styled from "./AppsCard.styles";

const Text = {
  iOS: {
    title: "Push notifications certificate",
    text: "How to generate and export this certificate",
    link: helpCenterLinks.pushNotifIos,
  },
  Android: {
    title: "Push notifications service account",
    text: "How to generate the service account in Firebase",
    link: helpCenterLinks.pushNotifAndroid,
  },
};

const acceptedIOSFileType = { "application/p12": [".p12"] } as Accept;
const acceptedAndroidFileType = { "application/json": [".json"] } as Accept;

interface Props {
  selectedDropdownApp: App;
  certificate?: Certificate;
  getCertificate: () => Promise<QueryObserverResult<Certificate | undefined>>;
  deleteCertificate: () => Promise<void>;
  disabled?: boolean;
  isSample?: boolean;
}

export default function PushNotificationsSettingsCard({
  selectedDropdownApp,
  certificate,
  getCertificate,
  deleteCertificate,
  disabled,
  isSample,
}: Props) {
  const {
    Modal: UploadCertificateModal,
    toggle: toggleUpload,
    modalProps: uploadCertificateInternal,
  } = useModal({ size: "narrow" });
  const { triggerShake } = useShakingAnimation();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { formState, handleFormChange, handleFormSubmit, loading } = useAddCertificateFormConsumer(
    selectedDropdownApp,
    getCertificate,
    toggleUpload,
  );

  const optionDidChange = (option: Option, name: string | undefined) => {
    name && handleFormChange(name, option);
  };

  useEffect(() => {
    handleFormChange("file", selectedFile);
  }, [selectedFile, handleFormChange]);

  const os = Object.values(PlatformOs).find((value) => value === selectedDropdownApp.platform.os);

  if (!os || os === PlatformOs.WEB) return <Fragment />;

  const helperLink = Text[os].link;
  const helperLinkText = Text[os].text;
  const title = Text[os].title;

  const handleAddCertificate = () => {
    handleFormSubmit();
  };

  return (
    <AdministrationCard
      title={title}
      subtitle="This is for Shake to instantly send your app’s push notification when you reply something to a user."
      topLeftAccessory={
        <Button
          disabled={disabled || !selectedDropdownApp.active || loading}
          onClick={() => (isSample ? triggerShake("AddAppButton") : toggleUpload())}
          size="small"
          tooltip={{
            text: isSample ? "Sample app can't be modified" : "Certificates can be added on active apps only",
            disabled: !isSample && (disabled || selectedDropdownApp.active),
            position: "top",
          }}
        >
          {os === PlatformOs.IOS ? "Upload certificate" : "Upload credentials"}
        </Button>
      }
    >
      {certificate &&
        (os == PlatformOs.IOS
          ? getIOSRow(certificate, deleteCertificate)
          : getAndroidRow(certificate, deleteCertificate))}

      <Styled.HelperText>
        We have prepared a step-by-step article on&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href={helperLink}
        >
          {helperLinkText}
        </a>
        .
      </Styled.HelperText>

      <UploadCertificateModal
        {...uploadCertificateInternal}
        heading="Add push notifications certificate"
        buttonElement={
          <Button
            onClick={handleAddCertificate}
            disabled={loading || !selectedFile}
            tooltip={{ text: !selectedFile ? "Please add certificate first" : "", disabled: !!selectedFile }}
          >
            Add certificate
          </Button>
        }
      >
        <UploadFileModalBody
          handleSelectedFile={setSelectedFile}
          acceptedFileTypes={os === PlatformOs.IOS ? acceptedIOSFileType : acceptedAndroidFileType}
          dropzoneText={
            selectedFile
              ? selectedFile.name
              : `Click to choose a ${os === PlatformOs.IOS ? ".p12 or .pem" : ".json"} file or drag it here to upload`
          }
          bottomView={
            os === PlatformOs.IOS ? (
              <Styled.SelectDropdown
                name="environment"
                label="For environment"
                options={environmentOptions}
                defaultValue={environmentOptions[0]}
                isSearchable={false}
                value={formState.environment}
                onChange={optionDidChange}
              />
            ) : undefined
          }
        />
      </UploadCertificateModal>
    </AdministrationCard>
  );
}

const getIOSRow = (certificate: Certificate, deleteCertificate: () => Promise<void>) => {
  return (
    <GridTable gridTemplateColumns="repeat(3, auto)">
      <CenteredCell gap={8}>
        <PushNotifIcon />

        <Paragraph color={vars.colors.grey30}>{`Uploaded on ${resolveDateString(certificate.updated)}`}</Paragraph>
      </CenteredCell>
      <CenteredCell>
        <Paragraph>{`Expires ${resolveDateString(certificate.expiration_date)}`}</Paragraph>
      </CenteredCell>
      <CellEnd>
        <Dropdown>
          <DropdownItem
            onClick={() => {
              deleteCertificate();
            }}
          >
            <p style={{ color: vars.colors.red }}>Remove</p>
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    </GridTable>
  );
};

const getAndroidRow = (certificate: Certificate, deleteCertificate: () => Promise<void>) => {
  return (
    <GridTable gridTemplateColumns="repeat(2, auto)">
      <CenteredCell gap={8}>
        <PushNotifIcon />

        <Paragraph color={vars.colors.grey30}>{`Uploaded on ${resolveDateString(certificate.updated)}`}</Paragraph>
      </CenteredCell>
      <CellEnd>
        <Dropdown>
          <DropdownItem
            onClick={() => {
              deleteCertificate();
            }}
          >
            <p style={{ color: vars.colors.red }}>Remove</p>
          </DropdownItem>
        </Dropdown>
      </CellEnd>
    </GridTable>
  );
};
