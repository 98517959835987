import styled from "styled-components";

import { HelperText as BaseHelperText } from "components/HelperText/HelperText";
import BaseParagraph from "components/Paragraph/Paragraph";
import BaseSelectDropdown from "components/SelectDropdown/SelectDropdown";

import { StripeLockIcon as BaseLockedIcon } from "pages/shared/icons/icons";

import { vars } from "styles";
import { Flex as BaseFlex } from "styles/reusable/Flex/Flex.styles";

export const ButtonNote = styled(BaseParagraph)`
  display: flex;
  align-items: center;
`;

export const SelectDropdown = styled(BaseSelectDropdown)`
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
`;

export const Flex = styled(BaseFlex)`
  padding-top: 2.4rem;
  border-top: 1px solid ${vars.colors.grey90};
`;

export const LockedIcon = styled(BaseLockedIcon)`
  margin-right: 0.8rem;
  height: 100%;
  align-items: center;
  display: inline-block;
`;

export const Container = styled.div`
  margin-right: 1.6rem;
`;

export const HelperText = styled(BaseHelperText)`
  margin-top: 2.4rem;
`;

export const PlanModalContainer = styled.div`
  width: 28.2rem;
`;
