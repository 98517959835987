import React, { forwardRef } from "react";

import { vars } from "styles";

import * as Styled from "./AddAppButton.styles";
import AddAppIcon from "../../assets/images/floating-menu/mini-icon-add-new-app@2x.png";
import Paragraph from "../Paragraph/Paragraph";

export type AddAppButtonProps = {
  navigateTo: string;
  wiggle?: boolean;
  id?: string;
  ref?: React.RefObject<HTMLDivElement>;
};

// eslint-disable-next-line react/display-name
export const AddAppButton = forwardRef<HTMLDivElement, AddAppButtonProps>((props, ref) => {
  return (
    <Styled.AddAppButton
      $isWiggling={props.wiggle}
      id={props.id}
      ref={ref}
    >
      <Styled.AddAppInner>
        <Styled.AddAppAction to={props.navigateTo}>
          <Styled.AddAppIcon src={AddAppIcon} />
          <Paragraph
            color={vars.colors.white}
            p2
          >
            Add new app
          </Paragraph>
        </Styled.AddAppAction>
      </Styled.AddAppInner>
    </Styled.AddAppButton>
  );
});
