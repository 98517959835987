import styled from "styled-components";

export const List = styled.div`
  max-height: 30rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.8rem;
  }
`;
