import React from "react";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./IntegrationsHelperText.styles";

interface Props {
  titleComponent: JSX.Element;
  arrowColor: string;
  textElements: JSX.Element[];
}

export const IntegrationHelperText = ({ titleComponent, arrowColor, textElements }: Props) => {
  return (
    <Styled.HelperTextContainer>
      <Styled.Paragraph>{titleComponent}</Styled.Paragraph>
      {textElements.map((element, index) => {
        return (
          <Flex key={index}>
            <Styled.Arrow $color={arrowColor}>→</Styled.Arrow>&nbsp;&nbsp;{element}
          </Flex>
        );
      })}
    </Styled.HelperTextContainer>
  );
};
