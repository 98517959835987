import React from "react";

import dependencyInjection from "util/dependencyInjection";

import { AnalyticsService } from "./services/Analytics.service";
import AnalyticsPageView from "./views/AnalyticsPageView";

interface Services {
  analyticsService: AnalyticsService;
}

const analyticsService = new AnalyticsService();

const [AnalyticsDependencyProvider, useAnalyticsDeps] = dependencyInjection<Services>({
  services: {
    analyticsService,
  },
});

export function AnalyticsPage() {
  return (
    <AnalyticsDependencyProvider>
      <AnalyticsPageView />
    </AnalyticsDependencyProvider>
  );
}

export { useAnalyticsDeps };
