import { Reducer } from "react";

import { AuthContextAction, AuthContextActionType, AuthState } from "./Auth.types";

export const AuthReducer: Reducer<AuthState, AuthContextAction> = (state, action) => {
  switch (action.type) {
    case AuthContextActionType.SET_USER_DATA:
      return { ...state, userData: action.payload };
    case AuthContextActionType.SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
  }
};
