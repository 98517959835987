import React from "react";

import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Paragraph from "components/Paragraph/Paragraph";

import { MappingFile, PlatformOs } from "models";
import { dateStringForMappingFiles } from "models/helpers/time/creationTime";

import * as Styled from "./MappingFilesRow.styles";

export function getContent(mappingFile: MappingFile, button: JSX.Element) {
  return {
    [PlatformOs.IOS]: [
      {
        tooltip: "UUID",
        component: (
          <>
            <Styled.CustomIcon />
            <Styled.Number>{mappingFile.uuid}</Styled.Number>
          </>
        ),
        position: "center",
      },
      {
        tooltip: "Build version",
        component: (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Styled.Version>{mappingFile.build_version}</Styled.Version>
          </div>
        ),
        position: "center",
      },
      {
        tooltip: "App version",
        component: (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Styled.Version>{mappingFile.app_version}</Styled.Version>
          </div>
        ),
        position: "center",
      },
      {
        tooltip: "Crash events count",
        component: <Paragraph>{mappingFile.crash_events_count}</Paragraph>,
        position: "end",
      },
    ],
    [PlatformOs.ANDROID]: [
      {
        tooltip: "Version name",
        component: (
          <>
            <Styled.CustomIcon />
            <Styled.Number>{mappingFile.app_version_name}</Styled.Number>
          </>
        ),
        position: "center",
      },

      {
        tooltip: "Version code",
        component: <Styled.Version>{mappingFile.app_version_code}</Styled.Version>,
        position: "center",
      },
      {
        tooltip: "Created",
        component: <Styled.Version>{dateStringForMappingFiles(new Date(mappingFile.created))}</Styled.Version>,
        position: "center",
      },
      { component: button, position: "end" },
    ],
  };
}

export const cell = {
  center: (component: JSX.Element) => <CenteredCell>{component}</CenteredCell>,
  end: (component: JSX.Element) => <CellEnd> {component} </CellEnd>,
};
