import displayToast from "components/Toast/displayToast";

import { useForgotPasswordDeps } from "../ForgotPassword";

export const useForgotPasswordConsumer = () => {
  const { forgotPasswordService } = useForgotPasswordDeps();

  const resendResetLink = async (email: string, showToast: boolean) => {
    try {
      await forgotPasswordService.forgotPassword(email);

      showToast &&
        displayToast({
          title: "Check your email",
          content: `Email successfully re-sent to ${email}.`,
        });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: `Failed to resend reset link to ${email}.`,
      });
    }
  };

  return { resendResetLink };
};
