import { useCallback } from "react";

import { useAppDeps } from "App.dependencies";

import { useUnreadNotificationsContext } from "context/App/UnreadNotificationsContext";

import { AppNotification, Notification } from "models/notifications";

export default function useNotificationsApiConsumer() {
  const { unreadNotifications, setUnreadNotifications } = useUnreadNotificationsContext();

  const { notificationsService } = useAppDeps();

  const fetchUnreadNotificationsAndDispatch = useCallback(
    async (workspaceId: string, signal?: AbortSignal) => {
      const { data } = await notificationsService.getUnreadNotifications(workspaceId, signal);
      if (signal && signal.aborted) return {};

      const adaptedNotificationRecord = constructAppNotifications(data);

      setUnreadNotifications(adaptedNotificationRecord);

      return adaptedNotificationRecord;
    },
    [notificationsService, setUnreadNotifications],
  );

  const constructAppNotifications = (serverNotifications: Notification[]): Record<string, AppNotification[]> => {
    const mapToNotifications = serverNotifications.map((serverNotif) => {
      return {
        app_id: serverNotif.app_id,
        app_notifications: serverNotif.app_notifications,
      };
    });

    return mapToNotifications.reduce((acc, curr) => {
      const { app_id, app_notifications } = curr;
      return { ...acc, [app_id]: app_notifications };
    }, {} as Record<string, AppNotification[]>);
  };

  return {
    unreadNotifications,
    fetchUnreadNotificationsAndDispatch,
  };
}
