export interface File {
  id: string;
  created: string;
  updated: string;
  active: boolean;
  url: string | null;
  filename: string;
}

export const enum SupportedFileTypes {
  IMAGE = "Image",
  VIDEO = "Video",
  TXT = "txt",
  PPT = "ppt",
  DOC = "doc",
  PDF = "pdf",
  ZIP = "zip",
  XLS = "xls",
}

export interface Attachment {
  url?: string;
  text?: string;
  num: number;
  type?: SupportedFileTypes;
  isHelper?: boolean;
}

export const supportedImageFileExtensions = [".jpg", ".jpeg", ".png"];

export const supportedVideoFileExtensions = [".mp4", ".mov", ".webm"];

export const supportedTextLogExtensions = [".txt", ".log"];

export const supportedPPTExtensions = [".ppt", ".pptx"];

export const supportedDocExtensions = [".doc", ".docx"];

export const supportedPdfExtensions = [".pdf"];

export const supportedZipExtensions = [".zip", ".rar"];

export const supportedXlsExtensions = [".xls", ".xlsx", ".csv"];
