import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { autoUpdate, FloatingFocusManager, FloatingPortal, useFloating, useInteractions } from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import * as Styled from "./BaseDialog.styles";

export function useSpaceToPreviewDialog<TItem>() {
  const [open, setOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState<TItem>();

  const useFloatingProps = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
  });

  const { getFloatingProps } = useInteractions();

  const onPreviewRequest = useCallback((item: TItem) => {
    setOpen(true);
    setPreviewItem(item);
  }, []);

  const onPreviewClose = useCallback(() => {
    setOpen(false);
    setPreviewItem(undefined);
  }, []);

  return {
    previewItem,
    onPreviewRequest,
    onPreviewClose,
    context: useFloatingProps.context,
    floating: useFloatingProps.floating,
    getFloatingProps,
    open,
    setOpen,
  };
}

export function SpaceToPreviewDialog<TItem>({
  size,
  ...baseDialogProps
}: ReturnType<typeof useSpaceToPreviewDialog<TItem>> & { children: ReactNode; size?: "small" | "normal" | "wide" }) {
  const { open, context, floating, getFloatingProps, children } = baseDialogProps;

  const MotionDialogWrap = useMemo(() => motion(Styled.DialogContentWrap), []);

  return (
    <AnimatePresence>
      {open && (
        <FloatingPortal
          id="__preview_portal"
          key={"__preview_portal"}
        >
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={-1}
          >
            <MotionDialogWrap
              id="__preview_container"
              $transparent
              ref={floating}
              {...getFloatingProps()}
              $maxHeigth="40rem"
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0, type: "just", duration: 0.2 }}
              style={{
                position: "fixed",
                top: "2.4rem",
                right: "2rem",
                width: "57.2rem",
                maxWidth: "57.2rem",
              }}
            >
              <DialogContextProvider useDialogProps={baseDialogProps}>{children}</DialogContextProvider>
            </MotionDialogWrap>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </AnimatePresence>
  );
}

type Context<TItem = unknown> = ReturnType<typeof useSpaceToPreviewDialog<TItem>>;

const dialogContext = createContext<Context>({} as Context);

function DialogContextProvider<TItem>({
  children,
  useDialogProps,
}: {
  children: ReactNode;
  useDialogProps: Context<TItem>;
}) {
  return <dialogContext.Provider value={useDialogProps as Context}>{children}</dialogContext.Provider>;
}

export function useSpaceToPreviewDialogContext<TData = unknown>() {
  return useContext(dialogContext) as Context<TData>;
}
