import React, { PropsWithChildren } from "react";

import * as Styled from "./Heading.styles";

export interface HeaderProps {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  heading1?: boolean;
  heading2?: boolean;
  heading3?: boolean;
  heading4?: boolean;
  marginBottom?: number;
  fontWeight?: "normal" | 500 | "bold" | 400;
  fontSize?: string;
  lineHeight?: number;
  className?: string;
  testId?: string;
}

export default function Heading({
  as,
  heading1,
  heading2,
  heading3,
  heading4,
  marginBottom,
  lineHeight,
  fontWeight,
  className,
  testId,
  children,
}: PropsWithChildren<HeaderProps>) {
  return (
    <Styled.Heading
      as={as}
      className={`heading ${className}`}
      $heading1={heading1}
      $heading2={heading2}
      $heading3={heading3}
      $heading4={heading4}
      $marginBottom={marginBottom}
      $lineHeight={lineHeight}
      $fontWeight={fontWeight}
      data-testid={testId}
    >
      {children}
    </Styled.Heading>
  );
}
