import React from "react";

import { AppDependencyProvider } from "App.dependencies";
import { APIProvider } from "provider/APIProvider";

import WebBanner from "components/WebBanner/WebBanner";

import { AppContextProvider } from "context/App/App.context";
import { AppSelectionContextProvider } from "context/App/AppSelectionContext";
import { TempAppSelectionContextProvider } from "context/App/TempAppSelectionContext";
import { UnreadNotificationsProvider } from "context/App/UnreadNotificationsContext";
import { SubscriptionProvider } from "context/Subscription/SubscriptionContext";

import AuthProtected from "./components/AuthProtected";
import AccountLayoutView from "./views/AccountLayoutView";
import AdministrationLayoutView from "./views/AdministrationLayoutView";
import EmptyOutletLayoutView from "./views/EmptyOutletLayoutView";
import HomeLayoutView from "./views/HomeLayoutView";
import MainLayoutView from "./views/MainLayoutView";
import { RootLayoutView } from "./views/RootLayoutView";
import { ShakingAnimationProvider } from "../context/ShakingAnimation/ShakingAnimationContext";

export function MainLayout() {
  return <MainLayoutView />;
}

export function RootLayout() {
  return (
    <AuthProtected>
      <AppDependencyProvider>
        <AppContextProvider>
          <AppSelectionContextProvider>
            <TempAppSelectionContextProvider>
              <SubscriptionProvider>
                <UnreadNotificationsProvider>
                  <APIProvider>
                    <WebBanner />
                    <RootLayoutView />
                  </APIProvider>
                </UnreadNotificationsProvider>
              </SubscriptionProvider>
            </TempAppSelectionContextProvider>
          </AppSelectionContextProvider>
        </AppContextProvider>
      </AppDependencyProvider>
    </AuthProtected>
  );
}

export function AdministrationLayout() {
  return (
    <ShakingAnimationProvider>
      <AdministrationLayoutView />
    </ShakingAnimationProvider>
  );
}

export function HomeLayout() {
  return <HomeLayoutView />;
}

export function AccountLayout() {
  return <AccountLayoutView />;
}

export function EmptyOutletLayout() {
  return <EmptyOutletLayoutView />;
}
