import { AxiosResponse } from "axios";

import { UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";

import { FilteringBody } from "hooks/filtering/sharedTypes";
import { FetchFNResult } from "hooks/filtering/useFilterHitsQuery";

import { AppUser, AppUsersResponse } from "models";
import { Issue } from "models/issueTracking";

import ApiService from "services/Api.service";

export class UsersService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getAppUsers(
    teamId: string,
    appId: string,
    limit: number,
    offset: number,
    sort: UserSortOption = "created",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AppUsersResponse>> {
    return this.apiService.get({
      resource: `user_model/${teamId}/apps/${appId}/users?limit=${limit}&offset=${offset}&sort=${sort}`,
      config: { signal: abortSignal },
    });
  }

  getUser(
    workspaceId: string,
    appId: string,
    appUserId: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AppUser>> {
    return this.apiService.get({
      resource: `user_model/${workspaceId}/apps/${appId}/users/${appUserId}`,
      config: { signal: abortSignal },
    });
  }

  updateUser(workspaceId: string, appId: string, appUserId: string, banned?: boolean): Promise<AxiosResponse<AppUser>> {
    return this.apiService.patch({
      resource: `user_model/${workspaceId}/apps/${appId}/users/${appUserId}`,
      data: { banned },
    });
  }

  getUserTickets(appUserId: string, abortSignal?: AbortSignal): Promise<AxiosResponse<Issue[]>> {
    return this.apiService.get({
      resource: `user_model/app_user/${appUserId}/tickets`,
      config: { signal: abortSignal },
    });
  }

  getUsersFiltersHits(
    workspaceId: string,
    appId: string,
    keyword: string,
    filterField: string,
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<FetchFNResult>> {
    return this.apiService.get({
      resource: `user_model/${workspaceId}/apps/${appId}/users/filter?search_field=${filterField}&search_text=${encodeURI(
        keyword,
      )}`,
      config: { signal: abortSignal },
    });
  }

  getUsersByFilters(
    workspaceId: string,
    appId: string,
    offset = 0,
    limit = 21,
    filters: FilteringBody[],
    sort: UserSortOption = "created",
    abortSignal?: AbortSignal,
  ): Promise<AxiosResponse<AppUsersResponse>> {
    return this.apiService.post({
      resource: `user_model/${workspaceId}/apps/${appId}/users/filter`,
      config: { signal: abortSignal },
      data: { filters: filters, limit, offset, sort },
    });
  }
}
