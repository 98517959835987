import React from "react";

import { FeaturesService } from "pages/Home/services/Features.service";
import FeaturesLatest from "pages/Home/views/Features/FeaturesLatest";
import FeaturesPopular from "pages/Home/views/Features/FeaturesPopular";

import dependencyInjection from "util/dependencyInjection";

interface Services {
  featuresService: FeaturesService;
}

const featuresService = new FeaturesService();

const [FeaturesDependencyProvider, useFeaturessDeps] = dependencyInjection<Services>({
  services: {
    featuresService,
  },
});

export function FeaturesLatestPage() {
  return (
    <FeaturesDependencyProvider>
      <FeaturesLatest />
    </FeaturesDependencyProvider>
  );
}

export function FeaturesPopularPage() {
  return (
    <FeaturesDependencyProvider>
      <FeaturesPopular />
    </FeaturesDependencyProvider>
  );
}

export { useFeaturessDeps };
