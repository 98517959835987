import { useEffect, useState } from "react";

import { Option } from "components/SelectDropdown/SelectDropdown";
import displayToast from "components/Toast/displayToast";

import { useForm } from "hooks/useForm";

import { automaticForwardOption, IntegrationType, manualForwardOption } from "models/integrations";
import { Trello, TrelloIntegrationInfo, TrelloIntegrationUpdatePayload } from "models/integrations/Trello.model";

import { useIntegrationFormConsumer } from "pages/Administration/consumers/useIntegrationFormConsumer";

interface Props {
  integration?: Trello;
  selectedAppId: string;
  onFinish: () => void;
}

export interface BoardOption {
  name: string;
  boardId: string;
  integrationId: string;
}

interface FormState {
  listOption: Option;
  boardOption: Option;
  forwardingOption: Option;
}

const initialFormState = {
  listOption: { value: "", label: "Fetching... Please wait, large projects may take a few minutes." },
  forwardingOption: automaticForwardOption,
  boardOption: { value: "{}", label: "Fetching... Please wait, large projects may take a few minutes." },
};

export const useTrelloIntegrationFormConsumer = ({ selectedAppId, integration, onFinish }: Props) => {
  const { integrationInfo, integrate, updateIntegration } = useIntegrationFormConsumer<TrelloIntegrationInfo[]>({
    selectedAppId,
    type: IntegrationType.TRELLO,
    toggle: onFinish,
  });

  const [loading, setLoading] = useState(false);
  const [boardOptions, setBoardOptions] = useState<Option[]>([]);
  const [listOptions, setListOptions] = useState<Option[]>([]);
  const { formState, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: initialFormState,
    onSubmit: handleConnect,
    validators: {},
  });

  const { boardOption, forwardingOption, listOption } = formState;

  async function handleConnect() {
    setLoading(true);
    try {
      if (!integrationInfo) throw new Error();

      const TrelloBoardInfo = JSON.parse(boardOption.value) as BoardOption;

      const payload: TrelloIntegrationUpdatePayload = {
        automatic_forward: !!forwardingOption.value,
        integration_id: TrelloBoardInfo.integrationId,
        board_id: TrelloBoardInfo.boardId,
        list_id: listOption.value,
      };

      const endpoint = !!integration ? updateIntegration : integrate;

      endpoint<Trello>(payload);
    } catch (error) {
      displayToast({ title: "Something went wrong", content: error.response.data.message });
    } finally {
      setLoading(false);
      onFinish();
    }
  }

  useEffect(() => {
    if (!integrationInfo) return;

    if (!integrationInfo.length) {
      setBoardOptions([]);
      setListOptions([]);
      handleFormChange("boardOption", null);
      handleFormChange("listOption", { value: "", label: "/" });
      return;
    }

    const newBoardOptions: Option[] = integrationInfo.flatMap((info) => {
      return info.boards.map((boardInfo) => {
        const spaceOption: BoardOption = {
          name: boardInfo.name,
          boardId: boardInfo.id,
          integrationId: boardInfo.integration_id,
        };
        return { value: JSON.stringify(spaceOption), label: boardInfo.name };
      });
    });

    setBoardOptions([...newBoardOptions]);

    const preSelectedSpace = integration
      ? newBoardOptions.find((option) => {
          const boardOption = JSON.parse(option.value) as BoardOption;
          return boardOption.boardId === integration.board_id;
        }) || null
      : null;

    handleFormChange("boardOption", preSelectedSpace);

    const preselectedForwardingOption = integration
      ? integration.automatic_forward
        ? automaticForwardOption
        : manualForwardOption
      : automaticForwardOption;

    handleFormChange("forwardingOption", preselectedForwardingOption);
  }, [integrationInfo, handleFormChange, integration]);

  useEffect(() => {
    if (!integrationInfo) return;

    if (boardOption === null) {
      handleFormChange("listOption", null);
      return;
    }

    const selectedBoardOption = JSON.parse(boardOption.value) as BoardOption;

    const allBoards = integrationInfo.flatMap((info) => {
      return info.boards.map((boardInfo) => {
        return boardInfo;
      });
    });

    const listsForSelectedBoardOption = allBoards
      .find((board) => board.id === selectedBoardOption.boardId)
      ?.lists.map(({ id, name }) => {
        return { value: id, label: name };
      });

    listsForSelectedBoardOption && setListOptions(listsForSelectedBoardOption);

    if (!listsForSelectedBoardOption) return;

    const preSelectedList = integration
      ? listsForSelectedBoardOption.find((listOption) => {
          return integration.list_id === listOption.value;
        }) || listsForSelectedBoardOption[0]
      : listsForSelectedBoardOption[0];

    preSelectedList && handleFormChange("listOption", preSelectedList);
  }, [boardOption, integrationInfo, handleFormChange, integration]);

  const disabled = !integrationInfo;

  return {
    disabled,
    loading,
    boardOptions,
    boardOption,
    listOption,
    listOptions,
    forwardingOption,
    handleFormChange,
    handleFormSubmit,
  };
};
