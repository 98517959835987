import styled from "styled-components";

export const CopyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: calc(50% - 14px);
  left: 0;

  height: 100%;

  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out, bottom 200ms ease-in-out;
`;

export const CopyButton = styled.button`
  background-color: rgba(0, 0, 0, 0.3);

  padding: 0.4rem 0.6rem;
  border-radius: 0.8rem;
  font-weight: bold;
  border: 0;
  color: white;
  height: 2.8rem;
`;
