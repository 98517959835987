import styled from "styled-components";

export const HeaderWrap = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .align-left {
    position: absolute;
    left: 0;
    top: -1.4rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
`;
